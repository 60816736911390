import { Box } from "@chakra-ui/react";
import {
	DashboardCardHeader,
	InfiniteScroll,
	Loader,
} from "../../../../common/components";
import { SlimVacancyApplication } from "../../types";
import { ApplicationItem } from "../../components";
import { ApplicationsPlaceholder } from "../../components/placeholders";

interface IApplicationCardProps {
	loading: boolean;
	items: SlimVacancyApplication[];
	loadMoreApplications: () => void;
	loadMoreLoading: boolean;
	totalCount: number;
	setApplicationIdToShow: (value: number) => void;
}

export const ApplicationsCard: React.FC<IApplicationCardProps> = ({
	items,
	loadMoreLoading,
	loading,
	totalCount,
	loadMoreApplications,
	setApplicationIdToShow,
}) => (
	<Box
		p={{ base: "10px 0", lg: "30px 40px 10px 40px" }}
		bg={{ base: "transparent", lg: "#FFFFFF" }}
		borderRadius="16px"
		boxShadow={{ base: "none", lg: "base" }}>
		<DashboardCardHeader buttonTitle="" title="Applications" />
		<Box height="calc(100vh - 250px)">
			{loading ? (
				<Loader centerHeight="100%" />
			) : (
				<>
					{items.length ? (
						<InfiniteScroll
							onReachBottom={() => {
								if (
									!loadMoreLoading &&
									totalCount > items.length
								) {
									loadMoreApplications();
								}
							}}>
							{items.map((application) => (
								<ApplicationItem
									key={application.id}
									onShowMore={() =>
										setApplicationIdToShow(application.id)
									}
									{...application}
								/>
							))}
							{loadMoreLoading && <Loader spinnerSize="md" />}
						</InfiniteScroll>
					) : (
						<ApplicationsPlaceholder />
					)}
				</>
			)}
		</Box>
	</Box>
);
