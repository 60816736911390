import { Box, Flex, Text, Avatar, Button } from "@chakra-ui/react";
import { ReactComponent as MessageIcon } from "../../../assets/ic_message.svg";
import { ParentResponseType } from "../../Dashboard/types";
import { getPictureURL } from "../../../common/utils";

interface IParentItemProps extends ParentResponseType {
	setShowSendMessageModal: () => void;
}

export const ParentItem: React.FC<IParentItemProps> = ({
	email,
	firstName,
	image,
	lastName,
	// students,
	// userId,
	setShowSendMessageModal,
}) => {
	return (
		<Flex
			justify="space-between"
			borderBottom="1px solid #EBEBEB"
			pt="16px"
			pb={{ base: 0, md: "16px" }}
			direction={{ base: "column", md: "row" }}>
			<Flex>
				<Avatar
					w="48px"
					h="48px"
					name={`${firstName} ${lastName}`}
					src={getPictureURL(image)}
					borderRadius="8px"
					mr="16px"
				/>
				<Box>
					<Text variant="body1" color="darkest">
						{`${firstName} ${lastName}`}
					</Text>
					<Text variant="body3" color="grey">
						{email}
					</Text>
				</Box>
			</Flex>
			<Button
				leftIcon={<MessageIcon />}
				variant="plain"
				color="darkGrey"
				alignSelf={{ base: "flex-start", lg: "center" }}
				onClick={() => {
					setShowSendMessageModal();
				}}>
				Send Message
			</Button>
		</Flex>
	);
};
