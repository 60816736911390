import { Tag, Text } from "@chakra-ui/react";

type IProps = {
	onSelectedClass: () => void;
	selected: boolean;
	value: string;
};

export const CustomTag: React.FC<IProps> = ({
	onSelectedClass,
	selected,
	value,
}) => (
	<Tag
		onClick={onSelectedClass}
		border={selected ? "1px solid #937DDB" : ""}
		// _focus={{outline:"none"}}
		as={"button"}
		bg={selected ? "rgba(243, 236, 253,.7)" : "#ECFBFD"}
		borderRadius={"full"}
		variant={"solid"}
		color={selected ? "primary" : "gray"}
		py={"5px"}
		px={"10px"}>
		<Text variant={"caption3"}>{value}</Text>
	</Tag>
);
