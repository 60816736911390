import { Box } from "@chakra-ui/react";
import {
	DashboardCardHeader,
	InfiniteScroll,
	Loader,
} from "../../../common/components";
import { ClassItem } from "../components";
import { SubscriptionPlan } from "../../../common/types";
import { SelectedClass } from "../types";
import { ClassesPlaceholder } from "../components/placeholders";

interface IClassesCard {
	items: SubscriptionPlan[];
	loading: boolean;
	selectedClass: SelectedClass;
	setSelectedClass: (value: SelectedClass) => void;
}

export const ClassesCard: React.FC<IClassesCard> = ({
	items,
	loading,
	selectedClass,
	setSelectedClass,
}) => {
	return (
		<Box
			p="30px 40px 0 40px"
			bg="white"
			borderRadius="16px"
			boxShadow="base"
			display={{ base: "none", lg: "block" }}>
			<DashboardCardHeader title="Classes" />
			<Box py="20px" height="calc(100vh - 230px)">
				{loading ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						{items.length ? (
							<InfiniteScroll height="100%">
								<ClassItem
									active={selectedClass === "all"}
									onClick={() => setSelectedClass("all")}
									name="All"
									image={{
										id: 0,
										extension: null,
										itemHash: null,
									}}
								/>
								<ClassItem
									active={selectedClass === "archived"}
									onClick={() => setSelectedClass("archived")}
									name="Archived"
									image={{
										id: 0,
										extension: null,
										itemHash: null,
									}}
								/>
								{items.map((classItem) => (
									<ClassItem
										key={classItem.id}
										active={selectedClass === classItem.id}
										startDate={classItem.startDate}
										endDate={classItem.endDate}
										image={classItem.image}
										name={classItem.name}
										onClick={() =>
											setSelectedClass(classItem.id)
										}
									/>
								))}
							</InfiniteScroll>
						) : (
							<ClassesPlaceholder />
						)}
					</>
				)}
			</Box>
		</Box>
	);
};
