import { IResponseWithoutValue } from "../../../../common/types";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
	sendBulkMessageRequest,
	sendBulkMessageSuccess,
	sendBulkMessageError,
	sendIndividualMessageRequest,
	sendIndividualMessageSuccess,
	sendIndividualMessageError,
} from "../actions";
import { Alerter } from "../../../../common/utils";

function* workerSendBulkMessage(
	action: ReturnType<typeof sendBulkMessageRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/messages/send-marketing-bulk",
			action.payload,
		);
		if (result.success) {
			yield put(sendBulkMessageSuccess());
			Alerter.success("Message(s) successfully sent");
		} else {
			yield put(sendBulkMessageError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerSendIndividualMessage(
	action: ReturnType<typeof sendIndividualMessageRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/messages/send-marketing",
			action.payload,
		);
		if (result.success) {
			yield put(sendIndividualMessageSuccess());
			Alerter.success("Message(s) successfully sent");
		} else {
			yield put(sendIndividualMessageError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchMessagesBroadcasrSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	>  {
	yield takeEvery(sendBulkMessageRequest.type, workerSendBulkMessage);
	yield takeEvery(sendIndividualMessageRequest.type, workerSendIndividualMessage);
}
