import { createAction } from "@reduxjs/toolkit";
import { StudentNamedSubscriptionType } from "../../types";
import { PayloadError } from "../../../../common/types";

export const getPlanSubscriptionsRequest = createAction<number>(
	"@subscription/GET_PLAN_NAMED_SUBSCRIPTIONS_REQUEST",
);
export const getPlanSubscriptionsSuccess = createAction<{
	subscriptions: StudentNamedSubscriptionType[];
	totalCount: number;
}>("@subscription/GET_PLAN_NAMED_SUBSCRIPTIONS_SUCCESS");
export const getPlanSubscriptionsError = createAction<PayloadError[]>(
	"@subscription/GET_PLAN_NAMED_SUBSCRIPTIONS_ERROR",
);

export const getMorePlanSubscriptionsRequest = createAction<number>(
	"@subscription/GET_MORE_PLAN_NAMED_SUBSCRIPTIONS_REQUEST",
);
export const getMorePlanSubscriptionsSuccess = createAction<{
	subscriptions: StudentNamedSubscriptionType[];
	totalCount: number;
}>("@subscription/GET_MORE_PLAN_NAMED_SUBSCRIPTIONS_SUCCESS");
export const getMorePlanSubscriptionsError = createAction<PayloadError[]>(
	"@subscription/GET_MORE_PLAN_NAMED_SUBSCRIPTIONS_ERROR",
);
