import {
	Flex,
	Box,
	Text,
	HStack,
	Heading,
	useBreakpointValue,
} from "@chakra-ui/react";
import { NotificationsPopover } from "../NotificationsPopover/NotificationsPopover";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux";

interface IDashboardHeaderProps {
	title: string;
	description: string;
}

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
	description,
	title,
}) => {
	const {
		firstName,
		lastName,
		roles = [""],
	} = useSelector((state: RootStore) => state.session);

	const belowDesktop = useBreakpointValue({
		base: true,
		lg: false,
	});

	return (
		<Box w="100%" h="200px" bgGradient="linear(to-r, #5E72E4, #825EE4)">
			<Flex
				px={{ base: "20px", lg: "40px" }}
				justify="space-between"
				align="center"
				h="75%">
				<Box>
					<Heading
						variant={belowDesktop ? "h4" : "h3"}
						as={belowDesktop ? "h4" : "h3"}
						color="white">
						{title}
					</Heading>
					<Text
						variant="body2"
						color="white"
						display={{ base: "none", lg: "block" }}>
						{description}
					</Text>
				</Box>
				<HStack>
					<Box mr="20px" display={{ base: "none", lg: "block" }}>
						<Heading as="h6" variant="h6" color="white">
							{`${firstName} ${lastName}`}
						</Heading>
						<Text variant="body2" color="white" textAlign="right">
							{roles[0].charAt(0).toUpperCase() +
								roles[0].slice(1)}
						</Text>
					</Box>

					<NotificationsPopover name={`${firstName} ${lastName}`} />
				</HStack>
			</Flex>
		</Box>
	);
};
