import { Box, Text, Flex, Avatar } from "@chakra-ui/react";
import { SubscriptionPlan, ImageType } from "../../../common/types";
import { getPictureURL } from "../../../common/utils";
import { SelectedFilters } from "../../../pages/Dashboard/types";

interface ILevelsStripProps {
	items: SubscriptionPlan[];
	setSelectedFilters: (value: SelectedFilters) => void;
	selectedFilters: SelectedFilters;
}

interface ILevelItemProps {
	name: string;
	image: ImageType;
	active: boolean;
	onClick: () => void;
}

const LevelItem: React.FC<ILevelItemProps> = ({
	image,
	name,
	active,
	onClick,
}) => {
	return (
		<Flex
			border={`1px solid ${active ? "#8c4bc9" : "#F3ECFD"}`}
			borderRadius="8px"
			// w="190px"
			p="8px 16px"
			mr="20px"
			shrink={0}
			as="button"
			onClick={onClick}
			height="min-content">
			<Avatar
				name={name}
				w="24px"
				h="24px"
				borderRadius="50%"
				src={getPictureURL(image)}
				sx={{
					div: {
						fontSize: "14px",
					},
				}}
			/>
			<Text
				variant="body3"
				color={active ? "primary" : "darkest"}
				ml="16px"
				noOfLines={1}>
				{name}
			</Text>
		</Flex>
	);
};

export const LevelsStrip: React.FC<ILevelsStripProps> = ({
	items,
	selectedFilters,
	setSelectedFilters,
}) => {
	return (
		<Box position="relative" my="24px" h="42px">
			<Flex
				w={{ base: "90vw", lg: "auto" }}
				pr="100px"
				overflowY="hidden"
				overflowX="scroll"
				sx={{
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}>
				<LevelItem
					name="All"
					image={{ extension: null, id: 0, itemHash: null }}
					active={selectedFilters === "all"}
					onClick={() => setSelectedFilters("all")}
				/>
				<LevelItem
					name="Archived"
					image={{ extension: null, id: 0, itemHash: null }}
					active={selectedFilters === "archived"}
					onClick={() => setSelectedFilters("archived")}
				/>
				{items.map((plan) => (
					<LevelItem
						name={plan.name}
						key={plan.id}
						image={plan.image}
						active={selectedFilters === plan.id}
						onClick={() => setSelectedFilters(plan.id)}
					/>
				))}
			</Flex>
			<Box
				bgGradient="linear(to-r, transparent, white)"
				w="150px"
				h="42px"
				position="absolute"
				right="0"
				top="0px"
				display={{ base: "none", lg: "block" }}
			/>
		</Box>
	);
};
