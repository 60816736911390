import { History } from "history";
import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import sessionReducer from "./session.reducer";
import authReducer from "./auth.reducer";
import subscriptionReducer from "../../pages/Subscriptions/store/reducers";
import classesReducer from "./classes.reducer";
// import dashboardReducer from "../../pages/Dashboard/store/reducer";
import attendanceReducer from "../../pages/Attendance/store/reducer";
import settingsReducer from "../../pages/Settings/store/reducer";
import dashboardReducer from "../../pages/Dashboard/store/reducers/root.reducer";
import teacherAttendanceReducer from "../../pages/TeacherAttendance/store/reducer";
import messagesReducer from "../../pages/Messages/store/reducers";
import directoriesReducer from "./directories";
import jobsReducer from "../../pages/Jobs/store/reducers";
import manualInvoicesReducer from "./manual-invoices.reducer";

const rootReducer = (history: History): Reducer =>
	combineReducers({
		router: connectRouter(history),
		auth: authReducer,
		session: sessionReducer,
		directories: directoriesReducer,
		subscription: subscriptionReducer,
		classes: classesReducer,
		dashboard: dashboardReducer,
		attendance: attendanceReducer,
		settings: settingsReducer,
		teacherAttendance: teacherAttendanceReducer,
		messages: messagesReducer,
		jobs: jobsReducer,
		manualInvoices: manualInvoicesReducer,
	});

export default rootReducer;
