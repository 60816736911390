import { Flex, Box, Text } from "@chakra-ui/react";
import { RippleButton } from "../../../../../common/components";

export const Footer: React.FC<{ onPublish: () => void }> = ({ onPublish }) => (
	<Flex
		align="center"
		justifyContent={{ base: "", md: "space-between" }}
		mt="10px">
		<Box w="48%" display={{ base: "none", md: "block" }}>
			<Text variant="caption3" color="darkest">
				Once published, parents will be able to see the level and
				subscribe <br />
			</Text>
		</Box>
		<Box w={{ base: "100%", md: "48%" }}>
			<RippleButton w="100%" type="submit" onClick={onPublish}>
				Publish
			</RippleButton>
		</Box>
	</Flex>
);
