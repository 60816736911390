import { useEffect } from "react";
import {
	Modal,
	RippleButton,
	InputText,
	InputSelect,
	InputEditor,
	InputPrice,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { useForm, Resolver, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { createVacancySchema } from "../../../../common/constants/validation";
import { PositionTeamType } from "../../../../common/types";
import { CreateJobPositionPayload } from "../../types";
import sanitizeHtml from "sanitize-html";

interface ICreateVacancyModalProps {
	handleClose: () => void;
	isOpen: boolean;
	setToPublishVacancy: (value: CreateJobPositionPayload) => void;
}

export interface ICreateVacancyForm {
	title: string;
	teamId: string;
	location: string;
	commitmentId: string;
	description: string;
	payRate: string;
}

export const CreateVacancyModal: React.FC<ICreateVacancyModalProps> = ({
	handleClose,
	isOpen,
	setToPublishVacancy,
}) => {
	const positionCommitments = useSelector(
		(state: RootStore) => state.directories.positionCommitments.items,
	);
	const positionTeams = useSelector(
		(state: RootStore) => state.directories.positionTeams.items,
	);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control,
		setValue,
	} = useForm<ICreateVacancyForm>({
		resolver: createVacancySchema as Resolver<ICreateVacancyForm>,
	});

	const onSubmit = (data: ICreateVacancyForm) => {
		setToPublishVacancy({
			...data,
			teamId: +data.teamId,
			commitmentId: +data.commitmentId,
			description: sanitizeHtml(data.description),
			payRate: parseFloat(data.payRate.replace("$", "")),
		});
	};

	useEffect(() => {
		if (!isOpen) {
			reset({});
		}
	}, [isOpen]);

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Create Vacancy">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Box>
						<InputText
							{...register("title")}
							label="Vacancy Name"
							placeholder="Vacancy Name"
							errorMsg={errors.title?.message}
						/>
						<InputText
							{...register("location")}
							label="Location"
							placeholder="Location"
							errorMsg={errors.location?.message}
						/>
						<Flex direction={{ base: "column", lg: "row" }}>
							<InputSelect
								{...register("teamId")}
								label="Job Position"
								options={positionTeams.map(
									(elem: PositionTeamType) => ({
										label: elem.name,
										value: elem.id,
									}),
								)}
								// errorMsg={errors.positionName?.message}
							/>
							<Box w="20px" />
							<InputSelect
								{...register("commitmentId")}
								label="Job Type"
								options={positionCommitments.map(
									(elem: PositionTeamType) => ({
										label: elem.name,
										value: elem.id,
									}),
								)}
							/>
						</Flex>
						<InputPrice
							label="Pay Rate"
							allowNegative={false}
							// fixedDecimalScale
							decimalScale={0}
							placeholder="Pay Rate"
							prefix="$"
							{...register("payRate")}
							errMessage={errors.payRate?.message}
						/>
						<Controller
							render={({ field }) => (
								<InputEditor
									{...field}
									onChange={(value) =>
										setValue("description", value)
									}
									label="Job Description"
									errorMsg={errors.description?.message}
									placeholder="Job Description"
								/>
							)}
							name="description"
							control={control}
						/>
					</Box>
				</ModalBody>
				<ModalFooter>
					<RippleButton
						variant="outline"
						width="100%"
						onClick={handleClose}>
						Cancel
					</RippleButton>
					<Box w="20px" />
					<RippleButton width="100%" type="submit">
						Publish
					</RippleButton>
				</ModalFooter>
			</form>
		</Modal>
	);
};
