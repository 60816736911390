import { Heading, Box, Center } from "@chakra-ui/react";
import { LoginPayload, PayloadError } from "../../common/types";
import { SubmitHandler } from "react-hook-form";
import { LoginForm } from "./components/Login.form";

interface ILoginProps {
	loading?: boolean;
	onSubmit: SubmitHandler<LoginPayload>;
	errors: PayloadError[];
	goToRestorePassword: () => void;
}

export const Login: React.FC<ILoginProps> = ({
	errors,
	onSubmit,
	loading,
	goToRestorePassword,
}) => {
	return (
		<Center h="100vh">
			<Box w="500px" px={{ base: "20px", lg: 0 }}>
				<Heading
					as="h4"
					variant="h4"
					textAlign="center"
					mb="20px"
					color="darkest">
					Login
				</Heading>
				<LoginForm
					loading={loading}
					onSubmit={onSubmit}
					stateErrors={errors}
					goToRestorePassword={goToRestorePassword}
				/>
			</Box>
		</Center>
	);
};
