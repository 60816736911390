/* eslint-disable indent */
import { useState, useEffect } from "react";
import {
	Modal,
	InputText,
	InputTime,
	DatePicker,
	TypeAhead,
	InputEditor,
} from "../../../../common/components";
import {
	ModalBody,
	Box,
	ModalFooter,
	Checkbox,
	Text,
	Flex,
	HStack,
	Button,
} from "@chakra-ui/react";
import { ICChek } from "../../../../assets/svg-components";
import { useForm, Resolver, Controller } from "react-hook-form";
import { sendMessageSchema } from "../../../../common/constants/validation";
import { useDebounce } from "../../../../common/utils";
import API from "../../../../common/api/api.base";
import dayjs from "dayjs";
import { SendMessageTo, SendIndividualMessagePayload } from "../../types";

interface ISendMessageModalProps {
	handleClose: () => void;
	isOpen: boolean;
	sendMessageTo: SendMessageTo;
	onSend: (value: {
		type: string;
		payload: SendIndividualMessagePayload;
		recipients?: string[];
	}) => void;
}

interface ISendMessageForm {
	senderName: string;
	recipientName: string[];
	messageText: string;
	sendingDate: number;
	sendingTime: string;
	sendImmediately: boolean;
}

export const SendMessageModal: React.FC<ISendMessageModalProps> = ({
	handleClose,
	isOpen,
	sendMessageTo,
	onSend,
}) => {
	const [checkboxChecked, setCheckboxChecked] = useState(true);
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		clearErrors,
		getValues,
		reset,
		setError,
		control,
	} = useForm<ISendMessageForm>({
		resolver: sendMessageSchema as Resolver<ISendMessageForm>,
	});

	const [searchQuery, setSearchQuery] = useState("");
	const [typeAheadItems, setTypeAheadItems] = useState([]);
	const [defaultSelectedRecipient, setDefaultSelectedRecipient] = useState<
		undefined | { value: string; label: string }
	>(undefined);
	const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
	const [typeaheadLoading, setTypeaheadLoading] = useState(false);

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchTerm) {
			(async function () {
				try {
					setTypeaheadLoading(true);
					const result = await API.get(
						`/api/v1/users?PageSize=10&StaffRole=${
							sendMessageTo.type === "parent" ? 2 : 1
						}${
							debouncedSearchTerm
								? `&SearchQuery=${debouncedSearchTerm}`
								: ""
						}`,
					);
					if (result.success) {
						setTypeAheadItems(
							result.value.users.map((user: any) => ({
								label: `${user.firstName} ${user.lastName}`,
								value: user.userId,
							})),
						);
					}
				} catch (error) {
					console.error(error);
				} finally {
					setTypeaheadLoading(false);
				}
			})();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (sendMessageTo.id) {
			setDefaultSelectedRecipient({
				value: sendMessageTo.id,
				label: sendMessageTo.name,
			});
			setValue("recipientName", [sendMessageTo.id]);
			setSelectedMembers([sendMessageTo.name]);
			reset({ recipientName: [sendMessageTo.id] });
		} else {
			setDefaultSelectedRecipient(undefined);
			setValue("recipientName", []);
			setSelectedMembers([]);
		}
	}, [sendMessageTo]);

	useEffect(() => {
		if (!isOpen) {
			reset({});
			setDefaultSelectedRecipient(undefined);
			setValue("recipientName", []);
			setSelectedMembers([]);
		} else {
			reset({ sendImmediately: true, recipientName: [sendMessageTo.id] });
		}
	}, [isOpen]);

	const onSubmit = (data: ISendMessageForm) => {
		if (selectedMembers.length) {
			onSend({
				type: "individual",
				payload: {
					messageText: data.messageText,
					sendImmediately: checkboxChecked,
					senderName: data.senderName,
					sendAtUtc: checkboxChecked
						? undefined
						: dayjs
								.utc(
									`${dayjs
										.utc(data.sendingDate)
										.format("DD MMMM, YYYY")} ${
										data.sendingTime
									}`,
								)
								.format(),
					userIds: data.recipientName,
				},
				recipients: selectedMembers,
			});
		} else {
			setError("recipientName", {
				message: "This field is required",
				type: "manual",
			});
		}
	};

	const [sendDate] = getValues(["sendingDate"]);

	return (
		<Modal isOpen={isOpen} handleClose={handleClose} title="Send Message">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Box>
						<InputText
							{...register("senderName")}
							label="Sender Name"
							placeholder="Sender Name"
							errorMsg={errors.senderName?.message}
						/>
						<TypeAhead
							label="Recipient Name"
							placeholder="Recipient Name"
							items={typeAheadItems}
							onStateChange={(props) => {
								setValue(
									"recipientName",
									props.selectedItems?.map(
										(elem) => elem.value,
									) || [],
								);
								setSelectedMembers(
									props.selectedItems?.map(
										(elem) => elem.label,
									) || [],
								);
								reset({
									...getValues(),
									recipientName:
										props.selectedItems?.map(
											(elem) => elem.value,
										) || [],
								});
							}}
							defaultSelectedItems={
								defaultSelectedRecipient
									? [defaultSelectedRecipient]
									: undefined
							}
							onInputChange={(value: string) =>
								setSearchQuery(value)
							}
							typeaheadLoading={typeaheadLoading}
							errorMsg={(errors.recipientName as any)?.message}
						/>
						<Controller
							render={({ field }) => (
								<InputEditor
									{...field}
									onChange={(value) =>
										setValue("messageText", value)
									}
									label="Message Text"
									errorMsg={errors.messageText?.message}
									placeholder="Message Text"
								/>
							)}
							name="messageText"
							control={control}
						/>
						<Checkbox
							isChecked={checkboxChecked}
							sx={{
								".chakra-checkbox__control": {
									borderRadius: "50%",
									border: "1px solid #8C4BC9",
								},
							}}
							icon={<ICChek enabled={checkboxChecked} />}
							mb="29px"
							onChange={(ev) => {
								setCheckboxChecked(ev.target.checked);
								setValue("sendImmediately", ev.target.checked);
							}}>
							<Text variant="body2" color="darkest">
								Send Now
							</Text>
						</Checkbox>
						<Flex direction={{ base: "column", md: "row" }}>
							<DatePicker
								{...register("sendingDate")}
								label="Sending Date"
								value={sendDate}
								displayValue={
									sendDate
										? dayjs
												.utc(sendDate)
												.format("DD MMMM, YYYY")
										: ""
								}
								setValue={(value: number) => {
									setValue("sendingDate", value);
									clearErrors("sendingDate");
								}}
								isDisabled={checkboxChecked}
								placeholder="Sending Date"
								errMessage={errors.sendingDate?.message}
							/>
							<Box w="20px" />
							<InputTime
								{...register("sendingTime")}
								name="sendingTime"
								isDisabled={checkboxChecked}
								label="Sending Time"
								errorMsg={errors.sendingTime?.message}
							/>
						</Flex>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack borderTop="1px solid #EBEBEB" w="100%" pt="16px">
						<Button
							w="100%"
							variant="outline"
							onClick={handleClose}>
							Cancel
						</Button>
						<Button w="100%" type="submit">
							Send
						</Button>
					</HStack>
				</ModalFooter>
			</form>
		</Modal>
	);
};
