import { createAction } from "@reduxjs/toolkit";
import { MessageHistoryItemType } from "../../types";
import { PayloadError } from "../../../../common/types";

export const getMessagesHistoryRequest = createAction<{
	sortByAscending: boolean;
}>("@messages/GET_HISTORY_REQUEST");
export const getMessagesHistorySuccess = createAction<{
	totalCount: number;
	messages: MessageHistoryItemType[];
}>("@messages/GET_HISTORY_SUCCESS");
export const getMessagesHistoryError = createAction<PayloadError[]>(
	"@messages/GET_HISTORY_ERROR",
);

export const getMoreMessagesHistoryRequest = createAction<{
	sortByAscending: boolean;
}>("@messages/GET_MORE_HISTORY_REQUEST");
export const getMoreMessagesHistorySuccess = createAction<{
	totalCount: number;
	messages: MessageHistoryItemType[];
}>("@messages/GET_MORE_HISTORY_SUCCESS");
export const getMoreMessagesHistoryError = createAction<PayloadError[]>(
	"@messages/GET_MORE_HISTORY_ERROR",
);
