import { Box, Text, Button, Flex, Avatar } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from "../../../assets/ic_edit.svg";
import { StaffItem as StaffItemType } from "../types";
import { getPictureURL } from "../../../common/utils/getPictureURL";

interface IStaffItemProps extends StaffItemType {
	setShowEditEmployeeModal: (value: boolean) => void;
	setToEditEmployee: (value: StaffItemType) => void;
}

export const StaffItem: React.FC<IStaffItemProps> = ({
	setShowEditEmployeeModal,
	email,
	firstName,
	image,
	lastName,
	positionName,
	staffRole,
	userId,
	setToEditEmployee,
}) => (
	<Flex justify="space-between" py="8px">
		<Flex align="center">
			<Avatar
				w="48px"
				h="48px"
				borderRadius="50%"
				mr="16px"
				name={`${firstName} ${lastName}`}
				src={getPictureURL(image)}
			/>
			<Box>
				<Text variant="body1" color="darkest">
					{`${firstName} ${lastName}`}
				</Text>
				<Text variant="body3" color="grey">
					{positionName}
				</Text>
			</Box>
		</Flex>
		<Button
			leftIcon={<EditIcon />}
			variant="plain"
			color="primary"
			onClick={() => {
				setShowEditEmployeeModal(true);
				setToEditEmployee({
					email,
					firstName,
					image,
					lastName,
					positionName,
					staffRole,
					userId,
				});
			}}>
			Edit
		</Button>
	</Flex>
);
