import { RootStore } from "./../../../redux/index";
import { quantity } from "./../../../common/constants/quantity";
import { call, put, takeEvery, ForkEffect, select } from "redux-saga/effects";
import {
	getTeacherAttendanceStudentsRequest,
	getTeacherAttendanceStudentsSuccess,
	getTeacherAttendanceStudentsError,
	getTeacherAttendanceClassesRequest,
	getTeacherAttendanceClassesSuccess,
	getTeacherAttendanceClassesError,
	setStudentAttendanceValueRequest,
	setStudentAttendanceValueSuccess,
	// setStudentAttendanceValueError,
	getMoreTeacherAttendanceStudentsRequest,
	getMoreTeacherAttendanceStudentsSuccess,
	getMoreTeacherAttendanceStudentsError,
} from "./actions";
import { Alerter } from "../../../common/utils";
import {
	SubscriptionPlan,
	IResponseWithCustomValue,
	IResponseWithoutValue,
} from "../../../common/types";
import API from "../../../common/api/api.base";
import { TeacherAttendanceType } from "../types";

function* workerTeacherAttendanceGetStudents(
	action: ReturnType<typeof getTeacherAttendanceStudentsRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			attendance: TeacherAttendanceType[];
		}> = yield call(
			API.get,
			`/api/v1/attendance/teacher?UtcDate=${action.payload.utcDate}${
				action.payload.subscriptionPlanId
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}&PageSize=${quantity.TEACHER_ATTENDANCE_STUDENTS_TAKE}`,
		);
		if (result.success) {
			yield put(getTeacherAttendanceStudentsSuccess(result.value));
		} else {
			yield put(getTeacherAttendanceStudentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerTeacherAttendanceGetMoreStudents(
	action: ReturnType<typeof getMoreTeacherAttendanceStudentsRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.teacherAttendance.students.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			attendance: TeacherAttendanceType[];
		}> = yield call(
			API.get,
			`/api/v1/attendance/teacher?UtcDate=${action.payload.utcDate}&SubscriptionPlanId=${action.payload.subscriptionPlanId}&PageSize=${quantity.TEACHER_ATTENDANCE_STUDENTS_TAKE}&PageNumber=${page}`,
		);
		if (result.success) {
			yield put(getMoreTeacherAttendanceStudentsSuccess(result.value));
		} else {
			yield put(getMoreTeacherAttendanceStudentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerTeacherAttendanceGetClasses() {
	try {
		const result: IResponseWithCustomValue<{
			subscriptionPlans: SubscriptionPlan[];
		}> = yield call(API.get, "/api/v1/plans");
		if (result.success) {
			yield put(
				getTeacherAttendanceClassesSuccess(
					result.value.subscriptionPlans,
				),
			);
		} else {
			yield put(getTeacherAttendanceClassesError());
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerSetStudentAttendanceValue(
	action: ReturnType<typeof setStudentAttendanceValueRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/attendance/update",
			action.payload,
		);
		if (result.success) {
			yield put(setStudentAttendanceValueSuccess(action.payload));
		} else {
			// yield put(setStudentAttendanceValueError());
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchTeacherAttendanceSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(
		getTeacherAttendanceStudentsRequest.type,
		workerTeacherAttendanceGetStudents,
	);
	yield takeEvery(
		getTeacherAttendanceClassesRequest.type,
		workerTeacherAttendanceGetClasses,
	);
	yield takeEvery(
		setStudentAttendanceValueRequest.type,
		workerSetStudentAttendanceValue,
	);
	yield takeEvery(getMoreTeacherAttendanceStudentsRequest.type, workerTeacherAttendanceGetMoreStudents);
}
