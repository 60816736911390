import { quantity } from "./../../../../common/constants";
import { IResponseWithCustomValue } from "../../../../common/types";
import { call, ForkEffect, put, takeEvery, select } from "redux-saga/effects";
import { RootStore } from "../../../../redux";
import API from "../../../../common/api/api.base";
import {
	getMessagesHistoryRequest,
	getMessagesHistorySuccess,
	getMessagesHistoryError,
	getMoreMessagesHistoryRequest,
	getMoreMessagesHistorySuccess,
	getMoreMessagesHistoryError,
} from "../actions";
import { Alerter } from "../../../../common/utils";
import { MessageHistoryItemType } from "../../types";

function* workerGetMessagesHistory(
	action: ReturnType<typeof getMessagesHistoryRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			messages: MessageHistoryItemType[];
		}> = yield call(
			API.get,
			`/api/v1/messages?SortByAscending=${action.payload.sortByAscending}&PageNumber=1&PageSize=${quantity.MESSAGES_HISTORY_TAKE}`,
		);
		if (result.success) {
			yield put(getMessagesHistorySuccess(result.value));
		} else {
			yield put(getMessagesHistoryError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreMessagesHistory(
	action: ReturnType<typeof getMoreMessagesHistoryRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.messages.messagesHistory.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			messages: MessageHistoryItemType[];
		}> = yield call(
			API.get,
			`/api/v1/messages?SortByAscending=${action.payload.sortByAscending}&PageNumber=${page}&PageSize=${quantity.MESSAGES_HISTORY_TAKE}`,
		);
		if (result.success) {
			yield put(getMoreMessagesHistorySuccess(result.value));
		} else {
			yield put(getMoreMessagesHistoryError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchMessagesHistorySaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	>  {
	yield takeEvery(getMessagesHistoryRequest.type, workerGetMessagesHistory);
	yield takeEvery(getMoreMessagesHistoryRequest.type, workerGetMoreMessagesHistory);
}
