import { Box, Text, Button, Flex, Avatar } from "@chakra-ui/react";
import { ReactComponent as MessageIcon } from "../../../assets/ic_message.svg";
import { StaffItem as StaffItemType } from "../../Dashboard/types";
import { getPictureURL } from "../../../common/utils";

interface IStaffItemProps extends StaffItemType {
	setShowSendMessageModal: () => void;
}

export const StaffItem: React.FC<IStaffItemProps> = ({
	firstName,
	image,
	lastName,
	positionName,
	// email,
	// staffRole,
	// userId,
	setShowSendMessageModal,
}) => {
	return (
		<Flex
			justify="space-between"
			pt="8px"
			pb={{ base: 0, md: "8px" }}
			direction={{ base: "column", md: "row" }}
			borderBottom="1px solid #EBEBEB">
			<Flex align="center">
				<Avatar
					w="48px"
					h="48px"
					borderRadius="50%"
					mr="16px"
					name={`${firstName} ${lastName}`}
					src={getPictureURL(image)}
				/>
				<Box>
					<Text variant="body1" color="darkest">
						{`${firstName} ${lastName}`}
					</Text>
					<Text variant="body3" color="grey">
						{positionName}
					</Text>
				</Box>
			</Flex>
			<Button
				leftIcon={<MessageIcon />}
				variant="plain"
				color="darkGrey"
				mr="10px"
				alignSelf={{ base: "flex-start", lg: "center" }}
				onClick={() => setShowSendMessageModal()}>
				Send Message
			</Button>
		</Flex>
	);
};
