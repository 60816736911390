import React from "react";
import {
	FormControl,
	Flex,
	FormLabel,
	FormErrorMessage,
	FormControlProps,
	Select,
	SelectProps,
} from "@chakra-ui/react";
import { SelectArrowIcon } from "../../../assets/svg-components";

interface IInputSelectProps extends SelectProps {
	errorMsg?: string;
	label: string;
	formControlProps?: FormControlProps;
	removeBottomMargin?: boolean;
	optionalColor?: string;
	placeholder?: string;
	options?: {
		value: string;
		label: string;
		[key: string]: boolean | string;
	}[];
}

export const InputSelect = React.forwardRef<null, IInputSelectProps>(
	(
		{
			errorMsg,
			formControlProps,
			label,
			removeBottomMargin = false,
			options,
			optionalColor,
			placeholder,
			...rest
		},
		ref,
	) => (
		<FormControl
			isInvalid={!!errorMsg}
			{...formControlProps}
			mb={!errorMsg && !removeBottomMargin ? "29px" : 0}
			sx={{
				".chakra-select": {
					paddingInlineStart: "8px",
				},
			}}>
			<Flex align="center" justify="space-between">
				<FormLabel
					fontSize="caption2"
					lineHeight="22px"
					color="darkest"
					marginBottom={0}>
					{label}
				</FormLabel>
			</Flex>
			<Select
				variant="outline"
				borderRadius="8px"
				color={optionalColor ? optionalColor : "darkest"}
				borderColor="lightGrey"
				fontSize="14px"
				lineHeight="22px"
				focusBorderColor="primary"
				placeholder={placeholder}
				_placeholder={{
					color: "grey",
				}}
				icon={
					<SelectArrowIcon
						fill={optionalColor ? optionalColor : "#1E0E62"}
					/>
				}
				ref={ref}
				{...rest}>
				{options?.map((opt) => (
					<option {...opt} value={opt.value} key={opt.value}>
						{opt.label}
					</option>
				))}
			</Select>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	),
);

InputSelect.displayName = "InputSelect";
