import React from "react";
import { Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";

interface IInputMultiValueProps {
	value: string;
	handleItemRemove: (value: string) => void;
}

export const InputMultiValue: React.FC<IInputMultiValueProps> = (props) => {
	const { value, handleItemRemove } = props;
	return (
		<Tag
			border="1px solid #8C4BC9"
			borderRadius="8px"
			bg="purple"
			mr="10px">
			<TagLabel color="primary" fontSize="12px" lineHeight="22px">
				{value}
			</TagLabel>
			<TagCloseButton
				color="primary"
				opacity={1}
				onClick={(e) => {
					e.stopPropagation();
					handleItemRemove(value);
				}}
				aria-label="Remove menu selection badge"
			/>
		</Tag>
	);
};
