import { Box } from "@chakra-ui/react";
import { TabPlaceholder, StudentItem } from "../../components";
import { InfiniteScroll, Loader } from "../../../../common/components";
import { StudentResponseType } from "../../types";

interface IStudentsTabProps {
	studentsLoading: boolean;
	studentItems: StudentResponseType[];
	getMoreStudents: () => void;
	studentsLoadMoreLoading: boolean;
	studentsTotalCount: number;
	setShowStudentInfoModal: (value: boolean) => void;
	setStudentInfo: (value: StudentResponseType) => void;
}

export const StudentsTab: React.FC<IStudentsTabProps> = ({
	studentItems,
	studentsLoading,
	getMoreStudents,
	studentsLoadMoreLoading,
	setShowStudentInfoModal,
	studentsTotalCount,
	setStudentInfo,
}) => {
	return (
		<>
			{studentsLoading ? (
				<Loader centerHeight="calc(100vh - 390px)" />
			) : (
				<>
					{studentItems.length ? (
						<Box>
							<InfiniteScroll
								height="calc(100vh - 390px)"
								onReachBottom={() => {
									if (
										!studentsLoadMoreLoading &&
										studentsTotalCount > studentItems.length
									) {
										getMoreStudents();
									}
								}}>
								{studentItems.map((student) => (
									<StudentItem
										key={student.id}
										{...student}
										setShowStudentInfoModal={
											setShowStudentInfoModal
										}
										setStudentInfo={setStudentInfo}
									/>
								))}
								{studentsLoadMoreLoading && (
									<Loader
										spinnerSize="md"
										centerProps={{ py: "10px" }}
									/>
								)}
							</InfiniteScroll>
						</Box>
					) : (
						<TabPlaceholder />
					)}
				</>
			)}
		</>
	);
};
