import { useEffect } from "react";
import { ParentsTab } from "./ParentsTab";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import {
	getParentsForMessagesRequest,
	getMoreParentsForMessagesRequest,
} from "../../store/actions";
import { SelectedFilters } from "../../../Dashboard/types";
import { SendMessageTo } from "../../types";

interface IParentsTabContainerProps {
	setShowSendMessageModal: (value: boolean) => void;
	searchQuery: string;
	selectedFilters: SelectedFilters;
	setSendMessageTo: (value: SendMessageTo) => void;
}

export const ParentsTabContainer: React.FC<IParentsTabContainerProps> = ({
	setShowSendMessageModal,
	searchQuery,
	selectedFilters,
	setSendMessageTo,
}) => {
	const dispatch = useDispatch();

	const {
		items: parents,
		loading,
		loadMoreLoading,
		totalCount,
	} = useSelector((state: RootStore) => state.messages.parents);

	useEffect(() => {
		dispatch(
			getParentsForMessagesRequest({
				searchQuery,
				subscriptionPlanId: selectedFilters,
			}),
		);
	}, []);

	const getMoreParents = () =>
		dispatch(
			getMoreParentsForMessagesRequest({
				searchQuery,
				subscriptionPlanId: selectedFilters,
			}),
		);

	return (
		<>
			<ParentsTab
				setShowSendMessageModal={setShowSendMessageModal}
				parents={parents}
				loading={loading}
				getMoreParents={getMoreParents}
				loadMoreLoading={loadMoreLoading}
				totalCount={totalCount}
				setSendMessageTo={setSendMessageTo}
			/>
		</>
	);
};
