import { useState } from "react";
import {
	Modal,
	InputSelect,
	ModalCalendar,
	RippleButton,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { months } from "../../../../common/constants";
import dayjs from "dayjs";

interface ISelectWeekModal {
	handleClose: () => void;
	isOpen: boolean;
	setAfterDate: (value: string) => void;
	setBeforeDate: (value: string) => void;
	afterDate: string;
}

export const SelectWeekModal: React.FC<ISelectWeekModal> = ({
	handleClose,
	isOpen,
	setAfterDate,
	setBeforeDate,
	afterDate,
}) => {
	const [selectedMonth, setSelectedMonth] = useState<number>(
		dayjs.utc().month(),
	);
	const [selectedYear, setSelectedYear] = useState<number>(
		dayjs.utc().year(),
	);
	const [localWeek, setLocalWeek] = useState<[string, string]>(["", ""]);

	const getSelectedRange = () => {
		const range = [
			dayjs.utc(afterDate).valueOf() - dayjs().utcOffset() * 60000,
		];
		for (let i = 1; i < 7; i++) {
			range[i] = range[0] + 86400000 * i;
		}
		return range;
	};

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Select Week">
			<ModalBody>
				<Flex>
					<InputSelect
						label=""
						// placeholder="Month"
						options={months.map((month) => ({
							...month,
							value: month.value + "",
						}))}
						formControlProps={{ flex: 0.7 }}
						defaultValue={selectedMonth}
						onChange={(ev) => {
							setSelectedMonth(+ev.target.value);
						}}
					/>
					<Box w="20px" />
					<InputSelect
						label=""
						// placeholder="Year"
						formControlProps={{ flex: 0.3 }}
						options={Array(15)
							.fill("")
							.map((_elem, index) => ({
								value: 2014 + index + "",
								label: 2014 + index + "",
							}))}
						defaultValue={selectedYear}
						onChange={(ev) => {
							setSelectedYear(+ev.target.value);
						}}
					/>
				</Flex>
				<ModalCalendar
					mode="week"
					selectedMonth={selectedMonth}
					selectedYear={selectedYear}
					onChange={(start, end) => setLocalWeek([start, end || ""])}
					selectedValue={getSelectedRange()}
				/>
			</ModalBody>
			<ModalFooter>
				<RippleButton
					onClick={handleClose}
					variant="outline"
					width="100%">
					Cancel
				</RippleButton>
				<Box w="20px" />
				<RippleButton
					width="100%"
					onClick={() => {
						setAfterDate(localWeek[0]);
						setBeforeDate(localWeek[1]);
						handleClose();
					}}>
					Select
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
