import { useEffect } from "react";
import {
	Modal,
	RippleButton,
	InputText,
	InputTextArea,
	InputPhone,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../redux";
import { getApplicationByIdRequest } from "../../store/actions";

interface IApplicationInfoModalProps {
	handleClose: () => void;
	isOpen: boolean;
	applicationIdToShow: number;
	onCancel: () => void;
}

export const ApplicationInfoModal: React.FC<IApplicationInfoModalProps> = ({
	handleClose,
	isOpen,
	applicationIdToShow,
	onCancel,
}) => {
	const dispatch = useDispatch();
	const { loading, values } = useSelector(
		(state: RootStore) => state.jobs.applications.targetApplication,
	);

	useEffect(() => {
		if (applicationIdToShow) {
			dispatch(getApplicationByIdRequest(applicationIdToShow + ""));
		}
	}, [applicationIdToShow]);

	return (
		<Modal
			handleClose={handleClose}
			isOpen={isOpen}
			title="Application Info">
			<ModalBody>
				<Box>
					<Flex direction={{ base: "column", md: "row" }}>
						<InputText
							label="Full Name"
							placeholder="Full Name"
							isReadOnly={true}
							value={values.fullName}
						/>
					</Flex>
					<Flex direction={{ base: "column", md: "row" }}>
						<InputText
							label="Email"
							placeholder="Email"
							type="email"
							isReadOnly={true}
							value={values.email}
						/>
						<Box width="20px" flexShrink={0} />
						<InputPhone
							label="Phone Number"
							name="phone"
							onChange={() => "void"}
							value={values.phone}
							readOnly={true}
						/>
					</Flex>
					<InputText
						label="Current Company"
						placeholder="Current Company"
						isReadOnly={true}
						value={values?.currentCompany || ""}
					/>
					<Flex direction={{ base: "column", md: "row" }}>
						<InputText
							label="LinkedIn URL"
							placeholder="LinkedIn URL"
							isReadOnly={true}
							value={values?.linkedinUrl || ""}
						/>
						<Box width="20px" flexShrink={0} />
						<InputText
							label="Twitter URL"
							placeholder="Twitter URL"
							isReadOnly={true}
							value={values?.twitterUrl || ""}
						/>
					</Flex>
					<InputTextArea
						label="Additional Information"
						placeholder="Additional Information"
						isReadOnly={true}
						value={values?.additionalInfo || ""}
						h="100px"
						// errorMsg={errors.password?.message}
					/>
				</Box>
			</ModalBody>
			<ModalFooter>
				<RippleButton variant="outline" width="100%" onClick={onCancel}>
					Remove
				</RippleButton>
				<Box w="20px" />
				<RippleButton width="100%" onClick={handleClose}>
					Close
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
