/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from "react";
import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	IconButton,
	Avatar,
	Box,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";
import { ReactComponent as CloseIcon } from "../../../assets/ic_close.svg";
import { SubscriptionPlan, ImageType } from "../../../common/types";
import { getFormattedRange, getPictureURL } from "../../../common/utils";
import { RippleButton } from "../../../common/components";

type IAlert = {
	isOpen: boolean;
	handleClose: () => void;
	selectedClass: SubscriptionPlan;
	firstDescription: string;
	secondDescription: string;
	confirmLabel: string;
	title: string;
	onConfirm?: () => void;
	confirmLoading?: boolean;
	alertAvatar: null | string | ImageType;
};

export const Alert: React.FC<IAlert> = ({
	isOpen,
	handleClose,
	selectedClass,
	firstDescription,
	secondDescription,
	confirmLabel,
	title,
	onConfirm,
	confirmLoading,
	alertAvatar,
}) => {
	const cancelRef = React.useRef();

	const getAvatar = () => {
		if (typeof alertAvatar === "string") {
			return alertAvatar;
		} else if (alertAvatar?.itemHash) {
			return getPictureURL(alertAvatar);
		}
		return undefined;
	};

	return (
		<AlertDialog
			leastDestructiveRef={cancelRef.current}
			isOpen={isOpen}
			onClose={handleClose}
			isCentered
			motionPreset={"slideInBottom"}>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius={"16px"}>
					<Flex justify="space-between" align="center" pt="24px">
						<AlertDialogHeader py={0}>
							<Heading variant={"h6"}>{title}</Heading>
						</AlertDialogHeader>
						<IconButton
							aria-label={"close alert dialog"}
							icon={<CloseIcon />}
							onClick={handleClose}
							variant={"ghost"}
							mr={"1.5rem"}
						/>
					</Flex>
					<Box py={"24px"}>
						<AlertDialogBody>
							<Flex
								borderRadius={"16px"}
								border={"1px solid #F5F5F6"}
								p={"16px"}
								align={"center"}>
								{/*	Avatar*/}
								<Avatar
									src={getAvatar()}
									name={selectedClass.name}
									w={"48px"}
									h={"48px"}
									borderRadius={"50%"}
									mr={"16px"}
								/>
								{/*	Info Course*/}
								<Box mr={"auto"}>
									<Text variant={"body1"} color={"darkest"}>
										{selectedClass.name}
									</Text>
									<Text variant={"body3"}>
										{getFormattedRange(
											selectedClass.startDate,
											selectedClass.endDate,
										)}
									</Text>
								</Box>
								{/*	Price*/}
								<Text
									variant={"subtitle1"}
									color={"primary"}
									alignSelf={"flex-start"}>
									{selectedClass.price
										? `${selectedClass.price.toFixed(2)}`
										: "FREE"}
								</Text>
							</Flex>
							{/*Info Text1*/}
							<Box my={"24px"}>
								<Text variant={"body2"} color={"darkest"}>
									{firstDescription}
								</Text>
							</Box>
							{/*Info Text2*/}
							<Box mb={"24px"}>
								<Text variant={"inputText"} color={"darkgray"}>
									{secondDescription}
								</Text>
							</Box>
							{/*Buttons*/}
							<Flex justifyContent={"space-between"}>
								<RippleButton
									w={"48%"}
									variant={"outline"}
									onClick={handleClose}
									ref={cancelRef.current}>
									Cancel
								</RippleButton>
								<RippleButton
									w={"48%"}
									onClick={onConfirm}
									isLoading={confirmLoading}>
									{confirmLabel}
								</RippleButton>
							</Flex>
						</AlertDialogBody>
					</Box>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
