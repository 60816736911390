import { Box } from "@chakra-ui/react";
import { StaffItem } from "../../components";
import { StaffItem as StaffItemType } from "../../../Dashboard/types";
import { InfiniteScroll, Loader } from "../../../../common/components";
import { StaffPlaceholder } from "../../components/placeholders";
import { SendMessageTo } from "../../types";

interface IStaffTabProps {
	staffItems: StaffItemType[];
	loading: boolean;
	getMoreStaff: () => void;
	loadMoreLoading: boolean;
	totalCount: number;
	setSendMessageTo: (value: SendMessageTo) => void;
	setShowSendMessageModal: (value: boolean) => void;
}

export const StaffTab: React.FC<IStaffTabProps> = ({
	loading,
	staffItems,
	getMoreStaff,
	loadMoreLoading,
	totalCount,
	setSendMessageTo,
	setShowSendMessageModal,
}) => {
	return (
		<>
			{loading ? (
				<Loader centerHeight="calc(100vh - 390px)" />
			) : (
				<>
					{staffItems.length ? (
						<Box>
							<InfiniteScroll
								height="calc(100vh - 390px)"
								onReachBottom={() => {
									if (
										!loadMoreLoading &&
										totalCount > staffItems.length
									) {
										getMoreStaff();
									}
								}}>
								{staffItems.map((staffMember) => (
									<StaffItem
										key={staffMember.userId}
										setShowSendMessageModal={() => {
											setSendMessageTo({
												id: staffMember.userId + "",
												name: `${staffMember.firstName} ${staffMember.lastName}`,
												type: "staff",
											});
											setShowSendMessageModal(true);
										}}
										{...staffMember}
									/>
								))}
								{loadMoreLoading && (
									<Loader
										spinnerSize="md"
										centerProps={{ py: "10px" }}
									/>
								)}
							</InfiniteScroll>
						</Box>
					) : (
						<StaffPlaceholder />
					)}
				</>
			)}
		</>
	);
};
