import { createAction } from "@reduxjs/toolkit";
import { SubscriptionPlan, PayloadError } from "../../../common/types";
import { AttendanceResponse } from "../types";

export const getAttendanceClassesRequest = createAction<number>(
	"@attendance/GET_CLASSES_REQUEST",
);
export const getAttendanceClassesSuccess = createAction<SubscriptionPlan[]>(
	"@attendance/GET_CLASSES_SUCCESS",
);
export const getAttendanceClassesError = createAction<PayloadError[]>(
	"@attendance/GET_CLASSES_ERROR",
);

export const getAttendanceStudentsRequest = createAction<{
	subscriptionPlanId: number;
	afterDate: string;
	beforeDate: string;
}>("@attendance/GET_ATTENDANCE_STUDENTS_REQUEST");
export const getAttendanceStudentsSuccess = createAction<AttendanceResponse>(
	"@attendance/GET_ATTENDANCE_STUDENTS_SUCCESS",
);
export const getAttendanceStudentsError = createAction<PayloadError[]>(
	"@attendance/GET_ATTENDANCE_STUDENTS_ERROR",
);

export const getMoreAttendanceStudentsRequest = createAction<{
	subscriptionPlanId: number;
	afterDate: string;
	beforeDate: string;
}>("@attendance/GET_MORE_ATTENDANCE_STUDENTS_REQUEST");
export const getMoreAttendanceStudentsSuccess =
	createAction<AttendanceResponse>(
		"@attendance/GET_MORE_ATTENDANCE_STUDENTS_SUCCESS",
	);
export const getMoreAttendanceStudentsError = createAction<PayloadError[]>(
	"@attendance/GET_MORE_ATTENDANCE_STUDENTS_ERROR",
);
