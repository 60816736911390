import { createAction } from "@reduxjs/toolkit";
import {
	PendingManualSubscription,
	CreateManualSubscriptionPayload,
	PayloadError,
} from "../../common/types";

export const getPendingInvoicesRequest = createAction(
	"@invoices/GET_PENDING_INVOICES_REQUEST",
);
export const getPendingInvoicesSuccess = createAction<{
	totalCount: number;
	subscriptions: PendingManualSubscription[];
}>("@invoices/GET_PENDING_INVOICES_SUCCESS");
export const getPendingInvoicesError = createAction<PayloadError[]>(
	"@invoices/GET_PENDING_INVOICES_ERROR",
);

// Pagination
export const getMorePendingInvoicesRequest = createAction(
	"@invoices/GET_MORE_PENDING_INVOICES_REQUEST",
);
export const getMorePendingInvoicesSuccess = createAction<{
	totalCount: number;
	subscriptions: PendingManualSubscription[];
}>("@invoices/GET_MORE_PENDING_INVOICES_SUCCESS");
export const getMorePendingInvoicesError = createAction<PayloadError[]>(
	"@invoices/GET_MORE_PENDING_INVOICES_ERROR",
);

// Create manual subscription
export const createManualSubscriptionRequest =
	createAction<CreateManualSubscriptionPayload>(
		"@invoices/CREATE_MANUAL_SUBSCRIPTION_REQUEST",
	);
export const createManualSubscriptionSuccess = createAction(
	"@invoices/CREATE_MANUAL_SUBSCRIPTION_SUCCESS",
);
export const createManualSubscriptionError = createAction<PayloadError[]>(
	"@invoices/CREATE_MANUAL_SUBSCRIPTION_ERROR",
);

// Confirm manual invoice
export const confirmManualInvoiceRequest = createAction<number>(
	"@invoices/CONFIRM_MANUAL_INVOICE_REQUEST",
);
export const confirmManualInvoiceSuccess = createAction<number>(
	"@invoices/CONFIRM_MANUAL_INVOICE_SUCCESS",
);
export const confirmManualInvoiceError = createAction<PayloadError[]>(
	"@invoices/CONFIRM_MANUAL_INVOICE_ERROR",
);

// Clear uo
export const clearManualInvoicesState = createAction("@invoices/CLEAR");
