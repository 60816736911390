import { useEffect } from "react";
import {
	Modal,
	InputNumber,
	RippleButton,
} from "../../../../common/components";
import { ModalBody, ModalFooter } from "@chakra-ui/react";
import {
	setDiscountValueRequest,
	getAllDiscountsRequest,
} from "../../store/actions/subscription.actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Resolver } from "react-hook-form";
import { RootStore } from "../../../../redux";
import { discountSchema } from "../../../../common/constants/validation";
import { useDelta } from "react-delta";

interface ISetDiscountModalProps {
	handleClose: () => void;
	isOpen: boolean;
}

interface ISetDiscountForm {
	value: number;
}

export const SetDiscountModal: React.FC<ISetDiscountModalProps> = ({
	handleClose,
	isOpen,
}) => {
	const dispatch = useDispatch();
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm<ISetDiscountForm>({
		resolver: discountSchema as Resolver<ISetDiscountForm>,
	});

	const { discounts, discountEditLoading, discountError } = useSelector(
		(state: RootStore) => state.subscription.subscriptions,
	);

	const discountSaveLoadingDelta = useDelta(discountEditLoading);

	useEffect(() => {
		if (discountSaveLoadingDelta && discountSaveLoadingDelta.prev) {
			if (
				discountSaveLoadingDelta.prev &&
				!discountSaveLoadingDelta.curr &&
				!discountError.length
			) {
				handleClose();
			}
		}
	}, [discountSaveLoadingDelta]);

	useEffect(() => {
		if (isOpen) {
			dispatch(getAllDiscountsRequest());
		}
	}, [isOpen]);

	useEffect(() => {
		if (discounts.length) {
			reset({ value: discounts[0].value });
		}
	}, [discounts]);

	const onSubmit = (data: ISetDiscountForm) => {
		dispatch(setDiscountValueRequest(data.value));
	};

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Set Sibling Discount">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<InputNumber
						{...register("value")}
						label="Discount %"
						placeholder="Discount %"
						min={0}
						errorMsg={errors.value?.message}
					/>
				</ModalBody>
				<ModalFooter>
					<RippleButton
						type="submit"
						w="100%"
						// isLoading={true}
						isLoading={discountEditLoading}>
						Save
					</RippleButton>
				</ModalFooter>
			</form>
		</Modal>
	);
};
