import { createReducer } from "@reduxjs/toolkit";
import { getPositionCommitmentsDirectorySuccess } from "../../actions/directories";
import { PositionTeamType } from "../../../common/types";

type InitialState = {
	items: PositionTeamType[];
};

const initialState: InitialState = {
	items: [],
};

const positionCommitmentsDirectoryReducer = createReducer(
	initialState,
	(builder) => {
		builder.addCase(
			getPositionCommitmentsDirectorySuccess,
			(state, action) => {
				state.items = action.payload;
			},
		);
	},
);

export default positionCommitmentsDirectoryReducer;
