/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { useEffect, useState, useCallback } from "react";
import {
	Modal,
	InputText,
	InputTextArea,
	InputSelect,
	InputNumber,
	RippleButton,
} from "../../../../common/components";
import {
	ModalBody,
	Box,
	Flex,
	Text,
	HStack,
	ModalFooter,
} from "@chakra-ui/react";
import { ImageUpload } from "./components";
import { useForm, Resolver } from "react-hook-form";
import { addClassSchema } from "../../../../common/constants/validation";
import { ClassType } from "../../types";
import { ImageType } from "../../../../common/types";
import { IAddClassForm } from "../add-class.modal/AddClassModal";

export interface IEditClassForm {
	name: string;
	complexity: 1 | 2 | 3;
	lessons: number;
	hours: number;
	description: string;
	imageFile: string | Blob | ImageType;
}

interface IEditClassModalProps {
	handleClose: () => void;
	isOpen: boolean;
	toEditClass: ClassType;
	onRemove: (data: IAddClassForm) => void;
	onEditClass: (
		value: IEditClassForm,
		uploadedPhoto: string | Blob | null,
	) => void;
	confirmLoading: boolean;
}

export const EditClassModal: React.FC<IEditClassModalProps> = ({
	handleClose,
	isOpen,
	toEditClass,
	onRemove,
	onEditClass,
	confirmLoading,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		reset,
		getValues,
	} = useForm<IEditClassForm>({
		resolver: addClassSchema as Resolver<IEditClassForm>,
	});

	const [uploadedPhoto, setUploadedPhoto] = useState<string | Blob | null>(
		null,
	);
	const onDrop = useCallback((acceptedFiles) => {
		if (Math.round(acceptedFiles[0].size / 1024) < 10240) {
			setUploadedPhoto(acceptedFiles[0]);
			setValue("imageFile", acceptedFiles[0]);
		} else {
			alert("File is too big");
		}
	}, []);

	useEffect(() => {
		if (isOpen && toEditClass) {
			reset({
				...toEditClass,
				imageFile: toEditClass.image,
			});
		} else {
			reset({});
			setUploadedPhoto(null);
		}
	}, [isOpen, toEditClass, reset]);

	const onSubmit = (data: IEditClassForm) => {
		onEditClass(data, uploadedPhoto);
	};

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Edit Class"
			size="5xl">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<HStack w="100%" spacing="24px" align="baseline">
						<Box w="50%" h="100%">
							<Box borderBottom="1px solid #EBEBEB">
								<Text color="darkest" variant="subtitle1">
									Image
								</Text>
							</Box>
							<ImageUpload
								image={toEditClass.image}
								uploadedPhoto={uploadedPhoto}
								onDrop={onDrop}
							/>
						</Box>
						<Box w="50%">
							<InputText
								{...register("name")}
								label="Class Name"
								placeholder="Class Name"
								errorMsg={errors.name?.message}
							/>
							<HStack mb="29px">
								<InputSelect
									{...register("complexity")}
									label="Complexity"
									options={[
										{ label: "Introductory", value: "1" },
										{ label: "Intermediate", value: "2" },
										{ label: "Advanced", value: "3" },
									]}
									removeBottomMargin={true}
									formControlProps={{ width: "60%" }}
									errorMsg={errors.complexity?.message}
								/>
								<InputNumber
									{...register("lessons")}
									label="Lessons"
									formControlProps={{ w: "100px" }}
									min={0}
									step={1}
									errorMsg={errors.lessons?.message}
									defaultValue={1}
								/>
								<InputNumber
									{...register("hours")}
									label="Hours"
									formControlProps={{ w: "100px" }}
									min={0}
									step={1}
									errorMsg={errors.hours?.message}
									defaultValue={1}
								/>
							</HStack>
							<InputTextArea
								{...register("description")}
								label="Class Description"
								placeholder="Class Description"
								h="130px"
								errorMsg={errors.description?.message}
							/>
						</Box>
					</HStack>
				</ModalBody>
				<ModalFooter>
					<Flex w="100%" borderTop="1px solid #E0E0E0" pt="16px">
						<Box w="50%" pr="20px">
							<Text variant="caption3" color="darkest">
								After removing the class, parents will not be
								able to see the class to choose. You will not be
								able to recover data.
							</Text>
						</Box>
						<Flex w="50%">
							<Box w="50%" pl="12px">
								<RippleButton
									w="100%"
									variant="outline"
									onClick={() => {
										onRemove({
											...getValues(),
											imageFile: uploadedPhoto
												? URL.createObjectURL(
														uploadedPhoto,
												  )
												: getValues("imageFile"),
										});
									}}>
									Remove
								</RippleButton>
							</Box>
							<Box w="50%" ml="8px">
								<RippleButton
									type="submit"
									w="100%"
									isLoading={confirmLoading}>
									Edit
								</RippleButton>
							</Box>
						</Flex>
					</Flex>
				</ModalFooter>
			</form>
		</Modal>
	);
};
