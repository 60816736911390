/* eslint-disable indent */
import { useState, useEffect } from "react";
import {
	useBreakpointValue,
	Wrap,
	WrapItem,
	ModalBody,
	Box,
	Flex,
	Text,
	Checkbox,
} from "@chakra-ui/react";
import {
	InputPrice,
	InputSelect,
	InputText,
	Modal,
	DatePicker,
	InputMulti,
	InputColor,
	ClassesBox,
} from "../../../../common/components";
import {
	ImageDropZone,
	ImageSelected,
	WorkingHoursBox,
} from "../../components";
import { images, ranges } from "../../../../common/constants/dammy-date";
import { Resolver, useForm, Controller } from "react-hook-form";
import { addLevelSchema } from "../../../../common/constants/validation";
import {
	AddLevelPayload,
	WorkingHoursType,
	ImageType,
} from "../../../../common/types";
import { ClassType } from "../../types";
import { Alerter } from "../../../../common/utils";
import { Footer } from "./components";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import dayjs from "dayjs";
import { ICChek } from "../../../../assets/svg-components";

type IProps = {
	handleClose: () => void;
	isOpen: boolean;
	size?: string;
	classesLoading: boolean;
	classes: ClassType[];
	onSubmit: (value: AddLevelPayload) => void;
	setAlertAvatar: (value: ImageType | null | string) => void;
};

export type IAddLevelForm = {
	name: string;
	price?: string;
	minGrade: string;
	maxGrade: string;
	period?: string;
	workingHours: WorkingHoursType[];
	imageFile: string | Blob;
	startDate: number;
	endDate: number;
	colorValue: string;
	achievements: string[];
	bulletPoints: string[];
	newOrderIndex?: string;
	isFree: boolean;
};

export const AddLevelModal: React.FC<IProps> = ({
	handleClose,
	isOpen,
	size,
	classesLoading,
	classes,
	onSubmit,
	setAlertAvatar,
}) => {
	const [selectedImage, setSelectedImage] = useState(images[0].id);
	const [selectedClasses, setSelectedClasses] = useState<{ id: number }[]>(
		[],
	);
	const [checkboxChecked, setCheckboxChecked] = useState(false);

	const { token } = useSelector(
		(state: RootStore) => state.session.accessToken,
	);

	//useForm
	const {
		register,
		formState: { errors },
		setValue,
		handleSubmit,
		clearErrors,
		getValues,
		watch,
		reset,
		control,
	} = useForm<IAddLevelForm>({
		resolver: addLevelSchema as Resolver<IAddLevelForm>,
	});
	const countSelectedClasses = selectedClasses.length;

	const [stDate, endDate] = getValues(["startDate", "endDate"]);
	const imageFile = watch("imageFile");

	useEffect(() => {
		if (isOpen) {
			if (selectedImage) {
				setAlertAvatar(
					`${process.env.REACT_APP_BASE_URL}/api/v1/images/static-images/level${selectedImage}.png`,
				);
			} else if (imageFile) {
				setAlertAvatar(URL.createObjectURL(imageFile));
			}
		}
	}, [isOpen, selectedImage, imageFile]);

	useEffect(() => {
		if (!isOpen) {
			reset({});
			setSelectedImage(images[0].id);
			setSelectedClasses([]);
			setCheckboxChecked(false);
		}
	}, [isOpen]);

	const isBaseSize = useBreakpointValue({
		base: true,
		md: false,
	});

	const onSelectedClass = (classItem: { id: number }) => {
		const findItem = selectedClasses.find(
			(item) => item.id === classItem.id,
		);
		if (findItem) {
			setSelectedClasses((prev) =>
				prev.filter((item) => item.id !== findItem.id),
			);
		} else {
			setSelectedClasses((prev) => [...prev, classItem]);
		}
	};

	const onConfirm = async (data: IAddLevelForm) => {
		const formData = new FormData();
		if (selectedImage) {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/v1/images/static-images/level${selectedImage}.png`,
				{
					method: "GET",
					headers: new Headers({
						Authorization: "Bearer " + token,
					}),
				},
			)
				.then((response) => response.blob())
				.then(function (myBlob) {
					formData.append(
						"imageFile",
						new File([myBlob], `level${selectedImage}.png`),
					);
				})
				.catch((err) => Alerter.error(err));
		} else {
			formData.append("imageFile", data.imageFile);
		}
		const payload: AddLevelPayload = {
			params: {
				Name: data.name,
				Price: parseFloat(data?.price?.replace("$", "") || "0"),
				MinGrade: data.minGrade,
				MaxGrade: data.maxGrade,
				Interval: data?.period || "",
				StartDate: dayjs.utc(+data.startDate).format(),
				EndDate: dayjs.utc(+data.endDate).format(),
				WorkingHours: data.workingHours,
				ExistingCourseIds: selectedClasses.map((item) => item.id),
				ColorValue: data.colorValue,
				Achievements: data.achievements,
				BulletPoints: data.bulletPoints,
				IsFree: data.isFree,
			},
			imageFile: formData,
		};
		onSubmit(payload);
	};

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Add Level"
			size={size}>
			<ModalBody>
				<Flex
					justifyContent={"space-between"}
					flexDir={{ base: "column", md: "row" }}>
					{/*Left Side*/}
					<Box width={{ base: "100%", md: "48%" }}>
						<Text
							variant={isBaseSize ? "body3" : "subtitle1"}
							pb={"4px"}
							borderBottom={"1px solid #EBEBEB"}
							color="darkest">
							Default Images
						</Text>
						<Wrap
							spacing={{ base: "35px", md: "24px" }}
							mt={"24px"}
							justify={"center"}>
							{images.map((item) => (
								<WrapItem key={item.id}>
									<ImageSelected
										selected={
											selectedImage === item.id && true
										}
										onSelected={() =>
											setSelectedImage(item.id)
										}
										path={item.path}
										alt={item.alt}
									/>
								</WrapItem>
							))}

							<WrapItem>
								<ImageDropZone
									setValue={setValue}
									setSelectedImage={setSelectedImage}
								/>
							</WrapItem>
						</Wrap>

						<Text
							variant={isBaseSize ? "body3" : "subtitle1"}
							pb={"4px"}
							color="darkest"
							borderBottom={"1px solid #EBEBEB"}
							mt={"38px"}>
							Schedule
						</Text>
						{/*	Working Hours Box*/}
						<WorkingHoursBox setValue={setValue} />

						<Checkbox
							isChecked={checkboxChecked}
							sx={{
								".chakra-checkbox__control": {
									borderRadius: "50%",
									border: "1px solid #8C4BC9",
								},
							}}
							icon={<ICChek enabled={checkboxChecked} />}
							my="29px"
							onChange={(ev) => {
								setCheckboxChecked(ev.target.checked);
								setValue("isFree", ev.target.checked);
							}}>
							<Text variant="body2" color="darkest">
								Free
							</Text>
						</Checkbox>
					</Box>
					{/*RightSide*/}
					<Box width={{ base: "100%", md: "48%" }}>
						{/*Level name form Box*/}
						<Flex flexDir={{ base: "column", md: "row" }}>
							<Box flex={"1"}>
								<InputText
									label="Level Name"
									{...register("name")}
									errorMsg={errors.name?.message}
									placeholder="Level Name"
								/>
							</Box>
							<Box
								d={"flex"}
								ml={{ base: 0, md: "8px" }}
								justifyContent={{
									base: "space-between",
									md: "",
								}}>
								<Box w={{ base: "48%" }}>
									<InputSelect
										{...register("minGrade")}
										label={"Range"}
										options={ranges}
										errorMsg={errors.minGrade?.message}
									/>
								</Box>
								<Box
									ml={{ base: 0, md: "8px" }}
									w={{ base: "48%" }}>
									<InputSelect
										{...register("maxGrade")}
										label={""}
										mt={"21px"}
										options={ranges}
										errorMsg={errors.maxGrade?.message}
									/>
								</Box>
							</Box>
						</Flex>
						{/*Test Date Picker*/}
						<Flex justifyContent={"space-between"} mb={"8px"}>
							<Box w={"48%"}>
								<DatePicker
									{...register("startDate")}
									label={"Start date"}
									setValue={(value: number) => {
										setValue("startDate", value);
										clearErrors("startDate");
									}}
									value={stDate}
									errMessage={errors.startDate?.message}
									placeholder="Start Date"
									displayValue={
										stDate
											? dayjs
													.utc(stDate)
													.format("DD MMMM, YYYY")
											: ""
									}
								/>
							</Box>
							<Box w={"48%"}>
								<DatePicker
									{...register("endDate")}
									label={"End date"}
									setValue={(value: number) => {
										setValue("endDate", value);
										clearErrors("endDate");
									}}
									value={endDate}
									errMessage={errors.endDate?.message}
									placeholder="End Date"
									displayValue={
										endDate
											? dayjs
													.utc(endDate)
													.format("DD MMMM, YYYY")
											: ""
									}
								/>
							</Box>
						</Flex>
						{/*Price Box*/}
						{!checkboxChecked && (
							<Flex justifyContent={"space-between"}>
								<Box w={{ base: "48%", md: "40%" }}>
									<InputPrice
										label={"Price"}
										allowNegative={false}
										fixedDecimalScale
										decimalScale={2}
										placeholder="Price"
										prefix={"$"}
										{...register("price")}
										errMessage={errors.price?.message}
									/>
								</Box>
								<Box w={{ base: "48%", md: "55%" }}>
									<InputSelect
										label={"Period"}
										{...register("period")}
										options={[
											{ value: "1", label: "Per Month" },
											{ value: "2", label: "Per Year" },
										]}
									/>
								</Box>
							</Flex>
						)}
						{/*Class Box*/}
						<ClassesBox
							countSelectedClasses={countSelectedClasses}
							classes={classes}
							classesLoading={classesLoading}
							onSelectedClass={(item) => onSelectedClass(item)}
							selectedClasses={selectedClasses}
						/>
						<InputMulti
							onItemAdded={(item: string, allItems: string[]) => {
								setValue("achievements", allItems);
							}}
							onItemDeleted={(
								item: string,
								allItems: string[],
							) => {
								setValue("achievements", allItems);
							}}
							label="Achievements"
							name="achievements"
							placeholder="Achievements (Press enter or comma to submit)"
							values={[]}
						/>
						<InputMulti
							onItemAdded={(item: string, allItems: string[]) => {
								setValue("bulletPoints", allItems);
							}}
							onItemDeleted={(
								item: string,
								allItems: string[],
							) => {
								setValue("bulletPoints", allItems);
							}}
							label="Bullet Points"
							name="bulletPoints"
							placeholder="Bullet Points (Press enter or comma to submit)"
							values={[]}
						/>
						<Controller
							render={({ field }) => (
								<InputColor
									{...register("colorValue")}
									title="Header color"
									value={field.value}
									name={field.name}
									onChange={field.onChange}
									onBlur={field.onBlur}
								/>
							)}
							control={control}
							name="colorValue"
							defaultValue="#CCF4FA"
						/>
					</Box>
				</Flex>
				{/*Footer*/}
				<Footer onPublish={handleSubmit(onConfirm)} />
			</ModalBody>
		</Modal>
	);
};
