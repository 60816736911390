import { Flex, Box, Text } from "@chakra-ui/react";
import { RippleButton } from "../../../../../common/components";

interface IFooterProps {
	onPublish: () => void;
	onCancel: () => void;
	saveLoading: boolean;
}

export const Footer: React.FC<IFooterProps> = ({
	onPublish,
	onCancel,
	saveLoading,
}) => (
	<Flex
		align="center"
		justifyContent={{ base: "", md: "space-between" }}
		mt="10px">
		<Box w="48%" display={{ base: "none", md: "block" }}>
			<Text variant="caption3" color="darkest">
				Once published, parents will be able to see the level and
				subscribe <br />
			</Text>
		</Box>
		<Flex w={{ base: "100%", md: "48%" }}>
			<RippleButton variant="outline" w="100%" onClick={onCancel}>
				Cancel
			</RippleButton>
			<Box w="20px" />
			<RippleButton
				w="100%"
				type="submit"
				isLoading={saveLoading}
				onClick={onPublish}>
				Save
			</RippleButton>
		</Flex>
	</Flex>
);
