import { Link, Flex, Text, Button, useBreakpointValue } from "@chakra-ui/react";
import { ReactComponent as DashboardIcon } from "../../../assets/ic_dashboard-tab.svg";
import { ReactComponent as AttendanceIcon } from "../../../assets/ic_attendance-tab.svg";
import { ReactComponent as SubscriptionsIcon } from "../../../assets/ic_billing-tab.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/ic_settings-tab.svg";
import { ReactComponent as MessagesIcon } from "../../../assets/ic_message.svg";
import { ReactComponent as JobsIcon } from "../../../assets/ic_dashboard-tab.svg";

import { useLocation } from "react-router-dom";
import { RootStore } from "../../../redux";
import { useSelector } from "react-redux";

const ALLOWED_ROUTES = {
	admin: [
		{
			icon: <DashboardIcon />,
			label: "Dashboard",
			href: "/dashboard/main",
		},
		{
			icon: <AttendanceIcon />,
			label: "Attendance",
			href: "/dashboard/attendance",
		},
		{
			icon: <SubscriptionsIcon />,
			label: "Subscriptions",
			href: "/dashboard/subscriptions",
		},
		{
			icon: <MessagesIcon />,
			label: "Messages",
			href: "/dashboard/messages",
		},
		{ icon: <JobsIcon />, label: "Jobs", href: "/dashboard/jobs" },
		{
			icon: <SettingsIcon />,
			label: "Settings",
			href: "/dashboard/settings",
		},
	],
	teacher: [
		{
			icon: <AttendanceIcon />,
			label: "Attendance",
			href: "/dashboard/teacher-attendance",
		},
		{
			icon: <SettingsIcon />,
			label: "Settings",
			href: "/dashboard/settings",
		},
	],
	none: [],
};

export const TabBar: React.FC = () => {
	const location = useLocation();
	const isTablet = useBreakpointValue({
		base: false,
		md: true,
		lg: false,
	});
	const userRoles = useSelector((state: RootStore) => state.session.roles);

	return (
		<Flex
			display={{ base: "flex", lg: "none" }}
			position="fixed"
			right={0}
			bottom={0}
			left={0}
			height="84px"
			bg="white"
			justify="space-evenly"
			align="center"
			w="100vw">
			{ALLOWED_ROUTES[
				(userRoles?.[0] || "none") as keyof typeof ALLOWED_ROUTES
			].map((tab) => (
				<Button
					key={tab.href}
					as={Link}
					href={tab.href}
					variant="unstyled"
					d="flex"
					bg={location.pathname === tab.href ? "purple" : "white"}
					p="0 10px"
					color={
						location.pathname === tab.href ? "primary" : "darkGrey"
					}>
					{tab.icon}
					{(location.pathname === tab.href || isTablet) && (
						<Text
							variant="subtitle1"
							ml="10px"
							color={
								location.pathname === tab.href
									? "primary"
									: "darkGrey"
							}>
							{tab.label}
						</Text>
					)}
				</Button>
			))}
		</Flex>
	);
};
