import {
	IResponseWithCustomValue,
	SubscriptionPlan,
} from "../../../../common/types";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
	getFilterClassesRequest,
	getFilterClassessError,
	getFilterClassesSuccess,
} from "../actions/filter-classes.actions";
import { Alerter } from "../../../../common/utils";

function* workerGetFilterClasses(
	action: ReturnType<typeof getFilterClassesRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			subscriptionPlans: SubscriptionPlan[];
		}> = yield call(API.get, `/api/v1/plans?StatusId=${action.payload}`);
		if (result.success) {
			yield put(getFilterClassesSuccess(result.value.subscriptionPlans));
		} else {
			yield put(getFilterClassessError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchDashboardFilterClassesSaga (): Generator<
	ForkEffect<never>,
	void,
	unknown
	>  {
	yield takeEvery(getFilterClassesRequest.type,workerGetFilterClasses);
}
