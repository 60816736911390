import { Box, Flex, Avatar, Text, Checkbox } from "@chakra-ui/react";
import { getPictureURL } from "../../../../../../../common/utils";
import { StudentResponseType } from "../../../../../../Dashboard/types";
import { ICChek } from "../../../../../../../assets/svg-components";

interface IStudentItemProps extends StudentResponseType {
	selectStudent: (value: number) => void;
	isSelected: boolean;
}

export const StudentItem: React.FC<IStudentItemProps> = ({
	age,
	grade,
	name,
	paidSubscriptionPlanNames,
	photo,
	parentFullName,
	isSelected,
	selectStudent,
	id,
}) => {
	return (
		<Flex
			justify="space-between"
			borderBottom="1px solid #EBEBEB"
			py="16px"
			pr="16px">
			<Flex>
				<Avatar
					name={name}
					src={getPictureURL(photo)}
					w="48px"
					h="48px"
					borderRadius="8px"
					mr="16px"
				/>
				<Box>
					<Text variant="body1" color="darkest">
						{name}
					</Text>
					<Text variant="body3" color="grey">
						{age} years old • Grade {grade} •{" "}
						{paidSubscriptionPlanNames.join(", ")}
					</Text>
				</Box>
			</Flex>
			<Checkbox
				isChecked={isSelected}
				sx={{
					".chakra-checkbox__control": {
						borderRadius: "50%",
						border: "1px solid #8C4BC9",
					},
				}}
				icon={<ICChek enabled={isSelected} />}
				mb="29px"
				onChange={(ev) => {
					selectStudent(id);
					// setCheckboxChecked(ev.target.checked);
				}}>
				<Text variant="body2" color="darkest">
					Select
				</Text>
			</Checkbox>
			{/* <Button
				leftIcon={<InfoIcon />}
				variant="plain"
				onClick={() => {
					setStudentInfo({
						age,
						grade,
						id,
						name,
						paidSubscriptionPlanNames,
						photo,
						parentFullName,
					});
					setShowStudentInfoModal(true);
				}}>
				Info
			</Button> */}
		</Flex>
	);
};
