// Get Student Subscription Plan
import {
	getMoreStudentPaymentsError,
	getMoreStudentPaymentsRequest,
	getMoreStudentPaymentsSuccess,
	getStudentPaymentsError,
	getStudentPaymentsRequest,
	getStudentPaymentsSuccess,
	getStudentSubscriptionPlansError,
	getStudentSubscriptionPlansRequest,
	getStudentSubscriptionPlansSuccess,
} from "../actions";
import {
	IResponseWithCustomValue,
	SubscriptionPlan,
} from "../../../../common/types";
import { call, ForkEffect, put, select, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { Alerter } from "../../../../common/utils";
import { StudentPaymentType } from "../../types";
import { quantity } from "../../../../common/constants";
import { RootStore } from "../../../../redux";

function* workerGetStudentSubscriptionPlans(
	action: ReturnType<typeof getStudentSubscriptionPlansRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			subscriptionPlans: SubscriptionPlan[];
		}> = yield call(
			API.get,
			`/api/v1/students/subscription-plans?StudentId=${action.payload}`,
		);
		if (result.success) {
			yield put(
				getStudentSubscriptionPlansSuccess(
					result.value.subscriptionPlans,
				),
			);
		} else {
			yield put(getStudentSubscriptionPlansError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//Get Student Payments
function* workerGetStudentPayments(
	action: ReturnType<typeof getStudentPaymentsRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			payments: StudentPaymentType[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/students/payments?StudentId=${action.payload.studentId}&PageSize=${quantity.STUDENT_PAYMENTS_TAKE}`,
		);
		if (result.success) {
			yield put(getStudentPaymentsSuccess(result.value));
		} else {
			yield put(getStudentPaymentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//Get More Student Payments
function* workerGetMoreStudentPayments(
	action: ReturnType<typeof getMoreStudentPaymentsRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) =>
				state.dashboard.studentSubscription.payments.page,
		);
		const result: IResponseWithCustomValue<{
			payments: StudentPaymentType[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/students/payments?StudentId=${action.payload.studentId}&PageSize=${quantity.STUDENT_PAYMENTS_TAKE}&PageNumber=${page}`,
		);
		if (result.success) {
			yield put(getMoreStudentPaymentsSuccess(result.value));
		} else {
			yield put(getMoreStudentPaymentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchStudentSubscriptionSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(
		getStudentSubscriptionPlansRequest.type,
		workerGetStudentSubscriptionPlans,
	);
	yield takeEvery(getStudentPaymentsRequest.type, workerGetStudentPayments);
	yield takeEvery(
		getMoreStudentPaymentsRequest.type,
		workerGetMoreStudentPayments,
	);
}
