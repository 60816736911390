import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { Button, Heading, Link, Text } from "./components";

export const theme = extendTheme({
	colors,
	fonts: {
		heading: "DM Sans, sans-serif",
		body: "DM Sans, sans-serif",
	},
	fontSizes: {
		subtitle1: "16px",
		subtitle2: "12px",
		body1: "18px",
		body2: "16px",
		body3: "14px",
		inputText: "14px",
		caption1: "12px",
		caption2: "10px",
		link1: "16px",
		link2: "12px",
		button: "16px",
	},
	components: {
		Heading,
		Text,
		Button,
		Link,
	},
});
