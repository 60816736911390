import { PayloadError } from "../../../../common/types";
import {
	inviteEmployeeRequest,
	inviteEmployeeSuccess,
	inviteEmployeeError,
	getEmployeesRequest,
	getEmployeesSuccess,
	getEmployeesError,
	getMoreEmployeesRequest,
	getMoreEmployeesSuccess,
	getMoreEmployeesError,
	editEmployeeRequest,
	editEmployeeSuccess,
	editEmployeeError,
	deleteEmployeeRequest,
	deleteEmployeeSuccess,
	deleteEmployeeError,
	clearDashboard,
} from "../actions";
import { StaffItem } from "../../types";
import { createReducer } from "@reduxjs/toolkit";

type InitialState = {
	inviteEmployee: {
		loading: boolean;
		errors: PayloadError[];
	};
	editEmployee: {
		loading: boolean;
		errors: PayloadError[];
	};
	deleteEmployee: {
		loading: boolean;
		errors: PayloadError[];
	};
	items: StaffItem[];
	totalCount: number;
	page: number;
	loadMoreLoading: boolean;
	loading: boolean;
	errors: PayloadError[];
};
const initialState: InitialState = {
	inviteEmployee: {
		loading: false,
		errors: [],
	},
	editEmployee: {
		loading: false,
		errors: [],
	},
	deleteEmployee: {
		loading: false,
		errors: [],
	},
	errors: [],
	items: [],
	loading: false,
	totalCount: 0,
	loadMoreLoading: false,
	page: 1,
};
const employeeReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getEmployeesRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getEmployeesSuccess, (state, action) => {
			state.loading = false;
			state.totalCount = action.payload.totalCount;
			state.items = action.payload.staffUsers;
			state.page++;
		})
		.addCase(getEmployeesError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		//Fetch more staff
		.addCase(getMoreEmployeesRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreEmployeesSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.totalCount = action.payload.totalCount;
			state.items = [...state.items, ...action.payload.staffUsers];
			state.page++;
		})
		.addCase(getMoreEmployeesError, (state) => {
			state.loadMoreLoading = false;
		})
		//Invite
		.addCase(inviteEmployeeRequest, (state) => {
			state.inviteEmployee.loading = true;
			state.inviteEmployee.errors = [];
		})
		.addCase(inviteEmployeeSuccess, (state) => {
			state.inviteEmployee.loading = false;
		})
		.addCase(inviteEmployeeError, (state, action) => {
			state.inviteEmployee.loading = false;
			state.inviteEmployee.errors = action.payload;
		})
		//Edit
		.addCase(editEmployeeRequest, (state) => {
			state.editEmployee.loading = true;
			state.editEmployee.errors = [];
		})
		.addCase(editEmployeeSuccess, (state) => {
			state.editEmployee.loading = false;
		})
		.addCase(editEmployeeError, (state, action) => {
			state.editEmployee.loading = false;
			state.editEmployee.errors = action.payload;
		})
		.addCase(deleteEmployeeRequest, (state) => {
			state.deleteEmployee.loading = true;
			state.deleteEmployee.errors = [];
		})
		.addCase(deleteEmployeeSuccess, (state) => {
			state.deleteEmployee.loading = false;
		})
		.addCase(deleteEmployeeError, (state, action) => {
			state.deleteEmployee.loading = false;
			state.deleteEmployee.errors = action.payload;
		})
		.addCase(clearDashboard, () => {
			return { ...initialState };
		});
});
export default employeeReducer;
