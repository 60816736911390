import { ResetPassword } from "./ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux";
import { ResetForm } from "./types";
import { resetPasswordRequest } from "../../redux/actions/auth.actions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const ResetPasswordContainer: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { loading, errors: stateErrors } = useSelector(
		(state: RootStore) => state.auth.resetPassword,
	);

	const onSubmit = (data: ResetForm) => {
		dispatch(
			resetPasswordRequest({
				...data,
				userId: queryString.parse(location.search, { decode: false })
					.userId,
				token: queryString
					.parse(location.search, { decode: false })
					.token?.replace(/\s/g, "+"),
			}),
		);
	};

	return (
		<ResetPassword
			errors={stateErrors}
			onSubmit={onSubmit}
			loading={loading}
		/>
	);
};
