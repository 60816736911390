import { useState, useEffect } from "react";
import { LevelsTab } from "./LevelsTab";
import { SubscriptionPlan, ImageType } from "../../../../common/types";
import {
	AddLevelModal,
	EditLevelDatesModal,
	EditLevelModal,
} from "../../modal";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { AddLevelPayload } from "../../../../common/types";
import { Alert } from "../../components";
import { defaultSubPlan } from "../../../../common/constants";
import {
	addLevel,
	deleteLevelRequest,
	archiveLevelRequest,
	editLevelRequest,
} from "../../store/actions";
import { useDelta } from "react-delta";

interface ILevelsTabContainerProps {
	belowDesktop?: boolean;
	setShowAddLevelModal: (value: boolean) => void;
	showAddLevelModal: boolean;
	searchQuery: string;
	selectedPlan: number;
	setSelectedPlan: (value: number) => void;
	withArchivedFilter: boolean;
}

export const LevelsTabContainer: React.FC<ILevelsTabContainerProps> = ({
	belowDesktop,
	setShowAddLevelModal,
	showAddLevelModal,
	searchQuery,
	selectedPlan,
	setSelectedPlan,
	withArchivedFilter,
}) => {
	const dispatch = useDispatch();
	const [toConfirmCreateLevel, setToConfirmCreateLevel] =
		useState<AddLevelPayload | null>(null);
	const [showPublishAlert, setShowPublishAlert] = useState(false);
	const [selectedLevel, setSelectedLevel] =
		useState<SubscriptionPlan>(defaultSubPlan);
	const [alertAvatar, setAlertAvatar] = useState<ImageType | null | string>(
		null,
	);
	const [showRemoveAlert, setShowRemoveAlert] = useState(false);
	const [showArchiveAlert, setShowArchiveAlert] = useState(false);
	const [showEditLevelModal, setShowEditLevelModal] = useState(false);
	// const [showAddFreeLevelModal, setShowAddFreeLevelModal] = useState(false);
	const [toEditLevelSelected, setToEditLevelSelected] =
		useState<SubscriptionPlan>(defaultSubPlan);
	const [showEditDatesModal, setShowEditDatesModal] = useState(false);
	const [toEditDatesData, setToEditDatesData] = useState({
		id: 0,
		startDate: "",
		endDate: "",
	});
	// Selectors

	const { loading: coursesLoading, items } = useSelector(
		(state: RootStore) => state.classes,
	);
	const { loading: addingLoading } = useSelector(
		(state: RootStore) => state.subscription.levels.addLevel,
	);
	const { loading: deleteLoading, errors: deleteErrors } = useSelector(
		(state: RootStore) => state.subscription.levels.deleteLevel,
	);
	const { loading: archiveLoading, errors: archiveErrors } = useSelector(
		(state: RootStore) => state.subscription.levels.archiveLevel,
	);
	const { loading: levelsLoading, items: levelsItems } = useSelector(
		(state: RootStore) => state.subscription.levels.levels,
	);

	const addingLoadingDelta = useDelta(addingLoading);
	const deleteLoadingDelta = useDelta(deleteLoading);
	const archiveLoadingDelta = useDelta(archiveLoading);
	// Effects
	useEffect(() => {
		if (addingLoadingDelta && addingLoadingDelta.prev) {
			if (
				addingLoadingDelta.prev &&
				!addingLoadingDelta.curr &&
				!archiveErrors.length
			) {
				setShowPublishAlert(false);
				setSelectedLevel(defaultSubPlan);
			}
		}
	}, [addingLoadingDelta]);

	useEffect(() => {
		if (deleteLoadingDelta && deleteLoadingDelta.prev) {
			if (
				deleteLoadingDelta.prev &&
				!deleteLoadingDelta.curr &&
				!deleteErrors.length
			) {
				setShowRemoveAlert(false);
				setSelectedLevel(defaultSubPlan);
			}
		}
	}, [deleteLoadingDelta]);

	useEffect(() => {
		if (archiveLoadingDelta && archiveLoadingDelta.prev) {
			if (
				archiveLoadingDelta.prev &&
				!archiveLoadingDelta.curr &&
				!archiveErrors.length
			) {
				setShowArchiveAlert(false);
				setShowAddLevelModal(false);
				setSelectedLevel(defaultSubPlan);
			}
		}
	}, [archiveLoadingDelta]);

	useEffect(() => {
		if (toEditLevelSelected.id) {
			setShowEditLevelModal(true);
		}
	}, [toEditLevelSelected]);

	useEffect(() => {
		if (toEditDatesData.id) {
			setShowEditDatesModal(true);
		}
	}, [toEditDatesData]);

	const onSubmitFromModal = (data: AddLevelPayload) => {
		setToConfirmCreateLevel(data);
	};

	const onSubmitFromAlert = () => {
		if (toConfirmCreateLevel) {
			dispatch(
				addLevel({
					...toConfirmCreateLevel,
					searchQuery,
					withArchivedFilter,
				}),
			);
		}
	};

	return (
		<>
			<LevelsTab
				levelsItems={levelsItems}
				levelsLoading={levelsLoading}
				setAlertAvatar={setAlertAvatar}
				setSelectedLevel={setSelectedLevel}
				setShowAddLevelModal={setShowAddLevelModal}
				setShowArchiveAlert={setShowArchiveAlert}
				setShowRemoveAlert={setShowRemoveAlert}
				belowDesktop={belowDesktop}
				setToEditLevelSelected={setToEditLevelSelected}
				setToEditDatesData={setToEditDatesData}
				selectedPlan={selectedPlan}
				setSelectedPlan={setSelectedPlan}
			/>
			<AddLevelModal
				size="5xl"
				handleClose={() => {
					setShowAddLevelModal(false);
					setAlertAvatar(null);
				}}
				isOpen={showAddLevelModal}
				classes={items}
				classesLoading={coursesLoading}
				onSubmit={(data) => {
					onSubmitFromModal(data);
					setShowPublishAlert(true);
					setSelectedLevel({
						id: 0,
						name: data.params.Name,
						classes: [],
						endDate: data.params.EndDate,
						image: { id: 0, extension: null, itemHash: null },
						interval: parseInt(data.params.Interval),
						isActive: false,
						isFree: false,
						studentsCount: 0,
						maxGrade: parseInt(data.params.MaxGrade),
						minGrade: parseInt(data.params.MinGrade),
						price: data.params.Price,
						startDate: data.params.StartDate,
						isPossibleToDelete: false,
						workingHours: data.params.WorkingHours,
						achievements: [],
						bulletPoints: [],
						colorValue: "",
						orderIndex: 0,
					});
				}}
				setAlertAvatar={setAlertAvatar}
			/>
			<EditLevelModal
				size="5xl"
				isOpen={showEditLevelModal}
				handleClose={() => {
					setShowEditLevelModal(false);
					setToEditLevelSelected(defaultSubPlan);
				}}
				classes={items}
				classesLoading={coursesLoading}
				toEditLevel={toEditLevelSelected}
				onSubmit={(data) => {
					dispatch(editLevelRequest(data));
				}}
			/>
			<EditLevelDatesModal
				isOpen={showEditDatesModal}
				handleClose={() => {
					setShowEditDatesModal(false);
					setToEditDatesData({ id: 0, endDate: "", startDate: "" });
				}}
				toEditDatesData={toEditDatesData}
			/>
			<Alert
				isOpen={showPublishAlert}
				handleClose={() => {
					setShowPublishAlert(false);
					setAlertAvatar(null);
				}}
				selectedClass={selectedLevel}
				firstDescription="Once published, parents will be able to
				see the class and subscribe"
				secondDescription="You can always edit the class
				information by clicking on the Edit
				button."
				confirmLabel="Publish"
				title="Publish"
				onConfirm={onSubmitFromAlert}
				confirmLoading={addingLoading}
				alertAvatar={alertAvatar}
			/>
			<Alert
				isOpen={showRemoveAlert}
				handleClose={() => {
					setShowRemoveAlert(false);
					setSelectedLevel(defaultSubPlan);
					setAlertAvatar(null);
				}}
				selectedClass={selectedLevel}
				firstDescription="After removing the level, parents will
				not be able to see the level or
				subscribe"
				secondDescription="You will not be able to recover data."
				confirmLabel="Remove"
				title="Remove"
				onConfirm={() =>
					dispatch(
						deleteLevelRequest({
							id: selectedLevel.id,
							searchQuery,
							withArchivedFilter,
						}),
					)
				}
				confirmLoading={deleteLoading}
				alertAvatar={alertAvatar}
			/>
			<Alert
				isOpen={showArchiveAlert}
				handleClose={() => {
					setShowArchiveAlert(false);
					setSelectedLevel(defaultSubPlan);
					setAlertAvatar(null);
				}}
				selectedClass={selectedLevel}
				firstDescription="After archiving the class, parents will
				not be able to see the class or
				subscribe"
				secondDescription="You will not be able to recover data."
				confirmLabel="Archive"
				title="Archive"
				onConfirm={() =>
					dispatch(
						archiveLevelRequest({
							id: selectedLevel.id,
							searchQuery,
							withArchivedFilter,
						}),
					)
				}
				confirmLoading={archiveLoading}
				alertAvatar={alertAvatar}
			/>
		</>
	);
};
