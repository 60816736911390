import { useEffect } from "react";
import { StaffTab } from "./StaffTab";
import {
	getStaffForMessagesRequest,
	getMoreStaffForMessagesRequest,
} from "../../store/actions";
import { RootStore } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { SendMessageTo } from "../../types";

interface IStaffTabContainerProps {
	setShowSendMessageModal: (value: boolean) => void;
	searchQuery: string;
	setSendMessageTo: (value: SendMessageTo) => void;
}

export const StaffTabContainer: React.FC<IStaffTabContainerProps> = ({
	setShowSendMessageModal,
	searchQuery,
	setSendMessageTo,
}) => {
	const dispatch = useDispatch();

	const {
		items: staffItems,
		loading,
		loadMoreLoading,
		totalCount,
	} = useSelector((state: RootStore) => state.messages.staff);

	useEffect(() => {
		dispatch(
			getStaffForMessagesRequest({
				searchQuery,
			}),
		);
	}, []);

	const getMoreStaff = () =>
		dispatch(getMoreStaffForMessagesRequest({ searchQuery }));

	return (
		<>
			<StaffTab
				staffItems={staffItems}
				loading={loading}
				getMoreStaff={getMoreStaff}
				loadMoreLoading={loadMoreLoading}
				totalCount={totalCount}
				setSendMessageTo={setSendMessageTo}
				setShowSendMessageModal={setShowSendMessageModal}
			/>
		</>
	);
};
