import { createAction } from "@reduxjs/toolkit";
import {
	LoginPayload,
	ILoginResponse,
	PayloadError,
	IResponseWithoutValue,
} from "../../common/types";

export const loginRequest = createAction<LoginPayload>("@auth/LOGIN");
export const loginSuccess = createAction<ILoginResponse>("@auth/LOGIN_SUCCESS");
export const loginError = createAction<PayloadError[]>("@auth/LOGIN_ERROR");

export const logoutRequest = createAction("@auth/LOGOUT");
export const logoutSuccess = createAction("@auth/LOGOUT_SUCCESS");
export const logoutError =
	createAction<IResponseWithoutValue>("@auth/LOGOUT_ERROR");

export const forgotPasswordRequest = createAction<{ email: string }>(
	"@auth/FORGOT_PASSWORD_REQUEST",
);
export const forgotPasswordSuccess = createAction(
	"@auth/FORGOT_PASSWORD_SUCCESS",
);
export const forgotPasswordError = createAction<PayloadError[]>(
	"@auth/FORGOT_PASSWORD_ERROR",
);

export const resetPasswordRequest = createAction<{
	userId: number;
	password: string;
	confirmPassword: string;
	token: string;
}>("@auth/RESET_PASSWORD_REQUEST");
export const resetPasswordSuccess = createAction(
	"@auth/RESET_PASSWORD_SUCCESS",
);
export const resetPasswordError = createAction<PayloadError[]>(
	"@auth/RESET_PASSWORD_ERROR",
);
