import PlaceholderPic from "../../../../assets/pic_users-placeholder.png";
import { Image, Center, VStack, Text } from "@chakra-ui/react";

export const StudentsPlaceholder: React.FC = () => (
	<Center h="calc(100% - 300px)">
		<VStack>
			<Image src={PlaceholderPic} />
			<Text
				variant={"body2"}
				color="darkGrey"
				textAlign="center"
				mt="20px">
				There is no one here. Make sure you have classes. <br /> Contact
				Administrator to add it.
			</Text>
		</VStack>
	</Center>
);
