import { createReducer } from "@reduxjs/toolkit";
import {
	getUserSettingsRequest,
	getUserSettingsSuccess,
	getUserSettingsError,
	updateProfilePhotoRequest,
	updateProfilePhotoSuccess,
	updateProfilePhotoError,
	updateProfileInfoRequest,
	updateProfileInfoSuccess,
	updateProfileInfoError,
	updatePasswordRequest,
	updatePasswordSuccess,
	updatePasswordError,
	clearSettings,
} from "./actions";
import { isAnyOf } from "../../../common/utils";
import { PayloadError, ImageType } from "../../../common/types";

type InitialState = {
	loading: boolean;
	profileInfo: {
		saveLoading: boolean;
		errors: PayloadError[];
		values: {
			id: number;
			firstName: string;
			lastName: string;
			email: string;
			phoneNumber: string;
		};
	};
	password: {
		saveLoading: boolean;
		errors: PayloadError[];
	};
	photo: {
		saveLoading: boolean;
		image: ImageType;
	};
};

const initialState: InitialState = {
	loading: false,
	profileInfo: {
		saveLoading: false,
		errors: [],
		values: {
			id: 0,
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
		},
	},
	password: {
		saveLoading: false,
		errors: [],
	},
	photo: {
		saveLoading: false,
		image: {
			id: 0,
			itemHash: "",
			extension: "",
		},
	},
};

const settingsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getUserSettingsRequest, (state) => {
			state.loading = true;
		})
		.addCase(getUserSettingsSuccess, (state, action) => {
			state.loading = false;
			state.profileInfo.values = { ...action.payload };
			state.photo.image = { ...action.payload.image };
		})
		.addCase(getUserSettingsError, (state, action) => {
			state.loading = false;
			state.profileInfo.errors = action.payload;
		})
		.addCase(updateProfilePhotoRequest, (state) => {
			state.photo.saveLoading = true;
		})
		.addCase(updateProfileInfoRequest, (state) => {
			state.profileInfo.saveLoading = true;
			state.profileInfo.errors = [];
		})
		.addCase(updateProfileInfoSuccess, (state) => {
			state.profileInfo.saveLoading = false;
		})
		.addCase(updateProfileInfoError, (state, action) => {
			state.profileInfo.saveLoading = false;
			state.profileInfo.errors = action.payload;
		})
		.addCase(updatePasswordRequest, (state) => {
			state.password.saveLoading = true;
			state.password.errors = [];
		})
		.addCase(updatePasswordSuccess, (state) => {
			state.password.saveLoading = false;
		})
		.addCase(updatePasswordError, (state, action) => {
			state.password.saveLoading = false;
			state.password.errors = action.payload;
		})
		.addCase(clearSettings, () => {
			return { ...initialState };
		})
		.addMatcher(
			isAnyOf(updateProfilePhotoSuccess, updateProfilePhotoError),
			(state) => {
				state.photo.saveLoading = false;
			},
		);
});
export default settingsReducer;
