import React from "react";
import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	FormControlProps,
	FormControl,
	Flex,
	FormLabel,
	FormErrorMessage,
} from "@chakra-ui/react";
import { ArrowUp, ArrowDown } from "../../../assets/svg-components";

interface IInputNumberProps {
	errorMsg?: string;
	label: string;
	formControlProps?: FormControlProps;
	min?: number;
	max?: number;
	defaultValue?: number;
	step?: number;
	value?: number;
	[key: string]: any;
}

export const InputNumber = React.forwardRef<null, IInputNumberProps>(
	(
		{
			errorMsg,
			formControlProps,
			label,
			defaultValue,
			max,
			min,
			step,
			value,
			...props
		},
		ref,
	) => (
		<FormControl isInvalid={!!errorMsg} {...formControlProps}>
			<Flex align="center" justify="space-between">
				<FormLabel
					fontSize="caption2"
					lineHeight="22px"
					color="darkest"
					marginBottom={0}>
					{label}
				</FormLabel>
			</Flex>
			<NumberInput
				size="md"
				defaultValue={defaultValue}
				min={min}
				max={max}
				step={step}
				value={value}
				borderColor="lightGrey"
				color="darkest"
				focusBorderColor="primary">
				<NumberInputField
					focusBorderColor="red.200"
					paddingInlineStart="12px"
					paddingInlineEnd="12px"
					fontSize="14px"
					lineHeight="22px"
					ref={ref}
					{...props}
				/>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
				<NumberInputStepper p="5px 0">
					<NumberIncrementStepper
						border="none"
						color="darkGrey"
						fill="currentcolor"
						_hover={{ color: "primary" }}>
						<ArrowUp />
					</NumberIncrementStepper>
					<NumberDecrementStepper
						border="none"
						color="darkGrey"
						_hover={{ color: "primary" }}>
						<ArrowDown />
					</NumberDecrementStepper>
				</NumberInputStepper>
			</NumberInput>
		</FormControl>
	),
);

InputNumber.displayName = "InputNumber";
