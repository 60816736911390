import { call, put, takeEvery, ForkEffect } from "redux-saga/effects";
import {
	resetPasswordRequest,
	resetPasswordSuccess,
	resetPasswordError,
} from "../../actions/auth.actions";
import API from "../../../common/api/api.base";
import { IResponseWithoutValue } from "../../../common/types";
import { Alerter } from "../../../common/utils";
import { push } from "connected-react-router";

function* workerResetPassword(action: ReturnType<typeof resetPasswordRequest>) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/auth/restore-password/confirm",
			action.payload,
		);
		if (result.success) {
			yield put(resetPasswordSuccess());
			Alerter.success("Password successfully changed");
			yield put(push("/sign-in"));
		} else {
			yield put(resetPasswordError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchResetPasswordSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(resetPasswordRequest.type, workerResetPassword);
}
