import React from "react";
import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	IconButton,
	Box,
	Flex,
	Heading,
} from "@chakra-ui/react";
import { ReactComponent as CloseIcon } from "../../../assets/ic_close.svg";
import { RippleButton } from "../../../common/components";

type IMessageAlert = {
	isOpen: boolean;
	handleClose: () => void;
	firstDescription: JSX.Element;
	confirmLabel?: string;
	title: string;
	onConfirm?: () => void;
	confirmLoading?: boolean;
};

export const MessageAlert: React.FC<IMessageAlert> = ({
	confirmLabel = "Send",
	firstDescription,
	handleClose,
	isOpen,
	title,
	confirmLoading,
	onConfirm,
}) => {
	const cancelRef = React.useRef();

	return (
		<AlertDialog
			leastDestructiveRef={cancelRef.current}
			isOpen={isOpen}
			onClose={handleClose}
			isCentered
			motionPreset={"slideInBottom"}>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius={"16px"}>
					<Flex justify="space-between" align="center" pt="24px">
						<AlertDialogHeader py={0} flex={1}>
							<Heading variant="h6" color="darkest" as="h6">
								{title}
							</Heading>
						</AlertDialogHeader>
						<IconButton
							aria-label="close alert dialog"
							icon={<CloseIcon />}
							onClick={handleClose}
							variant="ghost"
							mr="1.5rem"
						/>
					</Flex>
					<Box pb="24px">
						<AlertDialogBody>
							{/*Info Text*/}
							<Box my="24px">{firstDescription}</Box>
							{/*Buttons*/}
							<Flex justifyContent="space-between">
								<RippleButton
									w="48%"
									variant="outline"
									onClick={handleClose}
									ref={cancelRef.current}>
									Cancel
								</RippleButton>
								<RippleButton
									w="48%"
									onClick={onConfirm}
									isLoading={confirmLoading}>
									{confirmLabel}
								</RippleButton>
							</Flex>
						</AlertDialogBody>
					</Box>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
