export const Link = {
	variants: {
		footerLink: {
			fontSize: "16px",
			lineHeight: "26px",
			marginBottom: "8px",
			color: "darkest",
		},
	},
};
