import { createReducer } from "@reduxjs/toolkit";
import {
	getAttendanceClassesRequest,
	getAttendanceClassesSuccess,
	getAttendanceClassesError,
	getAttendanceStudentsRequest,
	getAttendanceStudentsSuccess,
	getAttendanceStudentsError,
	getMoreAttendanceStudentsRequest,
	getMoreAttendanceStudentsSuccess,
	getMoreAttendanceStudentsError,
} from "./actions";
import { PayloadError, SubscriptionPlan } from "../../../common/types";
import { TeacherAttendanceType } from "../../TeacherAttendance/types";

type InitialState = {
	classes: {
		items: SubscriptionPlan[];
		loading: boolean;
		errors: PayloadError[];
	};
	students: {
		items: TeacherAttendanceType[];
		loading: boolean;
		loadMoreLoading: boolean;
		totalCount: number;
		page: number;
		errors: PayloadError[];
	};
	dayOffCount: number;
	presentCount: number;
	absentCount: number;
};

const initialState: InitialState = {
	classes: {
		items: [],
		loading: false,
		errors: [],
	},
	students: {
		items: [],
		loadMoreLoading: false,
		loading: false,
		page: 1,
		totalCount: 0,
		errors: [],
	},
	absentCount: 0,
	presentCount: 0,
	dayOffCount: 0,
};

const attendanceReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getAttendanceClassesRequest, (state) => {
			state.classes.loading = true;
			state.classes.errors = [];
		})
		.addCase(getAttendanceClassesSuccess, (state, action) => {
			state.classes.loading = false;
			state.classes.items = action.payload;
		})
		.addCase(getAttendanceClassesError, (state, action) => {
			state.classes.loading = false;
			state.classes.errors = action.payload;
		})
		.addCase(getAttendanceStudentsRequest, (state) => {
			state.students.loading = true;
			state.students.errors = [];
		})
		.addCase(getAttendanceStudentsSuccess, (state, action) => {
			state.students.loading = false;
			state.students.totalCount = action.payload.totalCount;
			state.students.items = action.payload.students;
			state.absentCount = action.payload.absentCount;
			state.presentCount = action.payload.presentCount;
			state.dayOffCount = action.payload.dayOffCount;
			state.students.page = 1;
		})
		.addCase(getAttendanceStudentsError, (state, action) => {
			state.students.loading = false;
			state.students.errors = action.payload;
		})
		.addCase(getMoreAttendanceStudentsRequest, (state) => {
			state.students.loadMoreLoading = true;
		})
		.addCase(getMoreAttendanceStudentsSuccess, (state, action) => {
			state.students.loadMoreLoading = false;
			state.students.totalCount = action.payload.totalCount;
			state.students.items = [
				...state.students.items,
				...action.payload.students,
			];
			state.absentCount = action.payload.absentCount;
			state.presentCount = action.payload.presentCount;
			state.dayOffCount = action.payload.dayOffCount;
			state.students.page++;
		})
		.addCase(getMoreAttendanceStudentsError, (state) => {
			state.students.loadMoreLoading = false;
		});
});

export default attendanceReducer;
