import React, { useEffect } from "react";
import { Settings } from "./Settings";
import { useSelector, useDispatch } from "react-redux";
import { logoutSuccess } from "../../redux/actions/auth.actions";
import {
	getUserSettingsRequest,
	updateProfileInfoRequest,
	updateProfilePhotoRequest,
	updatePasswordRequest,
} from "./store/actions";
import { EditProfilePayload, UpdatePasswordPayload } from "./types";
import { RootStore } from "../../redux";

export const SettingsContainer: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading: profileLoading,
		profileInfo: { values: profileUser, saveLoading: profileEditLoading },
	} = useSelector((state: RootStore) => state.settings);

	const { saveLoading: photoSaveLoading, image } = useSelector(
		(state: RootStore) => state.settings.photo,
	);
	const { saveLoading: updatePasswordLoading } = useSelector(
		(state: RootStore) => state.settings.password,
	);

	const handleUpdateProfile = (
		values: EditProfilePayload,
		imageFile: FormData,
	) => {
		dispatch(updateProfileInfoRequest({ params: values, imageFile }));
	};
	const handleUpdatePhoto = (imageFile: FormData) => {
		dispatch(updateProfilePhotoRequest(imageFile));
	};
	const handleUpdatePassword = (data: UpdatePasswordPayload) => {
		dispatch(updatePasswordRequest(data));
	};
	const logout = () => {
		dispatch(logoutSuccess());
	};

	useEffect(() => {
		dispatch(getUserSettingsRequest());
	}, []);

	return (
		<Settings
			handleUpdatePassword={handleUpdatePassword}
			updatePasswordLoading={updatePasswordLoading}
			profileEditLoading={profileEditLoading}
			profileUser={profileUser}
			profileLoading={profileLoading}
			handleUpdateProfile={handleUpdateProfile}
			handleUpdatePhoto={handleUpdatePhoto}
			onLogout={logout}
			photoSaveLoading={photoSaveLoading}
			image={image}
		/>
	);
};
