import { all } from "redux-saga/effects";
import loginSaga from "../sagas/auth-sagas/login.saga";
import watchSubscriptionSaga from "../../pages/Subscriptions/store/sagas";
import watchCoursesSaga from "./courses/courses.saga";
import watchDashboardSaga from "../../pages/Dashboard/store/sagas/root.saga";
import watchLogoutSaga from "./auth-sagas/logout.saga";
import watchAttendanceSaga from "../../pages/Attendance/store/saga";
import watchSettingsSaga from "../../pages/Settings/store/saga";
import watchForgotPasswordSaga from "./auth-sagas/forgot-password.saga";
import watchResetPasswordSaga from "./auth-sagas/reset-password.saga";
import watchTeacherAttendanceSaga from "../../pages/TeacherAttendance/store/saga";
import watchMessagesSaga from "../../pages/Messages/store/sagas";
import directoriesSaga from "./directories";
import watchApplicationsSaga from "../../pages/Jobs/store/sagas";
import watchManualInvoicesSaga from "./manual-invoices.sagas";

// prettier-ignore
export default function* rootSaga(){
	yield all([
		loginSaga(),
		watchSubscriptionSaga(),
		watchCoursesSaga(),
		watchDashboardSaga(),
		watchLogoutSaga(),
		watchAttendanceSaga(),
		watchSettingsSaga(),
		watchForgotPasswordSaga(),
		watchResetPasswordSaga(),
		watchTeacherAttendanceSaga(),
		watchMessagesSaga(),
		directoriesSaga(),
		watchApplicationsSaga(),
		watchManualInvoicesSaga(),
	]);
}
