import React from "react";
import { Box, Center, Heading } from "@chakra-ui/react";
import { ForgotPasswordForm } from "./components/RestorePasswordForm";
import { PayloadError } from "../../common/types";

interface IForgotPasswordProps {
	onSubmit: (data: { email: string }) => void;
	loading: boolean;
	errors: PayloadError[];
}

export const ForgotPassword: React.FC<IForgotPasswordProps> = ({
	onSubmit,
	errors,
	loading,
}) => {
	return (
		<Center h="100vh">
			<Box w="500px" px={{ base: "20px", lg: 0 }}>
				<Heading
					as="h4"
					variant="h4"
					textAlign="center"
					mb="20px"
					color="darkest">
					Forgot Password
				</Heading>
				<ForgotPasswordForm
					onForgotPassword={onSubmit}
					loading={loading}
					stateErrors={errors}
				/>
			</Box>
		</Center>
	);
};
