import { Center, Flex, Image, Text, chakra } from "@chakra-ui/react";
import PlaceholderImg from "../../../../assets/pic_staff-placeholder.png";

interface IVacanciesPlaceholderProps {
	setShowCreateVacancyModal: (value: boolean) => void;
}

export const VacanciesPlaceholder: React.FC<IVacanciesPlaceholderProps> = ({
	setShowCreateVacancyModal,
}) => (
	<Center h="100%">
		<Flex direction="column" align="center">
			<Center>
				<Image src={PlaceholderImg} />
			</Center>
			<Text
				variant="body2"
				color="darkGrey"
				w="300px"
				textAlign="center"
				mt="20px">
				There are no vacancies. <br />
				<chakra.span
					as="button"
					display="inline"
					color="primary"
					onClick={() => setShowCreateVacancyModal(true)}>
					Create Vacancy
				</chakra.span>{" "}
				to find new employees
			</Text>
		</Flex>
	</Center>
);
