import { combineReducers } from "redux";
import employeeReducer from "./employee.reducer";
import filterClassesReducer from "./filter-classes.reducer";
import parentsReducer from "./parents.reducer";
import studentSubscriptionReducer from "./student-subscription.reducer";
import studentsReducer from "./students.reducer";

const rootReducer = combineReducers({
	staff: employeeReducer,
	parents: parentsReducer,
	students: studentsReducer,
	filterClasses: filterClassesReducer,
	studentSubscription: studentSubscriptionReducer,
});

export default rootReducer;
