import { ClassesItemType, SubscribersType } from "../../types/test.types";

export const classesItems: ClassesItemType[] = [
	{
		label: "Level 1",
		grade: "3 - 4",
		startDate: "01 March, 2021",
		endDate: "25 March, 2021",
		programmings: [
			{ id: 1, value: "Programming 101" },
			{ id: 2, value: "Programming 102" },
		],
		perMonth: "$175.00",
	},
	{
		label: "Level 2",
		grade: "3 - 4",
		startDate: "01 March, 2021",
		endDate: "25 March, 2021",
		programmings: [
			{ id: 1, value: "Programming 101" },
			{ id: 2, value: "Programming 102" },
		],
		perMonth: "$200.00",
	},
];

export const subscribers: SubscribersType[] = [
	{
		fullName: "Martin Gouse",
		endSubDate: "14:05  •  16 February, 2022",
		activeSub: false,
		per: "$175.00",
		levelValue: "Level 1",
	},
	{
		fullName: "Kaiya Curtis",
		endSubDate: "13:58  •  16 February, 2022",
		activeSub: false,
		per: "$175.00",
		levelValue: "Level 2",
	},
];
export const images = [
	{
		id: 1,
		alt: "pick level 1",
		path: `${process.env.REACT_APP_BASE_URL}/api/v1/images/static-images/level1.png`,
	},
	{
		id: 2,
		alt: "pick level 2",
		path: `${process.env.REACT_APP_BASE_URL}/api/v1/images/static-images/level2.png`,
	},
	{
		id: 3,
		alt: "pick level 3",
		path: `${process.env.REACT_APP_BASE_URL}/api/v1/images/static-images/level3.png`,
	},
];
export const dates = [
	{
		label: "Sun",
		indexDay: 7,
	},
	{
		label: "Mon",
		indexDay: 1,
	},
	{
		label: "Tue",
		indexDay: 2,
	},
	{
		label: "Wen",
		indexDay: 3,
	},
	{
		label: "Thu",
		indexDay: 4,
	},
	{
		label: "Fri",
		indexDay: 5,
	},
	{
		label: "Sat",
		indexDay: 6,
	},
];
export const programmings: { id: string; value: string }[] = [
	{
		id: "1",
		value: "Programming 101",
	},
	{
		id: "2",
		value: "Programming 102",
	},
	{
		id: "3",
		value: "Programming 103",
	},
	{
		id: "4",
		value: "Programming 104",
	},
	{
		id: "5",
		value: "Programming 105",
	},
];

export const ranges = [
	{ value: "1", label: "1" },
	{ value: "2", label: "2" },
	{ value: "3", label: "3" },
	{ value: "4", label: "4" },
	{ value: "5", label: "5" },
	{ value: "6", label: "6" },
	{ value: "7", label: "7" },
	{ value: "8", label: "8" },
	{ value: "9", label: "9" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
];
export const students = [
	{
		id: "1",
		fullName: "John McClaren",
	},
	{
		id: "2",
		fullName: "Rayna Calzoni",
	},
	{
		id: "3",
		fullName: "Talan Schleifer",
	},
	{
		id: "4",
		fullName: "Erin Lipshutz",
	},
	{
		id: "5",
		fullName: "Emerson Lubin",
	},
];

export const classItems = [
	{
		id: 1,
		name: "Level 1",
		startDate: "01 March, 2021",
		endDate: "25 March, 2021",
		countStudents: 12,
	},
	{
		id: 2,
		name: "Level 2",
		startDate: "01 March, 2021",
		endDate: "25 March, 2021",
		countStudents: 14,
	},
];
