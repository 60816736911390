import { Alerter } from "../../../common/utils";
import { put, call, takeEvery, select, ForkEffect } from "redux-saga/effects";
import {
	getUserSettingsRequest,
	getUserSettingsSuccess,
	getUserSettingsError,
	updateProfilePhotoRequest,
	updateProfilePhotoSuccess,
	updateProfilePhotoError,
	updateProfileInfoRequest,
	updateProfileInfoSuccess,
	updateProfileInfoError,
	updatePasswordRequest,
	updatePasswordSuccess,
	updatePasswordError,
} from "./actions";
import API from "../../../common/api/api.base";
import {
	IResponseWithoutValue,
	IResponseWithCustomValue,
} from "../../../common/types";
import { GetInfoProfileOnEditRequest } from "../types";
import { RootStore } from "../../../redux";

//GET SETTINGS
function* workerGetSettings() {
	try {
		const result: IResponseWithCustomValue<GetInfoProfileOnEditRequest> =
			yield call(API.get, "/api/v1/profile");
		if (result.success) {
			yield put(getUserSettingsSuccess(result.value));
		} else {
			yield put(getUserSettingsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//UPDATE PASSWORD PROFILE
function* workerUpdatePasswordProfile(
	action: ReturnType<typeof updatePasswordRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			"/api/v1/profile/change-password",
			action.payload,
		);

		if (result.success) {
			yield put(updatePasswordSuccess());
			yield Alerter.success(result.messages[0].messageText);
		} else {
			yield put(updatePasswordError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//UPDATE PROFILE
function* workerUpdateProfile(
	action: ReturnType<typeof updateProfileInfoRequest>,
) {
	try {
		const { firstName, lastName, phoneNumber, email } =
			action.payload.params;
		const result: IResponseWithoutValue = yield call(
			API.put,
			`/api/v1/profile/edit?FirstName=${firstName}
			&LastName=${lastName}&Email=${email}&PhoneNumber=${phoneNumber}`,
			action.payload.imageFile,
		);
		if (result.success) {
			yield put(updateProfileInfoSuccess());
			yield put(updateProfilePhotoSuccess());
			yield Alerter.success("Updated");
			yield workerGetSettings();
		} else {
			yield put(updateProfileInfoError(result.errors));
			yield put(updateProfilePhotoError());
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//UPDATE PHOTO
function* workerUpdatePhoto(
	action: ReturnType<typeof updateProfilePhotoRequest>,
) {
	try {
		const profileInfo: GetInfoProfileOnEditRequest = yield select(
			(state: RootStore) => state.settings.profileInfo.values,
		);
		const payload = {
			params: {
				firstName: profileInfo.firstName,
				lastName: profileInfo.lastName,
				email: profileInfo.email,
				phoneNumber: profileInfo.phoneNumber,
			},
			imageFile: action.payload,
		};
		yield workerUpdateProfile(updateProfileInfoRequest(payload));
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchSettingsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getUserSettingsRequest.type, workerGetSettings);
	yield takeEvery(updatePasswordRequest.type, workerUpdatePasswordProfile);
	yield takeEvery(updateProfileInfoRequest.type, workerUpdateProfile);
	yield takeEvery(updateProfilePhotoRequest.type, workerUpdatePhoto);
}
