import { createReducer } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { ClassType } from "../../types";
import {
	addClassRequest,
	addClassSuccess,
	addClassError,
	getClassesRequest,
	getClassesSuccess,
	getClassesError,
	editClassRequest,
	editClassSuccess,
	editClassError,
	deleteClassRequest,
	deleteClassSuccess,
	deleteClassError,
	clearSubscriptions,
} from "../actions";

type InitialState = {
	addClass: {
		loading: boolean;
		errors: PayloadError[];
	};
	classes: {
		items: ClassType[];
		errors: PayloadError[];
		loading: boolean;
	};
	editClass: {
		loading: boolean;
		errors: PayloadError[];
	};
	deleteClass: {
		loading: boolean;
		errors: PayloadError[];
	};
};

const initialState: InitialState = {
	addClass: {
		loading: false,
		errors: [],
	},
	classes: {
		loading: false,
		errors: [],
		items: [],
	},
	editClass: {
		errors: [],
		loading: false,
	},
	deleteClass: {
		loading: false,
		errors: [],
	},
};

export const classesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(addClassRequest, (state) => {
			state.addClass.loading = true;
			state.addClass.errors = [];
		})
		.addCase(addClassSuccess, (state) => {
			state.addClass.loading = false;
		})
		.addCase(addClassError, (state, action) => {
			state.addClass.loading = true;
			state.addClass.errors = action.payload;
		})
		.addCase(getClassesRequest, (state) => {
			state.classes.errors = [];
			state.classes.loading = true;
		})
		.addCase(getClassesSuccess, (state, action) => {
			state.classes.items = action.payload.classes;
			state.classes.loading = false;
		})
		.addCase(getClassesError, (state, action) => {
			state.classes.loading = false;
			state.classes.errors = action.payload;
		})
		.addCase(editClassRequest, (state) => {
			state.editClass.loading = true;
			state.editClass.errors = [];
		})
		.addCase(editClassSuccess, (state) => {
			state.editClass.loading = false;
		})
		.addCase(editClassError, (state, action) => {
			state.editClass.errors = action.payload;
			state.editClass.loading = false;
		})
		.addCase(deleteClassRequest, (state) => {
			state.deleteClass.errors = [];
			state.deleteClass.loading = true;
		})
		.addCase(deleteClassSuccess, (state) => {
			state.deleteClass.loading = false;
		})
		.addCase(deleteClassError, (state, action) => {
			state.deleteClass.errors = action.payload;
			state.deleteClass.loading = false;
		})
		.addCase(clearSubscriptions, () => {
			return { ...initialState };
		});
});
