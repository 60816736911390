import { Text, Box, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";

type ISubscriberItemProps = {
	studentName: string | null;
	amount: number;
	name: string;
	date: string;
	isManual: boolean;
};

export const SubscriberItem: React.FC<ISubscriberItemProps> = ({
	amount,
	date,
	name,
	studentName,
	isManual,
}) => (
	<Box
		p={{ base: "8px", lg: "0 10px 0 0" }}
		m={{ lg: "16px 0" }}
		borderRadius={{ base: "8px", lg: "none" }}>
		<Flex justifyContent="space-between" alignItems="center">
			<Box>
				<Text variant="body1" color="darkest">
					{studentName || "Deleted student"}
				</Text>
				<Text variant="body3" color="gray" fontWeight="500">
					{dayjs(date).format("hh:mm A • DD MMMM, YYYY")}{" "}
					{isManual ? " • (Manual)" : ""}
				</Text>
			</Box>
			<Box>
				<Text
					variant={"body3"}
					textAlign="right"
					color={"darkest"}
					fontWeight="500">
					${amount.toFixed(2)}
				</Text>
				<Text
					variant={"caption3"}
					color={"gray"}
					mt={"4px"}
					// isTruncated={true}
					noOfLines={1}
					textAlign="right"
					maxWidth="200px">
					{name}
				</Text>
			</Box>
		</Flex>
	</Box>
);
