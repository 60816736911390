import { createAction } from "@reduxjs/toolkit";
import { PayloadError, SubscriptionPlan } from "../../../../common/types";

export const getFilterClassesRequest = createAction<number>(
	"@messages/GET_FILTER_CLASSES_REQUEST",
);
export const getFilterClassesSuccess = createAction<SubscriptionPlan[]>(
	"@messages/GET_FILTER_CLASSES_SUCCESS",
);
export const getFilterClassessError = createAction<PayloadError[]>(
	"@messages/GET_FILTER_CLASSES_ERROR",
);
