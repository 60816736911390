/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { useState, useCallback, memo, useEffect } from "react";
import { dates } from "../../../common/constants/dammy-date";
import { Checkbox, Flex, HStack, Text, Box } from "@chakra-ui/react";
import { InputTime, RippleButton } from "../../../common/components";
import { UseFormSetValue } from "react-hook-form";
import { IAddLevelForm } from "../modal";
import { ICChek } from "../../../assets/svg-components";
import { WorkingHoursType } from "../../../common/types";

interface DayInfo {
	startTime: string;
	endTime: string;
	isDayOff: boolean;
}
const defaultDayInfo: DayInfo = {
	startTime: "00:00",
	endTime: "00:00",
	isDayOff: false,
};

type IWorkingHoursBoxProps = {
	setValue: UseFormSetValue<IAddLevelForm>;
	defaultValue?: WorkingHoursType[];
};

function checkTime(stime: string, etime: string) {
	return (
		Date.parse("01/01/1000 " + stime) < Date.parse("01/01/1000 " + etime)
	);
}

const getDefaults = (toEditDates: WorkingHoursType[]) => {
	const obj: any = {};
	toEditDates.forEach((elem) => {
		obj[
			dates.find((finder) => finder.indexDay === elem.dayOfWeek)
				?.label as any
		] = {
			...elem,
			startTime: elem.startTime.slice(0, -3),
			endTime: elem.endTime.slice(0, -3),
		};
	});
	return obj;
};

export const WorkingHoursBox = memo<IWorkingHoursBoxProps>(
	({ setValue, defaultValue }) => {
		const [workingHoursData, setWorkingHoursData] = useState<any>(() =>
			defaultValue
				? getDefaults(defaultValue)
				: Object.fromEntries(
						dates.map((item) => [
							item.label,
							{ dayOfWeek: item.indexDay, ...defaultDayInfo },
						]),
				  ),
		);
		const [selectedDayName, setSelectedDayName] = useState("Sun");
		const { endTime, startTime, isDayOff } =
			workingHoursData[selectedDayName];
		const [errMessages, setErrMessages] = useState({
			startTimeError: "",
			endTimeError: "",
		});

		useEffect(() => {
			setValue("workingHours", Object.values(workingHoursData));
		}, [workingHoursData]);

		const handleFormChange = useCallback(
			<T extends keyof DayInfo>(name: T, value: DayInfo[T]) => {
				setWorkingHoursData((prev: any) => ({
					...prev,
					[selectedDayName]: {
						...prev[selectedDayName],
						[name]: value,
					},
				}));
			},
			[selectedDayName],
		);

		return (
			<>
				<HStack
					my={"24px"}
					justifyContent={{ base: "space-between", md: "" }}>
					{dates.map((item) => (
						<RippleButton
							fontSize={{ base: "12px", md: "16px" }}
							key={item.label}
							variant={"outline"}
							border={selectedDayName === item.label ? "2px" : ""}
							borderColor={
								selectedDayName === item.label ? "#B78EDD" : ""
							}
							onClick={() => setSelectedDayName(item.label)}
							_focus={{ boxShadow: "none" }}
							color={
								selectedDayName === item.label
									? "#B78EDD"
									: "#1E0E62"
							}>
							{item.label}
						</RippleButton>
					))}
				</HStack>
				<Box my={"24px"}>
					<Checkbox
						isChecked={isDayOff}
						sx={{
							".chakra-checkbox__control": {
								borderRadius: "50%",
								border: "1px solid #8C4BC9",
							},
						}}
						icon={<ICChek enabled={isDayOff} />}
						onChange={(ev) =>
							handleFormChange("isDayOff", ev.target.checked)
						}>
						<Text variant={"caption3"}>No Lesson</Text>
					</Checkbox>
				</Box>
				<Flex justifyContent={"space-between"}>
					<Box w={"48%"}>
						<InputTime
							name={"startTime"}
							isDisabled={isDayOff}
							label={"Start time"}
							value={startTime}
							onChange={(ev) =>
								handleFormChange("startTime", ev.target.value)
							}
							onBlur={(ev) => {
								const isError = !checkTime(
									ev.target.value,
									workingHoursData[selectedDayName].endTime,
								);

								if (isError) {
									setErrMessages((prev) => ({
										...prev,
										startTimeError:
											"Should be earlier than End time",
									}));
								} else {
									setErrMessages((prev) => ({
										...prev,
										startTimeError: "",
									}));
								}
							}}
							errorMsg={errMessages.startTimeError}
						/>
					</Box>
					<Box w={"48%"}>
						<InputTime
							name={"endTime"}
							isDisabled={isDayOff}
							value={endTime}
							onBlur={(ev) => {
								const isError = !checkTime(
									workingHoursData[selectedDayName].startTime,
									ev.target.value,
								);

								if (isError) {
									setErrMessages((prev) => ({
										...prev,
										endTimeError:
											"Should be later than Start Time",
									}));
								} else {
									setErrMessages((prev) => ({
										...prev,
										endTimeError: "",
										startTimeError: "",
									}));
								}
							}}
							onChange={(ev) =>
								handleFormChange("endTime", ev.target.value)
							}
							label={"End time"}
							errorMsg={errMessages.endTimeError}
						/>
					</Box>
				</Flex>
			</>
		);
	},
);
WorkingHoursBox.displayName = "WorkingHoursBox";
