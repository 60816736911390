import { useEffect } from "react";
import {
	Modal,
	InfiniteScroll,
	RippleButton,
	Loader,
} from "../../../../common/components";
import {
	ModalBody,
	ModalFooter,
	Box,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/ic_arrow-left.svg";
import {
	getStudentSubscriptionPlansRequest,
	getStudentPaymentsRequest,
	getMoreStudentPaymentsRequest,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { StudentResponseType, StudentPaymentType } from "../../types";
import { PlanItem, PaymentItem } from "./components";
import { SubscriptionPlan } from "../../../../common/types";

type IProps = {
	isOpen: boolean;
	handleClose: () => void;
	studentInfo: StudentResponseType;
};

export const StudentSubscriptionInfoModal: React.FC<IProps> = ({
	isOpen,
	handleClose,
	studentInfo,
}) => {
	const dispatch = useDispatch();

	const {
		items: paymentItems,
		loading: paymentLoading,
		totalCount: paymentsTotalCount,
		loadMoreLoading: paymentsLoadMoreLoading,
	} = useSelector(
		(state: RootStore) => state.dashboard.studentSubscription.payments,
	);
	const { items: planItems, loading: planLoading } = useSelector(
		(state: RootStore) => state.dashboard.studentSubscription.plans,
	);

	useEffect(() => {
		if (isOpen) {
			dispatch(getStudentSubscriptionPlansRequest(studentInfo.id));
			dispatch(getStudentPaymentsRequest({ studentId: studentInfo.id }));
		}
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title={"Subscription Info"}
			size={"md"}
			scrollBehavior="outside">
			<ModalBody>
				{/*	Info Subscription Box*/}
				{planLoading ? (
					<Loader centerHeight="100px" />
				) : (
					<>
						{planItems.length ? (
							<InfiniteScroll>
								{planItems.map((plan: SubscriptionPlan) => (
									<PlanItem
										key={plan.id}
										name={plan.name}
										image={plan.image}
										price={plan.amount || 0}
										startDate={plan.startDate}
										endDate={plan.endDate}
										isActive={plan.isActive}
									/>
								))}
							</InfiniteScroll>
						) : (
							<Text
								variant="body1"
								color="darkest"
								textAlign="center">
								No plans to show
							</Text>
						)}
					</>
				)}

				{/*	Invoices*/}

				{/*Invoices List*/}
				<Box>
					{paymentLoading ? (
						<Loader centerHeight="100px" />
					) : (
						<>
							<Heading variant={"h8"} mt={"24px"}>
								Invoices
							</Heading>
							{paymentItems.length ? (
								<>
									<Flex
										justifyContent={"space-between"}
										pb={"8px"}
										borderBottom={"1px solid #F3ECFD"}>
										<Text variant={"subtitle2"}>Date</Text>
										<Text fontSize={"inputText"}>
											Amount
										</Text>
									</Flex>
									<InfiniteScroll
										onReachBottom={() => {
											if (
												!paymentsLoadMoreLoading &&
												paymentsTotalCount >
													paymentItems.length
											) {
												dispatch(
													getMoreStudentPaymentsRequest(
														{
															studentId:
																studentInfo.id,
														},
													),
												);
											}
										}}>
										{paymentItems.map(
											(payment: StudentPaymentType) => (
												<PaymentItem
													key={payment.id}
													amount={payment.amount}
													date={payment.confirmedAt}
												/>
											),
										)}
										{paymentsLoadMoreLoading && (
											<Loader
												centerHeight="100%"
												spinnerSize="md"
											/>
										)}
									</InfiniteScroll>
								</>
							) : (
								<Text
									variant="body1"
									color="darkest"
									textAlign="center">
									No invoices to show
								</Text>
							)}
						</>
					)}
				</Box>
			</ModalBody>
			<ModalFooter>
				<RippleButton
					onClick={handleClose}
					w={"100%"}
					variant={"outline"}
					leftIcon={<ArrowLeftIcon />}>
					Student Info
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
