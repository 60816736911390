import { createReducer } from "@reduxjs/toolkit";
import {
	getApplicationsRequest,
	getApplicationsSuccess,
	getApplicationsError,
	getMoreApplicationsRequest,
	getMoreApplicationsSuccess,
	getMoreApplicationsError,
	getApplicationByIdRequest,
	getApplicationByIdSuccess,
	getApplicationByIdError,
	clearApplicationById,
	deleteApplicationRequest,
	deleteApplicationSuccess,
	deleteApplicationError,
} from "../actions";
import { PayloadError } from "../../../../common/types";
import { SlimVacancyApplication, FullVacancyApplication } from "../../types";

type InitialState = {
	deleteApplication: {
		loading: boolean;
		errors: PayloadError[];
	};
	values: {
		items: SlimVacancyApplication[];
		loadMoreLoading: boolean;
		page: number;
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};

	targetApplication: { loading: boolean; values: FullVacancyApplication };
};

const initialState: InitialState = {
	deleteApplication: {
		errors: [],
		loading: false,
	},
	values: {
		errors: [],
		items: [],
		loadMoreLoading: false,
		loading: false,
		page: 1,
		totalCount: 0,
	},
	targetApplication: {
		loading: false,
		values: {
			jobPosition: {
				id: 0,
				title: "",
				team: { id: 0, name: "" },
				commitment: { id: 0, name: "" },
				location: "",
			},
			fullName: "",
			email: "",
			phone: "",
			currentCompany: "",
			linkedinUrl: "",
			twitterUrl: "",
			githubUrl: "",
			portfolioUrl: "",
			otherWebsiteUrl: "",
			additionalInfo: "",
			resumeHash: "",
		},
	},
};

const applicationsReducer = createReducer(initialState, (builder) => {
	builder
		// Get applications
		.addCase(getApplicationsRequest, (state) => {
			state.values.loading = true;
			state.values.errors = [];
		})
		.addCase(getApplicationsSuccess, (state, action) => {
			state.values.loading = false;
			state.values.items = action.payload.entities;
			state.values.totalCount = action.payload.totalCount;
			state.values.page = 1;
		})
		.addCase(getApplicationsError, (state, action) => {
			state.values.loading = false;
			state.values.errors = action.payload;
		})
		// Load more applications
		.addCase(getMoreApplicationsRequest, (state) => {
			state.values.loadMoreLoading = true;
		})
		.addCase(getMoreApplicationsSuccess, (state, action) => {
			state.values.loadMoreLoading = false;
			state.values.items = action.payload.entities;
			state.values.totalCount = action.payload.totalCount;
			state.values.page++;
		})
		.addCase(getMoreApplicationsError, (state) => {
			state.values.loadMoreLoading = false;
		})
		// Get application by Id
		.addCase(getApplicationByIdRequest, (state) => {
			state.targetApplication.loading = true;
		})
		.addCase(getApplicationByIdSuccess, (state, action) => {
			state.targetApplication.loading = false;
			state.targetApplication.values = action.payload;
		})
		.addCase(getApplicationByIdError, (state) => {
			state.targetApplication.loading = false;
		})
		.addCase(clearApplicationById, (state) => {
			state.targetApplication = { ...initialState.targetApplication };
		})
		// Delete application
		.addCase(deleteApplicationRequest, (state) => {
			state.deleteApplication.loading = true;
			state.deleteApplication.errors = [];
		})
		.addCase(deleteApplicationSuccess, (state) => {
			state.deleteApplication.loading = false;
		})
		.addCase(deleteApplicationError, (state, action) => {
			state.deleteApplication.loading = false;
			state.deleteApplication.errors = action.payload;
		});
});

export default applicationsReducer;
