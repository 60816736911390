import {
	getMoreParentsError,
	getMoreParentsRequest,
	getMoreParentsSuccess,
	getParentsError,
	getParentsRequest,
	getParentsSuccess,
	setPermanentDiscountRequest,
	setPermanentDiscountSuccess,
	setPermanentDiscountError,
} from "../actions";
import {
	IResponseWithCustomValue,
	IResponseWithoutValue,
} from "../../../../common/types";
import { ParentResponseType } from "../../types";
import { call, ForkEffect, put, select, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { quantity } from "../../../../common/constants";
import { Alerter } from "../../../../common/utils";
import { RootStore } from "../../../../redux";

// Get Parents
function* workerGetParents(action: ReturnType<typeof getParentsRequest>) {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: ParentResponseType[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.PARENTS_TAKE}&StaffRole=2${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}&SortByAscending=${action.payload.sortByAscending}${
				action.payload.subscriptionPlanId !== "all" &&
				action.payload.subscriptionPlanId !== "archived"
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getParentsSuccess(result.value));
		} else {
			yield put(getParentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
// Get More Parents

function* workerGetMoreParents(
	action: ReturnType<typeof getMoreParentsRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.dashboard.parents.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: ParentResponseType[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.PARENTS_TAKE}&PageNumber=${
				page + 1
			}&StaffRole=2${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}&SortByAscending=${action.payload.sortByAscending}${
				action.payload.subscriptionPlanId !== "all" &&
				action.payload.subscriptionPlanId !== "archived"
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getMoreParentsSuccess(result.value));
		} else {
			yield put(getMoreParentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerSetPermanentDiscount(
	action: ReturnType<typeof setPermanentDiscountRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			"/api/v1/discounts",
			action.payload,
		);
		if (result.success) {
			Alerter.success(
				`Discount successfully ${
					action.payload.isDiscountApplicable ? "applied" : "removed"
				}`,
			);
		} else {
			yield put(setPermanentDiscountError(action.payload));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchDashBoardParentsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getParentsRequest.type, workerGetParents);
	yield takeEvery(getMoreParentsRequest.type, workerGetMoreParents);
	yield takeEvery(setPermanentDiscountRequest.type, workerSetPermanentDiscount);
}
