import {
	IResponseWithCustomValue,
	IResponseWithoutValue,
} from "../../../../common/types";
import { StaffItem } from "../../types";
import { call, ForkEffect, put, select, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
	deleteEmployeeError,
	deleteEmployeeRequest,
	deleteEmployeeSuccess,
	editEmployeeError,
	editEmployeeRequest,
	editEmployeeSuccess,
	getEmployeesError,
	getEmployeesRequest,
	getEmployeesSuccess,
	getMoreEmployeesError,
	getMoreEmployeesRequest,
	getMoreEmployeesSuccess,
	inviteEmployeeError,
	inviteEmployeeRequest,
	inviteEmployeeSuccess,
} from "../actions";
import { Alerter } from "../../../../common/utils";
import { RootStore } from "../../../../redux";
import { quantity } from "../../../../common/constants";

//Get Employees
export function* workerGetEmployees() {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			staffUsers: StaffItem[];
		}> = yield call(API.get, "/api/v1/users/staff");
		if (result.success) {
			yield put(getEmployeesSuccess(result.value));
		} else {
			yield put(getEmployeesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//Get More Employees
function* workerGetMoreEmployees() {
	try {
		const page: number = yield select(
			(state: RootStore) => state.dashboard.staff.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			staffUsers: StaffItem[];
		}> = yield call(
			API.get,
			`/api/v1/users/staff?PageSize=${quantity.EMPLOYEES_TAKE}&PageNumber=${page}`,
		);
		if (result.success) {
			yield put(getMoreEmployeesSuccess(result.value));
		} else {
			yield put(getMoreEmployeesError());
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//Get Invite Employee
function* workerInviteEmployee(
	action: ReturnType<typeof inviteEmployeeRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/users/invite",
			action.payload,
		);
		if (result.success) {
			yield put(inviteEmployeeSuccess());
			Alerter.success("Invite successfully sent");
		} else {
			yield put(inviteEmployeeError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//Delete Employee
function* workerDeleteEmployee(
	action: ReturnType<typeof deleteEmployeeRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.delete,
			`/api/v1/users/${action.payload}/delete`,
		);
		if (result.success) {
			yield put(deleteEmployeeSuccess());
			Alerter.success("Employee was successfully deleted");
			yield workerGetEmployees();
		} else {
			yield put(deleteEmployeeError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
// Edit Employee
function* workerEditEmployee(action: ReturnType<typeof editEmployeeRequest>) {
	try {
		const { params } = action.payload;
		const result: IResponseWithoutValue = yield call(
			API.put,
			`/api/v1/users/edit?UserId=${params.userId}&FirstName=${params.firstName}&LastName=${params.lastName}&Email=${params.email}&PositionName=${params.positionName}&StaffRole=${params.staffRole}`,
			action.payload.imageFile,
		);
		if (result.success) {
			yield put(editEmployeeSuccess());
			Alerter.success("Successfully edited");
			yield workerGetEmployees();
		} else {
			yield put(editEmployeeError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchDashboardEmployeeSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getEmployeesRequest.type, workerGetEmployees);
	yield takeEvery(getMoreEmployeesRequest.type, workerGetMoreEmployees);
	yield takeEvery(editEmployeeRequest, workerEditEmployee);
	yield takeEvery(deleteEmployeeRequest, workerDeleteEmployee);
	yield takeEvery(inviteEmployeeRequest.type, workerInviteEmployee);
}
