import { Route, Redirect, RouteProps } from "react-router-dom";

interface UnathorizedRouteProps extends RouteProps {
	isSignedIn: boolean;
	component: any;
}

export const UnathorizedRoute: React.FC<UnathorizedRouteProps> = ({
	isSignedIn,
	...rest
}) => {
	const { component: Component } = rest;

	return (
		<Route
			path={rest.path}
			render={(routeProps) => {
				return isSignedIn ? (
					<Redirect
						to={{
							pathname: "/dashboard/main",
						}}
					/>
				) : (
					<Component {...routeProps} />
				);
			}}
		/>
	);
};
