import axios from "axios";
import { PayloadMessage, PayloadError } from "../types/api.types";
import intercept from "./interceptor";
import { store } from "../../redux";
import { setAuthHeaderToken } from "../utils";

intercept();

export const baseUrl = process.env.REACT_APP_BASE_URL;

axios.defaults.baseURL = baseUrl;

interface IApiResponse {
	success: boolean;
	errors: PayloadError[];
	messages: PayloadMessage[];
	value?: { [key: string]: string | number | null };
}

type KeyValue<U> = {
	[key: string]: U;
};

class Api<T> {
	async get(url: string, headers: KeyValue<string> = {}) {
		const token = store.getState().session.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.get(url, {
				headers,
			})
			.catch((err) => ({ data: err.response.data }));
		return response.data;
	}

	async post(
		url: string,
		body: T,
		headers: KeyValue<string> = {},
	): Promise<IApiResponse> {
		const token = store.getState().session.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.post(url, body, {
				headers: {
					...headers,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
			})
			.catch((err) => ({ data: err.response.data }));

		return response.data;
	}

	async put(url: string, body: T, headers: KeyValue<string> = {}) {
		const token = store.getState().session.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.put(url, body, {
				headers,
			})
			.catch((err) => ({
				data: err.response.data,
			}));
		return response.data;
	}
	async patch(url: string, body: T, headers: KeyValue<string> = {}) {
		const token = store.getState().session.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.patch(url, body, {
				headers,
			})
			.catch((err) => ({
				data: err.response.data,
			}));
		return response.data;
	}

	async delete(url: string, body?: T, headers: KeyValue<string> = {}) {
		const token = store.getState().session.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.delete(url, {
				data: body,
				headers,
			})
			.catch((err) => ({
				data: err.response.data,
			}));
		return response.data;
	}
}

export default new Api();
