import {
	createJobPositionRequest,
	createJobPositionSuccess,
	createJobPositionError,
	getVacanciesRequest,
	getVacanciesSuccess,
	getVacanciesError,
	getMoreVacanciesRequest,
	getMoreVacanciesSuccess,
	getMoreVacanciesError,
	getVacancyByIdRequest,
	getVacancyByIdSuccess,
	getVacancyByIdError,
	editVacancyRequest,
	editVacancySuccess,
	editVacancyError,
	deleteVacancyRequest,
	deleteVacancySuccess,
	deleteVacancyError,
} from "../actions";
import { call, ForkEffect, put, takeEvery, select } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { Alerter } from "../../../../common/utils";
import { IResponseWithCustomValue } from "../../../../common/types";
import { IVacancy, IFullVacancy } from "../../types";
import { quantity } from "../../../../common/constants";
import { RootStore } from "../../../../redux";

function* workerGetVacancies() {
	try {
		const result: IResponseWithCustomValue<{
			entities: IVacancy[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/job-positions/job-positions?PageSize=${quantity.VACANCIES_TAKE}&PageNumber=1`,
		);
		if (result.success) {
			yield put(getVacanciesSuccess(result.value));
		} else {
			yield put(getVacanciesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerCreateJobPosition(
	action: ReturnType<typeof createJobPositionRequest>,
) {
	try {
		const result: IResponseWithCustomValue<number> = yield call(
			API.post,
			"/api/v1/job-positions/job-position",
			action.payload,
		);
		if (result.success) {
			yield put(createJobPositionSuccess());
			Alerter.success("Vacancy successfully created");
			yield workerGetVacancies();
		} else {
			yield put(createJobPositionError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreVacancies() {
	try {
		const page: number = yield select(
			(state: RootStore) => state.jobs.vacancies.vacancies.page,
		);
		const result: IResponseWithCustomValue<{
			entities: IVacancy[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/job-positions/job-positions?PageSize=${
				quantity.VACANCIES_TAKE
			}&PageNumber=${page + 1}`,
		);
		if (result.success) {
			yield put(getMoreVacanciesSuccess(result.value));
		} else {
			yield put(getMoreVacanciesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetVacancyById(
	action: ReturnType<typeof getVacancyByIdRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			positionFullInfo: IFullVacancy;
		}> = yield call(API.get, `/api/v1/job-positions/${action.payload}`);
		if (result.success) {
			yield put(getVacancyByIdSuccess(result.value.positionFullInfo));
		} else {
			yield put(getVacancyByIdError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerEditJobPosition(action: ReturnType<typeof editVacancyRequest>) {
	try {
		const result: IResponseWithCustomValue<number> = yield call(
			API.put,
			"/api/v1/job-positions/job-position",
			action.payload,
		);
		if (result.success) {
			yield put(editVacancySuccess());
			Alerter.success("Successfully edited");
			yield workerGetVacancies();
		} else {
			yield put(editVacancyError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerDeleteVacancy(action: ReturnType<typeof deleteVacancyRequest>) {
	try {
		const result: IResponseWithCustomValue<number> = yield call(
			API.delete,
			`/api/v1/job-positions/${action.payload}/job-position`,
		);
		if (result.success) {
			yield put(deleteVacancySuccess());
			Alerter.success("Successfully deleted");
			yield workerGetVacancies();
		} else {
			yield put(deleteVacancyError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchVacanciesSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(createJobPositionRequest.type, workerCreateJobPosition);
	yield takeEvery(getVacanciesRequest.type, workerGetVacancies);
	yield takeEvery(getMoreVacanciesRequest.type, workerGetMoreVacancies);
	yield takeEvery(getVacancyByIdRequest.type, workerGetVacancyById);
	yield takeEvery(editVacancyRequest.type, workerEditJobPosition);
	yield takeEvery(deleteVacancyRequest.type, workerDeleteVacancy);
}
