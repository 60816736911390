import { RootStore } from "./../../../../redux";
import { StaffItem } from "./../../../Dashboard/types";
import { IResponseWithCustomValue } from "../../../../common/types";
import { call, ForkEffect, put, takeEvery, select } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
	getStaffForMessagesRequest,
	getStaffForMessagesSuccess,
	getStaffForMessagesError,
	getMoreStaffForMessagesRequest,
	getMoreStaffForMessagesSuccess,
	getMoreStaffForMessagesError,
} from "../actions";
import { Alerter } from "../../../../common/utils";
import { quantity } from "../../../../common/constants";

function* workerGetStaff(
	action: ReturnType<typeof getStaffForMessagesRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: StaffItem[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.PARENTS_TAKE}&StaffRole=1${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getStaffForMessagesSuccess(result.value));
		} else {
			yield put(getStaffForMessagesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreStaff(
	action: ReturnType<typeof getMoreStaffForMessagesRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.messages.staff.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: StaffItem[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.PARENTS_TAKE}&PageNumber=${
				page + 1
			}&StaffRole=1${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getMoreStaffForMessagesSuccess(result.value));
		} else {
			yield put(getMoreStaffForMessagesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchMessagesStaffSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	>  {
	yield takeEvery(getStaffForMessagesRequest.type, workerGetStaff);
	yield takeEvery(getMoreStaffForMessagesRequest.type, workerGetMoreStaff);
}
