import { ClassType } from "../../pages/Subscriptions/types";
import { createReducer } from "@reduxjs/toolkit";
import { PayloadError } from "../../common/types";
import {
	getClassesForLevelsRequest,
	getClassesForLevelsSuccess,
	getClaassesForLevelsError,
} from "../actions/classes.actions";

type InitialState = {
	loading: boolean;
	errors: PayloadError[];
	items: ClassType[];
};
const initialState: InitialState = {
	loading: false,
	errors: [],
	items: [],
};

const classesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getClassesForLevelsRequest, (state) => {
			state.loading = true;
		})
		.addCase(getClassesForLevelsSuccess, (state, action) => {
			state.loading = false;
			state.errors = [];
			state.items = action.payload.classes;
		})
		.addCase(getClaassesForLevelsError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		});
});
export default classesReducer;
