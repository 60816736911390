import { Box } from "@chakra-ui/react";
import { Loader, InfiniteScroll } from "../../../../common/components";
import { LevelsPlaceholder } from "../../components/placeholders";
import { LevelItem } from "../../components";
import { SubscriptionPlan, ImageType } from "../../../../common/types";
import { ClassItemType, EditDateInfo } from "../../types";

interface ILevelsTabProps {
	levelsLoading: boolean;
	belowDesktop?: boolean;
	levelsItems: SubscriptionPlan[];
	setSelectedLevel: (value: SubscriptionPlan) => void;
	setAlertAvatar: (value: ImageType | null | string) => void;
	setShowArchiveAlert: (value: boolean) => void;
	setShowRemoveAlert: (value: boolean) => void;
	setShowAddLevelModal: (value: boolean) => void;
	setToEditLevelSelected: (value: SubscriptionPlan) => void;
	setToEditDatesData: (value: EditDateInfo) => void;
	selectedPlan: number;
	setSelectedPlan: (value: number) => void;
}

export const LevelsTab: React.FC<ILevelsTabProps> = ({
	belowDesktop,
	levelsLoading,
	levelsItems,
	setSelectedLevel,
	setAlertAvatar,
	setShowArchiveAlert,
	setShowRemoveAlert,
	setShowAddLevelModal,
	setToEditLevelSelected,
	setToEditDatesData,
	selectedPlan,
	setSelectedPlan,
}) => {
	return (
		<>
			{levelsLoading ? (
				<Loader centerHeight="calc(100vh - 300px)" />
			) : (
				<>
					{levelsItems.length ? (
						<Box
							width={{ base: "calc(95vw - 20px)", lg: "auto" }}
							height={{ base: "auto", lg: "calc(100vh - 300px)" }}
							style={{
								WebkitOverflowScrolling: "touch",
							}}>
							<InfiniteScroll horizontal={belowDesktop}>
								{levelsItems.map((item) => (
									<LevelItem
										key={item.id}
										item={item}
										setSelectedClass={() => {
											setSelectedLevel(item);
											setAlertAvatar(item.image);
										}}
										setShowArchiveAlert={
											setShowArchiveAlert
										}
										setShowRemoveAlert={setShowRemoveAlert}
										onEditLevel={() => {
											setToEditLevelSelected(item);
										}}
										onEditDates={() => {
											setToEditDatesData({
												id: item.id,
												startDate: item.startDate,
												endDate: item.endDate,
											});
										}}
										selectedPlan={selectedPlan}
										setSelectedPlan={setSelectedPlan}
									/>
								))}
							</InfiniteScroll>
						</Box>
					) : (
						<LevelsPlaceholder
							createLevel={() => setShowAddLevelModal(true)}
						/>
					)}
				</>
			)}
		</>
	);
};
