/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { ImageType } from "../../common/types";

export function getPictureURL(
	photo?: ImageType,
	width = 0,
	height = 0,
): string | undefined {
	return photo?.itemHash
		? `${process.env.REACT_APP_BASE_URL}/api/v1/images/${width}/${height}/${photo.itemHash}${photo.extension}`
		: undefined;
}
