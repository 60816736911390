import { Box, Flex, Text, Avatar, Button, HStack } from "@chakra-ui/react";
import { getPictureURL, formatter } from "../../../../../../../common/utils";
import { PendingManualSubscription } from "../../../../../../../common/types";
import dayjs from "dayjs";

interface IInvoiceItemProp extends PendingManualSubscription {
	onSubmitInvoice: (value: number) => void;
	loading: boolean;
}

export const InvoiceItem: React.FC<IInvoiceItemProp> = ({
	amount,
	createdAt,
	id,
	onSubmitInvoice,
	parentFullName,
	studentName,
	subscriptionPlans,
	parentImage,
	loading,
}) => {
	return (
		<Flex align="center" justify="space-between" my="15px">
			<HStack align="flex-start">
				<Avatar
					name={parentFullName}
					src={getPictureURL(parentImage)}
					w="48px"
					h="48px"
					borderRadius="24px"
					mr="16px"
				/>
				<Box pr="20px">
					<Text variant="body1" color="darkest">
						{parentFullName}
					</Text>
					<Text variant="body3" color="grey">
						{studentName} •{" "}
						{subscriptionPlans.map((elem) => elem.name).join(", ")}
					</Text>
					<Text color="grey" variant="body3">
						{dayjs(createdAt).format("DD MMMM, YYYY HH:mm")}
					</Text>
				</Box>
			</HStack>

			<HStack flexShrink={0}>
				<Text color="darkest" variant="subtitle1" mr="20px">
					{formatter.format(amount)}
				</Text>
				<Button
					variant="plain"
					isLoading={loading}
					onClick={() => {
						if (
							window.confirm(
								"Are you sure you want to confirm this invoice?",
							)
						) {
							onSubmitInvoice(id);
						}
					}}>
					Confirm invoice
				</Button>
			</HStack>
		</Flex>
	);
};
