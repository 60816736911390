import { useState } from "react";
import { Modal, RippleButton } from "../../../../common/components";
import {
	ModalBody,
	ModalFooter,
	Button,
	Box,
	SimpleGrid,
} from "@chakra-ui/react";
import dayjs from "dayjs";

interface ISelectYearModal {
	handleClose: () => void;
	isOpen: boolean;
	setAfterDate: (value: string) => void;
	setBeforeDate: (value: string) => void;
	afterDate: string;
}

export const SelectYearModal: React.FC<ISelectYearModal> = ({
	handleClose,
	isOpen,
	afterDate,
	setAfterDate,
	setBeforeDate,
}) => {
	const [selectedYear, setSelectedYear] = useState<number>(
		dayjs(afterDate).year(),
	);

	const selectYear = () => {
		setAfterDate(dayjs.utc(new Date(selectedYear, 0, 2)).format());
		setBeforeDate(dayjs.utc(new Date(selectedYear, 11, 32)).format());
	};

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Select Year">
			<ModalBody>
				<Box px={{ base: "0px", lg: "30px" }}>
					<SimpleGrid columns={3} spacing="10px" my="30px">
						{Array(15)
							.fill("")
							.map((_elem, index) => (
								<Button
									key={index}
									variant="unstyled"
									fontSize="14px"
									lineHeight="22px"
									color="darkest"
									border={`1px solid ${
										selectedYear === 2014 + index
											? "#8C4BC9"
											: "#EBEBEB"
									}`}
									fontWeight="500"
									height="46px"
									onClick={() =>
										setSelectedYear(2014 + index)
									}>
									{2014 + index}
								</Button>
							))}
					</SimpleGrid>
				</Box>
			</ModalBody>
			<ModalFooter>
				<RippleButton
					variant="outline"
					onClick={handleClose}
					width="100%">
					Cancel
				</RippleButton>
				<Box w="20px" />
				<RippleButton
					onClick={() => {
						selectYear();
						handleClose();
					}}
					width="100%">
					Select
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
