export const ErrorMessages = {
	REQUIRED: "This field is required",
	EMAIL_INVALID: "Email is invalid",
	DATE_INVALID: "You sholud be at least 21 year old to sign up",
	NUMBER_INVALID: "This field should be a number",
	SHORT_PASSWORD: "Pasword should be minimum 6 characters long",
	URL_INVALID: "Invalid URL",
	TIME_DURATION: "Time duration is not correct",
	POSITIVE_NUMBER: "This value should be greater than 0",
	PASSWORD_UPPERCASE_CHAR: "Password should have at least one uppercase char",
	PASSWORD_DIGIT: "Password should have at least one digit",
	PASSWORD_MATCH: "Passwords do not match",
};
