import { Modal, InputText } from "../../../../common/components";
import {
	Avatar,
	ModalBody,
	VStack,
	Flex,
	Box,
	Center,
	Link,
	Button,
} from "@chakra-ui/react";
import { ReactComponent as MessageIcon } from "../../../../assets/ic_message.svg";
import { TeacherAttendanceType } from "../../types";
import { getPictureURL } from "../../../../common/utils";

type IStudentModalProps = {
	handleClose: () => void;
	isOpen: boolean;
	studentInfo: TeacherAttendanceType;
};

export const StudentInfoModal: React.FC<IStudentModalProps> = ({
	handleClose,
	isOpen,
	studentInfo,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title={"Student Info"}
			size={"md"}>
			<ModalBody>
				<Center>
					{/*Avatar*/}
					<Avatar
						w={"120px"}
						h={"120px"}
						borderRadius={"50%"}
						name={studentInfo.name}
						src={getPictureURL(studentInfo.photo)}
					/>
				</Center>
				<VStack spacing={"10px"}>
					<Box w={"100%"}>
						<InputText
							label={"Student Name"}
							value={studentInfo.name}
							isReadOnly={true}
						/>
					</Box>
					<Flex
						justifyContent={"space-between"}
						align={"center"}
						w={"100%"}>
						<Box w={"48%"}>
							<InputText
								label={"Students Age"}
								value={studentInfo.age}
								isReadOnly={true}
							/>
						</Box>
						<Box w={"48%"}>
							<InputText
								label={"Students Grade"}
								value={studentInfo.grade}
								isReadOnly={true}
							/>
						</Box>
					</Flex>
					<Box w={"100%"}>
						<InputText
							label={"Parent"}
							value={studentInfo.parentFullName}
							isReadOnly={true}
						/>
					</Box>
					<Box w={"100%"}>
						<InputText
							label={"Class"}
							value={studentInfo.paidSubscriptionPlanNames.join(
								", ",
							)}
							isReadOnly={true}
						/>
					</Box>
				</VStack>

				<Button
					rightIcon={<MessageIcon />}
					width={"100%"}
					as={Link}
					href={`mailto:${studentInfo.parentEmail}`}
					variant={"outline"}>
					Contact Parents
				</Button>
			</ModalBody>
		</Modal>
	);
};
