import PlaceholderPic from "../../../../assets/pic_payment_method.png";
import { Center, Box, Image, Text } from "@chakra-ui/react";

export const MessagesPlaceholder: React.FC = () => (
	<Center h="100%">
		<Box>
			<Image src={PlaceholderPic} />
			<Text textAlign="center" color="darkGrey" mt="20px" variant="body2">
				There are no messages here.
			</Text>
		</Box>
	</Center>
);
