export const months = [
	{ label: "January", value: 0 },
	{ label: "February", value: 1 },
	{ label: "March", value: 2 },
	{ label: "April", value: 3 },
	{ label: "May", value: 4 },
	{ label: "June", value: 5 },
	{ label: "July", value: 6 },
	{ label: "August", value: 7 },
	{ label: "September", value: 8 },
	{ label: "October", value: 9 },
	{ label: "November", value: 10 },
	{ label: "December", value: 11 },
];

export const monthLabels = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const dayLabels = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];
