import { useState, useEffect } from "react";
import { Subscriptions } from "./Subscriptions";
import {
	getLevelsForSubscriptionsRequest,
	clearSubscriptions,
	getAllSubscriptionsRequest,
	getMoreAllSubscriptionsRequest,
	getClassesRequest,
	getPlanSubscriptionsRequest,
	getMorePlanSubscriptionsRequest,
} from "./store/actions";
import { getClassesForLevelsRequest } from "../../redux/actions/classes.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux";
import { useDebounce } from "../../common/utils";
import { useDelta } from "react-delta";

export const SubscriptionsContainer: React.FC = () => {
	const dispatch = useDispatch();
	const [showAddLevelModal, setShowAddLevelModal] = useState(false);
	const [showAddClassModal, setShowAddClassModal] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [tabIndex, setTabIndex] = useState(0);
	const debouncedSearchTerm = useDebounce(searchQuery, 500);
	const [selectedPlan, setSelectedPlan] = useState(0);
	const [withArchivedFilter, setWithArchivedFilter] = useState(false);

	const searchQueryDelta = useDelta(debouncedSearchTerm);
	const tabIndexDelta = useDelta(tabIndex);

	const {
		items: subscriptionItems,
		loading: subscriptionsLoading,
		totalCount: subscriptionsTotalCount,
		loadMoreLoading: subscriptionsLoadMoreLoading,
	} = useSelector((state: RootStore) => state.subscription.subscriptions);

	const {
		items: planSubscriptionItems,
		loading: planSubscriptionsLoading,
		totalCount: planSubscriptionsTotalCount,
		loadMoreLoading: planSubscriptionsLoadMoreLoading,
	} = useSelector((state: RootStore) => state.subscription.planSubscriptions);

	const loadMoreSubscriptions = () =>
		dispatch(getMoreAllSubscriptionsRequest());

	const loadMorePlanSubscriptions = () =>
		dispatch(getMorePlanSubscriptionsRequest(selectedPlan));

	useEffect(() => {
		// dispatch(
		// 	getLevelsForSubscriptionsRequest({
		// 		searchQuery,
		// 		statusId: withArchivedFilter ? 3 : 2,
		// 	}),
		// );
		dispatch(getClassesRequest({ searchQuery }));
		dispatch(getClassesForLevelsRequest());
		// dispatch(getAllSubscriptionsRequest());
		return () => {
			dispatch(clearSubscriptions());
		};
	}, []);

	useEffect(() => {
		if (selectedPlan) {
			dispatch(getPlanSubscriptionsRequest(selectedPlan));
		}
	}, [selectedPlan]);

	const fetchData = () => {
		if (tabIndex === 0) {
			dispatch(
				getLevelsForSubscriptionsRequest({
					searchQuery,
					statusId: withArchivedFilter ? 3 : 2,
				}),
			);
		} else if (tabIndex === 1) {
			dispatch(getClassesRequest({ searchQuery }));
		}
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			fetchData();
		} else if (searchQueryDelta?.prev && !searchQueryDelta.curr) {
			fetchData();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (
			tabIndexDelta &&
			tabIndexDelta.prev?.toString() &&
			tabIndexDelta?.prev !== tabIndexDelta?.curr
		) {
			fetchData();
		}
	}, [tabIndexDelta]);

	useEffect(() => {
		fetchData();
	}, [withArchivedFilter]);

	return (
		<Subscriptions
			setShowAddLevelModal={setShowAddLevelModal}
			setShowAddClassModal={setShowAddClassModal}
			subscriptionItems={subscriptionItems}
			subscriptionsLoading={subscriptionsLoading}
			subscriptionsTotalCount={subscriptionsTotalCount}
			subscriptionsLoadMoreLoading={subscriptionsLoadMoreLoading}
			loadMoreSubscriptions={loadMoreSubscriptions}
			showAddLevelModal={showAddLevelModal}
			showAddClassModal={showAddClassModal}
			setSearchQuery={setSearchQuery}
			setTabIndex={setTabIndex}
			tabIndex={tabIndex}
			searchQuery={searchQuery}
			planSubscriptionItems={planSubscriptionItems}
			planSubscriptionsLoading={planSubscriptionsLoading}
			planSubscriptionsTotalCount={planSubscriptionsTotalCount}
			planSubscriptionsLoadMoreLoading={planSubscriptionsLoadMoreLoading}
			selectedPlan={selectedPlan}
			setSelectedPlan={setSelectedPlan}
			loadMorePlanSubscriptions={loadMorePlanSubscriptions}
			withArchivedFilter={withArchivedFilter}
			setWithArchivedFilter={setWithArchivedFilter}
		/>
	);
};
