import { Box, Text, Button, Flex, HStack, Avatar } from "@chakra-ui/react";
import { ReactComponent as AmountOfLessonsIcon } from "../../../assets/ic_amount-of-lessons.svg";
import { ReactComponent as AmountOfHoursIcon } from "../../../assets/ic_amount-of-hours.svg";
import { ReactComponent as EditIcon } from "../../../assets/ic_edit.svg";
import pluralize from "pluralize";
import { ClassType } from "../types";
import { complexity as complexityEnum } from "../../../common/constants";
import { getPictureURL } from "../../../common/utils";

interface IClassesItemProps extends ClassType {
	onEdit: () => void;
}

export const ClassesItem: React.FC<IClassesItemProps> = ({
	complexity,
	description,
	hours,
	id,
	image,
	lessons,
	name,
	onEdit,
}) => {
	return (
		<Box
			p="24px"
			border="1px solid #F3ECFD"
			borderRadius="16px"
			mb="16px"
			my="24px">
			<Flex>
				<Avatar
					w="100px"
					h="100px"
					name={name}
					src={getPictureURL(image)}
					mr="24px"
					borderRadius="16px"
				/>
				<Box w="100%">
					<Flex align="center" justify="space-between">
						<Box>
							<Text variant="subtitle2" color="primary">
								{complexityEnum[complexity]}
							</Text>
							<Text
								fontSize="20px"
								lineHeight="26px"
								fontWeight="bold"
								color="darkest">
								{name}
							</Text>
						</Box>

						<Button
							leftIcon={<EditIcon />}
							variant="plain"
							color="primary"
							onClick={onEdit}>
							Edit
						</Button>
					</Flex>
					<HStack my="16px">
						<HStack>
							<AmountOfLessonsIcon />
							<Text
								variant="body3"
								color="onBlue"
								fontWeight="500">
								{lessons} {pluralize("Lesson", lessons)}
							</Text>
						</HStack>
						<HStack>
							<AmountOfHoursIcon />
							<Text
								variant="body3"
								color="onBlue"
								fontWeight="500">
								{hours} {pluralize("Hour", hours)}
							</Text>
						</HStack>
					</HStack>
					<Text variant="caption3" color="grey">
						Description
					</Text>
					<Text variant="body3" color="darkGrey">
						{description}
					</Text>
				</Box>
			</Flex>
		</Box>
	);
};
