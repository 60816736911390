import { createReducer } from "@reduxjs/toolkit";
import { getPositionTeamsDirectorySuccess } from "../../actions/directories";
import { PositionTeamType } from "../../../common/types";

type InitialState = {
	items: PositionTeamType[];
};

const initialState: InitialState = {
	items: [],
};

const positionTeamsDirectoryReducer = createReducer(initialState, (builder) => {
	builder.addCase(getPositionTeamsDirectorySuccess, (state, action) => {
		state.items = action.payload;
	});
});

export default positionTeamsDirectoryReducer;
