import { createAction } from "@reduxjs/toolkit";
import {
	ParentResponseType,
	PermanentDiscountType,
	SelectedFilters,
} from "../../types";
import { PayloadError } from "../../../../common/types";

//GET PARENTS
export const getParentsRequest = createAction<{
	searchQuery: string;
	sortByAscending: boolean;
	subscriptionPlanId: SelectedFilters;
}>("@dashboard/GET_PARENTS_REQUEST");
export const getParentsSuccess = createAction<{
	totalCount: number;
	users: ParentResponseType[];
}>("@dashboard/GET_PARENTS_SUCCESS");
export const getParentsError = createAction<PayloadError[]>(
	"@dashboard/GET_PARENTS_ERROR",
);
// GET MORE PARENTS

export const getMoreParentsRequest = createAction<{
	searchQuery: string;
	sortByAscending: boolean;
	subscriptionPlanId: SelectedFilters;
}>("@dashboard/GET_MORE_PARENTS_REQUEST");
export const getMoreParentsSuccess = createAction<{
	totalCount: number;
	users: ParentResponseType[];
}>("@dashboard/GET_MORE_PARENTS_SUCCESS");
export const getMoreParentsError = createAction<PayloadError[]>(
	"@dashboard/GET_MORE_PARENTS_ERROR",
);

// Set permanent discount
export const setPermanentDiscountRequest = createAction<PermanentDiscountType>(
	"@dashboard/SET_PERMANENT_DISCOUNT_REQUEST",
);
export const setPermanentDiscountSuccess = createAction<PermanentDiscountType>(
	"@dashboard/SET_PERMANENT_DISCOUNT_SUCCESS",
);
export const setPermanentDiscountError = createAction<PermanentDiscountType>(
	"@dashboard/SET_PERMANENT_DISCOUNT_ERROR",
);
