export const Heading = {
	variants: {
		h1: {
			fontSize: "60px",
			lineHeight: "62px",
			fontWeight: "bold",
			letterSpacing: "-1px",
		},
		h2: {
			fontSize: "52px",
			lineHeight: "60px",
			fontWeight: "500",
		},
		h3: {
			fontSize: "42px",
			lineHeight: "56px",
			fontWeight: "bold",
		},
		h4: {
			fontSize: "32px",
			lineHeight: "32px",
			fontWeight: "500",
		},
		h5: {
			fontSize: "28px",
			lineHeight: "42px",
			fontWeight: "bold",
		},
		h6: {
			fontSize: "22px",
			lineHeight: "32px",
			fontWeight: "500",
		},
		h7: {
			fontSize: "20px",
			lineHeight: "22px",
			fontWeight: "bold",
		},
		h8: {
			fontSize: "18px",
			lineHeight: "40px",
			fontWeight: "bold",
		},
	},
};
