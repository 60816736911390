import { AllEffect, ForkEffect, all } from "redux-saga/effects";
import watchDashboardFilterClassesSaga from "./filter.saga";
import watchMessagesHistorySaga from "./messages-history.saga";
import watchMessagesBroadcasrSaga from "./broadcast.saga";
import watchMessagesParentsSaga from "./parents.saga";
import watchMessagesStaffSaga from "./staff.saga";

// prettier-ignore
export default function* watchMessagesSaga(): Generator<
	AllEffect<Generator<ForkEffect<never>, void, unknown>>,
	void,
	unknown
	> {
	yield all([
		watchDashboardFilterClassesSaga(),
		watchMessagesHistorySaga(),
		watchMessagesBroadcasrSaga(),
		watchMessagesParentsSaga(),
		watchMessagesStaffSaga(),
	]);
}
