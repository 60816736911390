import { Route, Redirect, RouteProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
	isSignedIn: boolean;
	componenTarget: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
	isSignedIn,
	...rest
}) => {
	const { componenTarget: Component } = rest;

	return (
		<Route
			{...rest}
			path={rest.path}
			render={(routeProps) => {
				return isSignedIn ? (
					<Component {...routeProps} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: routeProps.location.pathname },
						}}
					/>
				);
			}}
		/>
	);
};
