import { useEffect } from "react";
import {
	Modal,
	InputText,
	InputSelect,
	RippleButton,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { useForm, Resolver } from "react-hook-form";
import { editEmployeeSchema } from "../../../../common/constants/validation";
import { UploadImage } from "./components/UploadImage";
import { StaffItem, EditEmployeePayload } from "../../types";

interface IEditEmployeeModal {
	handleClose: () => void;
	isOpen: boolean;
	toEditEmployee: StaffItem;
	editEmployee: (value: EditEmployeePayload) => void;
	loading: boolean;
	deleteEmployee: (value: number) => void;
	deleteLoading: boolean;
}

export interface IEditEmployeeForm {
	firstName: string;
	lastName: string;
	email: string;
	positionName: string;
	staffRole: number;
	imageFile: string | Blob;
}

export const EditEmployeeModal: React.FC<IEditEmployeeModal> = ({
	handleClose,
	isOpen,
	toEditEmployee,
	editEmployee,
	loading,
	deleteEmployee,
	deleteLoading,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		reset,
	} = useForm<IEditEmployeeForm>({
		resolver: editEmployeeSchema as Resolver<IEditEmployeeForm>,
	});

	useEffect(() => {
		if (isOpen && toEditEmployee) {
			reset({
				...toEditEmployee,
			});
		} else {
			reset({});
		}
	}, [isOpen, toEditEmployee, reset]);

	const onSubmit = (data: IEditEmployeeForm) => {
		const formData = data.imageFile ? new FormData() : undefined;
		if (data.imageFile) {
			formData?.append("imageFile", data.imageFile);
		}
		editEmployee({
			params: {
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				positionName: data.positionName,
				staffRole: data.staffRole + "",
				userId: toEditEmployee.userId,
			},
			imageFile: formData,
		});
	};

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Edit Employee">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Box>
						<UploadImage
							setValue={setValue}
							photo={{ id: 0, itemHash: "", extension: "" }}
						/>

						<Flex direction={{ base: "column", lg: "row" }}>
							<InputText
								{...register("positionName")}
								label="Position Name"
								placeholder="Position"
								errorMsg={errors.positionName?.message}
							/>
							<Box w="20px" />
							<InputSelect
								{...register("staffRole")}
								label="Role"
								placeholder="Role"
								options={[{ value: "1", label: "Teacher" }]}
								errorMsg={errors.staffRole?.message}
							/>
						</Flex>
						<InputText
							{...register("email")}
							label="Employee Email"
							placeholder="Employee Email"
							type="Email"
							errorMsg={errors.email?.message}
						/>
						<Flex direction={{ base: "column", lg: "row" }}>
							<InputText
								{...register("firstName")}
								label="First Name"
								placeholder="First Name"
								errorMsg={errors.firstName?.message}
							/>
							<Box w="20px" />
							<InputText
								{...register("lastName")}
								label="Last Name"
								placeholder="Last Name"
								errorMsg={errors.lastName?.message}
							/>
						</Flex>
					</Box>
				</ModalBody>
				<ModalFooter>
					<RippleButton
						variant="outline"
						width="100%"
						onClick={() => deleteEmployee(toEditEmployee.userId)}
						isLoading={deleteLoading}>
						Remove
					</RippleButton>
					<Box w="20px" />
					<RippleButton
						width="100%"
						type="submit"
						isLoading={loading}>
						Save
					</RippleButton>
				</ModalFooter>
			</form>
		</Modal>
	);
};
