import React from "react";
import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	IconButton,
	Avatar,
	Box,
	Flex,
	Heading,
	Text,
	HStack,
} from "@chakra-ui/react";
import { ReactComponent as CloseIcon } from "../../../assets/ic_close.svg";
import { ReactComponent as AmountOfLessonsIcon } from "../../../assets/ic_amount-of-lessons.svg";
import { ReactComponent as AmountOfHoursIcon } from "../../../assets/ic_amount-of-hours.svg";
import { RippleButton } from "../../../common/components";
import { ImageType } from "../../../common/types";
import pluralize from "pluralize";
import { IAddClassForm } from "../modal/add-class.modal/AddClassModal";
import { getPictureURL } from "../../../common/utils";
import { complexity as complexityEnum } from "../../../common/constants";

type IClassAlert = {
	isOpen: boolean;
	handleClose: () => void;
	firstDescription: string;
	secondDescription: string;
	confirmLabel: string;
	title: string;
	onConfirm?: () => void;
	confirmLoading?: boolean;
	createdClass: IAddClassForm;
};

export const ClassAlert: React.FC<IClassAlert> = ({
	confirmLabel,
	firstDescription,
	handleClose,
	isOpen,
	secondDescription,
	title,
	confirmLoading,
	onConfirm,
	createdClass,
}) => {
	const cancelRef = React.useRef();

	const isBlob = (input: any) => "Blob" in window && input instanceof Blob;

	const getAvatar = () => {
		if (typeof createdClass.imageFile === "string") {
			return createdClass.imageFile;
		} else if (!isBlob(createdClass.imageFile)) {
			return getPictureURL(createdClass.imageFile as ImageType);
		} else {
			return URL.createObjectURL(createdClass.imageFile);
		}
		// return undefined;
	};

	return (
		<AlertDialog
			leastDestructiveRef={cancelRef.current}
			isOpen={isOpen}
			onClose={handleClose}
			isCentered
			motionPreset={"slideInBottom"}>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius={"16px"}>
					<Flex justify="space-between" align="center" pt="24px">
						<AlertDialogHeader py={0}>
							<Heading variant="h6" color="darkest">
								{title}
							</Heading>
						</AlertDialogHeader>
						<IconButton
							aria-label="close alert dialog"
							icon={<CloseIcon />}
							onClick={handleClose}
							variant="ghost"
							mr="1.5rem"
						/>
					</Flex>
					<Box py="24px">
						<AlertDialogBody>
							<Flex
								borderRadius="16px"
								border="1px solid #F5F5F6"
								p="16px"
								align="center">
								{/*	Avatar*/}
								<Avatar
									src={getAvatar()}
									name={createdClass.name}
									w="48px"
									h="48px"
									borderRadius="8px"
									mr="16px"
								/>
								{/*	Info Course*/}
								<Box>
									<Text
										variant="caption3"
										fontWeight="500"
										color="primary"
										alignSelf="flex-start">
										{
											complexityEnum[
												createdClass.complexity as
													| 1
													| 2
													| 3
											]
										}
									</Text>
									<Text variant="body1" color="darkest">
										{createdClass.name}
									</Text>
									<HStack spacing="30px">
										<HStack>
											<AmountOfLessonsIcon />
											<Text
												variant="body3"
												color="onBlue"
												fontWeight="500">
												{createdClass.lessons}{" "}
												{pluralize(
													"Lesson",
													createdClass.lessons,
												)}
											</Text>
										</HStack>
										<HStack>
											<AmountOfHoursIcon />
											<Text
												variant="body3"
												color="onBlue"
												fontWeight="500">
												{createdClass.hours}{" "}
												{pluralize(
													"Hour",
													createdClass.hours,
												)}
											</Text>
										</HStack>
									</HStack>
								</Box>
								{/*	Price*/}
							</Flex>
							{/*Info Text1*/}
							<Box my="24px">
								<Text variant="body2" color="darkest">
									{firstDescription}
								</Text>
							</Box>
							{/*Info Text2*/}
							<Box mb="24px">
								<Text variant="inputText" color="darkgray">
									{secondDescription}
								</Text>
							</Box>
							{/*Buttons*/}
							<Flex justifyContent="space-between">
								<RippleButton
									w="48%"
									variant="outline"
									onClick={handleClose}
									ref={cancelRef.current}>
									Cancel
								</RippleButton>
								<RippleButton
									w="48%"
									onClick={onConfirm}
									isLoading={confirmLoading}>
									{confirmLabel}
								</RippleButton>
							</Flex>
						</AlertDialogBody>
					</Box>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
