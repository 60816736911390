import { Box, Flex, Text, Avatar } from "@chakra-ui/react";
import { getFormattedRange, getPictureURL } from "../../../common/utils";
import { ImageType } from "../../../common/types";

interface IClassItemProps {
	name: string;
	startDate?: string;
	endDate?: string;
	image: ImageType;
	active: boolean;
	onClick: () => void;
}

export const ClassItem: React.FC<IClassItemProps> = ({
	active,
	endDate,
	image,
	name,
	onClick,
	startDate,
}) => {
	return (
		<Flex
			p="16px"
			border={`1px solid ${active ? "#8C4BC9" : "#F3ECFD"}`}
			borderRadius="16px"
			mb="15px"
			align="center"
			as="button"
			w="100%"
			onClick={onClick}>
			<Avatar
				name={name}
				src={getPictureURL(image)}
				w="48px"
				h="48px"
				borderRadius="50%"
				mr="16px"
			/>
			<Box>
				<Text variant="body1" color="darkest" textAlign="start">
					{name}
				</Text>
				<Text variant="body3" color="grey" textAlign="start">
					{startDate && endDate
						? getFormattedRange(startDate, endDate)
						: ""}
				</Text>
			</Box>
		</Flex>
	);
};
