import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const restorePasswordSchemaResolver = yup.object().shape({
	email: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.email(ErrorMessages.EMAIL_INVALID),
});

export const restorePasswordSchema = yupResolver(restorePasswordSchemaResolver);
