import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

const sendBulkMessageSchemaResolver = yup.object().shape({
	senderName: yup.string().required(ErrorMessages.REQUIRED),
	messageText: yup.string().required(ErrorMessages.REQUIRED),
	sendingDate: yup
		.number()
		.test(
			"required-if-not-immediately",
			ErrorMessages.REQUIRED,
			function (value) {
				return this.parent.sendImmediately ? true : !!value;
			},
		)
		.test("date-before", "Date can not be in the past", function (value) {
			return this.parent.sendImmediately
				? true
				: dayjs.utc().isSameOrBefore(dayjs.utc(value), "day");
		}),
	sendingTime: yup
		.string()
		.test(
			"required-if-not-immediately",
			ErrorMessages.REQUIRED,
			function (value) {
				return this.parent.sendImmediately ? true : !!value;
			},
		),
});

export const sendBulkMessageSchema = yupResolver(sendBulkMessageSchemaResolver);
