import axios from "axios";
import { setAuthHeaderToken, Alerter } from "../utils";
import { store } from "../../redux";
import { loginSuccess, logoutSuccess } from "../../redux/actions/auth.actions";

export default function intercept(): void {
	let isRefreshing = false;
	let subscribers: Array<(value: string) => void> = [];

	axios.interceptors.response.use(undefined, (err) => {
		const {
			config,
			response: { status },
		} = err;
		const originalRequest = config;
		if (status === 401) {
			if (!isRefreshing) {
				isRefreshing = true;
				axios
					.post("/api/v1/auth/refresh-access-token", {
						accessToken: store.getState().session.accessToken,
						refreshToken: store.getState().session.refreshToken,
					})
					.then((res) => {
						isRefreshing = false;
						onRrefreshed(res.data.value.accessToken);
						store.dispatch(loginSuccess(res.data.value));
						setAuthHeaderToken(res.data.value.accessToken);
						subscribers = [];
					})
					.catch(() => {
						Alerter.error("You need login again to continue");
						store.dispatch(logoutSuccess());
					});
			}
			const requestSubscribers = new Promise((resolve) => {
				subscribeTokenRefresh((token: string) => {
					originalRequest.headers.Authorization = `Bearer ${token}`;
					resolve(axios(originalRequest));
				});
			});
			return requestSubscribers;
		}

		return Promise.reject(err);
	});

	function subscribeTokenRefresh(cb: (value: string) => void) {
		subscribers.push(cb);
	}

	function onRrefreshed(token: string) {
		subscribers.map((cb) => cb(token));
	}
}
