import {ErrorMessages} from "./messages";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const passwordChangeSchemaResolver = yup.object().shape({
	oldPassword: yup.
		string()
		.min(6,ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.matches(/[A-Z]/,ErrorMessages.PASSWORD_UPPERCASE_CHAR)
		.matches(/\d/,ErrorMessages.PASSWORD_DIGIT),
	newPassword: yup
		.string()
		.min(6, ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
		.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE_CHAR),
	confirmNewPassword: yup
		.string()
		.min(6, ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.test("password-match", ErrorMessages.PASSWORD_MATCH, function (value) {
			const { newPassword } = this.parent;
			return newPassword === value;
		})
		.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE_CHAR)
		.matches(/\d/, ErrorMessages.PASSWORD_DIGIT),
});

export const  passwordChangeSchema = yupResolver(passwordChangeSchemaResolver);
