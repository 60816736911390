import { createReducer } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { StudentNamedSubscriptionType, DiscountData } from "../../types";
import {
	clearSubscriptions,
	getAllSubscriptionsRequest,
	getAllSubscriptionsSuccess,
	getAllSubscriptionsError,
	getMoreAllSubscriptionsRequest,
	getMoreAllSubscriptionsSuccess,
	getMoreAllSubscriptionsError,
	setDiscountValueRequest,
	setDiscountValueSuccess,
	setDiscountValueError,
	getAllDiscountsRequest,
	getAllDiscountsSuccess,
	getAllDiscountsError,
} from "../actions";

type InitialState = {
	items: StudentNamedSubscriptionType[];
	loading: boolean;
	loadMoreLoading: boolean;
	page: number;
	errors: PayloadError[];
	totalCount: number;
	discountEditLoading: boolean;
	discountSaveLoading: boolean;
	discountError: PayloadError[];
	discounts: DiscountData[];
};

const initialState: InitialState = {
	items: [],
	loadMoreLoading: false,
	loading: false,
	page: 1,
	errors: [],
	totalCount: 0,
	discountEditLoading: false,
	discountSaveLoading: false,
	discountError: [],
	discounts: [],
};

export const subscriptionsReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(getAllSubscriptionsRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getAllSubscriptionsSuccess, (state, action) => {
			state.loading = false;
			state.items = action.payload.subscriptions;
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getAllSubscriptionsError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		.addCase(getMoreAllSubscriptionsRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreAllSubscriptionsSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.items = [...state.items, ...action.payload.subscriptions];
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getMoreAllSubscriptionsError, (state) => {
			state.loadMoreLoading = false;
		})
		.addCase(setDiscountValueRequest, (state) => {
			state.discountEditLoading = true;
			state.discountError = [];
		})
		.addCase(setDiscountValueSuccess, (state) => {
			state.discountEditLoading = false;
		})
		.addCase(setDiscountValueError, (state, action) => {
			state.discountEditLoading = false;
			state.discountError = action.payload;
		})
		.addCase(getAllDiscountsRequest, (state) => {
			// state.discountSaveLoading = true;
			state.discountError = [];
		})
		.addCase(getAllDiscountsSuccess, (state, action) => {
			// state.discountSaveLoading = false;
			state.discounts = action.payload.discounts;
		})
		.addCase(getAllDiscountsError, (state, action) => {
			// state.discountSaveLoading = false;
			state.discountError = action.payload;
		})
		.addCase(clearSubscriptions, () => {
			return { ...initialState };
		}),
);
