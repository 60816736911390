import { DashboardHeader } from "../../common/sections";
import { Button, Grid, Image, Box } from "@chakra-ui/react";
import { InfoCard, PasswordCard, PhotoCard } from "./cards";
import LogOutIcon from "../../assets/ic_log-out.svg";
import {
	EditProfilePayload,
	GetInfoProfileOnEditRequest,
	UpdatePasswordPayload,
} from "./types";
import { ImageType } from "../../common/types";

interface ISettingsProps {
	handleUpdatePassword: (data: UpdatePasswordPayload) => void;
	onLogout: () => void;
	profileLoading: boolean;
	photoSaveLoading: boolean;
	profileEditLoading: boolean;
	updatePasswordLoading: boolean;
	profileUser: GetInfoProfileOnEditRequest;
	handleUpdateProfile: (
		values: EditProfilePayload,
		imageFile: FormData,
	) => void;
	handleUpdatePhoto: (imageFile: FormData) => void;
	image: ImageType;
}

export const Settings: React.FC<ISettingsProps> = ({
	onLogout,
	profileLoading,
	photoSaveLoading,
	profileEditLoading,
	profileUser,
	handleUpdatePhoto,
	handleUpdateProfile,
	image,
	handleUpdatePassword,
	updatePasswordLoading,
}) => {
	return (
		<Box>
			{/*Header*/}
			<DashboardHeader
				title={"Welcome"}
				description={"You can edit your information"}
			/>
			<Box
				px={{ base: "20px", lg: "40px" }}
				mt={{ base: "0px", lg: "-50px" }}
				w={{ base: "", lg: "calc(100vw - 255px)" }}>
				<Grid
					templateColumns={{ base: "auto", lg: "60% 1fr" }}
					templateRows={{ base: "auto auto auto", lg: "auto auto" }}
					gridGap="24px"
					height={{ base: "auto", lg: "calc(100vh - 160px)" }}
					overflow="auto">
					<InfoCard
						profileLoading={profileLoading}
						profileUser={profileUser}
						handleUpdateProfile={handleUpdateProfile}
						profileEditLoading={profileEditLoading}
					/>
					<PasswordCard
						handleUpdatePassword={handleUpdatePassword}
						updatePasswordLoading={updatePasswordLoading}
					/>
					<PhotoCard
						handleUpdatePhoto={handleUpdatePhoto}
						photoSaveLoading={photoSaveLoading}
						image={image}
						profileLoading={profileLoading}
					/>
					<Button
						onClick={onLogout}
						leftIcon={<Image src={LogOutIcon} />}
						variant={"plain"}
						display={{ lg: "none" }}
						d="flex">
						Log Out
					</Button>
					<Box
						height="100px"
						width="100%"
						display={{ base: "block", lg: "none" }}
					/>
				</Grid>
			</Box>
		</Box>
	);
};
