/* eslint-disable no-constant-condition */
import { Box, Flex, Heading, Button } from "@chakra-ui/react";
import { InfiniteScroll, Loader } from "../../../common/components";
import { ReactComponent as SortIcon } from "../../../assets/ic_sort.svg";
import { HistoryItem } from "../components";
import { MessageHistoryItemType } from "../types";
import { MessagesPlaceholder } from "../components/placeholders";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux";
import { getMoreMessagesHistoryRequest } from "../store/actions";

interface IMessagesHistoryCardProps {
	setToShowInfoMessage: (value: MessageHistoryItemType) => void;
	setSortByAscending: (value: boolean) => void;
	sortByAscending: boolean;
}

export const MessagesHistoryCard: React.FC<IMessagesHistoryCardProps> = ({
	setToShowInfoMessage,
	setSortByAscending,
	sortByAscending,
}) => {
	const dispatch = useDispatch();

	const { loading, loadMoreLoading, totalCount, items } = useSelector(
		(state: RootStore) => state.messages.messagesHistory,
	);

	return (
		<Box
			p={{ base: "10px 0", lg: "30px 40px 10px 40px" }}
			bg={{ base: "transparent", lg: "white" }}
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			<Flex
				justify="space-between"
				borderBottom={{ base: "none", lg: "1px solid #EBEBEB" }}>
				<Heading variant="h8" as="p" color="darkest" lineHeight="50px">
					Messages
				</Heading>
				<Button
					variant="plain"
					color="primary"
					leftIcon={<SortIcon />}
					onClick={() => setSortByAscending(!sortByAscending)}>
					{sortByAscending ? "First Sent" : "Last Sent"}
				</Button>
			</Flex>
			<Box height="calc(100vh - 250px)">
				{loading ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						{items.length ? (
							<InfiniteScroll
								onReachBottom={() => {
									if (
										!loadMoreLoading &&
										totalCount > items.length
									) {
										dispatch(
											getMoreMessagesHistoryRequest({
												sortByAscending,
											}),
										);
									}
								}}>
								{items.map(
									(historyItem: MessageHistoryItemType) => (
										<HistoryItem
											key={historyItem.id}
											setToShowInfoMessage={() =>
												setToShowInfoMessage(
													historyItem,
												)
											}
											{...historyItem}
										/>
									),
								)}
								{loadMoreLoading && <Loader spinnerSize="md" />}
							</InfiniteScroll>
						) : (
							<MessagesPlaceholder />
						)}
					</>
				)}
			</Box>
		</Box>
	);
};
