/* eslint-disable indent */
import { createReducer } from "@reduxjs/toolkit";
import {
	getTeacherAttendanceStudentsRequest,
	getTeacherAttendanceStudentsSuccess,
	getTeacherAttendanceStudentsError,
	getTeacherAttendanceClassesRequest,
	getTeacherAttendanceClassesSuccess,
	getTeacherAttendanceClassesError,
	// setStudentAttendanceValueRequest,
	setStudentAttendanceValueSuccess,
	// setStudentAttendanceValueError,
	getMoreTeacherAttendanceStudentsRequest,
	getMoreTeacherAttendanceStudentsSuccess,
	getMoreTeacherAttendanceStudentsError,
} from "./actions";
import { TeacherAttendanceType } from "../types";
import { PayloadError, SubscriptionPlan } from "./../../../common/types";

type InitialState = {
	students: {
		loading: boolean;
		loadMoreLoading: boolean;
		totalCount: number;
		items: TeacherAttendanceType[];
		page: number;
		errors: PayloadError[];
	};
	classes: {
		loading: boolean;
		items: SubscriptionPlan[];
	};
};

const initialState: InitialState = {
	students: {
		errors: [],
		items: [],
		loadMoreLoading: false,
		loading: false,
		page: 1,
		totalCount: 0,
	},
	classes: {
		items: [],
		loading: false,
	},
};

const replaceAttendanceStatus = (status: number) => {
	switch (status) {
		case 2:
			return {
				presentCount: 1,
				absentCount: 0,
				dayOffCount: 0,
			};
		case 4:
			return {
				presentCount: 0,
				absentCount: 1,
				dayOffCount: 0,
			};
		case 8:
			return {
				presentCount: 0,
				absentCount: 0,
				dayOffCount: 1,
			};
		default:
			return {};
	}
};

const teacherAttendanceReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getTeacherAttendanceStudentsRequest, (state) => {
			state.students.loading = true;
			state.students.errors = [];
		})
		.addCase(getTeacherAttendanceStudentsSuccess, (state, action) => {
			state.students.loading = false;
			state.students.items = action.payload.attendance;
			state.students.totalCount = action.payload.totalCount;
			state.students.page++;
		})
		.addCase(getTeacherAttendanceStudentsError, (state, action) => {
			state.students.loading = false;
			state.students.errors = action.payload;
		})
		.addCase(getTeacherAttendanceClassesRequest, (state) => {
			state.classes.loading = true;
		})
		.addCase(getTeacherAttendanceClassesSuccess, (state, action) => {
			state.classes.loading = false;
			state.classes.items = action.payload;
		})
		.addCase(getTeacherAttendanceClassesError, (state) => {
			state.classes.loading = false;
		})
		// .addCase(setStudentAttendanceValueRequest, (state) => {

		// })
		.addCase(setStudentAttendanceValueSuccess, (state, action) => {
			state.students.items = state.students.items.map((student) => {
				if (student.id === action.payload.studentId) {
					return {
						...student,
						...replaceAttendanceStatus(action.payload.status),
					};
				} else {
					return student;
				}
			});
		})
		// .addCase(setStudentAttendanceValueError, (state) => {})
		.addCase(getMoreTeacherAttendanceStudentsRequest, (state) => {
			state.students.loadMoreLoading = true;
		})
		.addCase(getMoreTeacherAttendanceStudentsSuccess, (state, action) => {
			state.students.loadMoreLoading = false;
			state.students.items = [
				...state.students.items,
				...action.payload.attendance,
			];
			state.students.totalCount = action.payload.totalCount;
			state.students.page++;
		})
		.addCase(getMoreTeacherAttendanceStudentsError, (state, action) => {
			state.students.loadMoreLoading = false;
			state.students.errors = action.payload;
		});
});

export default teacherAttendanceReducer;
