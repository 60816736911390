import { Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";

interface IPaymentItem {
	date: string;
	amount: number;
}

export const PaymentItem: React.FC<IPaymentItem> = ({ amount, date }) => (
	<Flex justifyContent={"space-between"} alignItems={"center"} my={"18px"}>
		<Text variant={"body2"}>{dayjs(date).format("D MMMM, YYYY")}</Text>
		<Text variant={"subtitle1"}>${amount.toFixed(2)}</Text>
	</Flex>
);
