import { useEffect, useState } from "react";
import { ApplicationsCard } from "./ApplicationsCard";
import {
	getApplicationsRequest,
	getMoreApplicationsRequest,
	clearApplicationById,
	deleteApplicationRequest,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { ApplicationInfoModal } from "../../modals";
import { VacancyAlert } from "../../components";
import { useDelta } from "react-delta";

export const ApplicationsCardContainer: React.FC = () => {
	const dispatch = useDispatch();

	const [showInfoModal, setShowInfoModal] = useState(false);
	const [applicationIdToShow, setApplicationIdToShow] = useState(0);
	const [showRemoveApplicationAlert, setShowRemoveApplicationAlert] =
		useState(false);

	const { items, loadMoreLoading, loading, totalCount } = useSelector(
		(state: RootStore) => state.jobs.applications.values,
	);
	const {
		loading: deleteApplicationLoading,
		errors: deleteApplicationErrors,
	} = useSelector(
		(state: RootStore) => state.jobs.applications.deleteApplication,
	);

	const deleteApplicationLoadingDelta = useDelta(deleteApplicationLoading);

	const loadMoreApplications = () => dispatch(getMoreApplicationsRequest());

	useEffect(() => {
		dispatch(getApplicationsRequest());
	}, []);

	useEffect(() => {
		if (applicationIdToShow) {
			setShowInfoModal(true);
		}
	}, [applicationIdToShow]);

	useEffect(() => {
		if (
			deleteApplicationLoadingDelta &&
			deleteApplicationLoadingDelta.prev
		) {
			if (
				deleteApplicationLoadingDelta.prev &&
				!deleteApplicationLoadingDelta.curr &&
				!deleteApplicationErrors.length
			) {
				setShowRemoveApplicationAlert(false);
				setApplicationIdToShow(0);
			}
		}
	}, [deleteApplicationLoadingDelta]);

	return (
		<>
			<ApplicationsCard
				items={items}
				loadMoreLoading={loadMoreLoading}
				loading={loading}
				totalCount={totalCount}
				loadMoreApplications={loadMoreApplications}
				setApplicationIdToShow={setApplicationIdToShow}
			/>
			<ApplicationInfoModal
				isOpen={showInfoModal}
				handleClose={() => {
					setShowInfoModal(false);
					setApplicationIdToShow(0);
					dispatch(clearApplicationById());
				}}
				applicationIdToShow={applicationIdToShow}
				onCancel={() => {
					setShowInfoModal(false);
					setShowRemoveApplicationAlert(true);
					dispatch(clearApplicationById());
				}}
			/>
			<VacancyAlert
				confirmLabel="Remove"
				firstDescription="After removing the application, person who submitted will not know about it."
				handleClose={() => setShowRemoveApplicationAlert(false)}
				isOpen={showRemoveApplicationAlert}
				secondDescription="You will not be able to recover data."
				title="Remove"
				confirmLoading={deleteApplicationLoading}
				createdVacancy={null}
				onConfirm={() =>
					dispatch(deleteApplicationRequest(applicationIdToShow + ""))
				}
			/>
		</>
	);
};
