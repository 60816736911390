import { useState } from "react";
import {
	Modal,
	InputSelect,
	ModalCalendar,
	RippleButton,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { months } from "../../../../common/constants";
import dayjs from "dayjs";

interface ISelectDateModal {
	handleClose: () => void;
	isOpen: boolean;
	setAfterDate: (value: string) => void;
	setBeforeDate: (value: string) => void;
	afterDate: string;
}

export const SelectDateModal: React.FC<ISelectDateModal> = ({
	handleClose,
	isOpen,
	setAfterDate,
	setBeforeDate,
	afterDate,
}) => {
	const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().month());
	const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());
	const [selectedDay, setSelectedDay] = useState(afterDate);
	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Select Date">
			<ModalBody>
				<Flex>
					<InputSelect
						label=""
						options={months.map((month) => ({
							...month,
							value: month.value + "",
						}))}
						defaultValue={selectedMonth}
						onChange={(ev) => {
							setSelectedMonth(+ev.target.value);
						}}
						formControlProps={{ flex: 0.7 }}
					/>
					<Box w="20px" />
					<InputSelect
						label=""
						formControlProps={{ flex: 0.3 }}
						options={Array(15)
							.fill("")
							.map((_elem, index) => ({
								value: 2014 + index + "",
								label: 2014 + index + "",
							}))}
						defaultValue={selectedYear}
						onChange={(ev) => {
							setSelectedYear(+ev.target.value);
						}}
					/>
				</Flex>
				<ModalCalendar
					mode="day"
					selectedMonth={selectedMonth}
					selectedYear={selectedYear}
					onChange={(day: string) => {
						setSelectedDay(day);
					}}
					selectedValue={dayjs(afterDate).valueOf()}
				/>
			</ModalBody>
			<ModalFooter>
				<RippleButton
					variant="outline"
					width="100%"
					onClick={handleClose}>
					Cancel
				</RippleButton>
				<Box w="20px" />
				<RippleButton
					onClick={() => {
						setAfterDate(selectedDay);
						setBeforeDate(selectedDay);
						handleClose();
					}}
					width="100%">
					Select
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
