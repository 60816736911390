import { AllEffect, ForkEffect, all } from "redux-saga/effects";
import watchSubscriptionSubscriptionsSaga from "./subscriptions.saga";
import watchSubscriptionClassesSaga from "./classes.saga";
import watchSubscriptionLevelsSaga from "./levels.saga";
import watchSubscriptionPlanSubscriptionsSaga from "./plan-subscriptions.saga";

// prettier-ignore
export default function* watchSubscriptionSaga(): Generator<
	AllEffect<Generator<ForkEffect<never>, void, unknown>>,
	void,
	unknown
	> {
	yield all([
		watchSubscriptionSubscriptionsSaga(),
		watchSubscriptionClassesSaga(),
		watchSubscriptionLevelsSaga(),
		watchSubscriptionPlanSubscriptionsSaga()
	]);
}
