import { useState, useEffect } from "react";
import { VacanciesCard } from "./VacanciesCard";
import { EditVacancyModal, CreateVacancyModal } from "../../modals";
import {
	getVacanciesRequest,
	getMoreVacanciesRequest,
	clearVacancyById,
	createJobPositionRequest,
	deleteVacancyRequest,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { VacancyAlert } from "../../components";
import { useDelta } from "react-delta";
import { CreateJobPositionPayload, EditJobPositionPayload } from "../../types";
import { defaultPublishVacancy } from "../../../../common/constants/defaults";

export const VacanciesCardContainer: React.FC = () => {
	const dispatch = useDispatch();
	const [showCreateVacancyModal, setShowCreateVacancyModal] = useState(false);
	const [showEditVacancyModal, setShowEditVacancyModal] = useState(false);
	const [toEditVacancyId, setToEditVacancyId] = useState(0);
	const [showPublishAlert, setShowPublishAlert] = useState(false);
	const [showRemoveAlert, setShowRemoveAlert] = useState(false);
	const [toPublishVacancy, setToPublishVacancy] =
		useState<CreateJobPositionPayload>(defaultPublishVacancy);
	const [toDeleteVacancy, setToDeleteVacancy] =
		useState<EditJobPositionPayload>({ id: 0, ...defaultPublishVacancy });

	const { items, loadMoreLoading, loading, totalCount } = useSelector(
		(state: RootStore) => state.jobs.vacancies.values,
	);
	const { loading: createPositionLoading, errors: createPositionErrors } =
		useSelector((state: RootStore) => state.jobs.vacancies.createPosition);
	const { loading: deletePositionLoading, errors: deletePositionErrors } =
		useSelector((state: RootStore) => state.jobs.vacancies.deletePosition);
	const { loading: editPositionLoading, errors: editPositionErrors } =
		useSelector((state: RootStore) => state.jobs.vacancies.editPosition);
	const positionCommitments = useSelector(
		(state: RootStore) => state.directories.positionCommitments.items,
	);
	const positionTeams = useSelector(
		(state: RootStore) => state.directories.positionTeams.items,
	);

	const createPositionLoadingDelta = useDelta(createPositionLoading);
	const deletePositionLoadingDelta = useDelta(deletePositionLoading);
	const editPositionLoadingDelta = useDelta(editPositionLoading);

	const loadMoreVacancies = () => dispatch(getMoreVacanciesRequest());

	useEffect(() => {
		dispatch(getVacanciesRequest());
	}, []);

	useEffect(() => {
		if (toEditVacancyId) {
			setShowEditVacancyModal(true);
		}
	}, [toEditVacancyId]);

	useEffect(() => {
		if (createPositionLoadingDelta && createPositionLoadingDelta.prev) {
			if (
				createPositionLoadingDelta.prev &&
				!createPositionLoadingDelta.curr &&
				!createPositionErrors.length
			) {
				setShowPublishAlert(false);
				setToPublishVacancy(defaultPublishVacancy);
			}
		}
	}, [createPositionLoadingDelta]);

	useEffect(() => {
		if (deletePositionLoadingDelta && deletePositionLoadingDelta.prev) {
			if (
				deletePositionLoadingDelta.prev &&
				!deletePositionLoadingDelta.curr &&
				!deletePositionErrors.length
			) {
				setShowRemoveAlert(false);
				setToDeleteVacancy({ id: 0, ...defaultPublishVacancy });
			}
		}
	}, [deletePositionLoadingDelta]);

	useEffect(() => {
		if (editPositionLoadingDelta && editPositionLoadingDelta.prev) {
			if (
				editPositionLoadingDelta.prev &&
				!editPositionLoadingDelta.curr &&
				!editPositionErrors.length
			) {
				setShowEditVacancyModal(false);
				setToEditVacancyId(0);
				dispatch(clearVacancyById());
			}
		}
	}, [editPositionLoadingDelta]);

	return (
		<>
			<VacanciesCard
				openCreateVacancy={() => setShowCreateVacancyModal(true)}
				items={items}
				loadMoreLoading={loadMoreLoading}
				loading={loading}
				totalCount={totalCount}
				loadMoreVacancies={loadMoreVacancies}
				setToEditVacancyId={setToEditVacancyId}
			/>
			<CreateVacancyModal
				handleClose={() => setShowCreateVacancyModal(false)}
				isOpen={showCreateVacancyModal}
				setToPublishVacancy={(data) => {
					setToPublishVacancy(data);
					setShowCreateVacancyModal(false);
					setShowPublishAlert(true);
				}}
			/>
			<EditVacancyModal
				isOpen={showEditVacancyModal}
				handleClose={() => {
					setShowEditVacancyModal(false);
					setToEditVacancyId(0);
					dispatch(clearVacancyById());
				}}
				toEditVacancyId={toEditVacancyId}
				setToDeleteVacancy={(data) => {
					setToDeleteVacancy(data);
					setShowEditVacancyModal(false);
					setShowRemoveAlert(true);
				}}
				editLoading={editPositionLoading}
			/>
			<VacancyAlert
				confirmLabel="Publish"
				firstDescription="Once published, people will be able to see the vacancy and apply."
				handleClose={() => setShowPublishAlert(false)}
				isOpen={showPublishAlert}
				secondDescription="You can always edit the level information by clicking on the Edit button."
				title="Publish"
				confirmLoading={createPositionLoading}
				onConfirm={() =>
					dispatch(createJobPositionRequest(toPublishVacancy))
				}
				createdVacancy={{
					commitment:
						positionCommitments.find(
							(elem: any) =>
								elem.id === toPublishVacancy.commitmentId,
						)?.name || "",
					location: toPublishVacancy.location,
					team:
						positionTeams.find(
							(elem: any) => elem.id === toPublishVacancy.teamId,
						)?.name || "",
					title: toPublishVacancy.title,
				}}
			/>
			<VacancyAlert
				confirmLabel="Remove"
				firstDescription="After removing the vacancy, people will not be able to see the vacancy or apply."
				handleClose={() => setShowRemoveAlert(false)}
				isOpen={showRemoveAlert}
				secondDescription="You will not be able to recover data."
				title="Remove"
				confirmLoading={deletePositionLoading}
				createdVacancy={{
					commitment:
						positionCommitments.find(
							(elem: any) =>
								elem.id === toDeleteVacancy.commitmentId,
						)?.name || "",
					location: toDeleteVacancy.location,
					team:
						positionTeams.find(
							(elem: any) => elem.id === toDeleteVacancy.teamId,
						)?.name || "",
					title: toDeleteVacancy.title,
				}}
				onConfirm={() =>
					dispatch(deleteVacancyRequest(toEditVacancyId + ""))
				}
			/>
		</>
	);
};
