import { all } from "redux-saga/effects";
import watchPositionTeamsDirectorySaga from "./position-team.directory.saga";
import watchPositionCommitmentsDirectorySaga from "./position-commitments.directory.saga";

// prettier-ignore
export default function* directoriesSaga(){
	yield all([
		watchPositionTeamsDirectorySaga(),
		watchPositionCommitmentsDirectorySaga(),
	]);
}
