import { GridItem, Box, Flex } from "@chakra-ui/react";
import {
	DashboardCardHeader,
	InputText,
	RippleButton,
} from "../../../common/components";
import { Resolver, useForm } from "react-hook-form";
import { passwordChangeSchema } from "../../../common/constants/validation";
import { UpdatePasswordPayload } from "../types";

interface IPasswordForm {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}

interface IPasswordProps {
	handleUpdatePassword: (data: UpdatePasswordPayload) => void;
	updatePasswordLoading: boolean;
}

export const PasswordCard: React.FC<IPasswordProps> = ({
	handleUpdatePassword,
	updatePasswordLoading,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<IPasswordForm>({
		resolver: passwordChangeSchema as Resolver<IPasswordForm>,
	});
	const onSubmit = (data: IPasswordForm) => {
		const payload = {
			currentPassword: data.oldPassword,
			newPassword: data.newPassword,
			confirmNewPassword: data.confirmNewPassword,
		};
		handleUpdatePassword(payload);
		reset({});
	};

	return (
		<GridItem
			rowSpan={2}
			bg="white"
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			<Box p={{ base: "10px 0", lg: "30px 40px 0px 40px" }}>
				<DashboardCardHeader title="Change Password" />
			</Box>
			<Flex
				px={{ base: 0, lg: "40px" }}
				direction="column"
				justify="space-between"
				h="calc(100% - 80px)"
				py="20px">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box>
						<InputText
							{...register("oldPassword")}
							label={"Old Password"}
							placeholder={"Old Password"}
							type={"password"}
							errorMsg={errors.oldPassword?.message}
						/>
						<InputText
							{...register("newPassword")}
							errorMsg={errors.newPassword?.message}
							label={"New Password"}
							placeholder={"New Password"}
							type={"password"}
						/>
						<InputText
							{...register("confirmNewPassword")}
							errorMsg={errors.confirmNewPassword?.message}
							label={"Re-enter Your New Password"}
							placeholder={"New Password"}
							type={"password"}
						/>
					</Box>
					<RippleButton
						variant="outline"
						w="100%"
						type="submit"
						isLoading={updatePasswordLoading}>
						Change
					</RippleButton>
				</form>
			</Flex>
		</GridItem>
	);
};
