export const quantity = {
	EMPLOYEES_TAKE: 20,
	STUDENTS_TAKE: 20,
	PARENTS_TAKE: 20,
	STUDENT_PAYMENTS_TAKE: 20,
	STUDENT_NAMED_SUBSCRIPTIONS_TAKE: 20,
	TEACHER_ATTENDANCE_STUDENTS_TAKE: 20,
	ADMIN_ATTENDANCE_STUDENTS_TAKE: 20,
	MESSAGES_HISTORY_TAKE: 20,
	VACANCIES_TAKE: 20,
	APPLICATIONS_TAKE: 20,
	INVOICES_TAKE: 20,
};
