import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { ClassItem } from "../components";
import { SubscriptionPlan } from "../../../common/types";
import { InfiniteScroll, Loader } from "../../../common/components";
import { ClassesPlaceholder } from "../components/placeholders";

interface IClassesCardProps {
	selectedClass: number;
	setSelectedClass: (value: number) => void;
	loading: boolean;
	items: SubscriptionPlan[];
	selectedClassName?: string;
}

export const Classes: React.FC<IClassesCardProps> = ({
	selectedClass,
	setSelectedClass,
	items,
	loading,
	selectedClassName,
}) => {
	return (
		<Box
			p="30px 40px 10px 40px"
			bg="white"
			borderRadius="16px"
			boxShadow="base"
			display={{ base: "none", lg: "block" }}>
			<Flex
				alignItems={"center"}
				justifyContent={"space-between"}
				pb={"6px"}
				borderBottom={"1px solid #EBEBEB"}
				mb={"24px"}>
				<Heading variant={"h8"} color="darkest">
					Levels
				</Heading>
				<Text color={"darkgrey"} variant={"subtitle1"}>
					{selectedClassName}
				</Text>
			</Flex>
			<Box h="calc(100vh - 270px)">
				{loading ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						{items.length ? (
							<InfiniteScroll>
								{items.map((item) => (
									<ClassItem
										key={item.id}
										name={item.name}
										studentsCount={item.studentsCount}
										startDate={item.startDate}
										endDate={item.endDate}
										selectedClass={selectedClass}
										setSelectedClass={setSelectedClass}
										id={item.id}
										image={item.image}
									/>
								))}
							</InfiniteScroll>
						) : (
							<ClassesPlaceholder />
						)}
					</>
				)}
			</Box>
		</Box>
	);
};
