import { createAction } from "@reduxjs/toolkit";
import { IAddClassForm } from "../../modal/add-class.modal/AddClassModal";
import { PayloadError } from "../../../../common/types";
import { ClassType } from "../../types";

interface IAddClassRequest extends IAddClassForm {
	searchQuery: string;
}

export const addClassRequest = createAction<IAddClassRequest>(
	"@subscription/ADD_CLASS_REQUEST",
);

export const addClassSuccess = createAction("@subscription/ADD_CLASS_SUCCESS");

export const addClassError = createAction<PayloadError[]>(
	"@subscription/ADD_CLASS_ERROR",
);

export const getClassesRequest = createAction<{ searchQuery: string }>(
	"@suscriptions/GET_CLASSES_REQUEST",
);
export const getClassesSuccess = createAction<{ classes: ClassType[] }>(
	"@subscriptions/GET_CLASSES_SUCCESS",
);
export const getClassesError = createAction<PayloadError[]>(
	"@subscriptions/GET_CLASSES_ERROR",
);

export const editClassRequest = createAction<{
	params: {
		id: number;
		name: string;
		complexity: string;
		lessons: number;
		hours: number;
		description: string;
		searchQuery: string;
	};
	imageFile?: FormData;
}>("@subscriptions/EDIT_CLASS_REQUEST");
export const editClassSuccess = createAction(
	"@subscriptions/EDIT_CLASS_SUCCESS",
);
export const editClassError = createAction<PayloadError[]>(
	"@subscriptions/EDIT_CLASS_ERROR",
);

export const deleteClassRequest = createAction<{
	id: number;
	searchQuery: string;
}>("@subscription/DELETE_CLASS_REQUEST");

export const deleteClassSuccess = createAction(
	"@subscription/DELETE_CLASS_SUCCESS",
);

export const deleteClassError = createAction<PayloadError[]>(
	"@subscription/DELETE_CLASS_ERROR",
);
