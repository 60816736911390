import { Box } from "@chakra-ui/react";
import {
	DashboardCardHeader,
	InfiniteScroll,
	Loader,
} from "../../../common/components";
import { StaffItem, StaffPlaceholder } from "../components";
import { StaffItem as StaffItemType } from "../types";

interface IStaffCardProps {
	setShowInviteEmployeeModal: (value: boolean) => void;
	setShowEditEmployeeModal: (value: boolean) => void;
	loading: boolean;
	items: StaffItemType[];
	setToEditEmployee: (value: StaffItemType) => void;
	getMoreEmployees: () => void;
	staffLoadMoreLoading: boolean;
	staffTotalCount: number;
}

export const StaffCard: React.FC<IStaffCardProps> = ({
	setShowInviteEmployeeModal,
	setShowEditEmployeeModal,
	items,
	loading,
	setToEditEmployee,
	getMoreEmployees,
	staffLoadMoreLoading,
	staffTotalCount,
}) => (
	<Box
		p="30px 40px 10px 40px"
		bg="white"
		borderRadius="16px"
		boxShadow="base"
		display={{ base: "none", lg: "block" }}>
		<DashboardCardHeader
			buttonTitle="Invite"
			onClick={() => setShowInviteEmployeeModal(true)}
			title="Staff"
		/>
		<Box height="calc(100vh - 250px)">
			{loading ? (
				<Loader centerHeight="100%" />
			) : (
				<>
					{items.length ? (
						<InfiniteScroll
							onReachBottom={() => {
								if (
									!staffLoadMoreLoading &&
									staffTotalCount > items.length
								) {
									getMoreEmployees();
								}
							}}>
							{items.map((staffMember) => (
								<StaffItem
									key={staffMember.userId}
									setShowEditEmployeeModal={
										setShowEditEmployeeModal
									}
									setToEditEmployee={setToEditEmployee}
									{...staffMember}
								/>
							))}
							{staffLoadMoreLoading && (
								<Loader spinnerSize="md" />
							)}
						</InfiniteScroll>
					) : (
						<StaffPlaceholder
							setShowInviteEmployeeModal={
								setShowInviteEmployeeModal
							}
							height="100%"
						/>
					)}
				</>
			)}
		</Box>
	</Box>
);
