import { useEffect } from "react";
import {
	Modal,
	RippleButton,
	InputText,
	InputSelect,
	InputEditor,
	InputPrice,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { getVacancyByIdRequest, editVacancyRequest } from "../../store/actions";
import { ICreateVacancyForm } from "../create-vacancy.modal/create-vacancy.modal";
import { useForm, Resolver, Controller } from "react-hook-form";
import { createVacancySchema } from "../../../../common/constants/validation";
import { PositionTeamType } from "../../../../common/types";
import { EditJobPositionPayload } from "../../types";
import sanitizeHtml from "sanitize-html";

interface IEditVacancyModalProps {
	handleClose: () => void;
	isOpen: boolean;
	toEditVacancyId: number;
	setToDeleteVacancy: (value: EditJobPositionPayload) => void;
	editLoading: boolean;
}

export const EditVacancyModal: React.FC<IEditVacancyModalProps> = ({
	handleClose,
	isOpen,
	toEditVacancyId,
	setToDeleteVacancy,
	editLoading,
}) => {
	const dispatch = useDispatch();

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control,
		setValue,
	} = useForm<ICreateVacancyForm>({
		resolver: createVacancySchema as Resolver<ICreateVacancyForm>,
	});

	const positionCommitments = useSelector(
		(state: RootStore) => state.directories.positionCommitments.items,
	);
	const positionTeams = useSelector(
		(state: RootStore) => state.directories.positionTeams.items,
	);
	const { loading, values } = useSelector(
		(state: RootStore) => state.jobs.vacancies.targetVacancy,
	);

	useEffect(() => {
		if (toEditVacancyId) {
			dispatch(getVacancyByIdRequest(toEditVacancyId + ""));
		}
	}, [toEditVacancyId]);

	useEffect(() => {
		if (isOpen && values.id) {
			reset({
				...values,
				teamId: values.team.id,
				commitmentId: values.commitment.id,
				payRate: values.payRate + "",
			});
		} else {
			reset({});
		}
	}, [isOpen, values, reset]);

	const onSubmit = (data: ICreateVacancyForm) => {
		dispatch(
			editVacancyRequest({
				commitmentId: +data.commitmentId,
				description: sanitizeHtml(data.description),
				id: toEditVacancyId,
				location: data.location,
				teamId: +data.teamId,
				title: data.title,
				payRate: parseFloat(data.payRate.replace("$", "")),
			}),
		);
	};

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Edit Vacancy">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Box>
						<InputText
							{...register("title")}
							label="Vacancy Name"
							placeholder="Vacancy Name"
							errorMsg={errors.title?.message}
						/>
						<InputText
							{...register("location")}
							label="Location"
							placeholder="Location"
							errorMsg={errors.location?.message}
						/>
						<Flex direction={{ base: "column", lg: "row" }}>
							<InputSelect
								{...register("teamId")}
								label="Job Position"
								options={positionTeams.map(
									(elem: PositionTeamType) => ({
										label: elem.name,
										value: elem.id,
									}),
								)}
								// errorMsg={errors.positionName?.message}
							/>
							<Box w="20px" />
							<InputSelect
								{...register("commitmentId")}
								label="Job Type"
								options={positionCommitments.map(
									(elem: PositionTeamType) => ({
										label: elem.name,
										value: elem.id,
									}),
								)}
								// errorMsg={errors.staffRole?.message}
							/>
						</Flex>
						<Controller
							render={({ field }) => {
								return (
									<InputPrice
										label="Pay Rate"
										allowNegative={false}
										// fixedDecimalScale
										decimalScale={0}
										placeholder="Pay Rate"
										prefix="$"
										{...field}
										errMessage={errors.payRate?.message}
									/>
								);
							}}
							name="payRate"
							control={control}
						/>

						<Controller
							render={({ field }) => {
								return (
									<InputEditor
										{...field}
										label="Job Description"
										errorMsg={errors.description?.message}
										placeholder="Job Description"
										value={field.value}
										onChange={(value) => {
											setValue("description", value);
										}}
									/>
								);
							}}
							name="description"
							control={control}
						/>
					</Box>
				</ModalBody>
				<ModalFooter>
					<RippleButton
						variant="outline"
						width="100%"
						onClick={() => {
							setToDeleteVacancy({
								teamId: values.team.id,
								commitmentId: values.commitment.id,
								description: values.description,
								id: toEditVacancyId,
								location: values.location,
								title: values.title,
								payRate: values.payRate,
							});
						}}>
						Remove
					</RippleButton>
					<Box w="20px" />
					<RippleButton
						width="100%"
						type="submit"
						isLoading={editLoading}>
						Publish
					</RippleButton>
				</ModalFooter>
			</form>
		</Modal>
	);
};
