import { useEffect, useState } from "react";
import { sliceIntoChunks, getDaysNewWay } from "../../utils";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import dayjs from "dayjs";
import { dayLabels } from "../../constants";

interface ITestCalendarProps {
	mode: "day" | "week";
	selectedMonth: number;
	selectedYear: number;
	onChange: (day1: string, day2?: string) => void;
	selectedValue?: number | number[];
}

export const ModalCalendar: React.FC<ITestCalendarProps> = ({
	mode,
	selectedMonth,
	selectedYear,
	onChange,
	selectedValue = null,
}) => {
	const [selectedItem, setSelectedItem] = useState<number[] | number | null>(
		selectedValue,
	);

	useEffect(() => {
		if (selectedItem && typeof selectedItem === "number") {
			onChange(dayjs.utc(selectedItem).format());
		} else if (typeof selectedItem === "object") {
			onChange(
				dayjs.utc(selectedItem?.[0]).format(),
				dayjs.utc(selectedItem?.[6]).format(),
			);
		}
	}, [selectedItem]);

	const getColorForCell = (day: number) => {
		if (day === selectedItem) {
			return "white";
		} else if (
			dayjs
				.utc()
				.month(selectedMonth)
				.year(selectedYear)
				.format("MMMM YYYY") === dayjs.utc(day).format("MMMM YYYY")
		) {
			return "darkest";
		} else {
			return "darkGrey";
		}
	};

	const getBackgroundForCell = (day: number) => {
		if (day === selectedItem) {
			return "primary";
		} else if (
			dayjs.utc(day).format("DD-MM-YYYY") ===
			dayjs.utc().format("DD-MM-YYYY")
		) {
			return "purple";
		} else {
			return "";
		}
	};

	return (
		<Box>
			<Flex py="16px" borderBottom="1px solid #EBEBEB">
				{dayLabels.map((label) => (
					<Box
						className="rlc-day-label"
						key={label.toLowerCase()}
						flex="1">
						<Text
							variant="body3"
							color="darkGrey"
							textAlign="center">
							{label.slice(0, 3)}
						</Text>
					</Box>
				))}
			</Flex>
			<Box>
				{sliceIntoChunks(
					getDaysNewWay(selectedMonth, selectedYear),
					7,
				).map((week: number[], index: number) =>
					mode === "day" ? (
						<Flex key={index} borderRadius="8px">
							{week.map((day: number) => (
								<Button
									variant="unstyled"
									key={day}
									flex="1"
									fontSize="14px"
									lineHeight="24px"
									fontWeight="500"
									color={getColorForCell(day)}
									height="40px"
									isDisabled={
										dayjs
											.utc()
											.month(selectedMonth)
											.year(selectedYear)
											.format("MMMM YYYY") !==
										dayjs.utc(day).format("MMMM YYYY")
									}
									onClick={() => setSelectedItem(day)}
									bg={getBackgroundForCell(day)}>
									{dayjs.utc(day).format("D")}
								</Button>
							))}
						</Flex>
					) : (
						<Flex
							key={index}
							as="button"
							w="100%"
							borderRadius="8px"
							onClick={() => setSelectedItem(week)}
							bg={
								JSON.stringify(selectedItem) ===
								JSON.stringify(week)
									? "purple"
									: ""
							}>
							{week.map((day: number) => (
								<Box key={day} flex="1" height="40px">
									<Text
										lineHeight="38px"
										variant="body3"
										textAlign="center"
										color="darkest">
										{dayjs.utc(day).format("D")}
									</Text>
								</Box>
							))}
						</Flex>
					),
				)}
			</Box>
		</Box>
	);
};
