import {
	Box,
	Avatar,
	AvatarBadge,
	Text,
	Button,
	Flex,
	HStack,
} from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "../../../assets/ic_info.svg";
import BulkIcon from "../../../assets/ic_bulk.png";
import dayjs from "dayjs";
import { MessageHistoryItemType } from "../types";
import { getPictureURL } from "../../../common/utils";

interface IHistoryItemProps extends MessageHistoryItemType {
	setToShowInfoMessage: () => void;
}

export const HistoryItem: React.FC<IHistoryItemProps> = ({
	sendAt,
	isBulk,
	setToShowInfoMessage,
	usersCount,
	users,
	senderName,
}) => {
	return (
		<Flex justify="space-between" py="10px">
			<HStack spacing="16px">
				{isBulk ? (
					<Avatar src={BulkIcon}>
						<AvatarBadge
							borderColor="primary"
							bg="primary"
							borderRadius="20px"
							px="5px">
							<Text variant="subtitle2" color="white">
								{usersCount}
							</Text>
						</AvatarBadge>
					</Avatar>
				) : (
					<Avatar
						w="48px"
						h="48px"
						borderRadius="8px"
						name={`${users?.[0]?.firstName} ${users?.[0]?.lastName}`}
						src={getPictureURL(users?.[0]?.image)}
					/>
				)}

				<Box>
					<Text variant="body1" color="darkest">
						{isBulk
							? senderName
							: `${users?.[0]?.firstName} ${users?.[0]?.lastName}`}
					</Text>
					<Text variant="body3" fontWeight="500" color="grey">
						{dayjs(sendAt).format("D MMMM, YYYY • hh:mm A")}
					</Text>
				</Box>
			</HStack>
			<Button
				variant="plain"
				leftIcon={<InfoIcon />}
				color="grey"
				onClick={() => setToShowInfoMessage()}>
				Info
			</Button>
		</Flex>
	);
};
