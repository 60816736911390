import { ModalFooter } from "@chakra-ui/react";
import {
	InfiniteScroll,
	Loader,
	RippleButton,
} from "../../../../../../common/components";
import { InvoiceItem, InvoicesPlaceholder } from "./components";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../../../redux";
import { PendingManualSubscription } from "../../../../../../common/types";
import {
	confirmManualInvoiceRequest,
	getMorePendingInvoicesRequest,
} from "../../../../../../redux/actions/manual-invoices.actions";

interface IInvoicesTabProps {
	handleClose: () => void;
}

export const InvoicesTab: React.FC<IInvoicesTabProps> = ({ handleClose }) => {
	const dispatch = useDispatch();

	const { items, loading, totalCount, loadMoreLoading } = useSelector(
		(state: RootStore) => state.manualInvoices.invoices,
	);
	const { itemId, loading: confirmLoading } = useSelector(
		(state: RootStore) => state.manualInvoices.confirmInvoice,
	);

	return (
		<>
			{loading ? (
				<Loader centerHeight="500px" />
			) : (
				<>
					{items.length ? (
						<InfiniteScroll
							height="500px"
							onReachBottom={() => {
								if (
									!loadMoreLoading &&
									totalCount > items.length
								) {
									dispatch(getMorePendingInvoicesRequest());
								}
							}}>
							{items.map((invoice: PendingManualSubscription) => (
								<InvoiceItem
									key={invoice.id}
									{...invoice}
									onSubmitInvoice={(value) =>
										dispatch(
											confirmManualInvoiceRequest(value),
										)
									}
									loading={
										confirmLoading && itemId === invoice.id
									}
								/>
							))}
							{loadMoreLoading && (
								<Loader
									spinnerSize="md"
									centerProps={{ py: "10px" }}
								/>
							)}
						</InfiniteScroll>
					) : (
						<InvoicesPlaceholder />
					)}
				</>
			)}
			<ModalFooter mt="20px">
				<RippleButton variant="outline" w="100%" onClick={handleClose}>
					Close
				</RippleButton>
			</ModalFooter>
		</>
	);
};
