/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { useState, memo, useCallback } from "react";
import { DashboardCardHeader, Loader } from "../../../common/components";
import { useDropzone } from "react-dropzone";
import { Button, Image, Box, Center, Flex, Text } from "@chakra-ui/react";
import { getPictureURL } from "../../../common/utils";
import { ImageType } from "../../../common/types";

type IPhotoCardProps = {
	handleUpdatePhoto: (imageFile: FormData) => void;
	image: ImageType;
	photoSaveLoading: boolean;
	profileLoading: boolean;
};

export const PhotoCard: React.FC<IPhotoCardProps> = memo(
	({ handleUpdatePhoto, image, photoSaveLoading, profileLoading }) => {
		const [uploadPhoto, setUploadPhoto] = useState<
			string | ArrayBuffer | null
		>(null);
		const onDrop = useCallback((acceptedFiles) => {
			if (Math.round(acceptedFiles[0].size / 1024) < 10240) {
				const formDate = new FormData();
				formDate.append("imageFile", acceptedFiles[0]);
				setUploadPhoto(acceptedFiles[0]);
				handleUpdatePhoto(formDate);
			} else {
				alert("File is too big");
			}
		}, []);
		const { getRootProps, getInputProps, isDragActive } = useDropzone({
			onDrop,
		});

		return (
			<Box
				bg={{ base: "transparent", lg: "white" }}
				p={{ base: "10px 0", lg: "30px 40px 30px 40px" }}
				borderRadius="16px"
				boxShadow={{ base: "none", lg: "base" }}
				sx={{
					gridArea: { base: "1 / 1 / 2 / 2", lg: "2 / 1 / 3 / 2" },
				}}>
				<DashboardCardHeader title={"Photo"} />
				{profileLoading || photoSaveLoading ? (
					<Loader centerHeight="calc(100% - 30px) " />
				) : (
					<Box
						{...getRootProps()}
						border="1px dashed #8C4BC9"
						borderRadius="8px"
						height={{ base: "auto", lg: "calc(100% - 50px)" }}
						bg={isDragActive ? "secondary" : ""}
						mt="15px">
						<Center h="100%" py={{ base: "10px", lg: 0 }}>
							<input {...getInputProps()} />
							<Flex direction="column" alignItems="center">
								<Image
									w="88px"
									h="88px"
									borderRadius="50%"
									border="1px solid #DFCCFA"
									bg="#F3ECFD"
									src={
										uploadPhoto
											? URL.createObjectURL(uploadPhoto)
											: image.itemHash
											? getPictureURL(image)
											: ""
									}
								/>
								{isDragActive ? (
									<Text
										variant="subtitle3"
										color="darkest"
										h="50px"
										lineHeight="50px">
										Drop the file here
									</Text>
								) : (
									<Button variant="plain">
										Upload Photo
									</Button>
								)}
							</Flex>
						</Center>
					</Box>
				)}
			</Box>
		);
	},
);
