/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useCallback, useState, memo, useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Image, Box } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as CameraIcon } from "../../../assets/ic_camera.svg";
import { ReactComponent as CloseIcon } from "../../../assets/ic_white-close.svg";
import { IAddLevelForm } from "../modal/add-level.modal/AddLevelModal";
import { ImageType } from "../../../common/types";
import { getPictureURL } from "../../../common/utils";

type IImageDropZoneProps = {
	setValue: any;
	responseImage?: ImageType;
	setSelectedImage: (value: number) => void;
};

export const ImageDropZone = memo<IImageDropZoneProps>(
	({ setValue, responseImage, setSelectedImage }) => {
		const [uploadedFile, setUploadedFile] = useState<
			string | ArrayBuffer | null
		>(null);
		const [resImage, setResImage] = useState<ImageType>();

		const onDrop = useCallback((acceptedFiles) => {
			if (Math.round(acceptedFiles[0].size / 1024) <= 10240) {
				setUploadedFile(acceptedFiles[0]);
				setSelectedImage(0);
				setValue("imageFile", acceptedFiles[0]);
			} else {
				alert("File is too big");
			}
		}, []);

		useEffect(() => {
			if (responseImage) {
				setResImage(responseImage);
			}
		}, [responseImage]);

		const { getRootProps, getInputProps, isDragActive } = useDropzone({
			onDrop,
			accept: "image/*",
			multiple: false,
		});

		return (
			<>
				{uploadedFile || resImage?.itemHash ? (
					<Box
						position={"relative"}
						w={"80px"}
						h={"80px"}
						mx={{ base: "auto" }}>
						<Image
							src={
								uploadedFile
									? URL.createObjectURL(uploadedFile)
									: resImage?.itemHash
									? getPictureURL(resImage)
									: ""
							}
							objectFit="cover"
							w={"100%"}
							h={"100%"}
							borderRadius={"16px"}
							border={"2px solid #DFCCFA"}
						/>
						<Box
							onClick={() => {
								setUploadedFile(null);
								setResImage(undefined);
							}}
							top={"-12px"}
							right={"-10px"}
							position={"absolute"}
							as={"button"}
							w={"24px"}
							height={"24px"}
							borderRadius={"50%"}
							bg={"primary"}
							d={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							p={"4px"}>
							<CloseIcon />
						</Box>
					</Box>
				) : (
					<Box
						{...getRootProps()}
						w={"88px"}
						h={"80px"}
						border={"1px dashed #8C4BC9"}
						borderRadius={"16px"}
						d={"flex"}
						bg={isDragActive ? "rgb(243, 236, 253,.7)" : ""}
						alignItems={"center"}
						justifyContent={"center"}
						cursor={"pointer"}>
						<CameraIcon />
						<input {...getInputProps()} />
					</Box>
				)}
			</>
		);
	},
);
