import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { InputText, RippleButton } from "../../../common/components";
import { SubmitHandler, useForm, Resolver } from "react-hook-form";
import { PayloadError } from "../../../common/types";
import { resetPasswordSchema } from "../../../common/constants/validation";
import { ResetForm } from "../types";

interface IResetPasswordFormProps {
	loading?: boolean;
	onSubmit: SubmitHandler<ResetForm>;
	stateErrors: PayloadError[];
}

export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({
	loading,
	onSubmit,
	stateErrors,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		clearErrors,
		setError,
	} = useForm<ResetForm>({
		resolver: resetPasswordSchema as Resolver<ResetForm>,
	});

	useEffect(() => {
		if (stateErrors.length) {
			setError("password", {
				type: "manual",
				message: stateErrors[0].errorMessage,
			});
		} else {
			clearErrors(undefined);
		}
	}, [stateErrors, setError, clearErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputText
					{...register("password")}
					label="Password"
					placeholder="Password"
					errorMsg={errors.password?.message}
					type="password"
				/>
				<InputText
					{...register("confirmPassword")}
					label="Confirm Password"
					placeholder="Confirm Password"
					errorMsg={errors.confirmPassword?.message}
					type="password"
				/>
				<RippleButton w="100%" type="submit" isLoading={loading}>
					Submit
				</RippleButton>
			</form>
		</Box>
	);
};
