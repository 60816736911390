import { createAction } from "@reduxjs/toolkit";
import {
	StudentPaymentType,
	StudentResponseType,
	SelectedFilters,
} from "../../types";
import { PayloadError, SubscriptionPlan } from "../../../../common/types";

//GET STUDENTS
export const getStudentsRequest = createAction<{
	searchQuery: string;
	sortByAscending: boolean;
	subscriptionPlanId: SelectedFilters;
}>("@dashboard/GET_STUDENTS_REQUEST");
export const getStudentsSuccess = createAction<{
	totalCount: number;
	users: StudentResponseType[];
}>("@dashboard/GET_STUDENTS_SUCCESS");
export const getStudentsError = createAction<PayloadError[]>(
	"@dashboard/GET_STUDENTS_ERROR",
);
//GET MORE STUDENTS
export const getMoreStudentsRequest = createAction<{
	searchQuery: string;
	sortByAscending: boolean;
	subscriptionPlanId: SelectedFilters;
}>("@dashboard/GET_MORE_STUDENTS_REQUEST");
export const getMoreStudentsSuccess = createAction<{
	totalCount: number;
	users: StudentResponseType[];
}>("@dashboard/GET_MORE_STUDENTS_SUCCESS");
export const getMoreStudentsError = createAction<PayloadError[]>(
	"@dashboard/GET_MORE_STUDENTS_ERROR",
);
// GET STUDENT SUBSCRIPTION PLAN
export const getStudentSubscriptionPlansRequest = createAction<number>(
	"@dashboard/GET_STUDENT_SUBSCRIPTION_PLANS_REQUEST",
);
export const getStudentSubscriptionPlansSuccess = createAction<
	SubscriptionPlan[]
>("@dashboard/GET_STUDENT_SUBSCRIPTION_PLANS_SUCCESS");
export const getStudentSubscriptionPlansError = createAction<PayloadError[]>(
	"@dashboard/GET_STUDENT_SUBSCRIPTION_PLANS_ERROR",
);

//GET STUDENT PAYMENTS
export const getStudentPaymentsRequest = createAction<{ studentId: number }>(
	"@dashboard/GET_STUDENT_PAYMENTS_REQUEST",
);
export const getStudentPaymentsSuccess = createAction<{
	payments: StudentPaymentType[];
	totalCount: number;
}>("@dashboard/GET_STUDENT_PAYMENTS_SUCCESS");
export const getStudentPaymentsError = createAction<PayloadError[]>(
	"@dashboard/GET_STUDENT_PAYMENTS_ERROR",
);
//GET MORE STUDENT PAYMENTS
export const getMoreStudentPaymentsRequest = createAction<{
	studentId: number;
}>("@dashboard/GET_MORE_STUDENT_PAYMENTS_REQUEST");
export const getMoreStudentPaymentsSuccess = createAction<{
	payments: StudentPaymentType[];
	totalCount: number;
}>("@dashboard/GET_MORE_STUDENT_PAYMENTS_SUCCESS");
export const getMoreStudentPaymentsError = createAction<PayloadError[]>(
	"@dashboard/GET_MORE_STUDENT_PAYMENTS_ERROR",
);
