import { Modal, InputText, InputTextArea } from "../../../../common/components";
import {
	ModalBody,
	Box,
	Flex,
	ModalFooter,
	Button,
	HStack,
} from "@chakra-ui/react";
import { MessageHistoryItemType } from "../../types";
import dayjs from "dayjs";

interface IBulkMessageDetailsModalProps {
	isOpen: boolean;
	handleClose: () => void;
	toShowInfoMessage: MessageHistoryItemType;
}

export const BulkMessageDetailsModal: React.FC<IBulkMessageDetailsModalProps> =
	({ handleClose, isOpen, toShowInfoMessage }) => {
		return (
			<Modal
				title="Bulk Message Details"
				isOpen={isOpen}
				handleClose={handleClose}>
				<ModalBody>
					<Box>
						<InputText
							label="Sender Name"
							placeholder="Sender Name"
							value={toShowInfoMessage.senderName}
							isReadOnly={true}
						/>
						<Flex direction={{ base: "column", md: "row" }}>
							<InputText
								label="Recipients"
								placeholder="Recipients"
								isReadOnly={true}
								value={
									toShowInfoMessage.staffRole === 1
										? "Staff"
										: "Parents"
								}
							/>
							<Box w="20px" />
							<InputText
								label="Level"
								value={
									toShowInfoMessage.subscriptionPlanName ||
									"All Levels"
								}
								isReadOnly={true}
								placeholder="Level"
							/>
						</Flex>
						<InputTextArea
							label="Message Text"
							placeholder="Message Text"
							value={toShowInfoMessage.messageText}
							isReadOnly={true}
							height="130px"
						/>
						<Flex direction={{ base: "column", md: "row" }}>
							<InputText
								label="Sending Date"
								value={dayjs
									.utc(toShowInfoMessage.sendAt)
									.format("DD MMMM, YYYY")}
								isReadOnly={true}
							/>
							<Box w="20px" />
							<InputText
								name="sendingTime"
								label="Sending Time"
								value={dayjs
									.utc(toShowInfoMessage.sendAt)
									.format("HH:mm")}
								isReadOnly={true}
							/>
						</Flex>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack borderTop="1px solid #EBEBEB" w="100%" pt="16px">
						<Button
							w="100%"
							variant="outline"
							onClick={handleClose}>
							Close
						</Button>
					</HStack>
				</ModalFooter>
			</Modal>
		);
	};
