import { Box } from "@chakra-ui/react";
import { StaffItem, StaffPlaceholder } from "../../components";
import { StaffItem as StaffItemType } from "../../types";
import { InfiniteScroll, Loader } from "../../../../common/components";

interface IStaffTabProps {
	staffItems: StaffItemType[];
	setShowEditEmployeeModal: (value: boolean) => void;
	setToEditEmployee: (value: StaffItemType) => void;
	staffLoading: boolean;
	getMoreEmployees: () => void;
	staffLoadMoreLoading: boolean;
	staffTotalCount: number;
	setShowInviteEmployeeModal: (value: boolean) => void;
}

export const StaffTab: React.FC<IStaffTabProps> = ({
	setShowEditEmployeeModal,
	setToEditEmployee,
	staffItems,
	getMoreEmployees,
	staffLoadMoreLoading,
	staffLoading,
	staffTotalCount,
	setShowInviteEmployeeModal,
}) => {
	return (
		<>
			{staffLoading ? (
				<Loader centerHeight="calc(100vh - 390px)" />
			) : (
				<>
					{staffItems.length ? (
						<Box>
							<InfiniteScroll
								height="calc(100vh - 450px)"
								onReachBottom={() => {
									if (
										!staffLoadMoreLoading &&
										staffTotalCount > staffItems.length
									) {
										getMoreEmployees();
									}
								}}>
								{staffItems.map((staffMember) => (
									<StaffItem
										key={staffMember.userId}
										setShowEditEmployeeModal={
											setShowEditEmployeeModal
										}
										setToEditEmployee={setToEditEmployee}
										{...staffMember}
									/>
								))}
								{staffLoadMoreLoading && (
									<Loader
										spinnerSize="md"
										centerProps={{ py: "10px" }}
									/>
								)}
							</InfiniteScroll>
						</Box>
					) : (
						<StaffPlaceholder
							setShowInviteEmployeeModal={
								setShowInviteEmployeeModal
							}
							height="calc(100vh - 390px)"
						/>
					)}
				</>
			)}
		</>
	);
};
