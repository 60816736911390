import { createReducer } from "@reduxjs/toolkit";
import { loginSuccess, logoutSuccess } from "../actions/auth.actions";
import { setAuthHeaderToken } from "../../common/utils";
import { getUserSettingsSuccess } from "../../pages/Settings/store/actions";

type InitialState = {
	id: number;
	firstName: string;
	lastName: string;
	userName: string;
	email: string;
	roles: string[];
	accessToken: string;
	tokenExpiration: string | Date;
	refreshToken: string;
};

const initialState: InitialState = {
	id: 0,
	firstName: "",
	lastName: "",
	userName: "",
	email: "",
	roles: [],
	accessToken: "",
	tokenExpiration: new Date().toString(),
	refreshToken: "",
};
const sessionReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(loginSuccess, (state, action) => {
			setAuthHeaderToken(action.payload.accessToken);
			return { ...action.payload };
		})
		.addCase(logoutSuccess, () => {
			setAuthHeaderToken(null);
			return { ...initialState };
		})
		.addCase(getUserSettingsSuccess, (state, action) => {
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
		});
});

export default sessionReducer;
