/* eslint-disable indent */
import { useState, useEffect } from "react";
import {
	Modal,
	InputText,
	InputSelect,
	InputTime,
	DatePicker,
	InputEditor,
} from "../../../../common/components";
import {
	ModalBody,
	ModalFooter,
	Button,
	Flex,
	Box,
	HStack,
	Checkbox,
	Text,
} from "@chakra-ui/react";
import { ICChek } from "../../../../assets/svg-components";
import { useForm, Resolver, Controller } from "react-hook-form";
import { sendBulkMessageSchema } from "../../../../common/constants/validation";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { RootStore } from "../../../../redux";
import { SubscriptionPlan } from "../../../../common/types";
import { SendBulkMessagePayload } from "../../types";

interface ISendBulkMessageModalProps {
	handleClose: () => void;
	isOpen: boolean;
	onSend: (value: { type: string; payload: SendBulkMessagePayload }) => void;
}

interface ISendBulkMessageForm {
	senderName: string;
	recipients: string;
	level: string;
	messageText: string;
	sendNow: boolean;
	sendingDate: number;
	sendingTime: string;
	sendImmediately: boolean;
}

export const SendBulkMessageModal: React.FC<ISendBulkMessageModalProps> = ({
	handleClose,
	isOpen,
	onSend,
}) => {
	const levelFilters = useSelector(
		(state: RootStore) => state.messages.filterClasses.items,
	);

	const [checkboxChecked, setCheckboxChecked] = useState(true);
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		clearErrors,
		getValues,
		watch,
		reset,
		control,
	} = useForm<ISendBulkMessageForm>({
		resolver: sendBulkMessageSchema as Resolver<ISendBulkMessageForm>,
	});

	const recipients = watch("recipients");

	const onSubmit = (data: ISendBulkMessageForm) => {
		onSend({
			type: "bulk",
			payload: {
				messageText: data.messageText,
				sendAtUtc: checkboxChecked
					? undefined
					: dayjs
							.utc(
								`${dayjs
									.utc(data.sendingDate)
									.format("DD MMMM, YYYY")} ${
									data.sendingTime
								}`,
							)
							.format(),
				sendImmediately: checkboxChecked,
				senderName: data.senderName,
				staffRole: data.recipients,
				subscriptionPlanId:
					data.recipients !== "1" || data.level !== "all"
						? data.level
						: null,
			},
		});
	};

	useEffect(() => {
		if (!isOpen) {
			reset({});
		} else {
			reset({ sendImmediately: true });
		}
	}, [isOpen]);

	const [sendDate] = getValues(["sendingDate"]);

	return (
		<Modal
			title="Send Bulk Message"
			handleClose={handleClose}
			isOpen={isOpen}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Box>
						<InputText
							{...register("senderName")}
							label="Sender Name"
							placeholder="Sender Name"
							errorMsg={errors.senderName?.message}
						/>
						<Flex direction={{ base: "column", md: "row" }}>
							<InputSelect
								{...register("recipients")}
								options={[
									{ value: "2", label: "Parents" },
									{ value: "1", label: "Staff" },
								]}
								label="Recipients"
							/>
							{recipients !== "1" && (
								<>
									<Box w="20px" />
									<InputSelect
										{...register("level")}
										label="Level"
										options={[
											{
												label: "All Levels",
												value: "all",
											},
											...levelFilters.map(
												(level: SubscriptionPlan) => ({
													value: level.id,
													label: level.name,
												}),
											),
										]}
									/>
								</>
							)}
						</Flex>
						<Controller
							render={({ field }) => (
								<InputEditor
									{...field}
									onChange={(value) =>
										setValue("messageText", value)
									}
									label="Message Text"
									errorMsg={errors.messageText?.message}
									placeholder="Message Text"
								/>
							)}
							name="messageText"
							control={control}
						/>
						<Checkbox
							isChecked={checkboxChecked}
							sx={{
								".chakra-checkbox__control": {
									borderRadius: "50%",
									border: "1px solid #8C4BC9",
								},
							}}
							icon={<ICChek enabled={checkboxChecked} />}
							mb="29px"
							onChange={(ev) => {
								setCheckboxChecked(ev.target.checked);
								setValue("sendImmediately", ev.target.checked);
							}}>
							<Text variant="body2" color="darkest">
								Send Now
							</Text>
						</Checkbox>
						<Flex direction={{ base: "column", md: "row" }}>
							<DatePicker
								{...register("sendingDate")}
								label="Sending Date"
								value={sendDate}
								displayValue={
									sendDate
										? dayjs
												.utc(sendDate)
												.format("DD MMMM, YYYY")
										: ""
								}
								setValue={(value: number) => {
									setValue("sendingDate", value);
									clearErrors("sendingDate");
								}}
								isDisabled={checkboxChecked}
								placeholder="Sending Date"
								errMessage={errors.sendingDate?.message}
							/>
							<Box w="20px" />
							<InputTime
								{...register("sendingTime")}
								name="sendingTime"
								isDisabled={checkboxChecked}
								label="Sending Time"
								errorMsg={errors.sendingTime?.message}
							/>
						</Flex>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack borderTop="1px solid #EBEBEB" w="100%" pt="16px">
						<Button
							w="100%"
							variant="outline"
							onClick={handleClose}>
							Cancel
						</Button>
						<Button w="100%" type="submit">
							Send
						</Button>
					</HStack>
				</ModalFooter>
			</form>
		</Modal>
	);
};
