import { DashboardHeader } from "../../common/sections";
import { Box, Grid } from "@chakra-ui/react";
import { Classes, Students } from "./cards";
import { SubscriptionPlan, WorkingHoursType } from "../../common/types";
import { TeacherAttendanceType, UpdateAttendanceClickPayload } from "./types";

type ITeacherAttendanceProps = {
	setShowStudentInfo: (value: boolean) => void;
	selectedClass: number;
	setSelectedClass: (value: number) => void;
	classesLoading: boolean;
	classesItems: SubscriptionPlan[];
	selectedDay: string;
	setSelectedDay: (value: string) => void;
	selectedMonth: number;
	setSelectedMonth: (value: number) => void;
	selectedYear: number;
	setSelectedYear: (value: number) => void;
	students: TeacherAttendanceType[];
	studentsLoading: boolean;
	studentsLoadMoreLoading: boolean;
	studentsTotalCount: number;
	updateAttendance: (value: UpdateAttendanceClickPayload) => void;
	selectedClassWorkingHours?: WorkingHoursType[];
	selectedClassName?: string;
	setStudentInfo: (value: TeacherAttendanceType) => void;
	loadMoreStudents: () => void;
};

export const TeacherAttendance: React.FC<ITeacherAttendanceProps> = ({
	setShowStudentInfo,
	selectedClass,
	setSelectedClass,
	classesItems,
	classesLoading,
	selectedDay,
	selectedMonth,
	setSelectedDay,
	setSelectedMonth,
	selectedYear,
	setSelectedYear,
	students,
	studentsLoading,
	studentsLoadMoreLoading,
	studentsTotalCount,
	updateAttendance,
	selectedClassWorkingHours,
	selectedClassName,
	setStudentInfo,
	loadMoreStudents,
}) => {
	return (
		<Box h="100vh" overflowY="auto" bg={{ base: "#F9F9FB", lg: "white" }}>
			<DashboardHeader title="Attendance" description="" />
			<Box
				px={{ base: "0", lg: "40px" }}
				mt="-50px"
				width={{ base: "auto", lg: "calc(100vw - 250px)" }}>
				<Grid
					templateColumns={{ base: "auto", lg: "60% 1fr" }}
					templateRows="1fr"
					gridGap="24px">
					<Students
						setShowStudentInfo={setShowStudentInfo}
						selectedDay={selectedDay}
						setSelectedDay={setSelectedDay}
						selectedMonth={selectedMonth}
						setSelectedMonth={setSelectedMonth}
						selectedYear={selectedYear}
						setSelectedYear={setSelectedYear}
						items={students}
						loading={studentsLoading}
						loadMoreLoading={studentsLoadMoreLoading}
						totalCount={studentsTotalCount}
						updateAttendance={updateAttendance}
						selectedClassWorkingHours={selectedClassWorkingHours}
						setStudentInfo={setStudentInfo}
						loadMoreStudents={loadMoreStudents}
						classesItems={classesItems}
						setSelectedClass={setSelectedClass}
						selectedClass={selectedClass}
					/>
					<Classes
						selectedClass={selectedClass}
						setSelectedClass={setSelectedClass}
						loading={classesLoading}
						items={classesItems}
						selectedClassName={selectedClassName}
					/>
					<Box
						height="100px"
						width="100%"
						display={{ base: "block", lg: "none" }}
					/>
				</Grid>
			</Box>
		</Box>
	);
};
