import { Box } from "@chakra-ui/react";
import { InfiniteScroll, Loader } from "../../../../common/components";
import { ParentItem } from "../../components";
import { TabPlaceholder } from "../../../Dashboard/components";
import { ParentResponseType } from "../../../Dashboard/types";
import { SendMessageTo } from "../../types";

interface IParentsTabProps {
	setShowSendMessageModal: (value: boolean) => void;
	loading: boolean;
	parents: ParentResponseType[];
	loadMoreLoading: boolean;
	getMoreParents: () => void;
	totalCount: number;
	setSendMessageTo: (value: SendMessageTo) => void;
}

export const ParentsTab: React.FC<IParentsTabProps> = ({
	setShowSendMessageModal,
	parents,
	loading,
	getMoreParents,
	loadMoreLoading,
	totalCount,
	setSendMessageTo,
}) => {
	return (
		<>
			{loading ? (
				<Loader centerHeight="calc(100vh - 390px)" />
			) : (
				<>
					{parents.length ? (
						<Box>
							<InfiniteScroll
								height="calc(100vh - 390px)"
								onReachBottom={() => {
									if (
										!loadMoreLoading &&
										totalCount > parents.length
									) {
										getMoreParents();
									}
								}}>
								{parents.map((parent) => (
									<ParentItem
										key={parent.userId}
										setShowSendMessageModal={() => {
											setSendMessageTo({
												id: parent.userId + "",
												name: `${parent.firstName} ${parent.lastName}`,
												type: "parent",
											});
											setShowSendMessageModal(true);
										}}
										{...parent}
									/>
								))}
								{loadMoreLoading && (
									<Loader
										spinnerSize="md"
										centerProps={{ py: "10px" }}
									/>
								)}
							</InfiniteScroll>
						</Box>
					) : (
						<TabPlaceholder />
					)}
				</>
			)}
		</>
	);
};
