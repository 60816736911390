import { createReducer } from "@reduxjs/toolkit";
import {
	createJobPositionRequest,
	createJobPositionSuccess,
	createJobPositionError,
	getVacanciesRequest,
	getVacanciesSuccess,
	getVacanciesError,
	getMoreVacanciesRequest,
	getMoreVacanciesSuccess,
	getMoreVacanciesError,
	getVacancyByIdRequest,
	getVacancyByIdSuccess,
	getVacancyByIdError,
	clearVacancyById,
	editVacancyRequest,
	editVacancySuccess,
	editVacancyError,
	deleteVacancyRequest,
	deleteVacancySuccess,
	deleteVacancyError,
} from "../actions";
import { PayloadError } from "../../../../common/types";
import { IVacancy, IFullVacancy } from "../../types";

type InitialState = {
	createPosition: {
		loading: boolean;
		errors: PayloadError[];
	};
	editPosition: {
		loading: boolean;
		errors: PayloadError[];
	};
	deletePosition: {
		loading: boolean;
		errors: PayloadError[];
	};
	targetVacancy: { loading: boolean; values: IFullVacancy };
	values: {
		items: IVacancy[];
		loadMoreLoading: boolean;
		page: number;
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};
};

const initialState: InitialState = {
	createPosition: {
		loading: false,
		errors: [],
	},
	editPosition: {
		loading: false,
		errors: [],
	},
	deletePosition: {
		errors: [],
		loading: false,
	},
	values: {
		errors: [],
		items: [],
		loadMoreLoading: false,
		loading: false,
		page: 1,
		totalCount: 0,
	},
	targetVacancy: {
		loading: false,
		values: {
			commitment: { id: 0, name: "" },
			description: "",
			id: 0,
			location: "",
			team: { id: 0, name: "" },
			title: "",
		},
	},
};

const vacanciesReducer = createReducer(initialState, (builder) => {
	builder
		// Create job
		.addCase(createJobPositionRequest, (state) => {
			state.createPosition.loading = true;
			state.createPosition.errors = [];
		})
		.addCase(createJobPositionSuccess, (state) => {
			state.createPosition.loading = false;
		})
		.addCase(createJobPositionError, (state, action) => {
			state.createPosition.loading = false;
			state.createPosition.errors = action.payload;
		})
		// Edit job
		.addCase(editVacancyRequest, (state) => {
			state.editPosition.loading = true;
			state.editPosition.errors = [];
		})
		.addCase(editVacancySuccess, (state) => {
			state.editPosition.loading = false;
		})
		.addCase(editVacancyError, (state, action) => {
			state.editPosition.loading = false;
			state.editPosition.errors = action.payload;
		})
		// Get vacancies
		.addCase(getVacanciesRequest, (state) => {
			state.values.loading = true;
			state.values.errors = [];
		})
		.addCase(getVacanciesSuccess, (state, action) => {
			state.values.loading = false;
			state.values.items = action.payload.entities;
			state.values.totalCount = action.payload.totalCount;
			state.values.page = 1;
		})
		.addCase(getVacanciesError, (state, action) => {
			state.values.loading = false;
			state.values.errors = action.payload;
		})
		// Load more vacancies
		.addCase(getMoreVacanciesRequest, (state) => {
			state.values.loadMoreLoading = true;
		})
		.addCase(getMoreVacanciesSuccess, (state, action) => {
			state.values.loadMoreLoading = false;
			state.values.items = action.payload.entities;
			state.values.totalCount = action.payload.totalCount;
			state.values.page++;
		})
		.addCase(getMoreVacanciesError, (state) => {
			state.values.loadMoreLoading = false;
		})
		// Get vacancy by id
		.addCase(getVacancyByIdRequest, (state) => {
			state.targetVacancy.loading = true;
		})
		.addCase(getVacancyByIdSuccess, (state, action) => {
			state.targetVacancy.loading = false;
			state.targetVacancy.values = action.payload;
		})
		.addCase(getVacancyByIdError, (state) => {
			state.targetVacancy.loading = false;
		})
		.addCase(clearVacancyById, (state) => {
			state.targetVacancy = { ...initialState.targetVacancy };
		})
		// Delete vacancy
		.addCase(deleteVacancyRequest, (state) => {
			state.deletePosition.loading = true;
			state.deletePosition.errors = [];
		})
		.addCase(deleteVacancySuccess, (state) => {
			state.deletePosition.loading = false;
		})
		.addCase(deleteVacancyError, (state, action) => {
			state.deletePosition.loading = false;
			state.deletePosition.errors = action.payload;
		});
});

export default vacanciesReducer;
