/* eslint-disable indent */
import { useState, useEffect } from "react";
import {
	useBreakpointValue,
	Wrap,
	WrapItem,
	ModalBody,
	Box,
	Flex,
	Text,
} from "@chakra-ui/react";
import {
	InputSelect,
	InputText,
	Modal,
	InputMulti,
	InputColor,
	ClassesBox,
} from "../../../../common/components";
import {
	ImageDropZone,
	ImageSelected,
	WorkingHoursBox,
} from "../../components";
import { images, ranges } from "../../../../common/constants/dammy-date";
import { Resolver, useForm, Controller } from "react-hook-form";
import { editLevelSchema } from "../../../../common/constants/validation";
import {
	EditLevelPayload,
	WorkingHoursType,
	SubscriptionPlan,
} from "../../../../common/types";
import { ClassType } from "../../types";
import { Alerter } from "../../../../common/utils";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import dayjs from "dayjs";
import { Footer } from "./components";

type IProps = {
	handleClose: () => void;
	isOpen: boolean;
	size?: string;
	classesLoading: boolean;
	classes: ClassType[];
	toEditLevel: SubscriptionPlan;
	onSubmit: (value: EditLevelPayload) => void;
};

export type IEditLevelForm = {
	name: string;
	minGrade: string;
	maxGrade: string;
	workingHours: WorkingHoursType[];
	imageFile: string | Blob;
	startDate: number;
	endDate: number;
	colorValue: string;
	achievements: string[];
	bulletPoints: string[];
	newOrderIndex?: string;
	isFree: boolean;
};

export const EditLevelModal: React.FC<IProps> = ({
	handleClose,
	isOpen,
	size,
	classesLoading,
	classes,
	toEditLevel,
	onSubmit,
}) => {
	const [selectedImage, setSelectedImage] = useState(0);
	const [selectedClasses, setSelectedClasses] = useState<{ id: number }[]>(
		[],
	);

	const { token } = useSelector(
		(state: RootStore) => state.session.accessToken,
	);
	const { loading } = useSelector(
		(state: RootStore) => state.subscription.levels.editLevel,
	);
	const levels = useSelector(
		(state: RootStore) => state.subscription.levels.levels.items,
	);

	const {
		register,
		formState: { errors },
		setValue,
		handleSubmit,
		getValues,
		reset,
		control,
	} = useForm<IEditLevelForm>({
		resolver: editLevelSchema as Resolver<IEditLevelForm>,
	});
	const countSelectedClasses = selectedClasses.length;

	const [workingHours, achievements, bulletPoints] = getValues([
		"workingHours",
		"achievements",
		"bulletPoints",
	]);

	useEffect(() => {
		if (!isOpen) {
			reset({});
			setSelectedClasses([]);
		} else {
			console.log(toEditLevel.orderIndex, "toEditLevel.orderIndex");
			reset({
				...toEditLevel,
				minGrade: toEditLevel.minGrade + "",
				maxGrade: toEditLevel.maxGrade + "",
				startDate: dayjs.utc(toEditLevel.startDate).valueOf(),
				endDate: dayjs.utc(toEditLevel.endDate).valueOf(),
				colorValue: `#${toEditLevel.colorValue}`,
				newOrderIndex: toEditLevel.orderIndex + "",
			});
			setSelectedClasses(
				toEditLevel.classes.map((elem) => ({ id: elem.id })),
			);
		}
	}, [isOpen]);

	const isBaseSize = useBreakpointValue({
		base: true,
		md: false,
	});

	const onSelectedClass = (classItem: { id: number }) => {
		const findItem = selectedClasses.find(
			(item) => item.id === classItem.id,
		);
		if (findItem) {
			setSelectedClasses((prev) =>
				prev.filter((item) => item.id !== findItem.id),
			);
		} else {
			setSelectedClasses((prev) => [...prev, classItem]);
		}
	};

	const onConfirm = async (data: IEditLevelForm) => {
		const formData = new FormData();
		if (selectedImage) {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/v1/images/static-images/level${selectedImage}.png`,
				{
					method: "GET",
					headers: new Headers({
						Authorization: "Bearer " + token,
					}),
				},
			)
				.then((response) => response.blob())
				.then(function (myBlob) {
					formData.append(
						"imageFile",
						new File([myBlob], `level${selectedImage}.png`),
					);
				})
				.catch((err) => Alerter.error(err));
		} else {
			formData.append("imageFile", data.imageFile);
		}
		const payload: EditLevelPayload = {
			params: {
				Id: toEditLevel.id,
				Name: data.name,
				MinGrade: data.minGrade,
				MaxGrade: data.maxGrade,
				WorkingHours: data.workingHours,
				ClassesIds: selectedClasses.map((item) => item.id),
				ColorValue: data.colorValue,
				Achievements: data.achievements,
				BulletPoints: data.bulletPoints,
				NewOrderIndex: parseInt(data.newOrderIndex || "0"),
			},
			imageFile: formData,
		};
		onSubmit(payload);
	};

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Edit Level"
			size={size}>
			<ModalBody>
				<Flex
					justifyContent={"space-between"}
					flexDir={{ base: "column", md: "row" }}>
					{/*Left Side*/}
					<Box width={{ base: "100%", md: "48%" }}>
						<Text
							variant={isBaseSize ? "body3" : "subtitle1"}
							pb={"4px"}
							borderBottom={"1px solid #EBEBEB"}
							color="darkest">
							Default Images
						</Text>
						<Wrap
							spacing={{ base: "35px", md: "24px" }}
							mt={"24px"}
							justify={"center"}>
							{images.map((item) => (
								<WrapItem key={item.id}>
									<ImageSelected
										selected={selectedImage === item.id}
										onSelected={() =>
											setSelectedImage(item.id)
										}
										path={item.path}
										alt={item.alt}
									/>
								</WrapItem>
							))}

							<WrapItem>
								<ImageDropZone
									setValue={setValue}
									setSelectedImage={setSelectedImage}
									responseImage={toEditLevel.image}
								/>
							</WrapItem>
						</Wrap>

						<Text
							variant={isBaseSize ? "body3" : "subtitle1"}
							pb={"4px"}
							color="darkest"
							borderBottom={"1px solid #EBEBEB"}
							mt={"38px"}>
							Schedule
						</Text>
						{/*	Working Hours Box*/}
						<WorkingHoursBox
							setValue={setValue}
							defaultValue={workingHours}
						/>
					</Box>
					{/*RightSide*/}
					<Box width={{ base: "100%", md: "48%" }}>
						{/*Level name form Box*/}
						<Flex flexDir={{ base: "column", md: "row" }}>
							<Box flex={"1"}>
								<InputText
									label="Level Name"
									{...register("name")}
									errorMsg={errors.name?.message}
									placeholder="Level Name"
								/>
							</Box>
							<Box
								d={"flex"}
								ml={{ base: 0, md: "8px" }}
								justifyContent={{
									base: "space-between",
									md: "",
								}}>
								<Box w={{ base: "48%" }}>
									<InputSelect
										{...register("minGrade")}
										label={"Range"}
										options={ranges}
										errorMsg={errors.minGrade?.message}
									/>
								</Box>
								<Box
									ml={{ base: 0, md: "8px" }}
									w={{ base: "48%" }}>
									<InputSelect
										{...register("maxGrade")}
										label={""}
										mt={"21px"}
										options={ranges}
										errorMsg={errors.maxGrade?.message}
									/>
								</Box>
							</Box>
						</Flex>
						{/*Class Box*/}
						<ClassesBox
							countSelectedClasses={countSelectedClasses}
							classes={classes}
							classesLoading={classesLoading}
							onSelectedClass={(item) => onSelectedClass(item)}
							selectedClasses={selectedClasses}
						/>
						<InputMulti
							onItemAdded={(item: string, allItems: string[]) => {
								setValue("achievements", allItems);
							}}
							onItemDeleted={(
								item: string,
								allItems: string[],
							) => {
								setValue("achievements", allItems);
							}}
							label="Achievements"
							name="achievements"
							placeholder="Achievements (Press enter or comma to submit)"
							values={achievements || []}
						/>
						<InputMulti
							onItemAdded={(item: string, allItems: string[]) => {
								setValue("bulletPoints", allItems);
							}}
							onItemDeleted={(
								item: string,
								allItems: string[],
							) => {
								setValue("bulletPoints", allItems);
							}}
							label="Bullet Points"
							name="bulletPoints"
							placeholder="Bullet Points (Press enter or comma to submit)"
							values={bulletPoints || []}
						/>
						<InputSelect
							{...register("newOrderIndex")}
							label={"Order"}
							// options={Array.from(
							// 	new Array(levelsLength),
							// 	(val, index) => index + 1,
							// ).map((val) => ({
							// 	value: val.toString(),
							// 	label: val.toString(),
							// }))}
							options={Array.from(
								new Set(
									levels.map((elem: any) => elem.orderIndex),
								),
							).map((val: any) => ({
								value: val.toString(),
								label: val.toString(),
							}))}
							// errorMsg={errors.minGrade?.message}
						/>
						<Controller
							render={({ field }) => (
								<InputColor
									{...register("colorValue")}
									title="Header color"
									value={field.value}
									name={field.name}
									onChange={field.onChange}
									onBlur={field.onBlur}
								/>
							)}
							control={control}
							name="colorValue"
							defaultValue="#CCF4FA"
						/>
					</Box>
				</Flex>
				{/*Footer*/}
				<Footer
					onPublish={handleSubmit(onConfirm)}
					onCancel={handleClose}
					saveLoading={loading}
				/>
			</ModalBody>
		</Modal>
	);
};
