import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const discountResolver = yup.object().shape({
	value: yup
		.number()
		.required(ErrorMessages.REQUIRED)
		.positive("Value should be greater than zero")
		.max(100, "Value can`t be larger than 100")
		.min(0, "Value can`t be smaller than 0")
		.nullable(true)
		.typeError("Must be a number"),
});

export const discountSchema = yupResolver(discountResolver);
