export const ArrowDown: React.FC = () => (
	<svg
		width="10"
		height="7"
		viewBox="0 0 10 7"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.276707 0.814217C-0.0922358 1.18955 -0.0922355 1.79809 0.276707 2.17343L4.28488 6.25107C4.48096 6.45054 4.74178 6.54401 4.99853 6.53146C5.25621 6.54485 5.51825 6.45143 5.71506 6.25121L9.72324 2.17357C10.0922 1.79824 10.0922 1.1897 9.72324 0.814362C9.3543 0.439026 8.75612 0.439026 8.38718 0.814363L5.00004 4.2602L1.61277 0.814217C1.24382 0.438881 0.645649 0.438881 0.276707 0.814217Z"
			fill="currentColor"
		/>
	</svg>
);
