import { createAction } from "@reduxjs/toolkit";
import {
	AddLevelPayload,
	SubscriptionPlan,
	PayloadError,
	EditLevelPayload,
} from "../../../../common/types";
import { EditDateInfo } from "../../types";

interface IAddLevelRequest extends AddLevelPayload {
	searchQuery: string;
	withArchivedFilter: boolean;
}

export const addLevel = createAction<IAddLevelRequest>("ADD_LEVEL");
export const addLevelSuccess = createAction("ADD_LEVEL_SUCCESS");
export const addLevelError = createAction<PayloadError[]>("ADD_LEVEL_ERROR");

export const getLevelsForSubscriptionsRequest = createAction<{
	searchQuery: string;
	statusId: number;
}>("@subscriptions/GET_LEVELS_REQUEST");
export const getLevelsForSubscriptionsSuccess = createAction<
	SubscriptionPlan[]
>("@subscriptions/GET_LEVELS_SUCCESS");
export const getLevelsForSubscriptionsError = createAction<PayloadError[]>(
	"@subscriptions/GET_LEVELS_ERROR",
);

export const deleteLevelRequest = createAction<{
	id: number;
	searchQuery: string;
	withArchivedFilter: boolean;
}>("@subscriptions/DELETE_LEVEL_REQUEST");
export const deleteLevelSuccess = createAction(
	"@subscriptions/DELETE_LEVEL_SUCCESS",
);
export const deleteLevelError = createAction<PayloadError[]>(
	"@subscriptions/DELETE_LEVEL_ERROR",
);

export const archiveLevelRequest = createAction<{
	id: number;
	searchQuery: string;
	withArchivedFilter: boolean;
}>("@subscriptions/ARCHIVE_LEVEL_REQUEST");
export const archiveLevelSuccess = createAction(
	"@subscriptions/ARCHIVE_LEVEL_SUCCESS",
);
export const archiveLevelError = createAction<PayloadError[]>(
	"@subscriptions/ARCHIVE_LEVEL_ERROR",
);

export const editLevelDatesRequest = createAction<EditDateInfo>(
	"@levels/EDIT_DATES_REQUEST",
);
export const editLevelDatesSuccess = createAction("@levels/EDIT_DATES_SUCCESS");
export const editLevelDatesError = createAction<PayloadError[]>(
	"@levels/EDIT_DATES_ERROR",
);

export const editLevelRequest = createAction<EditLevelPayload>(
	"@levels/EDIT_LEVEL_REQUEST",
);
export const editLevelSuccess = createAction("@levels/EDIT_LEVEL_SUCCESS");
export const editLevelError = createAction<PayloadError[]>(
	"@levels/EDIT_LEVEL_ERROR",
);
