import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const createVacancySchemaResolver = yup.object().shape({
	title: yup.string().required(ErrorMessages.REQUIRED),
	location: yup.string().required(ErrorMessages.REQUIRED),
	description: yup.string().required(ErrorMessages.REQUIRED),
	payRate: yup.string().required(ErrorMessages.REQUIRED),
});

export const createVacancySchema = yupResolver(createVacancySchemaResolver);
