import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import {
	getPositionTeamsDirectoryRequest,
	getPositionTeamsDirectorySuccess,
} from "../../actions/directories";
import API from "../../../common/api/api.base";
import { IResponseWithCustomValue } from "../../../common/types";
import { Alerter } from "../../../common/utils";
import { PositionTeamType } from "../../../common/types";

function* workerDirectoryGetPositionTeams(
	action: ReturnType<typeof getPositionTeamsDirectoryRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			jobPositionTeams: PositionTeamType[];
		}> = yield call(
			API.get,
			`/api/v1/job-positions/job-position-teams?SearchString=${action.payload}`,
		);
		if (result.success) {
			yield put(
				getPositionTeamsDirectorySuccess(result.value.jobPositionTeams),
			);
		} else {
			// yield put(getClaassesForLevelsError(result.errors));
			throw result;
		}
	} catch (err) {
		Alerter.error(err);
	}
}

// prettier-ignore
export default function* watchPositionTeamsDirectorySaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getPositionTeamsDirectoryRequest.type, workerDirectoryGetPositionTeams);
}
