import { useEffect } from "react";
import { MainLayout, PrivateRoute } from "../common/components";
import { Redirect, Switch } from "react-router-dom";
import {
	DashboardContainer,
	AttendanceContainer,
	SubscriptionsContainer,
	TeacherAttendanceContainer,
	SettingsContainer,
	MessagesContainer,
	JobsContainer,
} from "../pages";
import { logoutSuccess } from "../redux/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { TabBar } from "../common/components";
import { RootStore } from "../redux";
import { getInitialAccessRoute } from "../common/utils";
import { getUserSettingsRequest } from "../pages/Settings/store/actions";

interface IDashboardRoutingProps {
	isSignedIn: boolean;
}

const ALLOWED_ROUTES = {
	admin: [
		{ component: DashboardContainer, path: "/dashboard/main" },
		{ component: AttendanceContainer, path: "/dashboard/attendance" },
		{ component: SubscriptionsContainer, path: "/dashboard/subscriptions" },
		{ component: MessagesContainer, path: "/dashboard/messages" },
		{ component: JobsContainer, path: "/dashboard/jobs" },
	],
	teacher: [
		{
			component: TeacherAttendanceContainer,
			path: "/dashboard/teacher-attendance",
		},
	],
	none: [],
};

export const DashboardRouting: React.FC<IDashboardRoutingProps> = ({
	isSignedIn,
}) => {
	const dispatch = useDispatch();

	const accessToken = useSelector(
		(state: RootStore) => state.session.accessToken,
	);
	const userRoles = useSelector((state: RootStore) => state.session.roles);
	const profileInfo = useSelector(
		(state: RootStore) => state.settings.profileInfo,
	);

	const onLogout = () => {
		dispatch(logoutSuccess());
	};

	useEffect(() => {
		if (!profileInfo.values.id && accessToken) {
			// fetching user data because of lack of info
			dispatch(getUserSettingsRequest());
		}
	}, [profileInfo.values]);

	return (
		<>
			<MainLayout onLogout={onLogout}>
				<Switch>
					{ALLOWED_ROUTES[
						(userRoles?.[0] ||
							"none") as keyof typeof ALLOWED_ROUTES
					]?.map((elem: { component: React.FC; path: string }) => (
						<PrivateRoute
							key={elem.path}
							componenTarget={elem.component}
							isSignedIn={isSignedIn}
							path={elem.path}
						/>
					))}
					<PrivateRoute
						componenTarget={SettingsContainer}
						isSignedIn={isSignedIn}
						path="/dashboard/settings"
					/>
					<Redirect
						from="*"
						exact={isSignedIn}
						to={getInitialAccessRoute(userRoles?.[0])}
					/>
				</Switch>
			</MainLayout>
			<TabBar />
		</>
	);
};
