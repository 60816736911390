import React from "react";
import { ForgotPassword } from "./ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequest } from "../../redux/actions/auth.actions";
import { RootStore } from "../../redux";

export const ForgotPasswordContainer: React.FC = () => {
	const dispatch = useDispatch();
	const { loading, errors } = useSelector(
		(state: RootStore) => state.auth.forgotPassword,
	);

	const onSubmit = (data: { email: string }) =>
		dispatch(forgotPasswordRequest(data));

	return (
		<ForgotPassword onSubmit={onSubmit} errors={errors} loading={loading} />
	);
};
