import { Center, Flex, Image, Text } from "@chakra-ui/react";
import PlaceholderImg from "../../../../assets/pic_users-placeholder.png";

export const ApplicationsPlaceholder: React.FC = () => (
	<Center h="100%">
		<Flex direction="column" align="center">
			<Center>
				<Image src={PlaceholderImg} />
			</Center>
			<Text variant="body2" color="darkGrey" textAlign="center" mt="20px">
				There are no applications currently.
			</Text>
		</Flex>
	</Center>
);
