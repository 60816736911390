import PlaceholderPic from "../../../../assets/pic_payment.png";
import { Center, Box, Image, Text, chakra } from "@chakra-ui/react";

export const LevelsPlaceholder: React.FC<{ createLevel: () => void }> = ({
	createLevel,
}) => (
	<Center h="calc(100vh - 300px)">
		<Box>
			<Image src={PlaceholderPic} />
			<Text mt="20px" textAlign="center" variant="body2" color="darkGrey">
				There are no Levels. <br /> Go to{" "}
				<chakra.span color="primary" as="button" onClick={createLevel}>
					Create levels
				</chakra.span>
			</Text>
		</Box>
	</Center>
);
