import { AllEffect, ForkEffect, all } from "redux-saga/effects";
import watchDashboardFilterClassesSaga from "./filter.saga";
import watchDashBoardParentsSaga from "./parent.saga";
import watchDashboardStudentsSaga from "./students.saga";
import watchDashboardEmployeeSaga from "./employee.saga";
import watchStudentSubscriptionSaga from "./student-subscription.saga";

// prettier-ignore
export default function* watchDashboardSaga(): Generator<
	AllEffect<Generator<ForkEffect<never>, void, unknown>>,
	void,
	unknown
	> {
	yield all([
		watchDashboardEmployeeSaga(),
		watchDashboardStudentsSaga(),
		watchDashBoardParentsSaga(),
		watchDashboardFilterClassesSaga(),
		watchStudentSubscriptionSaga()
	]);
}
