interface ICalendarProps {
	fill?: string;
}

export const CalendarIcon: React.FC<ICalendarProps> = ({
	fill = "#8C4BC9",
}) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.5 0.0742188C5.96024 0.0742188 6.33333 0.447315 6.33333 0.907552V1.74089H9.66667V0.907552C9.66667 0.447315 10.0398 0.0742188 10.5 0.0742188C10.9602 0.0742188 11.3333 0.447315 11.3333 0.907552V1.74089H13.8333C14.7538 1.74089 15.5 2.48708 15.5 3.40755V14.2409C15.5 15.1614 14.7538 15.9076 13.8333 15.9076H2.16667C1.24619 15.9076 0.5 15.1614 0.5 14.2409V3.40755C0.5 2.48708 1.24619 1.74089 2.16667 1.74089H4.66667V0.907552C4.66667 0.447315 5.03976 0.0742188 5.5 0.0742188ZM4.66667 3.40755H2.16667V5.90755H13.8333V3.40755H11.3333V4.24089C11.3333 4.70112 10.9602 5.07422 10.5 5.07422C10.0398 5.07422 9.66667 4.70112 9.66667 4.24089V3.40755H6.33333V4.24089C6.33333 4.70112 5.96024 5.07422 5.5 5.07422C5.03976 5.07422 4.66667 4.70112 4.66667 4.24089V3.40755ZM13.8333 7.57422H2.16667V14.2409H13.8333V7.57422Z"
				fill={fill}
			/>
		</svg>
	);
};
