import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
	useBreakpointValue,
	Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import { ReactComponent as SortIcon } from "../../../assets/ic_sort.svg";
import { ReactComponent as MessageIcon } from "../../../assets/ic_message.svg";
import { LevelsStrip } from "../../../common/components";
import { SubscriptionPlan } from "../../../common/types";
import {
	SelectedFilters,
	StaffItem as StaffItemType,
	ParentResponseType,
	StudentResponseType,
} from "../types";
import {
	ParentsTabContainer,
	StaffTabContainer,
	StudentsTabContainer,
} from "../tabs";

interface IParticipantsCardProps {
	setShowStudentInfoModal: (value: boolean) => void;
	setShowEditEmployeeModal: (value: boolean) => void;
	filterClasses: SubscriptionPlan[];
	setSelectedFilters: (value: SelectedFilters) => void;
	selectedFilters: SelectedFilters;
	setShowInviteEmployeeModal: (value: boolean) => void;
	staffItems: StaffItemType[];
	staffLoading: boolean;
	setToEditEmployee: (value: StaffItemType) => void;
	tabIndex: number;
	setTabIndex: (value: number) => void;
	sortOrderAsc: boolean;
	setSortOrderAsc: (value: boolean) => void;
	setSearchQuery: (value: string) => void;
	studentsLoading: boolean;
	studentItems: StudentResponseType[];
	setStudentInfo: (value: StudentResponseType) => void;
	parentsTotalCount: number;
	studentsTotalCount: number;
	staffTotalCount: number;
	getMoreEmployees: () => void;
	staffLoadMoreLoading: boolean;
	studentsLoadMoreLoading: boolean;
	getMoreStudents: () => void;
	getMoreParents: () => void;
}

export const ParticipantsCard: React.FC<IParticipantsCardProps> = ({
	setShowStudentInfoModal,
	setShowEditEmployeeModal,
	filterClasses,
	selectedFilters,
	setSelectedFilters,
	setShowInviteEmployeeModal,
	staffItems,
	staffLoading,
	setToEditEmployee,
	setSearchQuery,
	setSortOrderAsc,
	setTabIndex,
	sortOrderAsc,
	tabIndex,
	studentItems,
	studentsLoading,
	setStudentInfo,
	parentsTotalCount,
	studentsTotalCount,
	staffTotalCount,
	getMoreEmployees,
	getMoreStudents,
	staffLoadMoreLoading,
	studentsLoadMoreLoading,
	getMoreParents,
}) => {
	const belowDesktop = useBreakpointValue({
		base: true,
		lg: false,
	});

	return (
		<Box
			p={{ base: "10px 0", lg: "30px 40px 0 40px" }}
			bg={{ base: "transparent", lg: "white" }}
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			{tabIndex !== 2 ? (
				<InputGroup>
					<InputLeftElement h="50px" pointerEvents="none">
						<SearchIcon />
					</InputLeftElement>
					<Input
						placeholder="Find parents and students"
						borderRadius="8px"
						paddingInlineStart="12px"
						paddingInlineEnd="12px"
						h="50px"
						borderColor="lightGrey"
						color="darkest"
						fontSize="14px"
						lineHeight="22px"
						focusBorderColor="primary"
						bg="white"
						_placeholder={{
							color: "grey",
						}}
						onChange={(ev) => setSearchQuery(ev.target.value)}
					/>
				</InputGroup>
			) : (
				<Button
					leftIcon={<MessageIcon />}
					variant="plain"
					bg="white"
					w="100%"
					onClick={() => setShowInviteEmployeeModal(true)}>
					Invite Employee
				</Button>
			)}
			<LevelsStrip
				items={filterClasses}
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
			/>
			<Tabs variant="unstyled" index={tabIndex}>
				<TabList borderBottom="1px solid #EBEBEB">
					<Tab
						onClick={() => setTabIndex(0)}
						_selected={{ borderBottom: "2px solid #8C4BC9" }}
						borderBottom="2px solid transparent">
						<Text fontSize="16px" lineHeight="21px" color="primary">
							Parents{" "}
							{!!parentsTotalCount && `(${parentsTotalCount})`}
						</Text>
					</Tab>
					<Tab
						onClick={() => setTabIndex(1)}
						_selected={{ borderBottom: "2px solid #8C4BC9" }}
						borderBottom="2px solid transparent">
						<Text fontSize="16px" lineHeight="21px" color="primary">
							Students{" "}
							{!!studentsTotalCount && `(${studentsTotalCount})`}
						</Text>
					</Tab>
					{belowDesktop ? (
						<Tab
							onClick={() => setTabIndex(2)}
							_selected={{ borderBottom: "2px solid #8C4BC9" }}
							borderBottom="2px solid transparent">
							<Text
								fontSize="16px"
								lineHeight="21px"
								color="primary">
								Staff ({staffTotalCount})
							</Text>
						</Tab>
					) : (
						<Flex marginLeft="auto">
							<Button
								leftIcon={<SortIcon />}
								variant="unstyled"
								fontSize="14px"
								lineHeight="24px"
								color="primary"
								height="auto"
								onClick={() => setSortOrderAsc(!sortOrderAsc)}>
								{sortOrderAsc ? "A-Z" : "Z-A"}
							</Button>
						</Flex>
					)}
				</TabList>

				<TabPanels>
					<TabPanel p="0" h="100%">
						<ParentsTabContainer getMoreParents={getMoreParents} />
					</TabPanel>
					<TabPanel h="100%" p="0">
						<StudentsTabContainer
							getMoreStudents={getMoreStudents}
							setShowStudentInfoModal={setShowStudentInfoModal}
							setStudentInfo={setStudentInfo}
							studentItems={studentItems}
							studentsLoadMoreLoading={studentsLoadMoreLoading}
							studentsLoading={studentsLoading}
							studentsTotalCount={studentsTotalCount}
						/>
					</TabPanel>
					<TabPanel h="100%" p="0">
						<StaffTabContainer
							setShowEditEmployeeModal={setShowEditEmployeeModal}
							setToEditEmployee={setToEditEmployee}
							staffItems={staffItems}
							staffLoading={staffLoading}
							getMoreEmployees={getMoreEmployees}
							staffLoadMoreLoading={staffLoadMoreLoading}
							staffTotalCount={staffTotalCount}
							setShowInviteEmployeeModal={
								setShowInviteEmployeeModal
							}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
