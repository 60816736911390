import { createReducer } from "@reduxjs/toolkit";
import {
	getParentsForMessagesRequest,
	getParentsForMessagesSuccess,
	getParentsForMessagesError,
	getMoreParentsForMessagesRequest,
	getMoreParentsForMessagesSuccess,
	getMoreParentsForMessagesError,
	clearMessages,
} from "../actions";
import { ParentResponseType } from "../../../Dashboard/types";
import { PayloadError } from "./../../../../common/types";

type InitialState = {
	items: ParentResponseType[];
	loading: boolean;
	errors: PayloadError[];
	totalCount: number;
	loadMoreLoading: boolean;
	page: number;
};

const initialState: InitialState = {
	loading: false,
	items: [],
	errors: [],
	totalCount: 0,
	loadMoreLoading: false,
	page: 1,
};

const parentsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getParentsForMessagesRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getParentsForMessagesSuccess, (state, action) => {
			state.loading = false;
			state.totalCount = action.payload.totalCount;
			state.items = action.payload.users;
			state.page = 1;
		})
		.addCase(getParentsForMessagesError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		// Get more parents
		.addCase(getMoreParentsForMessagesRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreParentsForMessagesSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.items = [...state.items, ...action.payload.users];
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getMoreParentsForMessagesError, (state) => {
			state.loadMoreLoading = false;
		})
		.addCase(clearMessages, () => {
			return { ...initialState };
		});
});
export default parentsReducer;
