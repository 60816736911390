import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { InputText, RippleButton } from "../../../common/components";
import { LoginPayload, PayloadError } from "../../../common/types";
import { SubmitHandler, useForm, Resolver } from "react-hook-form";
import { loginSchema } from "../../../common/constants/validation";

interface ILoginFormProps {
	loading?: boolean;
	onSubmit: SubmitHandler<LoginPayload>;
	stateErrors: PayloadError[];
	goToRestorePassword: () => void;
}

export const LoginForm: React.FC<ILoginFormProps> = ({
	loading,
	onSubmit,
	stateErrors,
	goToRestorePassword,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		clearErrors,
		setError,
	} = useForm<LoginPayload>({
		resolver: loginSchema as Resolver<LoginPayload>,
	});

	useEffect(() => {
		if (stateErrors.length) {
			setError("email", {
				type: "manual",
				message: stateErrors[0].errorMessage,
			});
		} else {
			clearErrors(undefined);
		}
	}, [stateErrors, setError, clearErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputText
					{...register("email")}
					label="E-Mail"
					placeholder="E-Mail"
					errorMsg={errors.email?.message}
				/>
				<InputText
					{...register("password")}
					label="Password"
					placeholder="Password"
					type="password"
					errorMsg={errors.password?.message}
					showAdditionalButton={true}
					additionalOnClick={goToRestorePassword}
				/>
				<RippleButton w="100%" type="submit" isLoading={loading}>
					Login
				</RippleButton>
			</form>
		</Box>
	);
};
