import { useEffect } from "react";
import { Jobs } from "./Jobs";
import { useDispatch } from "react-redux";
import {
	getPositionCommitmentsDirectoryRequest,
	getPositionTeamsDirectoryRequest,
} from "../../redux/actions/directories";

export const JobsContainer: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPositionCommitmentsDirectoryRequest(""));
		dispatch(getPositionTeamsDirectoryRequest(""));
	}, []);

	return (
		<>
			<Jobs />
		</>
	);
};
