import PlaceholderPic from "../../../../assets/pic_payment.png";
import { Center, Box, Image, Text } from "@chakra-ui/react";

export const SubscriptionsPlaceholder: React.FC = () => (
	<Center h="100%">
		<Box>
			<Image src={PlaceholderPic} />
			<Text textAlign="center" color="darkGrey" mt="20px" variant="body2">
				There are no Subscriptions. <br /> Select level to see if it has
				any
			</Text>
		</Box>
	</Center>
);
