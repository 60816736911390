import { Flex, Box, Text, Button, chakra, Image } from "@chakra-ui/react";
import Logo from "../../../assets/Logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux";

const ALLOWED_ROUTES = {
	admin: [
		{
			routeName: "/dashboard/main",
			label: "Dashboard",
		},
		{
			routeName: "/dashboard/attendance",
			label: "Attendance",
		},
		{ routeName: "/dashboard/subscriptions", label: "Subscriptions" },
		{ routeName: "/dashboard/messages", label: "Messages" },
		{ routeName: "/dashboard/jobs", label: "Jobs" },
		{ routeName: "/dashboard/settings", label: "Settings" },
	],
	teacher: [
		{
			routeName: "/dashboard/teacher-attendance",
			label: "Attendance",
		},
		{ routeName: "/dashboard/settings", label: "Settings" },
	],
	none: [],
};

interface ISidebarProps {
	onLogout: () => void;
}

export const Sidebar: React.FC<ISidebarProps> = ({ onLogout }) => {
	const location = useLocation();
	const userRoles = useSelector((state: RootStore) => state.session.roles);

	return (
		<Flex
			direction="column"
			w="255px"
			h="100vh"
			justify="space-between"
			display={{ base: "none", lg: "flex" }}>
			<Box>
				<Box p="32px 24px">
					<Image src={Logo} w="200px" h="40px" />
				</Box>
				<Text variant="caption3" color="grey" ml="40px" mt="80px">
					MENU
				</Text>
				<Flex direction="column" pl="40px">
					{ALLOWED_ROUTES[
						(userRoles?.[0] ||
							"none") as keyof typeof ALLOWED_ROUTES
					]?.map((route) => (
						<Button
							key={route.routeName}
							as={NavLink}
							variant="link"
							my="12px"
							to={route.routeName}
							fontSize="22px"
							lineHeight="32px"
							justifyContent="flex-start"
							color={
								location.pathname === route.routeName
									? "primary"
									: ""
							}>
							<chakra.label cursor="pointer">
								{route.label}
							</chakra.label>
						</Button>
					))}
				</Flex>
			</Box>
			<Flex mx="20px" py="30px" borderTop="1px solid #EBEBEB">
				<Button
					leftIcon={<Image src="/ic_log-out.svg" />}
					variant="plain"
					onClick={onLogout}>
					Log Out
				</Button>
			</Flex>
		</Flex>
	);
};
