import { Center, Flex, Image, Text, chakra } from "@chakra-ui/react";
import PlaceholderImg from "../../../../assets/pic_staff-placeholder.png";

interface IStaffPlaceholderProps {
	setShowInviteEmployeeModal: (value: boolean) => void;
	height: string;
}

export const StaffPlaceholder: React.FC<IStaffPlaceholderProps> = ({
	setShowInviteEmployeeModal,
	height,
}) => (
	<Center h={height}>
		<Flex direction="column" align="center">
			<Center>
				<Image src={PlaceholderImg} />
			</Center>
			<Text
				variant="body2"
				color="darkGrey"
				w="250px"
				textAlign="center"
				mt="20px">
				There are no staff here. Click{" "}
				<chakra.span
					as="button"
					display="inline"
					color="primary"
					onClick={() => setShowInviteEmployeeModal(true)}>
					Invite
				</chakra.span>{" "}
				employees by email.
			</Text>
		</Flex>
	</Center>
);
