import { Flex, Text, HStack, Avatar } from "@chakra-ui/react";
import { StudentResponseType } from "../types";
import { getPictureURL } from "../../../common/utils";

export const ParentInfoModalStudentInfo: React.FC<StudentResponseType> = ({
	// age,
	// grade,
	// id,
	name,
	paidSubscriptionPlanNames,
	photo,
}) => {
	return (
		<Flex justify="space-between" py="12px">
			<HStack>
				<Avatar
					w="24px"
					h="24px"
					borderRadius="50%"
					name={name}
					src={getPictureURL(photo)}
					sx={{
						div: {
							fontSize: "12px",
						},
					}}
				/>
				<Text variant="body2" color="darkest">
					{name}
				</Text>
			</HStack>
			<HStack>
				<Text variant="body3" color="darkest" mr="30px">
					{paidSubscriptionPlanNames.join(", ")}
				</Text>
				{/* <Button
					variant="unstyled"
					border="1px solid #8C4BC9"
					p="2px 16px"
					height="auto"
					fontSize="12px"
					lineHeight="22px"
					color="#8C4BC9"
					_hover={{
						color: "white",
						bg: "#8C4BC9",
					}}>
					Stop
				</Button> */}
			</HStack>
			{/* <Button
				variant="unstyled"
				border="1px solid #8C4BC9"
				p="2px 19px"
				height="auto"
				fontSize="12px"
				lineHeight="22px"
				bg="#8C4BC9"
				color="white"
				_hover={{
					color: "#8C4BC9",
					bg: "white",
				}}>
				Run
			</Button> */}
		</Flex>
	);
};
