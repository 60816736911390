import React from "react";
import {
	Textarea,
	FormControl,
	FormLabel,
	FormErrorMessage,
	TextareaProps,
} from "@chakra-ui/react";

interface ITextareaProps extends TextareaProps {
	label: string;
	errorMsg?: string;
}

export const InputTextArea = React.forwardRef<null, ITextareaProps>(
	({ label, errorMsg, ...rest }, ref) => (
		<FormControl isInvalid={!!errorMsg} mb={!errorMsg ? "29px" : 0}>
			<FormLabel
				fontSize="caption2"
				lineHeight="22px"
				color="darkest"
				marginBottom={0}>
				{label}
			</FormLabel>
			<Textarea
				borderRadius="8px"
				paddingInlineStart="12px"
				paddingInlineEnd="12px"
				borderColor="lightGrey"
				color="darkest"
				fontSize="14px"
				lineHeight="22px"
				focusBorderColor="primary"
				_placeholder={{
					color: "grey",
				}}
				ref={ref}
				{...rest}
			/>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	),
);

InputTextArea.displayName = "InputTextArea";
