import { createReducer } from "@reduxjs/toolkit";
import {
	getStaffForMessagesRequest,
	getStaffForMessagesSuccess,
	getStaffForMessagesError,
	getMoreStaffForMessagesRequest,
	getMoreStaffForMessagesSuccess,
	getMoreStaffForMessagesError,
	clearMessages,
} from "../actions";
import { StaffItem } from "./../../../Dashboard/types";
import { PayloadError } from "./../../../../common/types";

type InitialState = {
	items: StaffItem[];
	loading: boolean;
	errors: PayloadError[];
	totalCount: number;
	loadMoreLoading: boolean;
	page: number;
};

const initialState: InitialState = {
	loading: false,
	items: [],
	errors: [],
	totalCount: 0,
	loadMoreLoading: false,
	page: 1,
};

const staffReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getStaffForMessagesRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getStaffForMessagesSuccess, (state, action) => {
			state.loading = false;
			state.totalCount = action.payload.totalCount;
			state.items = action.payload.users;
			state.page = 1;
		})
		.addCase(getStaffForMessagesError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		.addCase(getMoreStaffForMessagesRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreStaffForMessagesSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.items = [...state.items, ...action.payload.users];
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getMoreStaffForMessagesError, (state) => {
			state.loadMoreLoading = false;
		})
		.addCase(clearMessages, () => {
			return { ...initialState };
		});
});
export default staffReducer;
