import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import { DashboardRouting } from "./DashboardRouting";
import {
	LoginContainer,
	ForgotPasswordContainer,
	ResetPasswordContainer,
} from "../pages";
import { UnathorizedRoute } from "../common/components";
import { RootStore } from "../redux";
import { useSelector } from "react-redux";

export const Routing: React.FC = () => {
	const accessToken = useSelector(
		(state: RootStore) => state.session.accessToken,
	);

	return (
		<Switch>
			<UnathorizedRoute
				path="/login"
				component={LoginContainer}
				isSignedIn={!!accessToken}
			/>
			<UnathorizedRoute
				path={"/forgot-password"}
				isSignedIn={!!accessToken}
				component={ForgotPasswordContainer}
			/>
			<UnathorizedRoute
				path="/reset-password"
				isSignedIn={!!accessToken}
				component={ResetPasswordContainer}
			/>
			<Suspense fallback={<div>loading ...</div>}>
				<DashboardRouting isSignedIn={!!accessToken} />
			</Suspense>
			<Redirect from="*" exact={true} to={"/dashboard/main"} />
		</Switch>
	);
};
