import { useState, useEffect } from "react";
import { ClassesTab } from "./ClassesTab";
import { ClassType } from "../../types";
import {
	AddClassModal,
	EditClassModal,
	IAddClassForm,
	IEditClassForm,
} from "../../modal";
import { ClassAlert } from "../../components";
import { defaultClass } from "../../../../common/constants";
import { useDelta } from "react-delta";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import {
	deleteClassRequest,
	editClassRequest,
	addClassRequest,
} from "../../store/actions";

interface IClassesTabContainerProps {
	belowDesktop?: boolean;
	setShowAddClassModal: (value: boolean) => void;
	showAddClassModal: boolean;
	searchQuery: string;
}

export const ClassesTabContainer: React.FC<IClassesTabContainerProps> = ({
	setShowAddClassModal,
	belowDesktop,
	showAddClassModal,
	searchQuery,
}) => {
	const dispatch = useDispatch();
	const [createdClass, setCreatedClass] =
		useState<IAddClassForm>(defaultClass);
	const [showClasPublishAlert, setShowClassPublishAlert] = useState(false);
	const [showEditClassModal, setShowEditClassModal] = useState(false);

	const [toEditClass, setToEditClass] = useState<ClassType>({
		id: 0,
		name: "",
		complexity: 1,
		lessons: 0,
		hours: 0,
		description: "",
		image: { id: 0, itemHash: null, extension: null },
	});
	const [showClassRemoveAlert, setShowClassRemoveAlert] = useState(false);

	const { loading: addClassLoading, errors: errorsAddClassLoading } =
		useSelector((state: RootStore) => state.subscription.classes.addClass);
	const { loading: removeClassLoading, errors: removeClassErrors } =
		useSelector(
			(state: RootStore) => state.subscription.classes.deleteClass,
		);
	const { loading: editClassLoading, errors: editClassErrors } = useSelector(
		(state: RootStore) => state.subscription.classes.editClass,
	);
	const { loading, items } = useSelector(
		(state: RootStore) => state.subscription.classes.classes,
	);

	const addingClassLoadingDelta = useDelta(addClassLoading);
	const removeClassLoadingDelta = useDelta(removeClassLoading);
	const editClassLoadingDelta = useDelta(editClassLoading);

	useEffect(() => {
		if (addingClassLoadingDelta && addingClassLoadingDelta.prev) {
			if (
				addingClassLoadingDelta.prev &&
				!addingClassLoadingDelta.curr &&
				!errorsAddClassLoading.length
			) {
				setShowClassPublishAlert(false);
			}
		}
	}, [addingClassLoadingDelta]);

	useEffect(() => {
		if (removeClassLoadingDelta && removeClassLoadingDelta.prev) {
			if (
				removeClassLoadingDelta.prev &&
				!removeClassLoadingDelta.curr &&
				!removeClassErrors.length
			) {
				setShowClassRemoveAlert(false);
				setShowEditClassModal(false);
				setToEditClass({
					id: 0,
					name: "",
					complexity: 1,
					lessons: 0,
					hours: 0,
					description: "",
					image: { id: 0, itemHash: null, extension: null },
				});
			}
		}
	}, [removeClassLoadingDelta]);

	useEffect(() => {
		if (editClassLoadingDelta && editClassLoadingDelta.prev) {
			if (
				editClassLoadingDelta.prev &&
				!editClassLoadingDelta.curr &&
				!editClassErrors.length
			) {
				setShowEditClassModal(false);
				setToEditClass({
					id: 0,
					name: "",
					complexity: 1,
					lessons: 0,
					hours: 0,
					description: "",
					image: { id: 0, itemHash: null, extension: null },
				});
			}
		}
	}, [editClassLoadingDelta]);

	useEffect(() => {
		if (toEditClass.id) {
			setShowEditClassModal(true);
		}
	}, [toEditClass]);

	const onEditClass = (
		data: IEditClassForm,
		uploadedImage: string | Blob | null,
	) => {
		const formData = uploadedImage ? new FormData() : undefined;
		if (uploadedImage) {
			formData?.append("imageFile", uploadedImage);
		}
		dispatch(
			editClassRequest({
				params: {
					id: toEditClass.id,
					complexity: data.complexity + "",
					description: data.description,
					hours: data.hours,
					lessons: data.lessons,
					name: data.name,
					searchQuery,
				},
				imageFile: formData,
			}),
		);
	};

	const onRemoveClass = (id: number) =>
		dispatch(deleteClassRequest({ id, searchQuery }));
	const onPublishClassFromModal = () => {
		dispatch(addClassRequest({ ...createdClass, searchQuery }));
	};

	return (
		<>
			<ClassesTab
				classesItems={items}
				classesLoading={loading}
				setShowAddClassModal={setShowAddClassModal}
				setToEditClass={setToEditClass}
				belowDesktop={belowDesktop}
			/>
			<AddClassModal
				isOpen={showAddClassModal}
				handleClose={() => setShowAddClassModal(false)}
				addClass={(data) => {
					setShowAddClassModal(false);
					setCreatedClass(data);
					setShowClassPublishAlert(true);
				}}
			/>
			<EditClassModal
				isOpen={showEditClassModal}
				handleClose={() => {
					setShowEditClassModal(false);
					setToEditClass({
						id: 0,
						name: "",
						complexity: 1,
						lessons: 0,
						hours: 0,
						description: "",
						image: { id: 0, itemHash: null, extension: null },
					});
				}}
				toEditClass={toEditClass}
				onRemove={(data: IAddClassForm) => {
					setCreatedClass(data);
					setShowClassRemoveAlert(true);
				}}
				onEditClass={(data, image) => onEditClass(data, image)}
				confirmLoading={editClassLoading}
			/>

			<ClassAlert
				createdClass={createdClass}
				isOpen={showClasPublishAlert}
				handleClose={() => setShowClassPublishAlert(false)}
				confirmLabel="Publish"
				firstDescription="Once published, you will be able to choose the class for your levels."
				secondDescription="You can always edit the class information by clicking on the Edit button."
				title="Publish"
				confirmLoading={addClassLoading}
				onConfirm={onPublishClassFromModal}
			/>
			<ClassAlert
				createdClass={createdClass}
				isOpen={showClassRemoveAlert}
				handleClose={() => setShowClassRemoveAlert(false)}
				confirmLabel="Remove"
				firstDescription="After removing the class, parents will not be able to see the class or subscribe."
				secondDescription="You will not be able to recover data."
				title="Remove"
				confirmLoading={removeClassLoading}
				onConfirm={() => onRemoveClass(toEditClass.id)}
			/>
		</>
	);
};
