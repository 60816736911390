import { createAction } from "@reduxjs/toolkit";
import { SubscriptionPlan, PayloadError } from "../../../common/types";
import { TeacherAttendanceType, UpdateAttendancePayload } from "../types";

export const getTeacherAttendanceStudentsRequest = createAction<{
	utcDate: string;
	subscriptionPlanId?: number;
}>("@teacher-attendance/GET_STUDENTS_REQUEST");
export const getTeacherAttendanceStudentsSuccess = createAction<{
	totalCount: number;
	attendance: TeacherAttendanceType[];
}>("@teacher-attendance/GET_STUDENTS_SUCCESS");
export const getTeacherAttendanceStudentsError = createAction<PayloadError[]>(
	"@teacher-attendance/GET_STUDENTS_ERROR",
);

export const getMoreTeacherAttendanceStudentsRequest = createAction<{
	utcDate: string;
	subscriptionPlanId: number;
}>("@teacher-attendance/GET_MORE_STUDENTS_REQUEST");
export const getMoreTeacherAttendanceStudentsSuccess = createAction<{
	totalCount: number;
	attendance: TeacherAttendanceType[];
}>("@teacher-attendance/GET_MORE_STUDENTS_SUCCESS");
export const getMoreTeacherAttendanceStudentsError = createAction<
	PayloadError[]
>("@teacher-attendance/GET_MORE_STUDENTS_ERROR");

export const getTeacherAttendanceClassesRequest = createAction(
	"@teacher-attendance/GET_CLASSES_REQUEST",
);
export const getTeacherAttendanceClassesSuccess = createAction<
	SubscriptionPlan[]
>("@teacher-attendance/GET_CLASSES_SUCCESS");
export const getTeacherAttendanceClassesError = createAction(
	"@teacher-attendance/GET_CLASSES_ERROR",
);

export const setStudentAttendanceValueRequest =
	createAction<UpdateAttendancePayload>(
		"@teacher-attendance/SET_STUDENT_ATTENDANCE_VALUE_REQUEST",
	);
export const setStudentAttendanceValueSuccess =
	createAction<UpdateAttendancePayload>(
		"@teacher-attendance/SET_STUDENT_ATTENDANCE_VALUE_SUCCESS",
	);
// export const setStudentAttendanceValueError = createAction(
// 	"@teacher-attendance/SET_STUDENT_ATTENDANCE_VALUE_ERROR",
// );
