export const ArrowUp: React.FC = () => (
	<svg
		width="10"
		height="7"
		viewBox="0 0 10 7"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.72329 6.25121C10.0922 5.87588 10.0922 5.26734 9.72329 4.892L5.71512 0.814361C5.51904 0.614884 5.25821 0.521421 5.00146 0.533973C4.74378 0.520583 4.48175 0.613997 4.28494 0.814217L0.27676 4.89185C-0.092182 5.26719 -0.0921821 5.87573 0.27676 6.25107C0.645702 6.6264 1.24388 6.6264 1.61282 6.25107L4.99996 2.80523L8.38723 6.25121C8.75618 6.62655 9.35435 6.62655 9.72329 6.25121Z"
			fill="currentColor"
		/>
	</svg>
);
