import * as yup from "yup";
import { ErrorMessages } from "./messages";
import { yupResolver } from "@hookform/resolvers/yup";

const editLevelDatesSchemaResolver = yup.object().shape({
	startDate: yup
		.number()
		.required(ErrorMessages.REQUIRED)
		.test(
			"start-date",
			"Start date can`t be later then End date",
			function (value) {
				if (value && value > this.parent.endDate) {
					return false;
				} else {
					return true;
				}
			},
		),
	endDate: yup.number().required(ErrorMessages.REQUIRED),
});

export const editLevelDatesSchema = yupResolver(editLevelDatesSchemaResolver);
