import { useState, useEffect } from "react";
import { Modal, InputText } from "../../../../common/components";
import {
	ModalBody,
	Box,
	FormLabel,
	Avatar,
	Center,
	Checkbox,
	Text,
} from "@chakra-ui/react";
import { ParentInfoModalStudentInfo } from "../../components";
import { ParentResponseType } from "../../types";
import { getPictureURL } from "../../../../common/utils";
import { ICChek } from "../../../../assets/svg-components";
import { useDispatch } from "react-redux";
import { setPermanentDiscountRequest } from "../../store/actions";

interface IParentInfoModal {
	handleClose: () => void;
	isOpen: boolean;
	parentInfo: ParentResponseType;
}

export const ParentInfoModal: React.FC<IParentInfoModal> = ({
	handleClose,
	isOpen,
	parentInfo,
}) => {
	const dispatch = useDispatch();
	const [checkboxChecked, setCheckboxChecked] = useState(false);

	useEffect(() => {
		setCheckboxChecked(parentInfo.isDiscountApplicable);
	}, [parentInfo]);

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Parent Info">
			<ModalBody>
				<Box>
					<Center>
						<Avatar
							w="120px"
							h="120px"
							name={`${parentInfo.firstName} ${parentInfo.lastName}`}
							src={getPictureURL(parentInfo.image)}
							borderRadius="16px"
							margin="32px auto"
						/>
					</Center>
					<InputText
						label="Parent Name"
						placeholder="Parent Name"
						value={`${parentInfo.firstName} ${parentInfo.lastName}`}
						// isDis={true}
					/>
					<InputText
						label="Parent Email"
						placeholder="Parent Email"
						value={parentInfo.email}
						// isDis={true}
					/>
					<InputText
						label="Parent Phone Number"
						placeholder="Parent Phone Number"
						value={parentInfo.phoneNumber}
						// isDis={true}
					/>
					<Checkbox
						isChecked={checkboxChecked}
						sx={{
							".chakra-checkbox__control": {
								borderRadius: "50%",
								border: "1px solid #8C4BC9",
							},
						}}
						icon={<ICChek enabled={checkboxChecked} />}
						mb="29px"
						onChange={(ev) => {
							setCheckboxChecked(ev.target.checked);
							dispatch(
								setPermanentDiscountRequest({
									userId: parentInfo.userId,
									isDiscountApplicable: ev.target.checked,
								}),
							);
						}}>
						<Text variant="body2" color="darkest">
							Permanent discount
						</Text>
					</Checkbox>
					{!!parentInfo.students.length && (
						<FormLabel
							fontSize="caption2"
							lineHeight="22px"
							color="darkest"
							marginBottom={0}>
							Student
						</FormLabel>
					)}
					{parentInfo.students.map((student) => (
						<ParentInfoModalStudentInfo
							key={student.id}
							{...student}
						/>
					))}
				</Box>
			</ModalBody>
		</Modal>
	);
};
