import {
	getApplicationsRequest,
	getApplicationsSuccess,
	getApplicationsError,
	getMoreApplicationsRequest,
	getMoreApplicationsSuccess,
	getMoreApplicationsError,
	getApplicationByIdRequest,
	getApplicationByIdSuccess,
	getApplicationByIdError,
	deleteApplicationRequest,
	deleteApplicationSuccess,
	deleteApplicationError,
} from "../actions";
import { call, ForkEffect, put, takeEvery, select } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { Alerter } from "../../../../common/utils";
import { IResponseWithCustomValue } from "../../../../common/types";
import { SlimVacancyApplication, FullVacancyApplication } from "../../types";
import { quantity } from "../../../../common/constants";
import { RootStore } from "../../../../redux";

function* workerGetApplications() {
	try {
		const result: IResponseWithCustomValue<{
			entities: SlimVacancyApplication[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/job-applications?PageSize=${quantity.APPLICATIONS_TAKE}&PageNumber=1`,
		);
		if (result.success) {
			yield put(getApplicationsSuccess(result.value));
		} else {
			yield put(getApplicationsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreApplications() {
	try {
		const page: number = yield select(
			(state: RootStore) => state.jobs.applications.applications.page,
		);
		const result: IResponseWithCustomValue<{
			entities: SlimVacancyApplication[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/job-applications?PageSize=${
				quantity.VACANCIES_TAKE
			}&PageNumber=${page + 1}`,
		);
		if (result.success) {
			yield put(getMoreApplicationsSuccess(result.value));
		} else {
			yield put(getMoreApplicationsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetApplicationById(
	action: ReturnType<typeof getApplicationByIdRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			jobApplicationInfoModel: FullVacancyApplication;
		}> = yield call(API.get, `/api/v1/job-applications/${action.payload}`);
		if (result.success) {
			yield put(
				getApplicationByIdSuccess(result.value.jobApplicationInfoModel),
			);
		} else {
			yield put(getApplicationByIdError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerDeleteApplication(
	action: ReturnType<typeof deleteApplicationRequest>,
) {
	try {
		const result: IResponseWithCustomValue<number> = yield call(
			API.delete,
			`/api/v1/job-applications/${action.payload}/job-application`,
		);
		if (result.success) {
			yield put(deleteApplicationSuccess());
			Alerter.success("Successfully deleted");
			yield workerGetApplications();
		} else {
			yield put(deleteApplicationError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchApplicationsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getApplicationsRequest.type, workerGetApplications);
	yield takeEvery(getMoreApplicationsRequest.type, workerGetMoreApplications);
	yield takeEvery(getApplicationByIdRequest.type, workerGetApplicationById);
	yield takeEvery(deleteApplicationRequest.type, workerDeleteApplication);
}
