import React from "react";
import { Text, Flex } from "@chakra-ui/react";

interface IInputColorProps {
	title: string;
	value: string;
	name: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onBlur: React.FocusEventHandler<HTMLInputElement>;
}

export const InputColor = React.forwardRef<null, IInputColorProps>(
	({ title, name, onBlur, onChange, value }, ref) => (
		<Flex align="center">
			<input
				type="color"
				name={name}
				onChange={(ev) => {
					onChange(ev);
				}}
				value={value}
				onBlur={onBlur}
			/>
			<Text color="darkest" ml="20px" variant="body1">
				{title}
			</Text>
		</Flex>
	),
);

InputColor.displayName = "InputColor";
