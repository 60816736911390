import { PayloadError } from "./../../common/types";
import { createReducer } from "@reduxjs/toolkit";
import {
	getPendingInvoicesRequest,
	getPendingInvoicesSuccess,
	getPendingInvoicesError,
	getMorePendingInvoicesRequest,
	getMorePendingInvoicesSuccess,
	getMorePendingInvoicesError,
	createManualSubscriptionRequest,
	createManualSubscriptionSuccess,
	createManualSubscriptionError,
	confirmManualInvoiceRequest,
	confirmManualInvoiceSuccess,
	confirmManualInvoiceError,
	clearManualInvoicesState,
} from "../actions/manual-invoices.actions";
import { PendingManualSubscription } from "../../common/types";
import { isAnyOf } from "../../common/utils";

type InitialState = {
	invoices: {
		loading: boolean;
		errors: PayloadError[];
		totalCount: number;
		loadMoreLoading: boolean;
		page: number;
		items: PendingManualSubscription[];
	};
	createSubscription: {
		loading: boolean;
		errors: PayloadError[];
	};
	confirmInvoice: {
		loading: boolean;
		itemId: number;
	};
};

const initialState: InitialState = {
	invoices: {
		loading: false,
		errors: [],
		items: [],
		page: 1,
		totalCount: 0,
		loadMoreLoading: false,
	},
	createSubscription: {
		errors: [],
		loading: false,
	},
	confirmInvoice: {
		itemId: 0,
		loading: false,
	},
};

const manualInvoicesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getPendingInvoicesRequest, (state) => {
			state.invoices.loading = true;
			state.invoices.errors = [];
		})
		.addCase(getPendingInvoicesSuccess, (state, action) => {
			state.invoices.loading = false;
			state.invoices.totalCount = action.payload.totalCount;
			state.invoices.items = action.payload.subscriptions;
			state.invoices.page = 1;
		})
		.addCase(getPendingInvoicesError, (state, action) => {
			state.invoices.loading = false;
			state.invoices.errors = action.payload;
		})
		.addCase(getMorePendingInvoicesRequest, (state) => {
			state.invoices.loadMoreLoading = true;
		})
		.addCase(getMorePendingInvoicesSuccess, (state, action) => {
			state.invoices.loadMoreLoading = false;
			state.invoices.items = [
				...state.invoices.items,
				...action.payload.subscriptions,
			];
			state.invoices.totalCount = action.payload.totalCount;
			state.invoices.page++;
		})
		.addCase(getMorePendingInvoicesError, (state) => {
			state.invoices.loadMoreLoading = false;
		})
		.addCase(createManualSubscriptionRequest, (state) => {
			state.createSubscription.loading = true;
			state.createSubscription.errors = [];
		})
		.addCase(createManualSubscriptionSuccess, (state) => {
			state.createSubscription.loading = false;
		})
		.addCase(createManualSubscriptionError, (state, action) => {
			state.createSubscription.loading = false;
			state.createSubscription.errors = action.payload;
		})
		.addCase(confirmManualInvoiceRequest, (state, action) => {
			state.confirmInvoice.itemId = action.payload;
			state.confirmInvoice.loading = true;
		})
		.addCase(clearManualInvoicesState, (state) => {
			state = { ...initialState };
		})
		.addMatcher(
			isAnyOf(confirmManualInvoiceSuccess, confirmManualInvoiceError),
			(state) => {
				state.confirmInvoice.itemId = 0;
				state.confirmInvoice.loading = false;
			},
		);
});

export default manualInvoicesReducer;
