import {
	Modal as ChakraModal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	Heading,
	IconButton,
	Flex,
	Box,
	useBreakpointValue,
} from "@chakra-ui/react";
import { ReactComponent as CloseIcon } from "../../../assets/ic_close.svg";

interface IModalProps {
	isOpen: boolean;
	handleClose: () => void;
	title: string;
	size?: string;
	scrollBehavior?: "inside" | "outside";
}

export const Modal: React.FC<IModalProps> = ({
	handleClose,
	isOpen,
	children,
	title,
	size = "xl",
	scrollBehavior,
}) => {
	const variantHeading = useBreakpointValue({
		base: true,
		md: false,
	});
	return (
		<ChakraModal
			onClose={handleClose}
			isOpen={isOpen}
			size={size}
			scrollBehavior={scrollBehavior}>
			<ModalOverlay />
			<ModalContent borderRadius="16px">
				<Flex
					justify="space-between"
					align="center"
					py={{ base: "16px", md: "24px" }}
					borderBottom="1px solid #EBEBEB">
					<ModalHeader flex="1">
						<Heading
							as="h5"
							variant={variantHeading ? "h8" : "h5"}
							color="darkest">
							{title}
						</Heading>
					</ModalHeader>
					<IconButton
						aria-label="close modal"
						icon={<CloseIcon />}
						variant="ghost"
						mr="1.5rem"
						onClick={handleClose}
					/>
				</Flex>
				<Box py="15px">{children}</Box>
			</ModalContent>
		</ChakraModal>
	);
};
