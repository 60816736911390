//GET COURSES ACTIONS
import { createAction } from "@reduxjs/toolkit";
import { IGetClassesResponse, PayloadError } from "../../common/types";

export const getClassesForLevelsRequest = createAction("@courses/GET_CLASSES");
export const getClassesForLevelsSuccess = createAction<IGetClassesResponse>(
	"@courses/GET_CLASSES_SUCCESS",
);
export const getClaassesForLevelsError = createAction<PayloadError[]>(
	"@courses/GET_CLASSES_ERROR",
);
