import { StudentsTab } from "./StudentsTab";
import { StudentResponseType } from "../../types";

interface IStudentsTabContainerProps {
	studentsLoading: boolean;
	studentItems: StudentResponseType[];
	getMoreStudents: () => void;
	studentsLoadMoreLoading: boolean;
	studentsTotalCount: number;
	setShowStudentInfoModal: (value: boolean) => void;
	setStudentInfo: (value: StudentResponseType) => void;
}

export const StudentsTabContainer: React.FC<IStudentsTabContainerProps> = ({
	getMoreStudents,
	setShowStudentInfoModal,
	setStudentInfo,
	studentItems,
	studentsLoadMoreLoading,
	studentsLoading,
	studentsTotalCount,
}) => {
	return (
		<>
			<StudentsTab
				getMoreStudents={getMoreStudents}
				setShowStudentInfoModal={setShowStudentInfoModal}
				setStudentInfo={setStudentInfo}
				studentItems={studentItems}
				studentsLoadMoreLoading={studentsLoadMoreLoading}
				studentsLoading={studentsLoading}
				studentsTotalCount={studentsTotalCount}
			/>
		</>
	);
};
