import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessages } from "./messages";

const editLevelSchemaResolver = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	minGrade: yup
		.number()
		.integer("Should be integer")
		.required(ErrorMessages.REQUIRED)
		.test(
			"empty-plus",
			"Can`t be larger than second value",
			function (value) {
				if (value && value > this.parent.maxGrade) {
					return false;
				} else {
					return true;
				}
			},
		),
	maxGrade: yup.number().integer("Should be integer"),
});

export const editLevelSchema = yupResolver(editLevelSchemaResolver);
