import { call, put, takeEvery, ForkEffect } from "redux-saga/effects";
import {
	loginRequest,
	loginSuccess,
	loginError,
} from "../../actions/auth.actions";
import { push } from "connected-react-router";
import {
	ILoginResponse,
	IResponseWithCustomValue,
} from "../../../common/types";
import API from "../../../common/api/api.base";
import { Alerter, getInitialAccessRoute } from "../../../common/utils";

function* workerLogin(action: ReturnType<typeof loginRequest>) {
	try {
		const result: IResponseWithCustomValue<ILoginResponse> = yield call(
			API.post,
			"/api/v1/auth/login",
			{ ...action.payload, isClientDashboard: false },
		);
		if (result.success) {
			if (
				result.value.roles.includes("admin") ||
				result.value.roles.includes("teacher")
			) {
				yield put(loginSuccess(result.value));
				yield put(push(getInitialAccessRoute(result.value.roles[0])));
			} else {
				Alerter.error("You are not allowed to login here");
				yield put(loginError([]));
			}
		} else {
			yield put(loginError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}
// prettier-ignore
export default function* watchLoginSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(loginRequest.type, workerLogin);
}
