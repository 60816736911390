import dayjs from "dayjs";

export const getFormattedRange = (
	startDate: string,
	endDate: string,
): string => {
	if (dayjs.utc(startDate).month() === dayjs.utc(endDate).month()) {
		return `${dayjs.utc(startDate).format("D")} - ${dayjs
			.utc(endDate)
			.format("D MMMM, YYYY")}`;
	} else {
		return `${dayjs.utc(startDate).format("D MMMM, YYYY")} - ${dayjs
			.utc(endDate)
			.format("D MMMM, YYYY")}`;
	}
};
