/* eslint-disable no-constant-condition */
import { Box, Flex, Heading, useBreakpointValue } from "@chakra-ui/react";
import {
	CalendarHeader,
	InputSelect,
	InfiniteScroll,
	Loader,
} from "../../../common/components";
import { Student } from "../components";
import { yearsList } from "../../../common/utils";
import { TeacherAttendanceType, UpdateAttendanceClickPayload } from "../types";
import { WorkingHoursType, SubscriptionPlan } from "../../../common/types";
import { StudentsPlaceholder } from "../components/placeholders";

type IStudentsProps = {
	setShowStudentInfo: (value: boolean) => void;
	selectedDay: string;
	setSelectedDay: (value: string) => void;
	selectedMonth: number;
	setSelectedMonth: (value: number) => void;
	selectedYear: number;
	setSelectedYear: (value: number) => void;
	items: TeacherAttendanceType[];
	loading: boolean;
	loadMoreLoading: boolean;
	totalCount: number;
	updateAttendance: (value: UpdateAttendanceClickPayload) => void;
	selectedClassWorkingHours?: WorkingHoursType[];
	setStudentInfo: (value: TeacherAttendanceType) => void;
	loadMoreStudents: () => void;
	classesItems: SubscriptionPlan[];
	setSelectedClass: (value: number) => void;
	selectedClass: number;
};

export const Students: React.FC<IStudentsProps> = ({
	setShowStudentInfo,
	selectedDay,
	selectedMonth,
	setSelectedDay,
	setSelectedMonth,
	selectedYear,
	setSelectedYear,
	items,
	loadMoreLoading,
	loading,
	totalCount,
	updateAttendance,
	selectedClassWorkingHours,
	setStudentInfo,
	loadMoreStudents,
	classesItems,
	setSelectedClass,
	selectedClass,
}) => {
	const belowDesktop = useBreakpointValue({
		base: true,
		lg: false,
	});

	return (
		<Box
			p={{ base: "0px", lg: "30px 40px 0 30px" }}
			bg={{ base: "transparent", lg: "white" }}
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			{/*	Header Card*/}
			<Flex
				justifyContent={"space-between"}
				pt={"10px"}
				borderBottom={{ base: "none", lg: "1px solid #EBEBEB" }}
				mt={{ base: "-65px", lg: 0 }}
				mb={{ base: "10px", lg: 0 }}
				pl={{ base: "20px", lg: 0 }}
				pb={{ base: 0, lg: "10px" }}>
				<Heading
					variant={"h8"}
					color="darkest"
					display={{ base: "none", lg: "initial" }}>
					Students
				</Heading>
				<Box w={{ base: "70%", lg: "auto" }}>
					{belowDesktop && (
						<InputSelect
							label={""}
							placeholder="Classes"
							options={classesItems.map((item) => ({
								value: item.id + "",
								label: item.name,
							}))}
							onChange={(ev) => {
								setSelectedClass(+ev.target.value);
							}}
							value={selectedClass + ""}
							removeBottomMargin={true}
							optionalColor={belowDesktop ? "white" : ""}
							mb="15px"
						/>
					)}
					<InputSelect
						label={""}
						options={yearsList()}
						onChange={(ev) =>
							setSelectedYear(parseInt(ev.target.value))
						}
						removeBottomMargin={true}
						optionalColor={belowDesktop ? "white" : ""}
					/>
				</Box>
			</Flex>
			<CalendarHeader
				selectedYear={selectedYear}
				selectedDay={selectedDay}
				setSelectedDay={setSelectedDay}
				selectedMonth={selectedMonth}
				setSelectedMonth={setSelectedMonth}
				selectedClassWorkingHours={selectedClassWorkingHours}
			/>
			{loading ? (
				<Loader
					centerHeight={belowDesktop ? "100%" : "calc(100% - 300px)"}
				/>
			) : (
				<>
					{items.length ? (
						<>
							<Box
								height={"calc(100vh - 415px)"}
								px={{ base: "20px", lg: 0 }}>
								<InfiniteScroll
									onReachBottom={() => {
										if (
											!loadMoreLoading &&
											totalCount > items.length
										) {
											loadMoreStudents();
										}
									}}>
									{items.map((student) => (
										<Student
											key={student.id}
											name={student.name}
											photo={student.photo}
											setShowStudentInfo={() => {
												setShowStudentInfo(true);
												setStudentInfo(student);
											}}
											onChangeStatus={(status: number) =>
												updateAttendance({
													status,
													studentId: student.id,
												})
											}
											presentStatus={student.presentCount}
											absentStatus={student.absentCount}
											dayOffStatus={student.dayOffCount}
											selectedDay={selectedDay}
										/>
									))}
									{loadMoreLoading && (
										<Loader
											spinnerSize="md"
											centerHeight="50px"
										/>
									)}
								</InfiniteScroll>
							</Box>
						</>
					) : (
						<StudentsPlaceholder />
					)}
				</>
			)}
		</Box>
	);
};
