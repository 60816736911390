export const ICChek: React.FC<{ enabled: boolean }> = ({ enabled }) => {
	return (
		<svg
			width="9"
			height="6"
			viewBox="0 0 9 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			{enabled && (
				<path
					d="M8.3224 0.544473C8.13937 0.361473 7.84255 0.361473 7.65952 0.544504L3.52018 4.68385L1.34122 2.50488C1.15819 2.32185 0.861375 2.32185 0.678312 2.50488C0.49525 2.68791 0.49525 2.98472 0.678312 3.16778L3.18872 5.67819C3.28025 5.76972 3.40022 5.81547 3.52015 5.81547C3.64009 5.81547 3.76009 5.76969 3.85159 5.67819L8.3224 1.20738C8.50546 1.02435 8.50546 0.727535 8.3224 0.544473Z"
					fill="#8C4BC9"
				/>
			)}
		</svg>
	);
};
