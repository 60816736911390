import { useState, useEffect } from "react";
import { Dashboard } from "./Dashboard";
import {
	InviteEmployeeModal,
	EditEmployeeModal,
	// ParentInfoModal,
	StudentInfoModal,
	StudentSubscriptionInfoModal,
} from "./modals";
import {
	getFilterClassesRequest,
	inviteEmployeeRequest,
	clearDashboard,
	getEmployeesRequest,
	editEmployeeRequest,
	getParentsRequest,
	getStudentsRequest,
	deleteEmployeeRequest,
	getMoreEmployeesRequest,
	getMoreStudentsRequest,
	getMoreParentsRequest,
} from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux";
import {
	SelectedFilters,
	InviteEmployeeForm,
	StaffItem,
	EditEmployeePayload,
	StudentResponseType,
} from "./types";
import { useDelta } from "react-delta";
import { useDebounce } from "../../common/utils";
import { defaultStaff, defaultStudent } from "../../common/constants";
import { getPositionTeamsDirectoryRequest } from "../../redux/actions/directories";

export const DashboardContainer: React.FC = () => {
	const dispatch = useDispatch();
	const [showInviteEmployeeModal, setShowInviteEmployeeModal] =
		useState(false);
	const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
	const [showStudentInfoModal, setShowStudentInfoModal] = useState(false);
	const [showSubscriptionInfoModal, setShowSubscriptionInfoModal] =
		useState(false);
	const [selectedFilters, setSelectedFilters] =
		useState<SelectedFilters>("all");
	const [toEditEmployee, setToEditEmployee] =
		useState<StaffItem>(defaultStaff);
	const [tabIndex, setTabIndex] = useState(0);
	const [sortOrderAsc, setSortOrderAsc] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");
	const [studentInfo, setStudentInfo] =
		useState<StudentResponseType>(defaultStudent);

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const { items: filterClasses } = useSelector(
		(state: RootStore) => state.dashboard.filterClasses,
	);
	const { loading: inviteLoading, errors: inviteErrors } = useSelector(
		(state: RootStore) => state.dashboard.staff.inviteEmployee,
	);
	const {
		loading: staffLoading,
		items: staffItems,
		totalCount: staffTotalCount,
		loadMoreLoading: staffLoadMoreLoading,
	} = useSelector((state: RootStore) => state.dashboard.staff);
	const { loading: editLoading, errors: editErrors } = useSelector(
		(state: RootStore) => state.dashboard.staff.editEmployee,
	);
	const { loading: deleteLoading, errors: deleteErrors } = useSelector(
		(state: RootStore) => state.dashboard.staff.deleteEmployee,
	);

	const { totalCount: parentsTotalCount } = useSelector(
		(state: RootStore) => state.dashboard.parents,
	);
	const {
		loading: studentsLoading,
		items: studentItems,
		totalCount: studentsTotalCount,
		loadMoreLoading: studentsLoadMoreLoading,
	} = useSelector((state: RootStore) => state.dashboard.students);

	const inviteLoadingDelta = useDelta(inviteLoading);
	const editLoadingDelta = useDelta(editLoading);
	const deleteLoadingDelta = useDelta(deleteLoading);
	const searchQueryDelta = useDelta(debouncedSearchTerm);
	const tabIndexDelta = useDelta(tabIndex);
	const selectedFiltersDelta = useDelta(selectedFilters);
	const sortOrderAscDelta = useDelta(sortOrderAsc);

	useEffect(() => {
		dispatch(getEmployeesRequest());
		dispatch(getPositionTeamsDirectoryRequest(""));
		dispatch(
			getParentsRequest({
				searchQuery,
				sortByAscending: sortOrderAsc,
				subscriptionPlanId: selectedFilters,
			}),
		);
		dispatch(
			getStudentsRequest({
				searchQuery,
				sortByAscending: sortOrderAsc,
				subscriptionPlanId: selectedFilters,
			}),
		);
		return () => {
			dispatch(clearDashboard());
		};
	}, []);

	useEffect(() => {
		if (typeof selectedFilters === "string") {
			dispatch(
				getFilterClassesRequest(selectedFilters === "all" ? 2 : 1),
			);
		}
	}, [selectedFilters]);

	const fetchData = () => {
		if (tabIndex === 0) {
			dispatch(
				getParentsRequest({
					searchQuery,
					sortByAscending: sortOrderAsc,
					subscriptionPlanId: selectedFilters,
				}),
			);
		} else if (tabIndex === 1) {
			dispatch(
				getStudentsRequest({
					searchQuery,
					sortByAscending: sortOrderAsc,
					subscriptionPlanId: selectedFilters,
				}),
			);
		}
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			fetchData();
		} else if (searchQueryDelta?.prev && !searchQueryDelta.curr) {
			fetchData();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (inviteLoadingDelta && inviteLoadingDelta.prev) {
			if (
				inviteLoadingDelta.prev &&
				!inviteLoadingDelta.curr &&
				!inviteErrors.length
			) {
				setShowInviteEmployeeModal(false);
			}
		}
	}, [inviteLoadingDelta]);

	useEffect(() => {
		if (editLoadingDelta && editLoadingDelta.prev) {
			if (
				editLoadingDelta.prev &&
				!editLoadingDelta.curr &&
				!editErrors.length
			) {
				setShowEditEmployeeModal(false);
				setToEditEmployee(defaultStaff);
			}
		}
	}, [editLoadingDelta]);

	useEffect(() => {
		if (deleteLoadingDelta && deleteLoadingDelta.prev) {
			if (
				deleteLoadingDelta.prev &&
				!deleteLoadingDelta.curr &&
				!deleteErrors.length
			) {
				setShowEditEmployeeModal(false);
				setToEditEmployee(defaultStaff);
			}
		}
	}, [deleteLoadingDelta]);

	// useEffect(() => {
	// 	fetchData();
	// }, [tabIndex, selectedFilters, sortOrderAsc]);

	useEffect(() => {
		if (
			(tabIndexDelta &&
				tabIndexDelta.prev?.toString() &&
				tabIndexDelta?.prev !== tabIndexDelta?.curr) ||
			(selectedFiltersDelta &&
				selectedFiltersDelta.prev &&
				selectedFiltersDelta?.prev !== selectedFiltersDelta?.curr) ||
			(sortOrderAscDelta &&
				sortOrderAscDelta.prev &&
				sortOrderAscDelta?.prev !== sortOrderAscDelta?.curr)
		) {
			fetchData();
		}
	}, [tabIndexDelta, selectedFiltersDelta, sortOrderAscDelta]);

	const inviteEmployee = (data: InviteEmployeeForm) =>
		dispatch(inviteEmployeeRequest(data));
	const editEmployee = (data: EditEmployeePayload) =>
		dispatch(editEmployeeRequest(data));
	const deleteEmployee = (id: number) => dispatch(deleteEmployeeRequest(id));
	const getMoreEmployees = () => dispatch(getMoreEmployeesRequest());
	const getMoreStudents = () =>
		dispatch(
			getMoreStudentsRequest({
				searchQuery,
				sortByAscending: sortOrderAsc,
				subscriptionPlanId: selectedFilters,
			}),
		);
	const getMoreParents = () =>
		dispatch(
			getMoreParentsRequest({
				searchQuery,
				sortByAscending: sortOrderAsc,
				subscriptionPlanId: selectedFilters,
			}),
		);

	return (
		<>
			<Dashboard
				parentsTotalCount={parentsTotalCount}
				setShowInviteEmployeeModal={setShowInviteEmployeeModal}
				setShowEditEmployeeModal={setShowEditEmployeeModal}
				setShowStudentInfoModal={setShowStudentInfoModal}
				filterClasses={filterClasses}
				setSelectedFilters={setSelectedFilters}
				selectedFilters={selectedFilters}
				staffLoading={staffLoading}
				staffItems={staffItems}
				setToEditEmployee={setToEditEmployee}
				tabIndex={tabIndex}
				setTabIndex={setTabIndex}
				sortOrderAsc={sortOrderAsc}
				setSortOrderAsc={setSortOrderAsc}
				setSearchQuery={setSearchQuery}
				studentsLoading={studentsLoading}
				studentItems={studentItems}
				setStudentInfo={setStudentInfo}
				studentsTotalCount={studentsTotalCount}
				staffTotalCount={staffTotalCount}
				getMoreEmployees={getMoreEmployees}
				staffLoadMoreLoading={staffLoadMoreLoading}
				studentsLoadMoreLoading={studentsLoadMoreLoading}
				getMoreStudents={getMoreStudents}
				getMoreParents={getMoreParents}
			/>
			<InviteEmployeeModal
				isOpen={showInviteEmployeeModal}
				handleClose={() => setShowInviteEmployeeModal(false)}
				inviteEmployee={inviteEmployee}
				loading={inviteLoading}
			/>
			<EditEmployeeModal
				isOpen={showEditEmployeeModal}
				handleClose={() => {
					setShowEditEmployeeModal(false);
					setToEditEmployee(defaultStaff);
				}}
				toEditEmployee={toEditEmployee}
				editEmployee={editEmployee}
				loading={editLoading}
				deleteEmployee={deleteEmployee}
				deleteLoading={deleteLoading}
			/>
			{/* <ParentInfoModal
				isOpen={showParentInfoModal}
				handleClose={() => {
					setShowParentInfoModal(false);
					setParentInfo(defaultParent);
				}}
				parentInfo={parentInfo}
			/> */}
			<StudentInfoModal
				isOpen={showStudentInfoModal}
				handleClose={() => {
					setShowStudentInfoModal(false);
					setStudentInfo(defaultStudent);
				}}
				setShowSubscriptionInfoModal={setShowSubscriptionInfoModal}
				studentInfo={studentInfo}
			/>
			<StudentSubscriptionInfoModal
				isOpen={showSubscriptionInfoModal}
				handleClose={() => setShowSubscriptionInfoModal(false)}
				studentInfo={studentInfo}
			/>
		</>
	);
};
