import { Box, Grid } from "@chakra-ui/react";
import { DashboardHeader } from "../../common/sections";
import { ClassesCard, StatsCard } from "./cards";
import { SubscriptionPlan } from "../../common/types";
import { SelectedClass } from "./types";
import { TeacherAttendanceType } from "../TeacherAttendance/types";
interface IAttendanceProps {
	setShowSelectDateModal: (value: boolean) => void;
	setShowSelectMonthModal: (value: boolean) => void;
	setShowSelectWeekModal: (value: boolean) => void;
	setShowSelectYearModal: (value: boolean) => void;
	classItems: SubscriptionPlan[];
	classesLoading: boolean;
	setSelectedClass: (value: SelectedClass) => void;
	selectedClass: SelectedClass;
	filterMode: string;
	setFilterMode: (value: string) => void;
	afterDate: string;
	beforeDate: string;
	absentCount: number;
	presentCount: number;
	dayOffCount: number;
	studentItems: TeacherAttendanceType[];
	studentsLoading: boolean;
	studentsLoadMoreLoading: boolean;
	studentsTotalCount: number;
	loadMoreStudents: () => void;
}

export const Attendance: React.FC<IAttendanceProps> = ({
	setShowSelectDateModal,
	setShowSelectMonthModal,
	setShowSelectWeekModal,
	setShowSelectYearModal,
	classItems,
	classesLoading,
	selectedClass,
	setSelectedClass,
	filterMode,
	setFilterMode,
	afterDate,
	beforeDate,
	absentCount,
	dayOffCount,
	presentCount,
	studentItems,
	studentsLoadMoreLoading,
	studentsLoading,
	studentsTotalCount,
	loadMoreStudents,
}) => {
	return (
		<Box h="100vh" overflowY="auto" bg={{ base: "#F9F9FB", lg: "white" }}>
			<DashboardHeader title="Attendance" description="" />
			<Box
				px={{ base: "20px", lg: "40px" }}
				mt={{ base: "-110px", lg: "-55px" }}
				width={{ base: "auto", lg: "calc(100vw - 250px)" }}>
				<Grid
					templateColumns={{ base: "1fr", lg: "60% 1fr" }}
					templateRows="1fr"
					gridGap="24px"
					// height={{
					// 	base: "calc(100vh - 160px)",
					// 	lg: "calc(100vh - 160px)",
					// }}
				>
					<StatsCard
						classItems={classItems}
						selectedClass={selectedClass}
						setSelectedClass={setSelectedClass}
						filterMode={filterMode}
						setFilterMode={setFilterMode}
						afterDate={afterDate}
						beforeDate={beforeDate}
						setShowSelectDateModal={setShowSelectDateModal}
						setShowSelectMonthModal={setShowSelectMonthModal}
						setShowSelectWeekModal={setShowSelectWeekModal}
						setShowSelectYearModal={setShowSelectYearModal}
						absentCount={absentCount}
						presentCount={presentCount}
						dayOffCount={dayOffCount}
						studentItems={studentItems}
						studentsLoading={studentsLoading}
						studentsLoadMoreLoading={studentsLoadMoreLoading}
						studentsTotalCount={studentsTotalCount}
						loadMoreStudents={loadMoreStudents}
					/>
					<ClassesCard
						items={classItems}
						loading={classesLoading}
						selectedClass={selectedClass}
						setSelectedClass={setSelectedClass}
					/>
				</Grid>
			</Box>
		</Box>
	);
};
