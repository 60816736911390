import {
	IResponseWithCustomValue,
	IGetClassesResponse,
} from "../../../common/types";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
	getClassesForLevelsRequest,
	getClassesForLevelsSuccess,
	getClaassesForLevelsError,
} from "../../actions/classes.actions";
import { Alerter } from "../../../common/utils";

function* workerGetCourses() {
	try {
		const result: IResponseWithCustomValue<IGetClassesResponse> =
			yield call(API.get, "/api/v1/classes");
		if (result.success) {
			yield put(getClassesForLevelsSuccess(result.value));
		} else {
			yield put(getClaassesForLevelsError(result.errors));
			throw result;
		}
	} catch (err) {
		Alerter.error(err);
	}
}

// prettier-ignore
export default function* watchCoursesSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getClassesForLevelsRequest.type, workerGetCourses);
}
