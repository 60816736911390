import { Sidebar } from "./Sidebar";
import { Flex, Box } from "@chakra-ui/react";

interface IMainLayoutProps {
	onLogout: () => void;
}

export const MainLayout: React.FC<IMainLayoutProps> = ({
	children,
	onLogout,
}) => (
	<Flex>
		<Sidebar onLogout={onLogout} />
		<Box w="100%">{children}</Box>
	</Flex>
);
