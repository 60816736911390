import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { ChakraProvider } from "@chakra-ui/react";
import { history } from "./redux/createStore";
import { Routing } from "./routing";
import { ConnectedRouter } from "connected-react-router";
import { theme } from "./common/theme";
import "time-input-polyfill/auto";

const App: React.FC = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ConnectedRouter history={history}>
				<ChakraProvider theme={theme}>
					<Routing />
				</ChakraProvider>
			</ConnectedRouter>
		</PersistGate>
	</Provider>
);

export default App;
