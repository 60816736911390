import {
	Box,
	InputGroup,
	InputLeftElement,
	Input,
	Tabs,
	TabList,
	Tab,
	Text,
	TabPanel,
	TabPanels,
	Flex,
	Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import { ReactComponent as BroadcastIcon } from "../../../assets/ic_broadcast.svg";
import { LevelsStrip } from "../../../common/components";
import { ParentsTabContainer, StaffTabContainer } from "../tabs";
import { SubscriptionPlan } from "../../../common/types";
import { SelectedFilters } from "../../Dashboard/types";
import { SendMessageTo } from "../types";

interface IRecipientsCardProps {
	tabIndex: number;
	setTabIndex: (value: number) => void;
	setShowSendBulkMessageModal: (value: boolean) => void;
	setShowSendMessageModal: (value: boolean) => void;
	searchQuery: string;
	filterClasses: SubscriptionPlan[];
	setSelectedFilters: (value: SelectedFilters) => void;
	selectedFilters: SelectedFilters;
	setSearchQuery: (value: string) => void;
	parentsCount: number;
	staffCount: number;
	setSendMessageTo: (value: SendMessageTo) => void;
}

export const RecipientsCard: React.FC<IRecipientsCardProps> = ({
	setTabIndex,
	tabIndex,
	setShowSendBulkMessageModal,
	setShowSendMessageModal,
	searchQuery,
	filterClasses,
	setSelectedFilters,
	selectedFilters,
	setSearchQuery,
	parentsCount,
	staffCount,
	setSendMessageTo,
}) => {
	return (
		<Box
			p={{ base: "10px 0", lg: "30px 40px 0 40px" }}
			bg={{ base: "transparent", lg: "white" }}
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			<InputGroup>
				<InputLeftElement h="50px" pointerEvents="none">
					<SearchIcon />
				</InputLeftElement>
				<Input
					placeholder="Find parents and staff"
					borderRadius="8px"
					paddingInlineStart="12px"
					paddingInlineEnd="12px"
					h="50px"
					borderColor="lightGrey"
					color="darkest"
					fontSize="14px"
					lineHeight="22px"
					focusBorderColor="primary"
					bg="white"
					_placeholder={{
						color: "grey",
					}}
					onChange={(ev) => setSearchQuery(ev.target.value)}
				/>
			</InputGroup>
			<LevelsStrip
				items={filterClasses}
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
			/>
			<Tabs variant="unstyled" index={tabIndex}>
				<TabList borderBottom="1px solid #EBEBEB">
					<Tab
						onClick={() => setTabIndex(0)}
						_selected={{ borderBottom: "2px solid #8C4BC9" }}
						borderBottom="2px solid transparent">
						<Text fontSize="16px" lineHeight="21px" color="primary">
							Parents {`(${parentsCount})`}
						</Text>
					</Tab>
					<Tab
						onClick={() => setTabIndex(1)}
						_selected={{ borderBottom: "2px solid #8C4BC9" }}
						borderBottom="2px solid transparent">
						<Text fontSize="16px" lineHeight="21px" color="primary">
							Staff {`(${staffCount})`}
						</Text>
					</Tab>
					<Flex marginLeft="auto">
						<Button
							leftIcon={<BroadcastIcon />}
							variant="unstyled"
							fontSize="14px"
							lineHeight="24px"
							color="primary"
							height="auto"
							d="flex"
							onClick={() => setShowSendBulkMessageModal(true)}>
							Send Bulk Message
						</Button>
					</Flex>
				</TabList>

				<TabPanels>
					<TabPanel p="0" h="100%">
						<ParentsTabContainer
							setShowSendMessageModal={setShowSendMessageModal}
							searchQuery={searchQuery}
							selectedFilters={selectedFilters}
							setSendMessageTo={setSendMessageTo}
						/>
					</TabPanel>
					<TabPanel h="100%" p="0">
						<StaffTabContainer
							setShowSendMessageModal={setShowSendMessageModal}
							searchQuery={searchQuery}
							setSendMessageTo={setSendMessageTo}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
