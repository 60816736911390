import { createReducer } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { StudentNamedSubscriptionType } from "../../types";
import {
	getPlanSubscriptionsRequest,
	getPlanSubscriptionsSuccess,
	getPlanSubscriptionsError,
	getMorePlanSubscriptionsRequest,
	getMorePlanSubscriptionsSuccess,
	getMorePlanSubscriptionsError,
} from "../actions";

type InitialState = {
	items: StudentNamedSubscriptionType[];
	loading: boolean;
	loadMoreLoading: boolean;
	page: number;
	errors: PayloadError[];
	totalCount: number;
};

const initialState: InitialState = {
	errors: [],
	items: [],
	loadMoreLoading: false,
	loading: false,
	page: 1,
	totalCount: 0,
};

export const planSubscriptionsReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(getPlanSubscriptionsRequest, (state) => {
			state.loading = true;
			state.errors = [];
			state.page = 1;
		})
		.addCase(getPlanSubscriptionsSuccess, (state, action) => {
			state.loading = false;
			state.items = action.payload.subscriptions;
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getPlanSubscriptionsError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		.addCase(getMorePlanSubscriptionsRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMorePlanSubscriptionsSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.items = [...state.items, ...action.payload.subscriptions];
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getMorePlanSubscriptionsError, (state) => {
			state.loadMoreLoading = false;
		}),
);
