/* eslint-disable indent */
import * as React from "react";
import {
	useCombobox,
	useMultipleSelection,
	UseMultipleSelectionProps,
} from "downshift";
import { matchSorter } from "match-sorter";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import {
	Input,
	Popover,
	PopoverContent,
	Tag,
	TagCloseButton,
	TagLabel,
	Text,
	Stack,
	Box,
	List,
	ListItem,
	PopoverTrigger,
	FormLabel,
} from "@chakra-ui/react";
import { Loader } from "../../components";

export interface Item {
	label: string;
	value: string;
}

export interface CUIAutoCompleteProps<T extends Item>
	extends UseMultipleSelectionProps<T> {
	items: T[];
	placeholder: string;
	label: string;
	optionFilterFunc?: (items: T[], inputValue: string) => T[];
	emptyState?: (inputValue: string) => React.ReactNode;
	onInputChange: (value: string) => void;
	errorMsg?: string;
	typeaheadLoading?: boolean;
	disableTextField?: boolean;
}

function defaultOptionFilterFunc<T>(items: T[], inputValue: string) {
	return matchSorter(items, inputValue, { keys: ["value", "label"] });
}

export const TypeAhead = <T extends Item>(
	props: CUIAutoCompleteProps<T>,
): React.ReactElement<CUIAutoCompleteProps<T>> => {
	const {
		items,
		optionFilterFunc = defaultOptionFilterFunc,
		placeholder,
		label,
		onInputChange,
		errorMsg,
		typeaheadLoading,
		disableTextField,
		...downshiftProps
	} = props;

	/* States */
	const [inputValue, setInputValue] = React.useState("");
	const [inputItems, setInputItems] =
		React.useState<{ label: string; value: string }[]>(items);

	/* Refs */
	const disclosureRef = React.useRef(null);
	const popupContentRef = React.useRef(null);

	/* Downshift Props */
	const {
		getSelectedItemProps,
		getDropdownProps,
		addSelectedItem,
		removeSelectedItem,
		selectedItems,
	} = useMultipleSelection(downshiftProps);
	const selectedItemValues = selectedItems.map((item) => item.value);

	const {
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
		openMenu,
		selectItem,
		setHighlightedIndex,
		closeMenu,
	} = useCombobox({
		inputValue,
		selectedItem: undefined,
		items: inputItems,
		onInputValueChange: ({ inputValue, selectedItem }) => {
			const filteredItems = optionFilterFunc(items, inputValue || "");
			onInputChange(inputValue || "");
			if (!selectedItem) {
				setInputItems(filteredItems);
			}
		},
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;
			switch (type) {
				case useCombobox.stateChangeTypes.InputBlur:
					return {
						...changes,
						isOpen: false,
					};
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					return {
						...changes,
						highlightedIndex: state.highlightedIndex,
						inputValue,
						isOpen: true,
					};
				case useCombobox.stateChangeTypes.FunctionSelectItem:
					return {
						...changes,
						inputValue,
					};
				default:
					return changes;
			}
		},
		onStateChange: ({ inputValue, type, selectedItem }) => {
			switch (type) {
				case useCombobox.stateChangeTypes.InputChange:
					setInputValue(inputValue || "");
					break;
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					if (selectedItem) {
						if (selectedItemValues.includes(selectedItem.value)) {
							removeSelectedItem(selectedItem as any);
							setInputValue("");
						} else {
							addSelectedItem(selectedItem as any);
							setInputValue("");
						}

						selectItem({ label: "", value: "" });
					}
					break;
				default:
					break;
			}
		},
	});

	useDeepCompareEffect(() => {
		setInputItems(items);
	}, [items]);

	React.useEffect(() => {
		if (disableTextField) {
			closeMenu();
		}
	}, [disableTextField]);

	/* Default Items Renderer */
	function defaultItemRenderer<T extends Item>(selected: T) {
		return (
			<Text fontSize="14px" lineHeight="22px" color="darkest">
				{selected.label}
			</Text>
		);
	}

	return (
		<Stack mb="29px">
			<FormLabel
				fontSize="caption2"
				lineHeight="22px"
				color="darkest"
				marginBottom={0}>
				{label}
			</FormLabel>
			<Popover
				initialFocusRef={popupContentRef}
				isOpen={isOpen}
				matchWidth={true}>
				<PopoverTrigger>
					{/* <> */}
					{/* <FormLabel
							fontSize="caption2"
							lineHeight="22px"
							color="darkest"
							marginBottom={0}>
							{label}
						</FormLabel> */}
					<Stack
						isInline
						{...getComboboxProps()}
						borderRadius="8px"
						border={
							errorMsg ? "1px solid #E53E3E" : "1px solid #E0E0E0"
						}
						paddingInlineStart="12px"
						paddingInlineEnd="12px"
						height="40px"
						overflow="auto"
						mt="0px !important"
						p="5px"
						sx={{
							scrollbarWidth: "none",
							"&::-webkit-scrollbar": {
								display: "none",
							},
						}}>
						<>
							{selectedItems && (
								<Stack spacing={2} isInline>
									{selectedItems.map(
										(selectedItem, index) => (
											<Tag
												key={`selected-item-${index}`}
												{...getSelectedItemProps({
													selectedItem,
													index,
												})}
												border="1px solid #8C4BC9"
												borderRadius="8px"
												bg="purple">
												<TagLabel
													color="primary"
													fontSize="12px"
													lineHeight="22px">
													{selectedItem.label}
												</TagLabel>
												<TagCloseButton
													color="primary"
													opacity={1}
													onClick={(e) => {
														e.stopPropagation();
														removeSelectedItem(
															selectedItem,
														);
													}}
													aria-label="Remove menu selection badge"
												/>
											</Tag>
										),
									)}
								</Stack>
							)}
						</>
						<>
							<Input
								{...getInputProps(
									getDropdownProps({
										placeholder,
										onClick: isOpen ? undefined : openMenu,
										onFocus: isOpen ? undefined : openMenu,
										ref: disclosureRef,
									}),
								)}
								variant="unstyled"
								minWidth="250px"
								color="darkest"
								fontSize="14px"
								lineHeight="22px"
								isDisabled={disableTextField}
							/>
						</>
					</Stack>
					{/* </> */}
				</PopoverTrigger>
				<PopoverContent width="100%">
					<Box ref={popupContentRef} w="100%">
						<List bg="white" borderRadius="8px" {...getMenuProps()}>
							{typeaheadLoading && (
								<Loader centerHeight="50px" spinnerSize="sm" />
							)}
							{isOpen &&
								inputItems.map((item: any, index: number) => (
									<ListItem
										px={2}
										py={1}
										bg={
											highlightedIndex === index
												? "purple"
												: "inherit"
										}
										key={`${item.value}${index}`}
										{...getItemProps({
											item,
											index,
										})}>
										<Box
											display="inline-flex"
											alignItems="center">
											{defaultItemRenderer(item)}
										</Box>
									</ListItem>
								))}
						</List>
					</Box>
				</PopoverContent>
			</Popover>
			<Text fontSize="sm" color="red">
				{errorMsg}
			</Text>
		</Stack>
	);
};
