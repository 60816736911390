import { PayloadError } from "./../../common/types";
import { createReducer } from "@reduxjs/toolkit";
import {
	loginRequest,
	loginSuccess,
	loginError,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordError,
	resetPasswordRequest,
	resetPasswordSuccess,
	resetPasswordError,
} from "../actions/auth.actions";

type InitialState = {
	loading: boolean;
	errors: PayloadError[];
	forgotPassword: {
		loading: boolean;
		errors: PayloadError[];
	};
	resetPassword: {
		loading: boolean;
		errors: PayloadError[];
	};
};

const initialState: InitialState = {
	loading: false,
	errors: [],
	forgotPassword: {
		errors: [],
		loading: false,
	},
	resetPassword: {
		errors: [],
		loading: false,
	},
};

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(loginRequest, (state) => {
			state.loading = true;
		})
		.addCase(loginSuccess, (state) => {
			state.loading = false;
			state.errors = [];
		})
		.addCase(loginError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		.addCase(forgotPasswordRequest, (state) => {
			state.forgotPassword.loading = true;
		})
		.addCase(forgotPasswordSuccess, (state) => {
			state.forgotPassword.loading = false;
			state.forgotPassword.errors = [];
		})
		.addCase(forgotPasswordError, (state, action) => {
			state.forgotPassword.loading = false;
			state.forgotPassword.errors = action.payload;
		})
		.addCase(resetPasswordRequest, (state) => {
			state.resetPassword.loading = true;
		})
		.addCase(resetPasswordSuccess, (state) => {
			state.resetPassword.loading = false;
			state.resetPassword.errors = [];
		})
		.addCase(resetPasswordError, (state, action) => {
			state.resetPassword.loading = false;
			state.resetPassword.errors = action.payload;
		});
});

export default authReducer;
