import { combineReducers } from "redux";
import positionCommitmentsDirectoryReducer from "./position-commitments.directory";
import positionTeamsDirectoryReducer from "./position-teams.directory";

const directoriesReducer = combineReducers({
	positionCommitments: positionCommitmentsDirectoryReducer,
	positionTeams: positionTeamsDirectoryReducer,
});

export default directoriesReducer;
