import { PayloadError } from "./../../../../common/types";
import { createReducer } from "@reduxjs/toolkit";
import {
	getMessagesHistoryRequest,
	getMessagesHistorySuccess,
	getMessagesHistoryError,
	clearMessages,
	getMoreMessagesHistoryRequest,
	getMoreMessagesHistorySuccess,
	getMoreMessagesHistoryError,
} from "../actions";
import { MessageHistoryItemType } from "../../types";

type InitialState = {
	items: MessageHistoryItemType[];
	loading: boolean;
	loadMoreLoading: boolean;
	page: number;
	totalCount: number;
	errors: PayloadError[];
};

const initialState: InitialState = {
	items: [],
	loading: false,
	errors: [],
	loadMoreLoading: false,
	page: 1,
	totalCount: 0,
};

const messagesHistoryReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getMessagesHistoryRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getMessagesHistorySuccess, (state, action) => {
			state.loading = false;
			state.totalCount = action.payload.totalCount;
			state.items = action.payload.messages;
			state.page++;
		})
		.addCase(getMessagesHistoryError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		.addCase(getMoreMessagesHistoryRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreMessagesHistorySuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.totalCount = action.payload.totalCount;
			state.items = [...state.items, ...action.payload.messages];
			state.page++;
		})
		.addCase(getMoreMessagesHistoryError, (state) => {
			state.loadMoreLoading = false;
		})
		.addCase(clearMessages, () => {
			return { ...initialState };
		});
});
export default messagesHistoryReducer;
