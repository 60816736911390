import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const generalInfoSchemaResolver = yup.object().shape({
	firstName: yup.string().required(ErrorMessages.REQUIRED),
	lastName: yup.string().required(ErrorMessages.REQUIRED),
	email: yup
		.string()
		.email(ErrorMessages.EMAIL_INVALID)
		.required(ErrorMessages.REQUIRED),
	phoneNumber: yup
		.string()
		.test("empty-plus", ErrorMessages.REQUIRED, function (value) {
			if (value === "+" || value === "") {
				return false;
			} else {
				return true;
			}
		})
		.typeError("Must be a phone number"),
});

export const generalInfoSchema = yupResolver(generalInfoSchemaResolver);
