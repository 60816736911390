import { combineReducers } from "redux";
import { classesReducer } from "./classes.reducer";
import { levelsReducer } from "./levels.reducer";
import { subscriptionsReducer } from "./subscriptions.reducer";
import { planSubscriptionsReducer } from "./plan-subscriptions.reducer";

const subscriptionReducer = combineReducers({
	classes: classesReducer,
	levels: levelsReducer,
	subscriptions: subscriptionsReducer,
	planSubscriptions: planSubscriptionsReducer,
});

export default subscriptionReducer;
