import { createAction } from "@reduxjs/toolkit";
import {
	InviteEmployeeForm,
	EditEmployeePayload,
	StaffItem,
} from "../../types";
import { PayloadError } from "../../../../common/types";

//INVITE
export const inviteEmployeeRequest = createAction<InviteEmployeeForm>(
	"@employee/INVITE_EMPLOYEE_REQUEST",
);
export const inviteEmployeeSuccess = createAction(
	"@employee/INVITE_EMPLOYEE_SUCCESS",
);
export const inviteEmployeeError = createAction<PayloadError[]>(
	"@employee/INVITE_EMPLOYEE_ERROR",
);

//EDITE
export const editEmployeeRequest = createAction<EditEmployeePayload>(
	"@employee/EDIT_EMPLOYEE_REQUEST",
);
export const editEmployeeSuccess = createAction(
	"@employee/EDIT_EMPLOYEE_SUCCESS",
);
export const editEmployeeError = createAction<PayloadError[]>(
	"@employee/EDIT_EMPLOYEE_ERROR",
);

//DELETE
export const deleteEmployeeRequest = createAction<number>(
	"@employee/DELETE_EMPLOYEE_REQUEST",
);
export const deleteEmployeeSuccess = createAction(
	"@employee/DELETE_EMPLOYEE_SUCCESS",
);
export const deleteEmployeeError = createAction<PayloadError[]>(
	"@employee/DELETE_EMPLOYEE_ERROR",
);

//GET EMPLOYEES
export const getEmployeesRequest = createAction(
	"@employee/GET_EMPLOYEES_REQUEST",
);
export const getEmployeesSuccess = createAction<{
	totalCount: number;
	staffUsers: StaffItem[];
}>("@employee/GET_EMPLOYEES_SUCCESS");
export const getEmployeesError = createAction<PayloadError[]>(
	"@employee/GET_EMPLOYEES_ERROR",
);

//GET MORE EMPLOYEES

export const getMoreEmployeesRequest = createAction(
	"@employee/GET_MORE_EMPLOYEES_REQUEST",
);
export const getMoreEmployeesSuccess = createAction<{
	totalCount: number;
	staffUsers: StaffItem[];
}>("@employee/GET_MORE_EMPLOYEES_SUCCESS");
export const getMoreEmployeesError = createAction(
	"@employee/GET_MORE_EMPLOYEES_ERROR",
);
