import { createReducer } from "@reduxjs/toolkit";
import {
	getStudentsRequest,
	getStudentsSuccess,
	getStudentsError,
	getMoreStudentsRequest,
	getMoreStudentsSuccess,
	getMoreStudentsError,
	clearDashboard,
} from "../actions";
import { StudentResponseType } from "../../types";
import { PayloadError } from "../../../../common/types";

type InitialState = {
	loading: boolean;
	items: StudentResponseType[];
	errors: PayloadError[];
	totalCount: number;
	loadMoreLoading: boolean;
	page: number;
};

const initialState: InitialState = {
	loading: false,
	items: [],
	errors: [],
	totalCount: 0,
	loadMoreLoading: false,
	page: 1,
};

const studentsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getStudentsRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getStudentsSuccess, (state, action) => {
			state.loading = false;
			state.totalCount = action.payload.totalCount;
			state.items = action.payload.users;
			state.page = 1;
		})
		.addCase(getStudentsError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		//Get More Student
		.addCase(getMoreStudentsRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreStudentsSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.items = [...state.items, ...action.payload.users];
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getMoreStudentsError, (state) => {
			state.loadMoreLoading = false;
		})
		.addCase(clearDashboard, () => {
			return { ...initialState };
		});
});

export default studentsReducer;
