import { Box } from "@chakra-ui/react";
import { ClassesItem } from "../../components";
import { ClassType } from "../../types";
import { Loader, InfiniteScroll } from "../../../../common/components";
import { ClassesPlaceholder } from "../../components/placeholders";

interface IClassesTabProps {
	classesItems: ClassType[];
	classesLoading: boolean;
	belowDesktop?: boolean;
	setToEditClass: (value: ClassType) => void;
	setShowAddClassModal: (value: boolean) => void;
}

export const ClassesTab: React.FC<IClassesTabProps> = ({
	classesItems,
	classesLoading,
	belowDesktop,
	setToEditClass,
	setShowAddClassModal,
}) => {
	return (
		<>
			{classesLoading ? (
				<Loader centerHeight="calc(100vh - 300px)" />
			) : (
				<>
					{classesItems.length ? (
						<Box
							width={{ base: "calc(95vw - 20px)", lg: "auto" }}
							height={{ base: "auto", lg: "calc(100vh - 300px)" }}
							style={{
								WebkitOverflowScrolling: "touch",
							}}>
							<InfiniteScroll horizontal={belowDesktop}>
								{classesItems.map((item) => (
									<ClassesItem
										key={item.id}
										{...item}
										onEdit={() => setToEditClass(item)}
									/>
								))}
							</InfiniteScroll>
						</Box>
					) : (
						<ClassesPlaceholder
							createClass={() => setShowAddClassModal(true)}
						/>
					)}
				</>
			)}
		</>
	);
};
