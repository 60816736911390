import { createReducer } from "@reduxjs/toolkit";
import {
	getStudentSubscriptionPlansRequest,
	getStudentSubscriptionPlansError,
	getStudentSubscriptionPlansSuccess,
	getStudentPaymentsRequest,
	getStudentPaymentsSuccess,
	getStudentPaymentsError,
	getMoreStudentPaymentsRequest,
	getMoreStudentPaymentsSuccess,
	getMoreStudentPaymentsError,
	clearDashboard,
} from "../actions";
import { StudentPaymentType } from "../../types";
import { SubscriptionPlan } from "../../../../common/types";

type InitialState = {
	plans: {
		items: SubscriptionPlan[];
		loading: boolean;
	};
	payments: {
		items: StudentPaymentType[];
		loading: boolean;
		totalCount: number;
		loadMoreLoading: boolean;
		page: number;
	};
};

const initialState: InitialState = {
	payments: {
		items: [],
		loading: false,
		loadMoreLoading: false,
		page: 1,
		totalCount: 0,
	},
	plans: {
		items: [],
		loading: false,
	},
};

const studentSubscriptionReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getStudentSubscriptionPlansRequest, (state) => {
			state.plans.loading = true;
		})
		.addCase(getStudentSubscriptionPlansSuccess, (state, action) => {
			state.plans.loading = false;
			state.plans.items = action.payload;
		})
		.addCase(getStudentSubscriptionPlansError, (state) => {
			state.plans.loading = false;
		})

		.addCase(getStudentPaymentsRequest, (state) => {
			state.payments.loading = true;
		})
		.addCase(getStudentPaymentsSuccess, (state, action) => {
			state.payments.loading = false;
			state.payments.items = action.payload.payments;
			state.payments.totalCount = action.payload.totalCount;
			state.payments.page++;
		})
		.addCase(getStudentPaymentsError, (state) => {
			state.payments.loading = false;
		})
		.addCase(getMoreStudentPaymentsRequest, (state) => {
			state.payments.loadMoreLoading = true;
		})
		.addCase(getMoreStudentPaymentsSuccess, (state, action) => {
			state.payments.loadMoreLoading = false;
			state.payments.items = [
				...state.payments.items,
				...action.payload.payments,
			];
			state.payments.totalCount = action.payload.totalCount;
			state.payments.page++;
		})
		.addCase(getMoreStudentPaymentsError, (state) => {
			state.payments.loadMoreLoading = false;
		})
		.addCase(clearDashboard, () => {
			return { ...initialState };
		});
});

export default studentSubscriptionReducer;
