/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useState, useEffect } from "react";
import { Messages } from "./Messages";
import {
	SendBulkMessageModal,
	SendMessageModal,
	BulkMessageDetailsModal,
	MessageDetailsModal,
} from "./modals";
import { useDebounce } from "../../common/utils";
import { useDelta } from "react-delta";
import {
	getParentsForMessagesRequest,
	getStaffForMessagesRequest,
	getFilterClassesRequest,
	getMessagesHistoryRequest,
	sendIndividualMessageRequest,
	sendBulkMessageRequest,
} from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import { SelectedFilters } from "../Dashboard/types";
import { RootStore } from "../../redux";
import { MessageAlert } from "./components";
import {
	SendMessageTo,
	MessageHistoryItemType,
	SendIndividualMessagePayload,
	SendBulkMessagePayload,
} from "./types";
import { defaultMessageHistoryItem } from "../../common/constants";
import { chakra, Text, Box } from "@chakra-ui/react";
import { SubscriptionPlan } from "../../common/types";
import dayjs from "dayjs";

export const MessagesContainer: React.FC = () => {
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState(0);
	const [showSendBulkMessageModal, setShowSendBulkMessageModal] =
		useState(false);
	const [showSendMessageModal, setShowSendMessageModal] = useState(false);
	const [selectedFilters, setSelectedFilters] =
		useState<SelectedFilters>("all");
	const [sendMessageTo, setSendMessageTo] = useState<SendMessageTo>({
		type: "",
		name: "",
		id: "",
	});
	const [toShowInfoMessage, setToShowInfoMessage] =
		useState<MessageHistoryItemType>(defaultMessageHistoryItem);
	const [toSubmitMessage, setToSubmitMessage] = useState<
		| {
				type: string;
				payload: SendIndividualMessagePayload;
				recipients?: string[];
		  }
		| { type: string; payload: SendBulkMessagePayload }
		| null
	>(null);
	const [sortByAscending, setSortByAscending] = useState(false);

	const [searchQuery, setSearchQuery] = useState("");

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const { items: filterClasses } = useSelector(
		(state: RootStore) => state.messages.filterClasses,
	);
	const { totalCount: parentsCount } = useSelector(
		(state: RootStore) => state.messages.parents,
	);
	const { totalCount: staffCount } = useSelector(
		(state: RootStore) => state.messages.staff,
	);
	const { loading: bulkMessageSendLoading, errors: bulkMessageSendErrors } =
		useSelector((state: RootStore) => state.messages.broadcast.bulkMessage);
	const {
		loading: individualMessageSendLoading,
		errors: individualMessageSendErrors,
	} = useSelector(
		(state: RootStore) => state.messages.broadcast.individualMessage,
	);

	const searchQueryDelta = useDelta(debouncedSearchTerm);
	const tabIndexDelta = useDelta(tabIndex);
	const selectedFiltersDelta = useDelta(selectedFilters);
	const bulkMessageSendLoadingDelta = useDelta(bulkMessageSendLoading);
	const individualMessageSendLoadingDelta = useDelta(
		individualMessageSendLoading,
	);

	const fetchData = () => {
		if (tabIndex === 0) {
			dispatch(
				getParentsForMessagesRequest({
					searchQuery,
					subscriptionPlanId: selectedFilters,
				}),
			);
		} else if (tabIndex === 1) {
			dispatch(
				getStaffForMessagesRequest({
					searchQuery,
				}),
			);
		}
	};

	const getMessageSummary = (messageObject: any | null): JSX.Element => {
		if (messageObject) {
			if (messageObject.payload.sendImmediately) {
				if (messageObject?.recipients) {
					return (
						<Text variant="body3" fontWeight="500" color="darkGrey">
							The message will be sent to{" "}
							<chakra.span color="darkest">
								{messageObject?.recipients.join(", ")}
							</chakra.span>{" "}
							and it cannot be canceled or deleted.
						</Text>
					);
				} else {
					return (
						<Text variant="body3" fontWeight="500" color="darkGrey">
							The message will be sent to{" "}
							<chakra.span color="darkest">
								{messageObject.payload?.staffRole === "1"
									? "Staff"
									: "Parents"}
							</chakra.span>{" "}
							for{" "}
							<chakra.span color="darkest">
								{messageObject.payload?.subscriptionPlanId
									? filterClasses.find(
											(elem: SubscriptionPlan) =>
												elem.id ===
												+messageObject.payload
													?.subscriptionPlanId,
									  ).name
									: "All levels"}
							</chakra.span>{" "}
							and it cannot be canceled or deleted.
						</Text>
					);
				}
			} else {
				if (messageObject?.recipients) {
					return (
						<Text variant="body3" fontWeight="500" color="darkGrey">
							The message will be sent to{" "}
							<chakra.span color="darkest">
								{messageObject?.recipients.join(", ")}
							</chakra.span>{" "}
							at{" "}
							<chakra.span color="darkest">
								{dayjs(messageObject.payload.sendAtUtc).format(
									"D MMMM, YYYY • hh:mm A",
								)}{" "}
							</chakra.span>
							and it cannot be canceled or deleted.
						</Text>
					);
				} else {
					return (
						<Text variant="body3" fontWeight="500" color="darkGrey">
							The message will be sent to{" "}
							<chakra.span color="darkest">
								{messageObject.payload?.staffRole === "1"
									? "Staff"
									: "Parents"}
							</chakra.span>{" "}
							for{" "}
							<chakra.span color="darkest">
								{messageObject.payload?.subscriptionPlanId &&
								messageObject.payload?.subscriptionPlanId !==
									"all"
									? filterClasses.find(
											(elem: SubscriptionPlan) =>
												elem.id ===
												+messageObject.payload
													?.subscriptionPlanId,
									  )?.name
									: "All levels"}
							</chakra.span>{" "}
							at{" "}
							<chakra.span color="darkest">
								{dayjs(messageObject.payload.sendAtUtc).format(
									"D MMMM, YYYY • hh:mm A",
								)}
							</chakra.span>{" "}
							and it cannot be canceled or deleted.
						</Text>
					);
				}
			}
		}

		return <Box />;
	};

	const sendMessage = () => {
		if (toSubmitMessage?.type === "bulk") {
			dispatch(
				sendBulkMessageRequest(
					toSubmitMessage?.payload as SendBulkMessagePayload,
				),
			);
		} else {
			dispatch(
				sendIndividualMessageRequest(
					toSubmitMessage?.payload as SendIndividualMessagePayload,
				),
			);
		}
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			fetchData();
		} else if (searchQueryDelta?.prev && !searchQueryDelta.curr) {
			fetchData();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (
			(tabIndexDelta &&
				tabIndexDelta.prev?.toString() &&
				tabIndexDelta?.prev !== tabIndexDelta?.curr) ||
			(selectedFiltersDelta &&
				selectedFiltersDelta.prev &&
				selectedFiltersDelta?.prev !== selectedFiltersDelta?.curr)
		) {
			fetchData();
		}
	}, [tabIndexDelta, selectedFiltersDelta]);

	useEffect(() => {
		if (typeof selectedFilters === "string") {
			dispatch(
				getFilterClassesRequest(selectedFilters === "all" ? 2 : 1),
			);
		}
	}, [selectedFilters]);

	useEffect(() => {
		dispatch(getMessagesHistoryRequest({ sortByAscending }));
	}, [sortByAscending]);

	useEffect(() => {
		if (bulkMessageSendLoadingDelta && bulkMessageSendLoadingDelta.prev) {
			if (
				bulkMessageSendLoadingDelta.prev &&
				!bulkMessageSendLoadingDelta.curr &&
				!bulkMessageSendErrors.length
			) {
				setToSubmitMessage(null);
			}
		}
	}, [bulkMessageSendLoadingDelta]);

	useEffect(() => {
		if (
			individualMessageSendLoadingDelta &&
			individualMessageSendLoadingDelta.prev
		) {
			if (
				individualMessageSendLoadingDelta.prev &&
				!individualMessageSendLoadingDelta.curr &&
				!individualMessageSendErrors.length
			) {
				setToSubmitMessage(null);
			}
		}
	}, [individualMessageSendLoadingDelta]);

	return (
		<>
			<Messages
				tabIndex={tabIndex}
				setTabIndex={setTabIndex}
				setShowSendBulkMessageModal={setShowSendBulkMessageModal}
				setShowSendMessageModal={setShowSendMessageModal}
				searchQuery={searchQuery}
				filterClasses={filterClasses}
				setSelectedFilters={setSelectedFilters}
				selectedFilters={selectedFilters}
				setSearchQuery={setSearchQuery}
				parentsCount={parentsCount}
				staffCount={staffCount}
				setSendMessageTo={setSendMessageTo}
				setToShowInfoMessage={setToShowInfoMessage}
				setSortByAscending={setSortByAscending}
				sortByAscending={sortByAscending}
			/>
			<SendBulkMessageModal
				isOpen={showSendBulkMessageModal}
				handleClose={() => setShowSendBulkMessageModal(false)}
				onSend={(data) => {
					setToSubmitMessage(data);
					setShowSendBulkMessageModal(false);
				}}
			/>
			<SendMessageModal
				isOpen={showSendMessageModal}
				handleClose={() => {
					setShowSendMessageModal(false);
					setSendMessageTo({ id: "", name: "", type: "" });
				}}
				sendMessageTo={sendMessageTo}
				onSend={(data) => {
					setToSubmitMessage(data);
					setShowSendMessageModal(false);
					setSendMessageTo({ id: "", name: "", type: "" });
				}}
			/>
			<BulkMessageDetailsModal
				isOpen={!!toShowInfoMessage.id ?? toShowInfoMessage.isBulk}
				handleClose={() =>
					setToShowInfoMessage(defaultMessageHistoryItem)
				}
				toShowInfoMessage={toShowInfoMessage}
			/>
			<MessageDetailsModal
				isOpen={!!toShowInfoMessage.id && !toShowInfoMessage.isBulk}
				handleClose={() =>
					setToShowInfoMessage(defaultMessageHistoryItem)
				}
				toShowInfoMessage={toShowInfoMessage}
			/>
			<MessageAlert
				isOpen={!!toSubmitMessage}
				title={`Send ${
					toSubmitMessage?.type === "bulk" ? "Bulk" : ""
				} Message`}
				firstDescription={getMessageSummary(toSubmitMessage)}
				handleClose={() => setToSubmitMessage(null)}
				confirmLoading={
					bulkMessageSendLoading || individualMessageSendLoading
				}
				onConfirm={sendMessage}
			/>
		</>
	);
};
