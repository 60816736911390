import { createAction } from "@reduxjs/toolkit";
import { StudentNamedSubscriptionType, DiscountData } from "../../types";
import { PayloadError } from "../../../../common/types";

export const getAllSubscriptionsRequest = createAction(
	"@subscription/GET_STUDENT_NAMED_SUBSCRIPTIONS_REQUEST",
);
export const getAllSubscriptionsSuccess = createAction<{
	subscriptions: StudentNamedSubscriptionType[];
	totalCount: number;
}>("@subscription/GET_STUDENT_NAMED_SUBSCRIPTIONS_SUCCESS");
export const getAllSubscriptionsError = createAction<PayloadError[]>(
	"@subscription/GET_STUDENT_NAMED_SUBSCRIPTIONS_ERROR",
);

export const getMoreAllSubscriptionsRequest = createAction(
	"@subscription/GET_MORE_STUDENT_NAMED_SUBSCRIPTIONS_REQUEST",
);
export const getMoreAllSubscriptionsSuccess = createAction<{
	subscriptions: StudentNamedSubscriptionType[];
	totalCount: number;
}>("@subscription/GET_MORE_STUDENT_NAMED_SUBSCRIPTIONS_SUCCESS");
export const getMoreAllSubscriptionsError = createAction<PayloadError[]>(
	"@subscription/GET_MORE_STUDENT_NAMED_SUBSCRIPTIONS_ERROR",
);

export const setDiscountValueRequest = createAction<number>(
	"@subscription/SET_DISCOUNT_VALUE_REQUEST",
);
export const setDiscountValueSuccess = createAction(
	"@subscription/SET_DISCOUNT_VALUE_SUCCESS",
);
export const setDiscountValueError = createAction<PayloadError[]>(
	"@subscription/SET_DISCOUNT_VALUE_ERROR",
);

export const getAllDiscountsRequest = createAction(
	"@subscription/GET_ALL_DISCOUNTS_REQUEST",
);
export const getAllDiscountsSuccess = createAction<{
	discounts: DiscountData[];
}>("@subscription/GET_ALL_DISCOUNTS_SUCCESS");
export const getAllDiscountsError = createAction<PayloadError[]>(
	"@subscription/GET_ALL_DISCOUNTS_ERROR",
);
