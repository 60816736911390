import { call, put, takeEvery, ForkEffect } from "redux-saga/effects";
import {
	addClassRequest,
	addClassSuccess,
	addClassError,
	getClassesRequest,
	getClassesSuccess,
	getClassesError,
	editClassRequest,
	editClassSuccess,
	editClassError,
	deleteClassRequest,
	deleteClassSuccess,
	deleteClassError,
} from "../actions";
import { Alerter } from "../../../../common/utils";
import {
	IResponseWithCustomValue,
	IResponseWithoutValue,
} from "../../../../common/types";
import { ClassType } from "../../types";
import API from "../../../../common/api/api.base";
import { getClassesForLevelsRequest } from "../../../../redux/actions/classes.actions";

function* workerGetClasses(action: ReturnType<typeof getClassesRequest>) {
	try {
		const result: IResponseWithCustomValue<{ classes: ClassType[] }> =
			yield call(
				API.get,
				`/api/v1/classes?SearchQuery=${action.payload.searchQuery}`,
			);
		if (result.success) {
			yield put(getClassesSuccess(result.value));
		} else {
			yield put(getClassesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerAddClass(action: ReturnType<typeof addClassRequest>) {
	try {
		const newFormData = new FormData();
		newFormData.append("imageFile", action.payload.imageFile as Blob);
		const result: IResponseWithCustomValue<{ value: number }> = yield call(
			API.post,
			`/api/v1/classes/add?Name=${action.payload.name}&Complexity=${action.payload.complexity}&Lessons=${action.payload.lessons}&Hours=${action.payload.hours}&Description=${action.payload.description}`,
			newFormData,
		);
		if (result.success) {
			yield put(addClassSuccess());
			yield put(getClassesForLevelsRequest());
			yield workerGetClasses(
				getClassesRequest({ searchQuery: action.payload.searchQuery }),
			);
		} else {
			yield put(addClassError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerEditClass(action: ReturnType<typeof editClassRequest>) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			`/api/v1/classes/${action.payload.params.id}/update?Name=${action.payload.params.name}&Complexity=${action.payload.params.complexity}&Lessons=${action.payload.params.lessons}&Hours=${action.payload.params.hours}&Description=${action.payload.params.description}`,
			action.payload.imageFile,
		);
		if (result.success) {
			yield put(editClassSuccess());
			yield workerGetClasses(
				getClassesRequest({
					searchQuery: action.payload.params.searchQuery,
				}),
			);
			Alerter.success("Successfully edited");
		} else {
			yield put(editClassError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerDeleteClass(action: ReturnType<typeof deleteClassRequest>) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.delete,
			`/api/v1/classes/${action.payload.id}/delete`,
		);
		if (result.success) {
			yield put(deleteClassSuccess());
			yield workerGetClasses(
				getClassesRequest({ searchQuery: action.payload.searchQuery }),
			);
			Alerter.success("Successfully deleted");
		} else {
			yield put(deleteClassError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchSubscriptionClassesSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(addClassRequest, workerAddClass);
	yield takeEvery(getClassesRequest, workerGetClasses);
	yield takeEvery(editClassRequest.type, workerEditClass);
	yield takeEvery(deleteClassRequest.type, workerDeleteClass);
}
