import { call, put, takeEvery, ForkEffect, select } from "redux-saga/effects";
import { RootStore } from "./../../../../redux";
import { quantity } from "./../../../../common/constants/quantity";
import API from "../../../../common/api/api.base";
import { Alerter } from "../../../../common/utils";
import {
	IResponseWithCustomValue,
	IResponseWithoutValue,
} from "../../../../common/types";
import { StudentNamedSubscriptionType, DiscountData } from "../../types";
import {
	getAllSubscriptionsRequest,
	getAllSubscriptionsSuccess,
	getAllSubscriptionsError,
	getMoreAllSubscriptionsRequest,
	getMoreAllSubscriptionsSuccess,
	getMoreAllSubscriptionsError,
	setDiscountValueRequest,
	setDiscountValueSuccess,
	setDiscountValueError,
	getAllDiscountsRequest,
	getAllDiscountsSuccess,
	getAllDiscountsError,
} from "../actions";

function* workerGetStudentNamedSubscriptions() {
	try {
		const result: IResponseWithCustomValue<{
			subscriptions: StudentNamedSubscriptionType[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/subscriptions/all?PageSize=${quantity.STUDENT_NAMED_SUBSCRIPTIONS_TAKE}`,
		);
		if (result.success) {
			yield put(getAllSubscriptionsSuccess(result.value));
		} else {
			yield put(getAllSubscriptionsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreStudentNamedSubscriptions() {
	try {
		const page: number = yield select(
			(state: RootStore) => state.subscription.subscriptions.page,
		);
		const result: IResponseWithCustomValue<{
			subscriptions: StudentNamedSubscriptionType[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/subscriptions/all?PageSize=${quantity.STUDENT_NAMED_SUBSCRIPTIONS_TAKE}&PageNumber=${page}`,
		);
		if (result.success) {
			yield put(getMoreAllSubscriptionsSuccess(result.value));
		} else {
			yield put(getMoreAllSubscriptionsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetAllDiscounts() {
	try {
		const result: IResponseWithCustomValue<{ discounts: DiscountData[] }> =
			yield call(API.get, "/api/v1/discounts");
		if (result.success) {
			yield put(getAllDiscountsSuccess(result.value));
		} else {
			yield put(getAllDiscountsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerSetDiscount(
	action: ReturnType<typeof setDiscountValueRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			"/api/v1/discounts/update-siblings-discount",
			{ value: action.payload },
		);
		if (result.success) {
			yield put(setDiscountValueSuccess());
			Alerter.success("Discount successfully changed");
		} else {
			yield put(setDiscountValueError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchSubscriptionSubscriptionsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getAllSubscriptionsRequest.type, workerGetStudentNamedSubscriptions);
	yield takeEvery(getMoreAllSubscriptionsRequest.type, workerGetMoreStudentNamedSubscriptions);
	yield takeEvery(setDiscountValueRequest.type, workerSetDiscount);
	yield takeEvery(getAllDiscountsRequest.type, workerGetAllDiscounts);
}
