import { useEffect } from "react";
import {
	DashboardCardHeader,
	InputPhone,
	InputText,
	RippleButton,
	Loader,
} from "../../../common/components";
import { GridItem, SimpleGrid, Box } from "@chakra-ui/react";
import { generalInfoSchema } from "../../../common/constants/validation/general-info.schema";
import { Resolver, useForm, Controller } from "react-hook-form";
import { EditProfilePayload, GetInfoProfileOnEditRequest } from "../types";

type IInfoCardProps = {
	profileUser: GetInfoProfileOnEditRequest;
	profileLoading: boolean;
	handleUpdateProfile: (
		values: EditProfilePayload,
		imageFile: FormData,
	) => void;
	profileEditLoading: boolean;
};

interface IGeneralInfoForm {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
}

export const InfoCard: React.FC<IInfoCardProps> = ({
	profileUser,
	profileLoading,
	profileEditLoading,
	handleUpdateProfile,
}) => {
	const {
		register,
		reset,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm<IGeneralInfoForm>({
		resolver: generalInfoSchema as Resolver<IGeneralInfoForm>,
	});

	useEffect(() => {
		if (profileUser) {
			reset({
				...profileUser,
			});
		} else {
			reset({});
		}
	}, [profileUser, reset]);

	const onSubmit = (data: IGeneralInfoForm) => {
		const formDate = new FormData();
		formDate.append("imageFile", "");
		handleUpdateProfile(data, formDate);
	};

	return (
		<Box
			p={{ base: "10px 0", lg: "30px 40px 30px 40px" }}
			bg="white"
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			<DashboardCardHeader title="Settings Info" />
			{profileLoading ? (
				<Loader centerHeight="100%" centerProps={{ py: "100%" }} />
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid
						columns={{ base: 1, lg: 2 }}
						columnGap="12px"
						pt="24px">
						<InputText
							{...register("firstName")}
							label={"Your First Name"}
							placeholder={"Your First Name"}
							errorMsg={errors.firstName?.message}
						/>
						<InputText
							{...register("lastName")}
							label={"Your Last Name"}
							placeholder={"Your Last Name"}
							errorMsg={errors.lastName?.message}
						/>
						<InputText
							{...register("email")}
							errorMsg={errors.email?.message}
							label={"Email"}
							placeholder={"Email"}
						/>
						<Controller
							render={({ field }) => (
								<InputPhone
									{...field}
									label="Phone Number"
									errorMsg={errors.phoneNumber?.message}
								/>
							)}
							name="phoneNumber"
							defaultValue={profileUser.phoneNumber}
							control={control}
						/>
						<GridItem
							colStart={{ base: 1, lg: 2 }}
							mt={{ base: "30px", lg: 0 }}>
							<RippleButton
								variant="outline"
								w="100%"
								type="submit"
								isLoading={profileEditLoading}>
								Change
							</RippleButton>
						</GridItem>
					</SimpleGrid>
				</form>
			)}
		</Box>
	);
};
