export const Button = {
	baseStyle: {
		fontWeight: "bold",
		fontSize: "16px",
		lineHeight: "22px",
		borderRadius: "8px",
		paddingTop: "2px",
	},
	sizes: {
		md: {
			height: "50px",
		},
	},
	variants: {
		outline: {
			border: "none",
			boxShadow: "inset 0px 0px 0px 2px #F0E8f8",
			borderColor: "secondary",
			color: "primary",
			_hover: {
				bg: "secondary",
			},
			_active: {
				bg: "secondary",
			},
		},
		solid: {
			bg: "primary",
			color: "white",
			_hover: {
				bg: "primaryHovered",
			},
			_active: {
				bg: "primaryHovered",
			},
		},
		link: {
			fontWeight: "normal",
			fontSize: "16px",
			lineHeight: "21px",
			_hover: {
				color: "primaryHovered",
				textDecoration: "none",
			},
		},
		ghost: {
			fontSize: "12px",
			lineHeight: "22px",
			color: "primary",
			height: "30px",
			paddingRight: 0,
			paddingLeft: 0,
			_hover: {
				textDecoration: "underline",
				bg: "trasparent",
			},
		},
		plain: {
			fontSize: "16px",
			lineHeight: "22px",
			color: "primary",
			paddingRight: 0,
			paddingLeft: 0,
			_hover: {
				bg: "trasparent",
			},
		},
		headerTab: {
			fontSize: "16px",
			lineHeight: "22px",
			color: "primary",
			paddingRight: 0,
			paddingLeft: 0,
			height: "35px",
			borderRadius: "0",
			borderBottom: "2px solid transparemt",
		},
	},
	// The default size and variant values
	defaultProps: {
		size: "md",
	},
};
