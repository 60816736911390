import { createReducer } from "@reduxjs/toolkit";
import {
	getParentsRequest,
	getParentsSuccess,
	getParentsError,
	getMoreParentsRequest,
	getMoreParentsSuccess,
	getMoreParentsError,
	clearDashboard,
	setPermanentDiscountRequest,
	setPermanentDiscountSuccess,
	setPermanentDiscountError,
} from "../actions";
import { ParentResponseType } from "../../types";
import { PayloadError } from "../../../../common/types";

type InitialState = {
	loading: boolean;
	items: ParentResponseType[];
	errors: PayloadError[];
	totalCount: number;
	loadMoreLoading: boolean;
	page: number;
};

const initialState: InitialState = {
	loading: false,
	items: [],
	errors: [],
	totalCount: 0,
	loadMoreLoading: false,
	page: 1,
};

const parentsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getParentsRequest, (state) => {
			state.loading = true;
			state.errors = [];
		})
		.addCase(getParentsSuccess, (state, action) => {
			state.loading = false;
			state.totalCount = action.payload.totalCount;
			state.items = action.payload.users;
			state.page = 1;
		})
		.addCase(getParentsError, (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		})
		//Get More Parents
		.addCase(getMoreParentsRequest, (state) => {
			state.loadMoreLoading = true;
		})
		.addCase(getMoreParentsSuccess, (state, action) => {
			state.loadMoreLoading = false;
			state.items = [...state.items, ...action.payload.users];
			state.totalCount = action.payload.totalCount;
			state.page++;
		})
		.addCase(getMoreParentsError, (state) => {
			state.loadMoreLoading = false;
		})
		.addCase(setPermanentDiscountRequest, (state, action) => {
			state.items = state.items.map((elem) => {
				if (elem.userId === action.payload.userId) {
					return {
						...elem,
						isDiscountApplicable:
							action.payload.isDiscountApplicable,
					};
				} else {
					return elem;
				}
			});
		})
		// .addCase(setPermanentDiscountSuccess)
		.addCase(setPermanentDiscountError, (state, action) => {
			state.items = state.items.map((elem) => {
				if (elem.userId === action.payload.userId) {
					return {
						...elem,
						isDiscountApplicable:
							!action.payload.isDiscountApplicable,
					};
				} else {
					return elem;
				}
			});
		})
		.addCase(clearDashboard, () => {
			return { ...initialState };
		});
});

export default parentsReducer;
