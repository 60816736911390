/* eslint-disable indent */
import { useState, useEffect } from "react";
import { Attendance } from "./Attendance";
import {
	SelectDateModal,
	SelectMonthModal,
	SelectWeekModal,
	SelectYearModal,
} from "./modals";
import { useDispatch, useSelector } from "react-redux";
import {
	getAttendanceClassesRequest,
	getAttendanceStudentsRequest,
	getMoreAttendanceStudentsRequest,
} from "./store/actions";
import { RootStore } from "../../redux";
import { SelectedClass } from "./types";
import dayjs from "dayjs";
import { useDelta } from "react-delta";

export const AttendanceContainer: React.FC = () => {
	const dispatch = useDispatch();
	const [showSelectDateModal, setShowSelectDateModal] = useState(false);
	const [showSelectMonthModal, setShowSelectMonthModal] = useState(false);
	const [showSelectWeekModal, setShowSelectWeekModal] = useState(false);
	const [showSelectYearModal, setShowSelectYearModal] = useState(false);
	const [selectedClass, setSelectedClass] = useState<SelectedClass>("all");

	const [afterDate, setAfterDate] = useState(dayjs.utc().format());
	const [beforeDate, setBeforeDate] = useState(dayjs.utc().format());
	const [filterMode, setFilterMode] = useState("daily");

	const { items: classes, loading: classesLoading } = useSelector(
		(state: RootStore) => state.attendance.classes,
	);
	const { absentCount, presentCount, dayOffCount } = useSelector(
		(state: RootStore) => state.attendance,
	);
	const {
		items: studentItems,
		loading: studentsLoading,
		loadMoreLoading: studentsLoadMoreLoading,
		totalCount: studentsTotalCount,
	} = useSelector((state: RootStore) => state.attendance.students);

	const selectedClassDelta = useDelta(selectedClass);

	useEffect(() => {
		if (typeof selectedClass === "string") {
			dispatch(
				getAttendanceClassesRequest(selectedClass === "all" ? 2 : 1),
			);
		}
	}, [selectedClass]);

	useEffect(() => {
		dispatch(
			getAttendanceStudentsRequest({
				subscriptionPlanId:
					selectedClass === "all" || selectedClass === "archived"
						? 0
						: selectedClass,
				afterDate,
				beforeDate,
			}),
		);
	}, [afterDate, beforeDate]);

	useEffect(() => {
		if (selectedClassDelta && selectedClassDelta.prev) {
			if (selectedClassDelta.curr) {
				dispatch(
					getAttendanceStudentsRequest({
						subscriptionPlanId:
							selectedClass === "all" ||
							selectedClass === "archived"
								? 0
								: selectedClass,
						afterDate,
						beforeDate,
					}),
				);
			}
		}
	}, [selectedClassDelta]);

	useEffect(() => {
		switch (filterMode) {
			case "daily":
				setAfterDate(dayjs.utc().format());
				setBeforeDate(dayjs.utc().format());
				break;
			case "weekly":
				setAfterDate(dayjs.utc().startOf("w").format());
				setBeforeDate(dayjs.utc().endOf("w").format());
				break;
			case "monthly":
				setAfterDate(
					dayjs
						.utc(
							new Date(
								new Date().getFullYear(),
								new Date().getMonth(),
								2,
							),
						)
						.format(),
				);
				setBeforeDate(
					dayjs
						.utc(
							new Date(
								new Date().getFullYear(),
								new Date().getMonth() + 1,
								1,
							),
						)
						.format(),
				);
				break;
			case "yearly":
				setAfterDate(
					dayjs
						.utc(new Date(new Date().getUTCFullYear(), 0, 2))
						.format(),
				);
				setBeforeDate(
					dayjs
						.utc(new Date(new Date().getUTCFullYear(), 11, 32))
						.format(),
				);
				break;
			default:
				break;
		}
	}, [filterMode]);

	const loadMoreStudents = () =>
		dispatch(
			getMoreAttendanceStudentsRequest({
				subscriptionPlanId:
					selectedClass === "all" || selectedClass === "archived"
						? 0
						: selectedClass,
				afterDate,
				beforeDate,
			}),
		);

	return (
		<>
			<Attendance
				setShowSelectDateModal={setShowSelectDateModal}
				setShowSelectMonthModal={setShowSelectMonthModal}
				setShowSelectWeekModal={setShowSelectWeekModal}
				setShowSelectYearModal={setShowSelectYearModal}
				classItems={classes}
				classesLoading={classesLoading}
				setSelectedClass={setSelectedClass}
				selectedClass={selectedClass}
				filterMode={filterMode}
				setFilterMode={setFilterMode}
				afterDate={afterDate}
				beforeDate={beforeDate}
				absentCount={absentCount}
				presentCount={presentCount}
				dayOffCount={dayOffCount}
				studentItems={studentItems}
				studentsLoading={studentsLoading}
				studentsLoadMoreLoading={studentsLoadMoreLoading}
				studentsTotalCount={studentsTotalCount}
				loadMoreStudents={loadMoreStudents}
			/>
			<SelectDateModal
				isOpen={showSelectDateModal}
				handleClose={() => setShowSelectDateModal(false)}
				setAfterDate={setAfterDate}
				setBeforeDate={setBeforeDate}
				afterDate={afterDate}
			/>
			<SelectMonthModal
				isOpen={showSelectMonthModal}
				handleClose={() => setShowSelectMonthModal(false)}
				setAfterDate={setAfterDate}
				setBeforeDate={setBeforeDate}
				afterDate={afterDate}
			/>
			<SelectWeekModal
				isOpen={showSelectWeekModal}
				handleClose={() => setShowSelectWeekModal(false)}
				setAfterDate={setAfterDate}
				setBeforeDate={setBeforeDate}
				afterDate={afterDate}
			/>
			<SelectYearModal
				isOpen={showSelectYearModal}
				handleClose={() => setShowSelectYearModal(false)}
				setAfterDate={setAfterDate}
				setBeforeDate={setBeforeDate}
				afterDate={afterDate}
			/>
		</>
	);
};
