import { useRef } from "react";
import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	IconButton,
	Box,
	Flex,
	Heading,
	Text,
	HStack,
	Tag,
} from "@chakra-ui/react";
import { ReactComponent as CloseIcon } from "../../../assets/ic_close.svg";
import { RippleButton } from "../../../common/components";
import { VacancyAlertCardContent } from "../types";

type IVacancyAlert = {
	isOpen: boolean;
	handleClose: () => void;
	firstDescription: string;
	secondDescription: string;
	confirmLabel: string;
	title: string;
	onConfirm?: () => void;
	confirmLoading?: boolean;
	createdVacancy: VacancyAlertCardContent | null;
};

export const VacancyAlert: React.FC<IVacancyAlert> = ({
	confirmLabel,
	firstDescription,
	handleClose,
	isOpen,
	secondDescription,
	title,
	confirmLoading,
	onConfirm,
	createdVacancy,
}) => {
	const cancelRef = useRef();
	return (
		<AlertDialog
			leastDestructiveRef={cancelRef.current}
			isOpen={isOpen}
			onClose={handleClose}
			isCentered
			motionPreset={"slideInBottom"}>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius={"16px"}>
					<Flex justify="space-between" align="center" pt="24px">
						<AlertDialogHeader py={0}>
							<Heading variant="h6" color="darkest">
								{title}
							</Heading>
						</AlertDialogHeader>
						<IconButton
							aria-label="close alert dialog"
							icon={<CloseIcon />}
							onClick={handleClose}
							variant="ghost"
							mr="1.5rem"
						/>
					</Flex>
					<Box py="24px">
						<AlertDialogBody>
							{createdVacancy && (
								<Box
									border="1px solid #EBEBEB"
									borderRadius="16px"
									p="16px">
									<Text color="grey" variant="body3">
										{createdVacancy.team}
									</Text>
									<Text color="darkest" variant="body1">
										{createdVacancy.title}
									</Text>
									<HStack my="12px">
										<Tag
											bg="lightBlue"
											color="onBlue"
											height="30px"
											px="15px"
											borderRadius="15px"
											fontSize="12px"
											lineHeight="22px"
											fontWeight="normal">
											{createdVacancy.location}
										</Tag>
										<Tag
											bg="lightBlue"
											color="onBlue"
											height="30px"
											px="15px"
											borderRadius="15px"
											fontSize="12px"
											lineHeight="22px"
											fontWeight="normal">
											{createdVacancy.team}
										</Tag>
										<Tag
											bg="lightBlue"
											color="onBlue"
											height="30px"
											px="15px"
											borderRadius="15px"
											fontSize="12px"
											lineHeight="22px"
											fontWeight="normal">
											{createdVacancy.commitment}
										</Tag>
									</HStack>
								</Box>
							)}
							<Box my="24px">
								<Text variant="body2" color="darkest">
									{firstDescription}
								</Text>
							</Box>
							{/*Info Text2*/}
							<Box mb="24px">
								<Text variant="inputText" color="darkgray">
									{secondDescription}
								</Text>
							</Box>
							{/*Buttons*/}
							<Flex justifyContent="space-between">
								<RippleButton
									w="48%"
									variant="outline"
									onClick={handleClose}
									ref={cancelRef.current}>
									Cancel
								</RippleButton>
								<RippleButton
									w="48%"
									onClick={onConfirm}
									isLoading={confirmLoading}>
									{confirmLabel}
								</RippleButton>
							</Flex>
						</AlertDialogBody>
					</Box>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
