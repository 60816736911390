/* eslint-disable indent */
import { useEffect, useState, useRef } from "react";
import { Box, Text, Button, HStack } from "@chakra-ui/react";
import { monthLabels } from "../../constants";
import dayjs from "dayjs";
import { WorkingHoursType } from "../../types";

type ICalendarProps = {
	selectedYear: number;
	selectedDay: string;
	setSelectedDay: (value: string) => void;
	selectedMonth: number;
	setSelectedMonth: (value: number) => void;
	selectedClassWorkingHours?: WorkingHoursType[];
};

export const CalendarHeader: React.FC<ICalendarProps> = ({
	selectedYear,
	selectedDay,
	selectedMonth,
	setSelectedDay,
	setSelectedMonth,
	selectedClassWorkingHours,
}) => {
	const [toHighlightDays, setToHighlightDays] = useState<number[]>([]);
	const monthRef = useRef<HTMLDivElement>(null);
	const daysRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const workDaysAvailable: number[] = [];
		const monthDays: number[] = [];
		const toHighlight: number[] = [];
		// Filter what days are not off`s, and have some time set up
		selectedClassWorkingHours?.forEach((elem: WorkingHoursType) => {
			if (
				!elem.isDayOff &&
				elem.startTime !== "00:00:00" &&
				elem.endTime !== "00:00:00"
			) {
				workDaysAvailable.push(elem.dayOfWeek);
			}
		});

		// Create array with indexes of days (Sunday = 7, Monday = 1) for month
		for (let i = 0; i < dayjs().daysInMonth(); i++) {
			const d = new Date(selectedYear, selectedMonth, i + 1);
			monthDays.push(dayjs(d).day() === 0 ? 7 : dayjs(d).day());
		}
		// Save indexes for displaying in mapping
		monthDays.forEach((elem, index) => {
			if (workDaysAvailable.includes(elem)) {
				toHighlight.push(index);
			}
		});
		setToHighlightDays(toHighlight);
	}, [selectedMonth, selectedYear, selectedClassWorkingHours]);

	// MonthMouseWheel
	// useEffect(() => {
	// 	const el = monthRef.current;
	// 	const onWheel = (e: WheelEvent) => {
	// 		el?.scrollTo({
	// 			left: el?.scrollLeft + e.deltaY,
	// 			behavior: "smooth",
	// 		});
	// 	};
	// 	if (el) {
	// 		el.addEventListener("wheel", onWheel);
	// 	}
	// 	return () => {
	// 		el?.removeEventListener("wheel", onWheel);
	// 	};
	// }, []);
	// // DaysMouseWheel
	// useEffect(() => {
	// 	const el = daysRef.current;

	// 	const onWheel = (e: WheelEvent) => {
	// 		daysRef?.current?.scrollTo({
	// 			left: daysRef.current.scrollLeft + e.deltaY * 2,
	// 			behavior: "smooth",
	// 		});
	// 	};
	// 	if (el) {
	// 		el.addEventListener("wheel", onWheel);
	// 	}
	// 	return () => {
	// 		el?.removeEventListener("wheel", onWheel);
	// 	};
	// }, []);

	const getBackgroundForDayCircle = (index: number) => {
		if (
			dayjs
				.utc()
				.year(selectedYear)
				.month(selectedMonth)
				.date(index + 1)
				.format("DD-MM-YYYY") ===
			dayjs.utc(selectedDay).format("DD-MM-YYYY")
		) {
			return "primary";
		} else if (
			dayjs
				.utc()
				.year(selectedYear)
				.month(selectedMonth)
				.date(index + 1)
				.format("DD-MM-YYYY") === dayjs.utc().format("DD-MM-YYYY")
		) {
			return "purple";
		} else if (toHighlightDays.includes(index)) {
			return "blue";
		}
		return "";
	};

	const getColorForDayCircle = (index: number) => {
		if (
			dayjs
				.utc()
				.year(selectedYear)
				.month(selectedMonth)
				.date(index + 1)
				.format("DD-MM-YYYY") ===
			dayjs.utc(selectedDay).format("DD-MM-YYYY")
		) {
			return "white";
		} else {
			return "darkest";
		}
	};

	const getDays = () => {
		const days = [];
		for (let i = 0; i < dayjs().month(selectedMonth).daysInMonth(); i++) {
			days.push(
				<Box key={i}>
					<Button
						border={"1px solid #EBEBEB"}
						borderRadius={"50%"}
						variant="unstyled"
						flex="1"
						fontSize="14px"
						lineHeight="24px"
						fontWeight="500"
						color={getColorForDayCircle(i)}
						height="40px"
						onClick={() =>
							setSelectedDay(
								dayjs
									.utc()
									.year(selectedYear)
									.month(selectedMonth)
									.date(i + 1)
									.format(),
							)
						}
						bg={getBackgroundForDayCircle(i)}>
						{dayjs
							.utc()
							.year(selectedYear)
							.month(selectedMonth)
							.date(i + 1)
							.format("D")}
					</Button>
					<Text
						color={
							dayjs
								.utc()
								.year(selectedYear)
								.month(selectedMonth)
								.date(i + 1)
								.format("DD-MM-YYYY") ===
							dayjs.utc(selectedDay).format("DD-MM-YYYY")
								? "primary"
								: ""
						}
						mt={"8px"}
						textAlign={"center"}
						variant={"caption2"}>
						{dayjs()
							.year(selectedYear)
							.month(selectedMonth)
							.date(i + 1)
							.format("ddd")}
					</Text>
				</Box>,
			);
		}
		return days;
	};

	return (
		<Box bg={"#FFFFFF"}>
			<HStack
				ref={monthRef}
				spacing={"40px"}
				p="16px"
				w={{ base: "100vw", lg: "auto" }}
				borderBottom="1px solid #EBEBEB"
				overflowX={"scroll"}
				sx={{
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}>
				{monthLabels.map((label, index) => (
					<Box key={label.toLowerCase()}>
						<Button
							variant={"link"}
							color={selectedMonth === index ? "primary" : ""}
							onMouseDown={() => {
								setSelectedMonth(index);
							}}>
							{label}
						</Button>
					</Box>
				))}
			</HStack>
			<HStack
				sx={{
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}
				ref={daysRef}
				spacing={"16px"}
				overflowX={"scroll"}
				py={"24px"}
				px={"16px"}
				w={{ base: "100vw", lg: "auto" }}>
				{getDays()}
			</HStack>
		</Box>
	);
};
