import {
	getMoreStudentsError,
	getMoreStudentsRequest,
	getMoreStudentsSuccess,
	getStudentsError,
	getStudentsRequest,
	getStudentsSuccess,
} from "../actions";
import { IResponseWithCustomValue } from "../../../../common/types";
import { StudentResponseType } from "../../types";
import { call, ForkEffect, put, select, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { quantity } from "../../../../common/constants";
import { Alerter } from "../../../../common/utils";
import { RootStore } from "../../../../redux";

//Get Students
function* workerGetStudents(action: ReturnType<typeof getStudentsRequest>) {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: StudentResponseType[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.STUDENTS_TAKE}&StaffRole=4${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}&SortByAscending=${action.payload.sortByAscending}${
				action.payload.subscriptionPlanId !== "all" &&
				action.payload.subscriptionPlanId !== "archived"
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getStudentsSuccess(result.value));
		} else {
			yield put(getStudentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}
//Get More Students
function* workerGetMoreStudents(
	action: ReturnType<typeof getMoreStudentsRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.dashboard.students.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: StudentResponseType[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.STUDENTS_TAKE}&PageNumber=${
				page + 1
			}&StaffRole=4${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}&SortByAscending=${action.payload.sortByAscending}${
				action.payload.subscriptionPlanId !== "all" &&
				action.payload.subscriptionPlanId !== "archived"
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getMoreStudentsSuccess(result.value));
		} else {
			yield put(getMoreStudentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchDashboardStudentsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getStudentsRequest.type, workerGetStudents);
	yield takeEvery(getMoreStudentsRequest.type, workerGetMoreStudents);
}
