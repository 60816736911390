import { Center, Image, Link, Flex, Text } from "@chakra-ui/react";
import PlaceholderImg from "../../../../assets/pic_users-placeholder.png";

export const TabPlaceholder: React.FC = () => (
	<Center height="calc(100vh - 390px)">
		<Flex direction="column" align="center">
			<Image src={PlaceholderImg} />
			<Text
				variant="body2"
				color="darkGrey"
				w={{ base: "auto", lg: "400px" }}
				textAlign="center"
				mt="20px">
				There is no one here. Make sure you{" "}
				<Link color="primary" href="/dashboard/subscriptions">
					create classes{" "}
				</Link>
				so that parents and children know about it{" "}
			</Text>
		</Flex>
	</Center>
);
