/* eslint-disable react/display-name */
import {
	createStandaloneToast,
	Text,
	Box,
	HStack,
	Heading,
	Center,
	IconButton,
	Image,
} from "@chakra-ui/react";
import { PayloadError } from "../types/api.types";
import { ReactComponent as SuccessIcon } from "../../assets/ic_present.svg";
import { ReactComponent as FailIcon } from "../../assets/ic_absent.svg";
import CloseIcon from "../../assets/ic_close.svg";
import { theme } from "../theme";

const toast = createStandaloneToast({ theme });

interface IToastMessageProps {
	onClose: () => void;
	variant: "success" | "error";
	title: string;
	description: string;
}

const ToastMessage: React.FC<IToastMessageProps> = ({
	onClose,
	variant,
	title,
	description,
}) => (
	<Box
		border={`2px solid ${variant === "success" ? "#ECFDEF" : "#FDECEC"}`}
		borderRadius="16px"
		p={{ base: "10px", md: "24px" }}
		bg="white"
		boxShadow="0px 6px 24px rgba(59, 41, 2, 0.08);">
		<HStack>
			<Center
				w="56px"
				h="56px"
				borderRadius="50%"
				flexShrink={0}
				bg={variant === "success" ? "green" : "lightRed"}>
				{variant === "success" ? <SuccessIcon /> : <FailIcon />}
			</Center>
			<Box w={{ base: "100%", md: "300px" }}>
				<Heading color="darkest" variant="h7" as="p">
					{title}
				</Heading>
				<Text color="darkGrey" variant="subtitle1">
					{description}
				</Text>
			</Box>
			<IconButton
				aria-label="close notification"
				icon={<Image src={CloseIcon} width="16px" height="16px" />}
				variant="ghost"
				mr="1.5rem"
				onClick={onClose}
				alignSelf="flex-start"
			/>
		</HStack>
	</Box>
);

class AlerterService {
	success(message: string) {
		let toastIdRef: any = null;

		toastIdRef = toast({
			status: "success",
			duration: 5000,
			isClosable: true,
			position: "top-right",
			render: () => (
				<ToastMessage
					onClose={() => {
						if (toastIdRef) {
							toast.close(toastIdRef);
						}
					}}
					variant="success"
					title="Success"
					description={message}
				/>
			),
		});
	}
	error(message: { errors: PayloadError[] } | string) {
		let toastIdRef: any = null;
		if (typeof message === "string") {
			toastIdRef = toast({
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "top-right",
				render: () => (
					<ToastMessage
						onClose={() => {
							if (toastIdRef) {
								toast.close(toastIdRef);
							}
						}}
						variant="error"
						title="Something went wrong!"
						description={message}
					/>
				),
			});
		} else {
			toastIdRef = toast({
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "top-right",
				render: () => (
					<ToastMessage
						onClose={() => {
							if (toastIdRef) {
								toast.close(toastIdRef);
							}
						}}
						variant="error"
						title="Something went wrong!"
						description={message.errors[0].errorMessage}
					/>
				),
			});
		}
	}
	warning(message: string) {
		toast({
			title: "Warning",
			description: message,
			status: "warning",
			duration: 5000,
			isClosable: true,
			position: "top-right",
		});
	}
}
export const Alerter = new AlerterService();
