import React from "react";
import {
	FormControl,
	FormControlProps,
	FormLabel,
	Input,
	FormErrorMessage,
	InputProps,
	Button,
	Flex,
} from "@chakra-ui/react";

interface IInputProps extends InputProps {
	errorMsg?: string;
	label: string;
	showAdditionalButton?: boolean;
	formControlProps?: FormControlProps;
	additionalOnClick?: () => void;
}

export const InputText = React.forwardRef<null, IInputProps>(
	(
		{
			label,
			errorMsg,
			showAdditionalButton,
			formControlProps,
			additionalOnClick,
			...rest
		},
		ref,
	) => (
		<FormControl
			isInvalid={!!errorMsg}
			{...formControlProps}
			mb={!errorMsg ? "29px" : 0}>
			<Flex align="center" justify="space-between">
				<FormLabel
					fontSize="caption2"
					lineHeight="22px"
					color="darkest"
					marginBottom={0}>
					{label}
				</FormLabel>
				{showAdditionalButton && (
					<Button onClick={additionalOnClick} variant="ghost">
						Forgot Password
					</Button>
				)}
			</Flex>
			<Input
				borderRadius="8px"
				paddingInlineStart="12px"
				paddingInlineEnd="12px"
				borderColor="lightGrey"
				color="darkest"
				fontSize="14px"
				lineHeight="22px"
				focusBorderColor="primary"
				_placeholder={{
					color: "grey",
				}}
				ref={ref}
				{...rest}
			/>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	),
);
InputText.displayName = "InputText";
