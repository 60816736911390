import { Box, Grid } from "@chakra-ui/react";
import { DashboardHeader } from "../../common/sections";
import { ClassesCard, SubscriptionsCard } from "./cards";
import { StudentNamedSubscriptionType } from "./types";

type IProps = {
	setShowAddLevelModal: (value: boolean) => void;
	setShowAddClassModal: (value: boolean) => void;
	subscriptionItems: StudentNamedSubscriptionType[];
	subscriptionsLoading: boolean;
	subscriptionsTotalCount: number;
	subscriptionsLoadMoreLoading: boolean;
	loadMoreSubscriptions: () => void;
	showAddLevelModal: boolean;
	showAddClassModal: boolean;
	setSearchQuery: (value: string) => void;
	setTabIndex: (value: number) => void;
	tabIndex: number;
	searchQuery: string;
	planSubscriptionItems: StudentNamedSubscriptionType[];
	planSubscriptionsLoading: boolean;
	planSubscriptionsTotalCount: number;
	planSubscriptionsLoadMoreLoading: boolean;
	selectedPlan: number;
	setSelectedPlan: (value: number) => void;
	loadMorePlanSubscriptions: () => void;
	withArchivedFilter: boolean;
	setWithArchivedFilter: (value: boolean) => void;
};

export const Subscriptions: React.FC<IProps> = ({
	setShowAddLevelModal,
	setShowAddClassModal,
	subscriptionItems,
	subscriptionsLoading,
	subscriptionsTotalCount,
	subscriptionsLoadMoreLoading,
	loadMoreSubscriptions,
	showAddLevelModal,
	showAddClassModal,
	setSearchQuery,
	setTabIndex,
	tabIndex,
	searchQuery,
	planSubscriptionItems,
	planSubscriptionsLoadMoreLoading,
	planSubscriptionsLoading,
	planSubscriptionsTotalCount,
	selectedPlan,
	setSelectedPlan,
	loadMorePlanSubscriptions,
	withArchivedFilter,
	setWithArchivedFilter,
}) => {
	return (
		<Box bg={{ base: "#F9F9FB", lg: "white" }}>
			<DashboardHeader
				title="Subscriptions"
				description="Select classes to get started"
			/>
			<Box
				px={{ base: "20px", lg: "40px" }}
				mt={{ base: 0, lg: "-50px" }}>
				<Grid
					gap={"24px"}
					templateRows={{ base: "max-content auto", lg: "1fr" }}
					templateColumns={{ base: "1fr", lg: "60% 1fr" }}
					height={{ base: "auto", lg: "calc(100vh - 160px)" }}>
					<ClassesCard
						setShowAddLevelModal={setShowAddLevelModal}
						showAddLevelModal={showAddLevelModal}
						setShowAddClassModal={setShowAddClassModal}
						showAddClassModal={showAddClassModal}
						setSearchQuery={setSearchQuery}
						setTabIndex={setTabIndex}
						tabIndex={tabIndex}
						searchQuery={searchQuery}
						selectedPlan={selectedPlan}
						setSelectedPlan={setSelectedPlan}
						withArchivedFilter={withArchivedFilter}
						setWithArchivedFilter={setWithArchivedFilter}
					/>
					<SubscriptionsCard
						items={planSubscriptionItems}
						loadMoreLoading={planSubscriptionsLoadMoreLoading}
						loading={planSubscriptionsLoading}
						totalCount={planSubscriptionsTotalCount}
						loadMoreSubscriptions={loadMorePlanSubscriptions}
					/>
				</Grid>
				<Box
					height="100px"
					width="100%"
					display={{ base: "block", lg: "none" }}
				/>
			</Box>
		</Box>
	);
};
