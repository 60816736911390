import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/en";
import range from "lodash.range";

dayjs.extend(weekday);

export const isLeapYear = (year: number): boolean =>
	(year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export const dateIsBetween = (
	date: number,
	start: number,
	end: number,
): boolean => date > start && date < end;

export const dateIsOut = (date: number, start: number, end: number): boolean =>
	date < start || date > end;

export const formartTime = (value: string): string => `0${value}`.slice(-2);

export const yearsList = (): { value: string; label: string }[] => {
	const year = new Date().getFullYear();
	return Array.from(new Array(10), (val, index) => index + year).map(
		(year) => ({
			value: year.toString(),
			label: year.toString(),
		}),
	);
};

export const getDaysNewWay = (
	selectedMonth: number,
	selectedYear: number,
): number[] => {
	const daysInMonth = dayjs
		.utc(`${selectedYear}-${selectedMonth + 1}-1`)
		.daysInMonth();

	const dayObjOf1 = dayjs
		.utc(`${selectedYear}-${selectedMonth + 1}-1`)
		.hour(0)
		.minute(0)
		.millisecond(0);
	const weekDayOf1 = dayObjOf1.day();

	const dayObjOfLast = dayjs
		.utc(`${selectedYear}-${selectedMonth + 1}-${daysInMonth}`)
		.hour(0)
		.minute(0)
		.millisecond(0);
	const weekDayOfLast = dayObjOfLast.day();
	return [
		...range(weekDayOf1 ? weekDayOf1 : 0).map((i: number) =>
			dayObjOf1.subtract(weekDayOf1 - i, "day").valueOf(),
		),
		...range(daysInMonth).map((i: number) =>
			dayjs
				.utc(`${selectedYear}-${selectedMonth + 1}-${i + 1}`)
				.valueOf(),
		),
		...range(6 - weekDayOfLast).map((i: number) =>
			dayObjOfLast.add(i + 1, "day").valueOf(),
		),
	];
};
