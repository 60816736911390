/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Avatar, Flex } from "@chakra-ui/react";
import { UseFormSetValue } from "react-hook-form";
import { IEditEmployeeForm } from "../edit-employee.modal";
import { ReactComponent as CloseIcon } from "../../../../../assets/ic_white-close.svg";
import { ReactComponent as CameraIcon } from "../../../../../assets/ic_camera.svg";
import { getPictureURL } from "../../../../../common/utils";
import { ImageType } from "../../../../../common/types";

interface IUploadImageProps {
	setValue: UseFormSetValue<IEditEmployeeForm>;
	photo: ImageType;
}

export const UploadImage: React.FC<IUploadImageProps> = ({
	setValue,
	photo,
}) => {
	const [uploadedFile, setUploadedFile] = useState<
		string | ArrayBuffer | null
	>(null);
	const [resImage, setResImage] = useState<ImageType>();

	const onDrop = useCallback((acceptedFiles) => {
		if (Math.round(acceptedFiles[0].size / 1024) < 10240) {
			setUploadedFile(acceptedFiles[0]);
			setValue("imageFile", acceptedFiles[0]);
		} else {
			alert("File is too big");
		}
	}, []);

	useEffect(() => {
		if (photo) {
			setResImage(photo);
		}
	}, [photo]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: false,
	});

	return (
		<>
			{uploadedFile || resImage?.itemHash ? (
				<Box
					position="relative"
					w="120px"
					h="120px"
					margin="10px auto 30px auto">
					<Avatar
						src={
							uploadedFile
								? URL.createObjectURL(uploadedFile)
								: resImage?.itemHash
								? getPictureURL(resImage)
								: ""
						}
						objectFit="cover"
						w="100%"
						h="100%"
						borderRadius="50%"
						border="2px solid #DFCCFA"
					/>
					<Box
						onClick={() => {
							setUploadedFile(null);
							setResImage(undefined);
						}}
						top={"-12px"}
						right={"-10px"}
						position={"absolute"}
						as={"button"}
						w={"24px"}
						height={"24px"}
						borderRadius={"50%"}
						bg={"primary"}
						d={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						p={"4px"}>
						<CloseIcon />
					</Box>
				</Box>
			) : (
				<Flex
					{...getRootProps()}
					w="120px"
					h="120px"
					borderRadius="50%"
					align="center"
					justify="center"
					bg={isDragActive ? "rgb(243, 236, 253,.7)" : ""}
					border="2px solid #DFCCFA"
					margin="10px auto 30px auto">
					<CameraIcon />
					<input {...getInputProps()} />
				</Flex>
			)}
		</>
	);
};
