export const Text = {
	variants: {
		subtitle1: {
			fontSize: "16px",
			lineHeight: "36px",
			fontWeight: "500",
		},
		subtitle2: {
			fontSize: "12px",
			lineHeight: "20px",
			fontWeight: "bold",
		},
		subtitle3: {
			fontSize: "16px",
			lineHeight: "20px",
			fontWeight: "bold",
		},
		body1: {
			fontSize: "18px",
			lineHeight: "30px",
		},
		body2: {
			fontSize: "16px",
			lineHeight: "26px",
		},
		body3: {
			fontSize: "14px",
			lineHeight: "24px",
		},
		caption1: {
			fontSize: "12px",
			lineHeight: "22px",
			fontWeight: "bold",
		},
		caption2: {
			fontSize: "10px",
			lineHeight: "14px",
		},
		caption3: {
			fontSize: "12px",
			lineHeight: "22px",
		},
	},
};
