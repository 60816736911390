import { Box, Grid } from "@chakra-ui/react";
import { DashboardHeader } from "../../common/sections";
import { MessagesHistoryCard, RecipientsCard } from "./cards";
import { SubscriptionPlan } from "../../common/types";
import { SelectedFilters } from "../Dashboard/types";
import { MessageHistoryItemType, SendMessageTo } from "./types";

interface IMessagesProps {
	tabIndex: number;
	setTabIndex: (value: number) => void;
	setShowSendBulkMessageModal: (value: boolean) => void;
	setShowSendMessageModal: (value: boolean) => void;
	searchQuery: string;
	filterClasses: SubscriptionPlan[];
	setSelectedFilters: (value: SelectedFilters) => void;
	selectedFilters: SelectedFilters;
	setSearchQuery: (value: string) => void;
	parentsCount: number;
	staffCount: number;
	setSendMessageTo: (value: SendMessageTo) => void;
	setToShowInfoMessage: (value: MessageHistoryItemType) => void;
	setSortByAscending: (value: boolean) => void;
	sortByAscending: boolean;
}

export const Messages: React.FC<IMessagesProps> = ({
	setTabIndex,
	tabIndex,
	setShowSendBulkMessageModal,
	setShowSendMessageModal,
	searchQuery,
	filterClasses,
	selectedFilters,
	setSelectedFilters,
	setSearchQuery,
	parentsCount,
	staffCount,
	setSendMessageTo,
	setToShowInfoMessage,
	setSortByAscending,
	sortByAscending,
}) => {
	return (
		<Box bg={{ base: "#F9F9FB", lg: "white" }} h="100vh" overflowY="auto">
			<DashboardHeader
				title="Messages"
				description="Send messages to students' parents or your staff"
			/>
			<Box
				px={{ base: "20px", lg: "40px" }}
				mt={{ base: "-30px", lg: "-50px" }}
				width={{ base: "auto", lg: "calc(100vw - 250px)" }}>
				<Grid
					templateColumns={{ base: "auto", lg: "60% 1fr" }}
					templateRows="1fr"
					gridGap="24px">
					<RecipientsCard
						tabIndex={tabIndex}
						setTabIndex={setTabIndex}
						setShowSendBulkMessageModal={
							setShowSendBulkMessageModal
						}
						setShowSendMessageModal={setShowSendMessageModal}
						searchQuery={searchQuery}
						filterClasses={filterClasses}
						selectedFilters={selectedFilters}
						setSelectedFilters={setSelectedFilters}
						setSearchQuery={setSearchQuery}
						parentsCount={parentsCount}
						staffCount={staffCount}
						setSendMessageTo={setSendMessageTo}
					/>
					<MessagesHistoryCard
						setToShowInfoMessage={setToShowInfoMessage}
						setSortByAscending={setSortByAscending}
						sortByAscending={sortByAscending}
					/>
					<Box h="100px" display={{ lg: "none" }} />
				</Grid>
			</Box>
		</Box>
	);
};
