import { createAction } from "@reduxjs/toolkit";
import { ParentResponseType, SelectedFilters } from "../../../Dashboard/types";
import { PayloadError } from "../../../../common/types";

export const getParentsForMessagesRequest = createAction<{
	searchQuery: string;
	subscriptionPlanId: SelectedFilters;
}>("@messages/GET_PARENTS_REQUEST");
export const getParentsForMessagesSuccess = createAction<{
	totalCount: number;
	users: ParentResponseType[];
}>("@messages/GET_PARENTS_SUCCESS");
export const getParentsForMessagesError = createAction<PayloadError[]>(
	"@messages/GET_PARENTS_ERROR",
);

export const getMoreParentsForMessagesRequest = createAction<{
	searchQuery: string;
	subscriptionPlanId: SelectedFilters;
}>("@messages/GET_MORE_PARENTS_REQUEST");
export const getMoreParentsForMessagesSuccess = createAction<{
	totalCount: number;
	users: ParentResponseType[];
}>("@messages/GET_MORE_PARENTS_SUCCESS");
export const getMoreParentsForMessagesError = createAction<PayloadError[]>(
	"@messages/GET_MORE_PARENTS_ERROR",
);
