import { Box, Text, Flex } from "@chakra-ui/react";
import { Loader } from "../Loader/Loader";
import { CustomTag } from "../../../pages/Subscriptions/components";
import { ClassType } from "../../../pages/Subscriptions/types";

interface IClassesBoxProps {
	classesLoading: boolean;
	countSelectedClasses: number;
	classes: ClassType[];
	onSelectedClass: (classItem: { id: number }) => void;
	selectedClasses: { id: number }[];
	counterLabel?: string;
}

export const ClassesBox: React.FC<IClassesBoxProps> = ({
	classesLoading,
	countSelectedClasses,
	classes,
	onSelectedClass,
	selectedClasses,
	counterLabel = "Class",
}) => (
	<Box>
		<Text
			variant="caption2"
			color="primary"
			mb="4px">{`${counterLabel} (${countSelectedClasses})`}</Text>
		<Flex
			flexWrap={"wrap"}
			borderRadius={"8px"}
			border={"1px solid #ECECEC"}
			p={"10px"}>
			{classesLoading ? (
				<Loader centerHeight="100%" centerProps={{ w: "100%" }} />
			) : (
				classes.map((item) => {
					const selected = selectedClasses.find(
						(tag) => tag.id == item.id,
					);
					return (
						<Box key={item.id} mb={"8px"} mr={"8px"}>
							<CustomTag
								onSelectedClass={() => onSelectedClass(item)}
								selected={!!selected}
								value={`${item.name}`}
							/>
						</Box>
					);
				})
			)}
		</Flex>
	</Box>
);
