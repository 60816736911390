import { createReducer } from "@reduxjs/toolkit";
import { SubscriptionPlan } from "../../../../common/types";
import {
	getFilterClassesRequest,
	getFilterClassesSuccess,
	getFilterClassessError,
	clearMessages,
} from "../actions";

type InitialState = {
	items: SubscriptionPlan[];
	loading: boolean;
};

const initialState: InitialState = {
	items: [],
	loading: false,
};

const filterClassesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(getFilterClassesRequest, (state) => {
			state.loading = true;
		})
		.addCase(getFilterClassesSuccess, (state, action) => {
			state.loading = false;
			state.items = action.payload;
		})
		.addCase(getFilterClassessError, (state) => {
			state.loading = false;
		})
		.addCase(clearMessages, () => {
			return { ...initialState };
		});
});
export default filterClassesReducer;
