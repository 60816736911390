import React from "react";
import ReactQuill from "react-quill";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

interface IInputEditorProps {
	onChange: (value: string) => void;
	initValue?: string;
	label: string;
	errorMsg?: string;
	placeholder?: string;
	value?: string;
	defaultValue?: string;
}

const modules = {
	toolbar: [
		[{ header: [1, 2, 3, 4, 5, false] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[{ list: "ordered" }, { list: "bullet" }],
	],
};

export const InputEditor = React.forwardRef<null, IInputEditorProps>(
	(
		{
			initValue,
			label,
			onChange,
			errorMsg,
			placeholder,
			value = "",
			defaultValue,
		},
		ref,
	) => (
		<FormControl isInvalid={!!errorMsg} mb={!errorMsg ? "29px" : 0}>
			<FormLabel
				fontSize="caption2"
				lineHeight="22px"
				color="darkest"
				marginBottom={0}>
				{label}
			</FormLabel>
			<ReactQuill
				onChange={onChange}
				modules={modules}
				placeholder={placeholder}
				defaultValue={defaultValue}
				value={value}
				style={{
					border: `1px solid ${errorMsg ? "#E53E3E" : "transparent"}`,
					boxShadow: errorMsg ? "0 0 0 1px #E53E3E" : "none",
					borderRadius: "8px",
				}}
			/>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	),
);

InputEditor.displayName = "InputEditor";
