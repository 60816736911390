import { AllEffect, ForkEffect, all } from "redux-saga/effects";
import watchApplicationsSaga from "./applications.saga";
import watchVacanciesSaga from "./vacancies.saga";

// prettier-ignore
export default function* watchJobsSaga(): Generator<
	AllEffect<Generator<ForkEffect<never>, void, unknown>>,
	void,
	unknown
	> {
	yield all([
		watchApplicationsSaga(),
		watchVacanciesSaga(),
	]);
}
