import { createAction } from "@reduxjs/toolkit";
import {
	GetInfoProfileOnEditRequest,
	EditProfilePayload,
	UpdatePasswordPayload,
} from "../types";
import { PayloadError } from "../../../common/types";

//GET SETTINGS
export const getUserSettingsRequest = createAction(
	"@settings/GET_USER_SETTINGS_REQUEST",
);
export const getUserSettingsSuccess = createAction<GetInfoProfileOnEditRequest>(
	"@settings/GET_USER_SETTINGS_SUCCESS",
);
export const getUserSettingsError = createAction<PayloadError[]>(
	"@settings/GET_USER_SETTINGS_ERROR",
);

//UPDATE PROFILE PHOTO
export const updateProfilePhotoRequest = createAction<FormData>(
	"@settings/UPDATE_PROFILE_PHOTO_REQUEST",
);
export const updateProfilePhotoSuccess = createAction(
	"@settings/UPDATE_PROFILE_PHOTO_SUCCESS",
);
export const updateProfilePhotoError = createAction(
	"@settings/UPDATE_PROFILE_PHOTO_ERROR",
);

//UPDATE PROFILE INFO
export const updateProfileInfoRequest = createAction<{
	params: EditProfilePayload;
	imageFile: FormData;
}>("@settings/UPDATE_PROFILE_INFO_REQUEST");
export const updateProfileInfoSuccess = createAction(
	"@settings/UPDATE_PROFILE_INFO_SUCCESS",
);
export const updateProfileInfoError = createAction<PayloadError[]>(
	"@settings/UPDATE_PROFILE_INFO_ERROR",
);

//UPDATE PASSWORD
export const updatePasswordRequest = createAction<UpdatePasswordPayload>(
	"@settings/UPDATE_PASSWORD_REQUEST",
);
export const updatePasswordSuccess = createAction(
	"@settings/UPDATE_PASSWORD_SUCCESS",
);
export const updatePasswordError = createAction<PayloadError[]>(
	"@settings/UPDATE_PASSWORD_ERROR",
);

//CLEAR SETTINGS
export const clearSettings = createAction("@settings/CLEAR");
