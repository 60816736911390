export const colors = {
	lightestGrey: "#EBEBEB",
	lightGrey: "#E0E0E0",
	grey: "#A9b0B6",
	darkGrey: "#6E7693",
	darkest: "#1E0E62",
	blue: "#CCF4FA",
	lightBlue: "#ECFBFD",
	onBlue: "#0E3947",
	orange: "#FDF5EC",
	onOrange: "#62310E",
	green: "#ECFDEF",
	onGreen: "#10620E",
	lightRed: "#FDECEC",
	onRed: "#620E0E",
	purple: "#F3ECFD",
	primary: "#8C4BC9",
	primaryHovered: "#A876D6",
	primaryPressed: "rgba(255,255,255, .24)",
	secondary: "#F0E8f8",
	secondaryPressed: "#F7F3FC",
};
