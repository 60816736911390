import { Box } from "@chakra-ui/react";
import {
	DashboardCardHeader,
	InfiniteScroll,
	Loader,
} from "../../../../common/components";
import { VacancyItem } from "../../components";
import { IVacancy } from "../../types";
import { VacanciesPlaceholder } from "../../components/placeholders";

interface IVacanciesCardProps {
	openCreateVacancy: () => void;
	loading: boolean;
	items: IVacancy[];
	setToEditVacancyId: (value: number) => void;
	loadMoreVacancies: () => void;
	loadMoreLoading: boolean;
	totalCount: number;
}

export const VacanciesCard: React.FC<IVacanciesCardProps> = ({
	openCreateVacancy,
	items,
	loadMoreLoading,
	loading,
	totalCount,
	loadMoreVacancies,
	setToEditVacancyId,
}) => (
	<Box
		p={{ base: "10px 0", lg: "30px 40px 10px 40px" }}
		bg={{ base: "transparent", lg: "#FFFFFF" }}
		borderRadius="16px"
		boxShadow={{ base: "none", lg: "base" }}>
		<DashboardCardHeader
			buttonTitle="Create"
			onClick={openCreateVacancy}
			title="Vacancies"
			forceWhiteText={true}
		/>
		<Box height="calc(100vh - 250px)">
			{loading ? (
				<Loader centerHeight="100%" />
			) : (
				<>
					{items.length ? (
						<InfiniteScroll
							onReachBottom={() => {
								if (
									!loadMoreLoading &&
									totalCount > items.length
								) {
									loadMoreVacancies();
								}
							}}>
							{items.map((vacancy) => (
								<VacancyItem
									key={vacancy.id}
									onEdit={() =>
										setToEditVacancyId(vacancy.id)
									}
									{...vacancy}
								/>
							))}
							{loadMoreLoading && <Loader spinnerSize="md" />}
						</InfiniteScroll>
					) : (
						<VacanciesPlaceholder
							setShowCreateVacancyModal={openCreateVacancy}
						/>
					)}
				</>
			)}
		</Box>
	</Box>
);
