import {
	Modal,
	InputText,
	InputSelect,
	InputNumber,
	RippleButton,
} from "../../../../common/components";
import {
	ModalBody,
	ModalFooter,
	Box,
	Flex,
	chakra,
	Avatar,
	Center,
} from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "../../../../assets/ic_info.svg";
import { StudentResponseType } from "../../types";
import { getPictureURL } from "../../../../common/utils";

interface IStudentInfoModal {
	handleClose: () => void;
	isOpen: boolean;
	setShowSubscriptionInfoModal: (value: boolean) => void;
	studentInfo: StudentResponseType;
}

export const StudentInfoModal: React.FC<IStudentInfoModal> = ({
	handleClose,
	isOpen,
	setShowSubscriptionInfoModal,
	studentInfo,
}) => {
	return (
		<Modal
			handleClose={handleClose}
			isOpen={isOpen}
			title="Student Info"
			size={"md"}>
			<ModalBody>
				<Box>
					<Center>
						<Avatar
							w="120px"
							h="120px"
							borderRadius="50%"
							border="2px solid #DFCCFA"
							name={studentInfo.name}
							src={getPictureURL(studentInfo.photo)}
							margin="10px auto 30px auto"
						/>
					</Center>
					<InputText
						label="Students Name"
						placeholder="Student Name"
						value={studentInfo.name}
						isReadOnly={true}
					/>
					<Flex>
						<InputNumber
							label="Students Age"
							value={studentInfo.age}
							isReadOnly={true}
							pointerEvents="none"
							tabIndex={-1}
						/>
						<Box w="20px" />
						<InputSelect
							label="Students Grade"
							options={Array(12)
								.fill("")
								.map((_elem, index) => ({
									value: index + 1 + "",
									label: index + 1 + "",
									selected:
										index + 1 === studentInfo.grade
											? true
											: false,
								}))}
							isReadOnly={true}
							pointerEvents="none"
							tabIndex={-1}
						/>
					</Flex>
					<InputText
						label="Parent"
						placeholder="Parent"
						value={studentInfo.parentFullName}
						isReadOnly={true}
					/>
					<InputText
						label="Class"
						placeholder="Class"
						value={studentInfo.paidSubscriptionPlanNames.join(", ")}
						isReadOnly={true}
					/>
				</Box>
			</ModalBody>
			<ModalFooter>
				<RippleButton
					variant="outline"
					width="100%"
					onClick={() => {
						setShowSubscriptionInfoModal(true);
					}}>
					<chakra.span mr="10px">Subscription</chakra.span>{" "}
					<InfoIcon />
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
