/* eslint-disable indent */
export const rightsRoutes = {
	admin: "/dashboard/main",
	teacher: "/dashboard/teacher-attendance",
};

export function getInitialAccessRoute(role: string): string {
	switch (role) {
		case "admin":
			return "/dashboard/main";
		case "teacher":
			return "/dashboard/teacher-attendance";
		default:
			return "/dashboard/settings";
	}
}
