/* eslint-disable indent */
import { Box, Text, Flex, Button, VStack } from "@chakra-ui/react";
import { SlimVacancyApplication } from "../types";
import { ReactComponent as InfoIcon } from "../../../assets/ic_info.svg";
import { ReactComponent as ResumeIcon } from "../../../assets/ic_dashboard-tab.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux";

interface IApplicationItem extends SlimVacancyApplication {
	onShowMore: () => void;
}

export const ApplicationItem: React.FC<IApplicationItem> = ({
	email,
	fullName,
	id,
	phone,
	resumeHash,
	onShowMore,
}) => {
	const token = useSelector((state: RootStore) => state.session.accessToken);

	const getExtension = (blobType: string) => {
		switch (blobType) {
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				return ".docx";
			case "application/msword":
				return ".doc";
			case "application/pdf":
				return ".pdf";
			default:
				return "";
		}
	};

	const downloadFile = async () => {
		try {
			const result = await axios.get(
				process.env.REACT_APP_BASE_URL +
					"/api/v1/job-applications/resumes/" +
					resumeHash,
				{
					responseType: "blob",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);
			const url = window.URL.createObjectURL(new Blob([result.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				resumeHash + getExtension(result.data.type),
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			alert(error);
		}
	};

	return (
		<Box borderBottom="1px solid #EBEBEB" py="24px">
			<Flex
				justify="space-between"
				direction={{ base: "column", lg: "row" }}>
				<Box>
					<Text color="darkest" variant="body1">
						{fullName}
					</Text>
					<Text color="grey" variant="body3">
						{email}
					</Text>
					<Text color="grey" variant="body3">
						{phone}
					</Text>
				</Box>
				<VStack>
					<Button
						variant="plain"
						height="30px"
						alignSelf="flex-start"
						leftIcon={<InfoIcon />}
						onClick={onShowMore}>
						More Details
					</Button>
					{!!resumeHash && (
						<Button
							variant="plain"
							height="30px"
							alignSelf="flex-start"
							leftIcon={<ResumeIcon />}
							onClick={downloadFile}>
							Download Resume
						</Button>
					)}
				</VStack>
			</Flex>
		</Box>
	);
};
