import { Box, Text, Button, Tag, Flex } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from "../../../assets/ic_edit.svg";
import { IVacancy } from "../types";

interface IVacancyItemProps extends IVacancy {
	onEdit: () => void;
}

export const VacancyItem: React.FC<IVacancyItemProps> = ({
	commitment,
	location,
	team,
	title,
	onEdit,
}) => (
	<Box borderBottom="1px solid #EBEBEB" py={{ base: "10px", lg: "24px" }}>
		<Flex justify="space-between" align="flex-start">
			<Box>
				<Text color="grey" variant="body3">
					{team.name}
				</Text>
				<Text color="darkest" variant="body1">
					{title}
				</Text>
			</Box>
			<Button
				leftIcon={<EditIcon />}
				variant="plain"
				color="darkGrey"
				flexShrink={0}
				onClick={onEdit}>
				Edit
			</Button>
		</Flex>
		<Flex
			wrap="wrap"
			my={{ base: 0, lg: "12px" }}
			mt={{ base: "12px", lg: 0 }}>
			<Tag
				bg="lightBlue"
				color="onBlue"
				height="30px"
				px="15px"
				borderRadius="15px"
				fontSize="12px"
				lineHeight="22px"
				fontWeight="normal"
				mb="10px"
				mr="15px">
				{location}
			</Tag>
			<Tag
				bg="lightBlue"
				color="onBlue"
				height="30px"
				px="15px"
				borderRadius="15px"
				fontSize="12px"
				lineHeight="22px"
				fontWeight="normal"
				mb="10px"
				mr="15px">
				{team.name}
			</Tag>
			<Tag
				bg="lightBlue"
				color="onBlue"
				height="30px"
				px="15px"
				borderRadius="15px"
				fontSize="12px"
				lineHeight="22px"
				fontWeight="normal"
				mb="10px"
				mr="15px">
				{commitment.name}
			</Tag>
		</Flex>
	</Box>
);
