import { Login } from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../redux/actions/auth.actions";
import { LoginPayload } from "../../common/types";
import { SubmitHandler } from "react-hook-form";
import { RootStore } from "../../redux";
import { useHistory } from "react-router-dom";

export const LoginContainer: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const loading = useSelector((state: RootStore) => state.auth.loading);
	const errors = useSelector((state: RootStore) => state.auth.errors);

	const onSubmit: SubmitHandler<LoginPayload> = (data) => {
		dispatch(loginRequest(data));
	};
	const goToRestorePassword = () => {
		history.push("/forgot-password");
	};

	return (
		<Login
			onSubmit={onSubmit}
			loading={loading}
			errors={errors}
			goToRestorePassword={goToRestorePassword}
		/>
	);
};
