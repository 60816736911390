import { useState, useEffect } from "react";
import { Modal } from "../../../../common/components";
import {
	ModalBody,
	Tabs,
	Text,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";
import { AllocateLevelsTab, InvoicesTab } from "./tabs";
import { useDispatch } from "react-redux";
import {
	getPendingInvoicesRequest,
	clearManualInvoicesState,
} from "../../../../redux/actions/manual-invoices.actions";

interface IManualInvoicesModalProps {
	handleClose: () => void;
	isOpen: boolean;
}

export const ManualInvoicesModal: React.FC<IManualInvoicesModalProps> = ({
	handleClose,
	isOpen,
}) => {
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState(0);

	useEffect(() => {
		if (tabIndex === 1) {
			dispatch(getPendingInvoicesRequest());
		}
	}, [tabIndex]);

	useEffect(() => {
		if (!isOpen) {
			dispatch(clearManualInvoicesState());
			setTabIndex(0);
		}
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			title="Manual Invoices"
			handleClose={handleClose}
			size="4xl">
			<ModalBody>
				<Tabs variant="unstyled" index={tabIndex}>
					<TabList borderBottom="1px solid #EBEBEB">
						<Tab
							onClick={() => setTabIndex(0)}
							_selected={{ borderBottom: "2px solid #8C4BC9" }}
							borderBottom="2px solid transparent">
							<Text
								fontSize="16px"
								lineHeight="21px"
								color="primary">
								Allocate levels
							</Text>
						</Tab>
						<Tab
							onClick={() => setTabIndex(1)}
							_selected={{ borderBottom: "2px solid #8C4BC9" }}
							borderBottom="2px solid transparent">
							<Text
								fontSize="16px"
								lineHeight="21px"
								color="primary">
								Invoices
							</Text>
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel p="0" h="100%">
							<AllocateLevelsTab handleClose={handleClose} />
						</TabPanel>
						<TabPanel h="100%" p="0">
							<InvoicesTab handleClose={handleClose} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</ModalBody>
		</Modal>
	);
};
