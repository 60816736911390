interface ISelectArrowIconProps {
	fill?: string;
}

export const SelectArrowIcon: React.FC<ISelectArrowIconProps> = ({
	fill = "#1E0E62",
}) => {
	return (
		<svg
			width="14"
			height="5"
			viewBox="0 0 14 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.16634 0.583374L4.99967 5.58337L0.833008 0.583374H9.16634Z"
				fill={fill}
			/>
		</svg>
	);
};
