import PlaceholderPic from "../../../../assets/pic_payment_method.png";
import { Image, Box, Center, Text, chakra } from "@chakra-ui/react";

export const ClassesPlaceholder: React.FC<{ createClass: () => void }> = ({
	createClass,
}) => (
	<Center h="calc(100vh - 300px)">
		<Box>
			<Image src={PlaceholderPic} />
			<Text mt="20px" textAlign="center" variant="body2" color="darkGrey">
				There are no Classes. <br /> Go to{" "}
				<chakra.span color="primary" as="button" onClick={createClass}>
					Create classes
				</chakra.span>
			</Text>
		</Box>
	</Center>
);
