import { useState } from "react";
import {
	Modal,
	InputSelect,
	RippleButton,
} from "../../../../common/components";
import {
	ModalBody,
	ModalFooter,
	Button,
	Box,
	SimpleGrid,
} from "@chakra-ui/react";
import { months } from "../../../../common/constants";
import dayjs from "dayjs";

interface ISelectMonthModal {
	handleClose: () => void;
	isOpen: boolean;
	setAfterDate: (value: string) => void;
	setBeforeDate: (value: string) => void;
	afterDate: string;
}

export const SelectMonthModal: React.FC<ISelectMonthModal> = ({
	handleClose,
	isOpen,
	setAfterDate,
	setBeforeDate,
	afterDate,
}) => {
	const [selectedMonth, setSelectedMonth] = useState(
		dayjs(afterDate).month(),
	);
	const [selectedYear, setSelectedYear] = useState(dayjs().year());

	const selectMonth = () => {
		setAfterDate(
			dayjs.utc(new Date(selectedYear, selectedMonth, 2)).format(),
		);
		setBeforeDate(
			dayjs.utc(new Date(selectedYear, selectedMonth + 1, 1)).format(),
		);
	};

	return (
		<Modal handleClose={handleClose} isOpen={isOpen} title="Select Month">
			<ModalBody>
				<Box px={{ base: "0px", lg: "30px" }}>
					<InputSelect
						label=""
						options={Array(15)
							.fill("")
							.map((_elem, index) => ({
								value: 2014 + index + "",
								label: 2014 + index + "",
							}))}
						placeholder="Year"
						value={selectedYear}
						onChange={(ev) =>
							setSelectedYear(parseInt(ev.target.value))
						}
						w="32%"
					/>
					<SimpleGrid columns={3} spacing="10px" my="30px">
						{months.map((month) => (
							<Button
								key={month.value}
								variant="unstyled"
								fontSize="14px"
								lineHeight="22px"
								color="darkest"
								border={`1px solid ${
									selectedMonth === month.value
										? "#8C4BC9"
										: "#EBEBEB"
								}`}
								fontWeight="500"
								height="46px"
								onClick={() => setSelectedMonth(month.value)}>
								{month.label}
							</Button>
						))}
					</SimpleGrid>
				</Box>
			</ModalBody>
			<ModalFooter>
				<RippleButton
					variant="outline"
					width="100%"
					onClick={handleClose}>
					Cancel
				</RippleButton>
				<Box w="20px" />
				<RippleButton
					onClick={() => {
						selectMonth();
						handleClose();
					}}
					width="100%">
					Select
				</RippleButton>
			</ModalFooter>
		</Modal>
	);
};
