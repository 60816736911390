import { call, put, takeEvery, ForkEffect } from "redux-saga/effects";
import {
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordError,
} from "../../actions/auth.actions";
import API from "../../../common/api/api.base";
import { IResponseWithoutValue } from "../../../common/types";
import { Alerter } from "../../../common/utils";

function* workerForgotPassword(
	action: ReturnType<typeof forgotPasswordRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/auth/admin/restore-password/send",
			action.payload,
		);
		if (result.success) {
			yield put(forgotPasswordSuccess());
			Alerter.success(result.messages[0].messageText);
		} else {
			yield put(forgotPasswordError(result.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchForgotPasswordSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(forgotPasswordRequest.type, workerForgotPassword);
}
