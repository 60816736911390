import React, { useEffect, useState } from "react";
import { sliceIntoChunks, getDaysNewWay } from "../../utils";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import dayjs from "dayjs";
import { InputSelect } from "../InputSelect/InputSelect";
import { monthLabels, dayLabels } from "../../constants";

const yearsList = () => {
	const year = dayjs.utc().year();
	return Array.from(new Array(10), (val, index) => index + year).map(
		(yearValue) => ({
			value: yearValue.toString(),
			label: yearValue.toString(),
		}),
	);
};

interface CalendarForDatePicker {
	setValue: (value: number) => void;
	onClose: () => void;
	value?: number;
}

export const CalendarForDatePicker = React.forwardRef<
	null,
	CalendarForDatePicker
>(({ setValue, onClose, value }, ref) => {
	const [selectedItem, setSelectedItem] = useState<number[] | number | null>(
		null,
	);
	const [selectedMonth, setSelectedMonth] = useState(
		dayjs.utc(value).month(),
	);
	const [selectedYear, setSelectedYear] = useState(
		dayjs.utc(value).year() < dayjs.utc().year()
			? dayjs.utc().year()
			: dayjs.utc(value).year(),
	);

	useEffect(() => {
		if (typeof selectedItem === "number") {
			setValue(selectedItem);
			onClose();
		}
	}, [selectedItem]);

	return (
		<Box
			p={"16px"}
			borderRadius={"16px"}
			boxShadow={"0px 6px 16px rgba(59, 41, 2, 0.16)"}
			bg={"#FFFFFF"}>
			{/*Month List Header*/}
			<Flex justifyContent={"space-between"}>
				<Box w={"55%"}>
					<InputSelect
						label={""}
						options={monthLabels.map((month, indx) => ({
							value: indx.toString(),
							label: month,
						}))}
						onChange={(ev) => {
							setSelectedMonth(+ev.target.value);
						}}
						defaultValue={selectedMonth}
					/>
				</Box>
				<Box w={"40%"}>
					<InputSelect
						name="test1"
						label={""}
						options={yearsList()}
						defaultValue={selectedYear}
						onChange={(ev) => setSelectedYear(+ev.target.value)}
					/>
				</Box>
			</Flex>
			<Flex pb="16px" borderBottom="1px solid #EBEBEB">
				{dayLabels.map((label) => (
					<Box
						className="rlc-day-label"
						key={label.toLowerCase()}
						flex="1">
						<Text
							variant="body3"
							color="darkGrey"
							textAlign="center">
							{label.slice(0, 3)}
						</Text>
					</Box>
				))}
			</Flex>
			<Box>
				{sliceIntoChunks(
					getDaysNewWay(selectedMonth, selectedYear),
					7,
				).map((week: number[], index: number) => (
					<Flex key={index} borderRadius="8px">
						{week.map((day: number) => (
							<Button
								variant="unstyled"
								key={day}
								flex="1"
								fontSize="14px"
								lineHeight="24px"
								fontWeight="500"
								color={
									dayjs
										.utc()
										.month(selectedMonth)
										.year(selectedYear)
										.format("MMMM YYYY") ===
									dayjs.utc(day).format("MMMM YYYY")
										? "darkest"
										: "darkGrey"
								}
								height="40px"
								onClick={() => {
									setSelectedItem(day);
								}}
								bg={day === selectedItem ? "purple" : ""}>
								{dayjs.utc(day).format("D")}
							</Button>
						))}
					</Flex>
				))}
			</Box>
		</Box>
	);
});

CalendarForDatePicker.displayName = "CalendarForDatePicker";
