import React from "react";
import {
	FormControl,
	FormLabel,
	FormControlProps,
	FormErrorMessage,
} from "@chakra-ui/react";
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";

interface IInputPriceProps extends NumberFormatPropsBase {
	label: string;
	errMessage?: string;
	formControlProps?: FormControlProps;
}

export const InputPrice = React.forwardRef<null, IInputPriceProps>(
	({ label, errMessage, formControlProps, ...rest }, ref) => (
		<FormControl
			isInvalid={!!errMessage}
			mb={!errMessage ? "29px" : 0}
			{...formControlProps}>
			<FormLabel
				fontSize="caption2"
				lineHeight="22px"
				color="darkest"
				marginBottom={0}>
				{label}
			</FormLabel>
			<NumberFormat
				className={`input-price ${
					errMessage ? "input-price-error" : ""
				}`}
				{...rest}
				ref={ref}
			/>
			<FormErrorMessage>{errMessage}</FormErrorMessage>
		</FormControl>
	),
);
InputPrice.displayName = "InputPrice";
