import { createAction } from "@reduxjs/toolkit";
import {
	SendIndividualMessagePayload,
	SendBulkMessagePayload,
} from "../../types";
import { PayloadError } from "../../../../common/types";

export const sendBulkMessageRequest = createAction<SendBulkMessagePayload>(
	"@messages/SEND_BULK_MESSAGE_REQUEST",
);
export const sendBulkMessageSuccess = createAction(
	"@messages/SEND_BULK_MESSAGE_SUCCESS",
);
export const sendBulkMessageError = createAction<PayloadError[]>(
	"@messages/SEND_BULK_MESSAGE_ERROR",
);

export const sendIndividualMessageRequest =
	createAction<SendIndividualMessagePayload>(
		"@messages/SEND_INDIVIDUAL_MESSAGE_REQUEST",
	);
export const sendIndividualMessageSuccess = createAction(
	"@messages/SEND_INDIVIDUAL_MESSAGE_SUCCESS",
);
export const sendIndividualMessageError = createAction<PayloadError[]>(
	"@messages/SEND_INDIVIDUAL_MESSAGE_ERROR",
);
