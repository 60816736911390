import { Box, Flex, Text, Avatar, Button } from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "../../../assets/ic_info.svg";
import { ParentResponseType } from "../types";
import { getPictureURL } from "../../../common/utils";

interface IParentItemProps extends ParentResponseType {
	setShowParentInfoModal: (value: boolean) => void;
	setParentInfo: (value: ParentResponseType) => void;
}

export const ParentItem: React.FC<IParentItemProps> = ({
	setShowParentInfoModal,
	email,
	firstName,
	image,
	lastName,
	students,
	userId,
	isDiscountApplicable,
	setParentInfo,
	phoneNumber,
}) => (
	<Flex
		justify="space-between"
		borderBottom="1px solid #EBEBEB"
		py="16px"
		pr="16px">
		<Flex>
			<Avatar
				w="48px"
				h="48px"
				name={`${firstName} ${lastName}`}
				src={getPictureURL(image)}
				borderRadius="8px"
				mr="16px"
			/>
			<Box>
				<Text variant="body1" color="darkest">
					{`${firstName} ${lastName}`}
				</Text>
				<Text variant="body3" color="grey">
					{email}
				</Text>
			</Box>
		</Flex>
		<Button
			leftIcon={<InfoIcon />}
			variant="plain"
			onClick={() => {
				setParentInfo({
					email,
					firstName,
					image,
					lastName,
					students,
					userId,
					isDiscountApplicable,
					phoneNumber,
				});
				setShowParentInfoModal(true);
			}}>
			Info
		</Button>
	</Flex>
);
