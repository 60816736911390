import React, { useRef } from "react";
import { useRipple } from "react-use-ripple";
import { Button, ButtonProps, useMergeRefs } from "@chakra-ui/react";

export const RippleButton = React.forwardRef<null, ButtonProps>(
	(props, ref) => {
		const rippleRef = useRef(null);
		useRipple(rippleRef, {
			animationLength: 500,
			rippleColor:
				props.variant === "outline"
					? "#F7F3FC"
					: "rgba(255,255,255, .24)",
		});
		const refs = useMergeRefs(ref, rippleRef);
		return <Button ref={refs} {...props} />;
	},
);

RippleButton.displayName = "RippleButton";
