import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { StaffItem } from "../../../Dashboard/types";

export const getStaffForMessagesRequest = createAction<{
	searchQuery: string;
}>("@messages/GET_STAFF_REQUEST");
export const getStaffForMessagesSuccess = createAction<{
	totalCount: number;
	users: StaffItem[];
}>("@messages/GET_STAFF_SUCCESS");
export const getStaffForMessagesError = createAction<PayloadError[]>(
	"@messages/GET_STAFF_ERROR",
);

export const getMoreStaffForMessagesRequest = createAction<{
	searchQuery: string;
}>("@messages/GET_MORE_STAFF_REQUEST");
export const getMoreStaffForMessagesSuccess = createAction<{
	totalCount: number;
	users: StaffItem[];
}>("@messages/GET_MORE_STAFF_SUCCESS");
export const getMoreStaffForMessagesError = createAction<PayloadError[]>(
	"@messages/GET_MORE_STAFF_ERROR",
);
