import PlaceholderPic from "../../../../assets/pic_payment.png";
import { Center, VStack, Image, Text } from "@chakra-ui/react";

export const ClassesPlaceholder: React.FC = () => (
	<Center h={"calc(100% - 100px)"}>
		<VStack>
			<Image src={PlaceholderPic} />
			<Text variant="body2" color="darkGrey" textAlign="center" mt="20px">
				There are no Classes. <br /> Contact Administrator to add it.
			</Text>
		</VStack>
	</Center>
);
