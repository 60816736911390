import { useEffect } from "react";
import { Modal, RippleButton, DatePicker } from "../../../../common/components";
import { ModalBody, ModalFooter, Box } from "@chakra-ui/react";
import { EditDateInfo } from "../../types";
import { Resolver, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { editLevelDatesSchema } from "../../../../common/constants/validation";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { useDelta } from "react-delta";
import { editLevelDatesRequest } from "../../store/actions";

interface IEditLevelDatesModalProps {
	handleClose: () => void;
	isOpen: boolean;
	toEditDatesData: EditDateInfo;
}

interface IEditLevelDatesForm {
	startDate: number;
	endDate: number;
}

export const EditLevelDatesModal: React.FC<IEditLevelDatesModalProps> = ({
	handleClose,
	isOpen,
	toEditDatesData,
}) => {
	const dispatch = useDispatch();
	const {
		register,
		formState: { errors },
		setValue,
		handleSubmit,
		clearErrors,
		getValues,
		reset,
	} = useForm<IEditLevelDatesForm>({
		resolver: editLevelDatesSchema as Resolver<IEditLevelDatesForm>,
	});

	const { loading, errors: editErrors } = useSelector(
		(state: RootStore) => state.subscription.levels.editDates,
	);

	const loadingDelta = useDelta(loading);

	useEffect(() => {
		if (isOpen && toEditDatesData) {
			reset({
				startDate: dayjs.utc(toEditDatesData.startDate).valueOf(),
				endDate: dayjs.utc(toEditDatesData.endDate).valueOf(),
			});
		}
	}, [isOpen, toEditDatesData]);

	useEffect(() => {
		if (loadingDelta && loadingDelta.prev) {
			if (loadingDelta.prev && !loadingDelta.curr && !editErrors.length) {
				handleClose();
			}
		}
	}, [loadingDelta]);

	const onSubmit = (data: IEditLevelDatesForm) => {
		dispatch(
			editLevelDatesRequest({
				id: toEditDatesData.id,
				startDate: dayjs.utc(data.startDate).format(),
				endDate: dayjs.utc(data.endDate).format(),
			}),
		);
	};

	const [stDate, endDate] = getValues(["startDate", "endDate"]);
	return (
		<Modal
			handleClose={handleClose}
			isOpen={isOpen}
			title="Edit level dates">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<DatePicker
						{...register("startDate")}
						value={stDate}
						label="Start date"
						setValue={(value: number) => {
							setValue("startDate", value);
							clearErrors("startDate");
						}}
						errMessage={errors.startDate?.message}
						placeholder="Start Date"
						displayValue={
							stDate
								? dayjs.utc(stDate).format("DD MMMM, YYYY")
								: ""
						}
					/>
					<DatePicker
						{...register("endDate")}
						label="End date"
						setValue={(value: number) => {
							setValue("endDate", value);
							clearErrors("endDate");
						}}
						value={endDate}
						errMessage={errors.endDate?.message}
						placeholder="End Date"
						displayValue={
							endDate
								? dayjs.utc(endDate).format("DD MMMM, YYYY")
								: ""
						}
					/>
				</ModalBody>
				<ModalFooter>
					<RippleButton
						w="100%"
						variant="outline"
						onClick={handleClose}>
						Cancel
					</RippleButton>
					<Box w="20px" />
					<RippleButton type="submit" w="100%" isLoading={loading}>
						Save
					</RippleButton>
				</ModalFooter>
			</form>
		</Modal>
	);
};
