import { useState, useCallback, useEffect } from "react";
import {
	Modal,
	InputText,
	InputTextArea,
	InputSelect,
	InputNumber,
} from "../../../../common/components";
import {
	ModalBody,
	Box,
	Flex,
	Text,
	Button,
	HStack,
	ModalFooter,
} from "@chakra-ui/react";
import { ImageUpload } from "./components";
import { useForm, Resolver } from "react-hook-form";
import { addClassSchema } from "../../../../common/constants/validation";
import { ImageType } from "../../../../common/types";

export interface IAddClassForm {
	name: string;
	complexity: number;
	lessons: number;
	hours: number;
	description: string;
	imageFile: string | Blob | ImageType;
}

interface IAddClassModalProps {
	handleClose: () => void;
	isOpen: boolean;
	addClass: (data: IAddClassForm) => void;
}

export const AddClassModal: React.FC<IAddClassModalProps> = ({
	handleClose,
	isOpen,
	addClass,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		reset,
	} = useForm<IAddClassForm>({
		resolver: addClassSchema as Resolver<IAddClassForm>,
	});

	const [uploadedPhoto, setUploadedPhoto] = useState<
		string | ArrayBuffer | null
	>(null);
	const onDrop = useCallback((acceptedFiles) => {
		if (Math.round(acceptedFiles[0].size / 1024) < 10240) {
			setUploadedPhoto(acceptedFiles[0]);
			setValue("imageFile", acceptedFiles[0]);
		} else {
			alert("File is too big");
		}
	}, []);

	const onSubmit = (data: IAddClassForm) => {
		addClass(data);
	};

	useEffect(() => {
		if (!isOpen) {
			reset({});
			setUploadedPhoto(null);
		}
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Add Class"
			size="5xl">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<HStack w="100%" spacing="24px" align="baseline">
						<Box w="50%" h="100%">
							<Box borderBottom="1px solid #EBEBEB">
								<Text color="darkest" variant="subtitle1">
									Image
								</Text>
							</Box>
							<ImageUpload
								image={{
									id: 0,
									extension: null,
									itemHash: null,
								}}
								uploadedPhoto={uploadedPhoto}
								onDrop={onDrop}
							/>
						</Box>
						<Box w="50%">
							<InputText
								{...register("name")}
								label="Class Name"
								placeholder="Class Name"
								errorMsg={errors.name?.message}
							/>
							<HStack mb="29px">
								<InputSelect
									{...register("complexity")}
									label="Complexity"
									options={[
										{ label: "Introductory", value: "1" },
										{ label: "Intermediate", value: "2" },
										{ label: "Advanced", value: "3" },
									]}
									removeBottomMargin={true}
									formControlProps={{ width: "60%" }}
									errorMsg={errors.complexity?.message}
								/>
								<InputNumber
									{...register("lessons")}
									label="Lessons"
									formControlProps={{ w: "100px" }}
									min={0}
									step={1}
									errorMsg={errors.lessons?.message}
									defaultValue={1}
								/>
								<InputNumber
									{...register("hours")}
									label="Hours"
									formControlProps={{ w: "100px" }}
									min={0}
									step={1}
									errorMsg={errors.hours?.message}
									defaultValue={1}
								/>
							</HStack>
							<InputTextArea
								{...register("description")}
								label="Class Description"
								placeholder="Class Description"
								h="130px"
								errorMsg={errors.description?.message}
							/>
						</Box>
					</HStack>
				</ModalBody>
				<ModalFooter>
					<Flex w="100%" borderTop="1px solid #E0E0E0" pt="16px">
						<Box w="50%" pr="20px">
							<Text variant="caption3" color="darkest">
								Once published, you will be able to choose the
								class for your levels. You can always edit the
								level information by clicking on the Edit
								button.
							</Text>
						</Box>
						<Box w="50%" pl="12px">
							<Button w="100%" type="submit" pl="12px">
								Publish
							</Button>
						</Box>
					</Flex>
				</ModalFooter>
			</form>
		</Modal>
	);
};
