/* eslint-disable indent */
import {
	Button,
	ButtonGroup,
	Text,
	Flex,
	Box,
	Avatar,
	HStack,
} from "@chakra-ui/react";
import { ImageType } from "../../../common/types";
import { getPictureURL } from "../../../common/utils";
import dayjs from "dayjs";
import { ReactComponent as InfoIcon } from "../../../assets/ic_info.svg";

type IStudent = {
	name: string;
	setShowStudentInfo: () => void;
	photo: ImageType;
	onChangeStatus: (value: number) => void;
	presentStatus: number;
	absentStatus: number;
	dayOffStatus: number;
	selectedDay: string;
};
const buttons = [
	{ label: "Present", status: 2 },
	{ label: "Absent", status: 4 },
	{ label: "Day Off", status: 8 },
];

const pickBackground = (
	index: number,
	presentStatus: number,
	absentStatus: number,
	dayOffStatus: number,
) => {
	switch (index) {
		case 0:
			if (presentStatus) {
				return { bg: "green", color: "onGreen" };
			}
			break;
		case 1:
			if (absentStatus) {
				return { bg: "orange", color: "onOrange" };
			}
			break;
		case 2:
			if (dayOffStatus) {
				return { bg: "blue", color: "onBlue" };
			}
			break;
		default:
			return { bg: "", color: "primary" };
	}
};

export const Student: React.FC<IStudent> = ({
	name,
	photo,
	setShowStudentInfo,
	onChangeStatus,
	presentStatus,
	absentStatus,
	dayOffStatus,
	selectedDay,
}) => {
	return (
		<>
			<Flex
				alignItems={{ base: "flex-start", lg: "center" }}
				py={"20px"}
				w="100%"
				justify="space-between"
				borderBottom={"1px solid #EBEBEB"}
				direction={{ base: "column", lg: "row" }}>
				{/*	Avatar*/}
				<HStack>
					<Avatar
						mr={"12px"}
						w={"40px"}
						h={"40px"}
						name={name}
						borderRadius={"50%"}
						border={"1px solid #8C4BC9"}
						src={getPictureURL(photo)}
					/>
					<Box mr={"auto"}>
						<Text>{name}</Text>
						<Button
							onClick={setShowStudentInfo}
							height={"0"}
							variant={"ghost"}
							_focus={{ outline: "none" }}>
							Get Info
						</Button>
					</Box>
				</HStack>
				{/*	Student Status*/}

				{dayjs(selectedDay).isAfter(dayjs()) ? (
					<HStack color="darkGrey">
						<Text color="darkGrey" variant="body2">
							The lesson has not started
						</Text>
						<InfoIcon />
					</HStack>
				) : (
					<ButtonGroup
						variant={"outline"}
						spacing={"0"}
						width={{ base: "100%", lg: "auto" }}
						my={{ base: "15px", lg: "0" }}>
						{buttons.map((item, index) => (
							<Button
								borderRightRadius={index === 0 ? "none" : ""}
								borderRadius={index === 1 ? "none" : "8px"}
								borderLeftRadius={index === 2 ? "none" : ""}
								height={"32px"}
								fontSize={"subtitle2"}
								onClick={() => {
									onChangeStatus(item.status);
								}}
								key={item.label}
								bg={
									pickBackground(
										index,
										presentStatus,
										absentStatus,
										dayOffStatus,
									)?.bg
								}
								width="100%"
								color={
									pickBackground(
										index,
										presentStatus,
										absentStatus,
										dayOffStatus,
									)?.color || "primary"
								}>
								{item.label}
							</Button>
						))}
					</ButtonGroup>
				)}
			</Flex>
		</>
	);
};
