import { Image, Box } from "@chakra-ui/react";

type IImageSelectedProps = {
	selected: boolean;
	onSelected: () => void;
	path: string;
	alt: string;
};

export const ImageSelected: React.FC<IImageSelectedProps> = ({
	selected,
	onSelected,
	path,
	alt,
}) => {
	return (
		<Box
			onClick={onSelected}
			w={"80px"}
			h={"80px"}
			borderRadius={"16px"}
			border={selected ? "2px solid #8C4BC9" : ""}
			cursor={"pointer"}
			overflow={"hidden"}>
			<Image src={path} alt={alt} objectFit="cover" />
		</Box>
	);
};
