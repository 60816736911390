/* eslint-disable indent */
import { Box, Center, Flex, Image, Text, Button } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { getPictureURL } from "../../../../../common/utils";
import { ImageType } from "../../../../../common/types";

interface IImageUploadProps {
	image: ImageType;
	onDrop: (acceptedFiles: any) => void;
	uploadedPhoto: string | ArrayBuffer | null;
}

export const ImageUpload: React.FC<IImageUploadProps> = ({
	image,
	uploadedPhoto,
	onDrop,
}) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	return (
		<Box
			{...getRootProps()}
			border="1px dashed #8C4BC9"
			borderRadius="8px"
			height="230px"
			bg={isDragActive ? "secondary" : ""}
			mt="15px">
			<Center h="100%" py={{ base: "10px", lg: 0 }}>
				<input {...getInputProps()} />
				<Flex direction="column" alignItems="center">
					<Image
						w="88px"
						h="88px"
						borderRadius="50%"
						border="1px solid #DFCCFA"
						bg="#F3ECFD"
						src={
							uploadedPhoto
								? URL.createObjectURL(uploadedPhoto)
								: image.itemHash
								? getPictureURL(image)
								: ""
						}
					/>
					{isDragActive ? (
						<Text
							variant="subtitle3"
							color="darkest"
							h="50px"
							lineHeight="50px">
							Drop the file here
						</Text>
					) : (
						<Button variant="plain">Upload Photo</Button>
					)}
				</Flex>
			</Center>
		</Box>
	);
};
