import {
	Tag,
	TagLabel,
	Text,
	Box,
	Flex,
	Heading,
	Grid,
	Avatar,
	Wrap,
	WrapItem,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
} from "@chakra-ui/react";
import { SubscriptionPlan } from "../../../common/types";
import { formattedDate } from "../helpers";
import { getPictureURL } from "../../../common/utils";
import { ReactComponent as MoreIcon } from "../../../assets/ic_more-vert.svg";

type IProps = {
	item: SubscriptionPlan;
	setSelectedClass: () => void;
	setShowArchiveAlert: (value: boolean) => void;
	setShowRemoveAlert: (value: boolean) => void;
	onEditLevel: () => void;
	onEditDates: () => void;
	selectedPlan: number;
	setSelectedPlan: (value: number) => void;
};

export const LevelItem: React.FC<IProps> = ({
	item,
	setSelectedClass,
	setShowArchiveAlert,
	setShowRemoveAlert,
	onEditLevel,
	onEditDates,
	selectedPlan,
	setSelectedPlan,
}) => {
	const stDate = formattedDate(item.startDate);
	const endDate = formattedDate(item.endDate);

	return (
		<>
			<Flex
				flexShrink={0}
				p={"24px"}
				border={`1px solid ${
					selectedPlan === item.id ? "#8C4BC9" : "#F5EEFE"
				}`}
				borderRadius={"8px"}
				mr={{ base: "20px", lg: "0" }}
				my={"24px"}
				bg="white">
				<Grid
					templateColumns={{
						base: "300px auto",
						lg: "auto 1fr auto",
					}}
					templateRows={{
						base: "auto auto auto auto",
						lg: "auto auto auto",
					}}
					w="100%">
					<Box
						sx={{
							gridArea: {
								base: " 1 / 1 / 2 / 2",
								lg: "1 / 1 / 3 / 2",
							},
						}}
						pr="20px">
						<Avatar
							objectFit={"cover"}
							alt={"Course picture"}
							width={"104px"}
							height={"104px"}
							borderRadius="8px"
							background={
								item.image.extension ? "transparent" : undefined
							}
							name={item.name}
							src={getPictureURL(item.image)}
						/>
					</Box>
					<Box
						sx={{
							gridArea: {
								base: "1 / 2 / 2 / 3",
								lg: "1 / 2 / 2 / 3",
							},
						}}>
						<Heading
							variant={"h6"}
							as="button"
							onClick={() => {
								// if (item.id === selectedPlan) {
								// 	setSelectedPlan(0);
								// } else {
								setSelectedPlan(item.id);
								// }
							}}>
							{item.name}
						</Heading>
						<Text fontSize={12} color={"darkgrey"}>
							{`Grade ${item.minGrade} - ${item.maxGrade}`}
						</Text>
					</Box>
					<Box
						sx={{
							gridArea: {
								base: "4 / 2 / 5 / 3",
								lg: "1 / 3 / 2 / 4",
							},
						}}
						justifySelf="end">
						<Box d={"flex"} alignItems={"center"}>
							{/* <EditIcon /> */}
							<Menu strategy="fixed">
								<MenuButton
									as={IconButton}
									aria-label={"More"}
									variant={"outline"}
									icon={<MoreIcon />}
									boxShadow="none"
								/>
								<MenuList minW={"150px"}>
									<MenuItem
										onClick={() => {
											setSelectedClass();
											if (
												item.isPossibleToDelete ||
												item.isFree
											) {
												setShowRemoveAlert(true);
											} else {
												setShowArchiveAlert(true);
											}
										}}>
										<Text color="darkest">
											{item.isPossibleToDelete ||
											item.isFree
												? "Delete"
												: "Archive"}
										</Text>
									</MenuItem>
									<MenuItem onClick={onEditLevel}>
										<Text color="darkest">Edit level</Text>
									</MenuItem>
									<MenuItem onClick={onEditDates}>
										<Text color="darkest">Edit dates</Text>
									</MenuItem>
								</MenuList>
							</Menu>
						</Box>
					</Box>
					<Flex
						sx={{
							gridArea: {
								base: "2 / 1 / 3 / 2",
								lg: "2 / 2 / 3 / 4",
							},
						}}
						pt={{ base: "20px", lg: 0 }}>
						<Box>
							<Text variant={"caption1"}>Start Date</Text>
							<Text fontSize={"inputText"} color={"darkgray"}>
								{`${stDate.day} ${stDate.month}, ${stDate.year}`}
							</Text>
						</Box>
						<Box ml={"32px"}>
							<Text variant={"caption1"}>End Date</Text>
							<Text fontSize={"inputText"} color={"darkgray"}>
								{`${endDate.day} ${endDate.month}, ${endDate.year}`}
							</Text>
						</Box>
					</Flex>
					<Box
						sx={{
							gridArea: {
								base: "3 / 1 / 4 / 2",
								lg: "3 / 2 / 4 / 3",
							},
						}}
						textAlign={"start"}>
						<Text mb={"8px"} variant={"caption1"} color={"gray"}>
							Programming
						</Text>
						{/* Need refactoring! */}
						<Wrap>
							{item.classes.map((name) => (
								<WrapItem key={name.id}>
									<Tag
										bg={"#ECFBFD"}
										borderRadius={"full"}
										variant={"solid"}
										color={"blue.600"}>
										<TagLabel fontSize={12}>
											{name.name}
										</TagLabel>
									</Tag>
								</WrapItem>
							))}
						</Wrap>
					</Box>
					<Box
						mt={"8px"}
						sx={{
							gridArea: {
								base: "4 / 1 / 5 / 2",
								lg: "3 / 3 / 4 / 4",
							},
						}}
						textAlign={{ base: "start", lg: "right" }}>
						{item.isFree ? (
							<Text variant="subtitle1" color="primary" mr="10px">
								FREE
							</Text>
						) : (
							<>
								<Text variant={"subtitle2"} color={"primary"}>
									${item.price.toFixed(2)}
								</Text>
								<Text variant={"caption2"} color={"gray"}>
									per month
								</Text>
							</>
						)}
					</Box>
					{/* )} */}
				</Grid>
			</Flex>
		</>
	);
};
