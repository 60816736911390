import { useState } from "react";
import { ParentsTab } from "./ParentsTab";
import { ParentResponseType } from "../../types";
import { RootStore } from "../../../../redux";
import { useSelector } from "react-redux";
import { defaultParent } from "../../../../common/constants";
import { ParentInfoModal } from "../../modals";

interface IParentsTabContainerProps {
	getMoreParents: () => void;
}

export const ParentsTabContainer: React.FC<IParentsTabContainerProps> = ({
	getMoreParents,
}) => {
	const [parentInfo, setParentInfo] =
		useState<ParentResponseType>(defaultParent);
	const [showParentInfoModal, setShowParentInfoModal] = useState(false);
	const {
		loading: parentsLoading,
		items: parentItems,
		totalCount: parentsTotalCount,
		loadMoreLoading: parentsLoadMoreLoading,
	} = useSelector((state: RootStore) => state.dashboard.parents);

	return (
		<>
			<ParentsTab
				getMoreParents={getMoreParents}
				parentItems={parentItems}
				parentsLoadMoreLoading={parentsLoadMoreLoading}
				parentsLoading={parentsLoading}
				parentsTotalCount={parentsTotalCount}
				setParentInfo={setParentInfo}
				setShowParentInfoModal={setShowParentInfoModal}
			/>
			<ParentInfoModal
				isOpen={showParentInfoModal}
				handleClose={() => {
					setShowParentInfoModal(false);
					setParentInfo(defaultParent);
				}}
				parentInfo={parentInfo}
			/>
		</>
	);
};
