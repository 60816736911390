import { useState, useEffect } from "react";
import {
	Box,
	Heading,
	ModalFooter,
	Center,
	Text,
	HStack,
} from "@chakra-ui/react";
import {
	TypeAhead,
	InfiniteScroll,
	RippleButton,
	ClassesBox,
} from "../../../../../../common/components";
import { useDebounce } from "../../../../../../common/utils";
import API from "../../../../../../common/api/api.base";
import { StudentItem } from "./components";
import { ParentResponseType } from "../../../../../Dashboard/types";
import { IResponseWithCustomValue } from "../../../../../../common/types";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../../../redux";
import { useDelta } from "react-delta";
import { createManualSubscriptionRequest } from "../../../../../../redux/actions/manual-invoices.actions";

interface IAllocateLevelsTabProps {
	handleClose: () => void;
}

export const AllocateLevelsTab: React.FC<IAllocateLevelsTabProps> = ({
	handleClose,
}) => {
	const dispatch = useDispatch();
	const [selectedParent, setSelectedParent] =
		useState<ParentResponseType | null>(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [typeAheadItems, setTypeAheadItems] = useState<
		{ label: string; value: string; parentInfo: ParentResponseType }[]
	>([]);
	const [typeaheadLoading, setTypeaheadLoading] = useState(false);
	const [selectedStudentsIds, setSelectedStudentsIds] = useState<number[]>(
		[],
	);
	const [selectedLevels, setSelectedLevels] = useState<{ id: number }[]>([]);
	const [resetKey, setResetKey] = useState(1);

	const { loading: levelsLoading, items: levels } = useSelector(
		(state: RootStore) => state.subscription.levels.levels,
	);
	const { loading: saveLoading, errors } = useSelector(
		(state: RootStore) => state.manualInvoices.createSubscription,
	);

	const saveLoadingDelta = useDelta(saveLoading);

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const onSelectStudent = (value: number) => {
		if (selectedStudentsIds.includes(value)) {
			setSelectedStudentsIds(
				selectedStudentsIds.filter((elem) => elem !== value),
			);
		} else {
			setSelectedStudentsIds([...selectedStudentsIds, value]);
		}
	};

	const onSelectLevel = (levelItem: { id: number }) => {
		const findItem = selectedLevels.find(
			(item) => item.id === levelItem.id,
		);
		if (findItem) {
			setSelectedLevels((prev) =>
				prev.filter((item) => item.id !== findItem.id),
			);
		} else {
			setSelectedLevels((prev) => [...prev, levelItem]);
		}
	};

	const onCreateManualSubscription = () => {
		dispatch(
			createManualSubscriptionRequest({
				parentId: selectedParent?.userId || 0,
				studentIds: selectedStudentsIds,
				subscriptionPlanIds: selectedLevels.map((elem) => elem.id),
			}),
		);
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			(async function () {
				try {
					setTypeaheadLoading(true);
					const result: IResponseWithCustomValue<{
						totalCount: number;
						users: ParentResponseType[];
					}> = await API.get(
						`/api/v1/users?PageSize=10&StaffRole=2${
							debouncedSearchTerm
								? `&SearchQuery=${debouncedSearchTerm}`
								: ""
						}`,
					);
					if (result.success) {
						setTypeAheadItems(
							result.value.users.map(
								(user: ParentResponseType) => ({
									label: `${user.firstName} ${user.lastName}`,
									value: user.userId + "",
									parentInfo: user,
								}),
							),
						);
					}
				} catch (error) {
					console.error(error);
				} finally {
					setTypeaheadLoading(false);
				}
			})();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (saveLoadingDelta && saveLoadingDelta.prev) {
			if (
				saveLoadingDelta.prev &&
				!saveLoadingDelta.curr &&
				!errors.length
			) {
				setSelectedParent(null);
				setTypeAheadItems([]);
				setSearchQuery("");
				setSelectedStudentsIds([]);
				setSelectedLevels([]);
				setResetKey(new Date().getTime());
			}
		}
	}, [saveLoadingDelta]);

	return (
		<Box pt="20px">
			<TypeAhead
				key={resetKey}
				label="Organization"
				placeholder="Organization"
				items={typeAheadItems}
				onStateChange={(props) => {
					// console.log(props, "props");
					setSelectedParent(
						props.selectedItems?.[0]?.parentInfo || null,
					);
					if (!props.selectedItems?.length) {
						setSelectedStudentsIds([]);
						setSelectedLevels([]);
						setTypeAheadItems([]);
					}
				}}
				onInputChange={(value: string) => setSearchQuery(value)}
				typeaheadLoading={typeaheadLoading}
				disableTextField={!!selectedParent}
				// errorMsg={(errors.recipientName as any)?.message}
			/>
			{selectedParent ? (
				<>
					<Heading as="h6" variant="h6" color="darkest">
						Students
					</Heading>
					{selectedParent.students.length ? (
						<InfiniteScroll height="300px">
							<>
								{selectedParent.students.map((stud) => (
									<StudentItem
										key={stud.id}
										{...stud}
										isSelected={selectedStudentsIds.includes(
											stud.id,
										)}
										selectStudent={onSelectStudent}
									/>
								))}
							</>
						</InfiniteScroll>
					) : (
						<Center height="300px">
							<Text variant="body2" color="darkGrey">
								This organization has no students
							</Text>
						</Center>
					)}
				</>
			) : null}
			{selectedStudentsIds.length ? (
				<Box mb="20px">
					<Heading as="h6" variant="h6" color="darkest">
						Levels
					</Heading>
					<ClassesBox
						countSelectedClasses={selectedLevels.length}
						classes={levels.filter((level: any) => level.isActive)}
						classesLoading={levelsLoading}
						onSelectedClass={(item) => onSelectLevel(item)}
						selectedClasses={selectedLevels}
						counterLabel="Level"
					/>
				</Box>
			) : null}
			<ModalFooter>
				<HStack w="100%">
					<RippleButton
						variant="outline"
						w="100%"
						onClick={handleClose}>
						Close
					</RippleButton>
					{selectedLevels.length ? (
						<RippleButton
							w="100%"
							onClick={onCreateManualSubscription}
							isLoading={saveLoading}>
							Save
						</RippleButton>
					) : null}
				</HStack>
			</ModalFooter>
		</Box>
	);
};
