import axios from "axios";

// when exists token, set header Authorisation for every request
export const setAuthHeaderToken = (token: string | null): void => {
	if (token) {
		// Apply to every req
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	} else {
		// delete auth header
		delete axios.defaults.headers.common["Authorization"];
	}
};
