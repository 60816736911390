import { Box, Flex, Text, Avatar, Button } from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "../../../assets/ic_info.svg";
import { StudentResponseType } from "../types";
import { getPictureURL } from "../../../common/utils";

interface IStudentItemProps extends StudentResponseType {
	setShowStudentInfoModal: (value: boolean) => void;
	setStudentInfo: (value: StudentResponseType) => void;
}

export const StudentItem: React.FC<IStudentItemProps> = ({
	setShowStudentInfoModal,
	age,
	grade,
	id,
	name,
	photo,
	paidSubscriptionPlanNames,
	setStudentInfo,
	parentFullName,
}) => (
	<Flex
		justify="space-between"
		borderBottom="1px solid #EBEBEB"
		py="16px"
		pr="16px">
		<Flex>
			<Avatar
				name={name}
				src={getPictureURL(photo)}
				w="48px"
				h="48px"
				borderRadius="8px"
				mr="16px"
			/>
			<Box>
				<Text variant="body1" color="darkest">
					{name}
				</Text>
				<Text variant="body3" color="grey">
					{age} years old • Grade {grade} •{" "}
					{paidSubscriptionPlanNames.join(", ")}
				</Text>
			</Box>
		</Flex>
		<Button
			leftIcon={<InfoIcon />}
			variant="plain"
			onClick={() => {
				setStudentInfo({
					age,
					grade,
					id,
					name,
					paidSubscriptionPlanNames,
					photo,
					parentFullName,
				});
				setShowStudentInfoModal(true);
			}}>
			Info
		</Button>
	</Flex>
);
