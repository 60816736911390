import { useState } from "react";
import { Box, Flex, Heading, Button, HStack } from "@chakra-ui/react";
import { InfiniteScroll, Loader } from "../../../../common/components";
import { StudentNamedSubscriptionType } from "../../types";
import { SubscriberItem } from "../../components";
import { SubscriptionsPlaceholder } from "../../components/placeholders";
import { SetDiscountModal, ManualInvoicesModal } from "../../modal";

interface ISubscriptionCardProps {
	items: StudentNamedSubscriptionType[];
	loading: boolean;
	totalCount: number;
	loadMoreLoading: boolean;
	loadMoreSubscriptions: () => void;
}

export const SubscriptionsCard: React.FC<ISubscriptionCardProps> = ({
	items,
	loadMoreLoading,
	loading,
	totalCount,
	loadMoreSubscriptions,
}) => {
	const [showDiscountModal, setShowDiscountModal] = useState(false);
	const [showInvoicesModal, setShowInvoicesModal] = useState(false);

	return (
		<>
			<Box
				mt={{ base: "8px", lg: 0 }}
				p={{ base: "10px 0", lg: "24px 40px 0 40px" }}
				bg={{ base: "transparent", lg: "#FFFFFF" }}
				borderRadius={"8px"}
				boxShadow={{ base: "none", lg: "base" }}>
				<Flex
					justify="space-between"
					borderBottom={{ base: "none", lg: "1px solid #EBEBEB" }}>
					<Heading
						variant="h8"
						as="p"
						color="darkest"
						lineHeight="50px">
						Subscriptions
					</Heading>
					<HStack spacing="20px" wrap="wrap" justify="flex-end">
						<Button
							variant="plain"
							color="primary"
							onClick={() => setShowInvoicesModal(true)}>
							Manual Invoices
						</Button>
						<Button
							variant="plain"
							color="primary"
							onClick={() => setShowDiscountModal(true)}>
							Set Discount
						</Button>
					</HStack>
				</Flex>
				<Box height="calc(100vh - 250px)">
					{loading ? (
						<Loader centerHeight="90%" />
					) : (
						<>
							{items.length ? (
								<InfiniteScroll
									onReachBottom={() => {
										if (
											!loadMoreLoading &&
											totalCount > items.length
										) {
											loadMoreSubscriptions();
										}
									}}>
									{items.map((item) => (
										<SubscriberItem
											key={item.id}
											studentName={item.studentName}
											amount={item.amount}
											name={item.subscriptionPlans
												.map((subPlan) => subPlan.name)
												.join(", ")}
											date={
												item.lastActivatedAt ||
												item.createdAt
											}
											isManual={item.isManual}
										/>
									))}
									{loadMoreLoading && (
										<Loader spinnerSize="md" />
									)}
								</InfiniteScroll>
							) : (
								<SubscriptionsPlaceholder />
							)}
						</>
					)}
				</Box>
			</Box>
			<SetDiscountModal
				isOpen={showDiscountModal}
				handleClose={() => setShowDiscountModal(false)}
			/>
			<ManualInvoicesModal
				isOpen={showInvoicesModal}
				handleClose={() => setShowInvoicesModal(false)}
			/>
		</>
	);
};
