import { Heading, Flex, Button, useBreakpointValue } from "@chakra-ui/react";

interface IDashboardCardHeader {
	title: string;
	buttonTitle?: string;
	onClick?: () => void;
	forceWhiteText?: boolean;
}

export const DashboardCardHeader: React.FC<IDashboardCardHeader> = ({
	buttonTitle,
	onClick,
	title,
	forceWhiteText,
}) => {
	const belowDesktop = useBreakpointValue({
		base: true,
		lg: false,
	});
	return (
		<Flex
			justify="space-between"
			borderBottom={{ base: "none", lg: "1px solid #EBEBEB" }}>
			<Heading
				variant="h8"
				as="p"
				color={forceWhiteText && belowDesktop ? "white" : "darkest"}
				lineHeight="50px">
				{title}{" "}
			</Heading>
			{buttonTitle && (
				<Button
					onClick={onClick}
					variant="plain"
					color={
						forceWhiteText && belowDesktop ? "white" : "primary"
					}>
					{buttonTitle}
				</Button>
			)}
		</Flex>
	);
};
