import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const editEmployeeSchemaResolver = yup.object().shape({
	positionName: yup.string().required(ErrorMessages.REQUIRED),
	email: yup
		.string()
		.email(ErrorMessages.EMAIL_INVALID)
		.required(ErrorMessages.REQUIRED),
	firstName: yup.string().required(ErrorMessages.REQUIRED),
	lastName: yup.string().required(ErrorMessages.REQUIRED),
	staffRole: yup.string().required(ErrorMessages.REQUIRED),
});

export const editEmployeeSchema = yupResolver(editEmployeeSchemaResolver);
