import dayjs from "dayjs";
export const formattedDate = (
	value: string,
): { day: string; month: string; year: string } => {
	const date = new Date(value)
		.toLocaleDateString(["en-GB"], {
			year: "numeric",
			month: "long",
			day: "2-digit",
		})
		.split(" ");

	return {
		day: date[0],
		month: date[1],
		year: date[2],
	};
};

export const toHumanDate = (value: number | string): string =>
	dayjs.utc(value).format("DD MMMM, YYYY");
// export const toIsoString = (value:string) => dayjs(value).unix();
export const toTimeStamp = (value: string): number => dayjs.utc(value).unix();
export const toIsoString = (value: string): string =>
	dayjs(value).toISOString();
