import {
	Modal,
	InputText,
	InputSelect,
	InputTextArea,
	RippleButton,
} from "../../../../common/components";
import { ModalBody, ModalFooter, Box, Flex } from "@chakra-ui/react";
import { useForm, Resolver } from "react-hook-form";
import { editEmployeeSchema } from "../../../../common/constants/validation";
import { InviteEmployeeForm } from "../../types";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../redux";
import { PositionTeamType } from "../../../../common/types";

interface IInviteEmployeeModal {
	handleClose: () => void;
	isOpen: boolean;
	inviteEmployee: (value: InviteEmployeeForm) => void;
	loading: boolean;
}

export const InviteEmployeeModal: React.FC<IInviteEmployeeModal> = ({
	handleClose,
	isOpen,
	inviteEmployee,
	loading,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<InviteEmployeeForm>({
		resolver: editEmployeeSchema as Resolver<InviteEmployeeForm>,
	});

	const onSubmit = (data: InviteEmployeeForm) => {
		inviteEmployee(data);
	};

	const positionTeams = useSelector(
		(state: RootStore) => state.directories.positionTeams.items,
	);

	return (
		<Modal
			handleClose={handleClose}
			isOpen={isOpen}
			title="Invite Employee">
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Box>
						<Flex>
							<InputText
								{...register("firstName")}
								label="First Name"
								placeholder="First Name"
								errorMsg={errors.firstName?.message}
							/>
							<Box w="20px" />
							<InputText
								{...register("lastName")}
								label="Last Name"
								placeholder="Last Name"
								errorMsg={errors.lastName?.message}
							/>
						</Flex>
						<Flex>
							<InputText
								{...register("positionName")}
								label="Position Name"
								placeholder="Position"
								errorMsg={errors.positionName?.message}
							/>
							<Box w="20px" />
							<InputSelect
								{...register("staffRole")}
								label="Role"
								placeholder="Role"
								options={positionTeams.map(
									(elem: PositionTeamType) => ({
										label: elem.name,
										value: elem.id,
									}),
								)}
								errorMsg={errors.staffRole?.message}
							/>
						</Flex>
						<InputText
							{...register("email")}
							label="Employee Email"
							placeholder="Employee Email"
							type="Email"
							errorMsg={errors.email?.message}
						/>
						<InputTextArea
							{...register("message")}
							label="Message"
							placeholder="Message"
						/>
					</Box>
				</ModalBody>
				<ModalFooter>
					<RippleButton
						type="submit"
						width="100%"
						isLoading={loading}>
						Invite
					</RippleButton>
				</ModalFooter>
			</form>
		</Modal>
	);
};
