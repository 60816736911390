import React, { useState } from "react";
import { InputMultiValue } from "./InputMultiValue";
import { Box, Flex, FormControl, Input, FormLabel } from "@chakra-ui/react";
// import { InputText } from "../InputText/InputText";

interface IInputMultiProps {
	values: string[];
	/** Method which should be called when an item is added to the collection */
	onItemAdded: (value: string, items: string[]) => void;
	/** Method which should be called when an item is removed from the collection */
	onItemDeleted: (value: string, items: string[]) => void;
	/** Label to be attached to the input, if desired */
	label: string;
	/** Name attribute for the input */
	name: string;
	/** Placeholder attribute for the input, if desired */
	placeholder: string;
	/** ASCII charcode for the keys which should
	 * trigger an item to be added to the collection (defaults to comma (44) and Enter (13))
	 */
	charCodes?: number[];
	/** Whether or not the blur event should trigger the added-item handler */
	shouldAddOnBlur?: boolean;
}
/**
 * A text input component for React which maintains and displays a collection
 * of entered values as an array of strings.
 */
export const InputMulti: React.FC<IInputMultiProps> = ({
	placeholder,
	label,
	name,
	onItemAdded,
	onItemDeleted,
	charCodes = [13, 44],
	values: initialValues,
	shouldAddOnBlur,
	...forwardedProps
}) => {
	const [values, setValues] = useState(initialValues);
	const [value, setValue] = useState("");

	const handleValueChange: React.ChangeEventHandler<HTMLInputElement> = (
		e,
	) => {
		setValue(e.target.value);
	};
	const handleItemAdd = (addedValue: string) => {
		if (values.includes(addedValue) || !addedValue) {
			setValue("");
			return;
		}
		const newValues = values.concat(addedValue);
		setValues(newValues);
		setValue("");
		onItemAdded(value, newValues);
	};
	const handleItemRemove = (removedValue: string) => {
		const currentValues = values;
		const newValues = currentValues.filter((v) => v !== removedValue);
		onItemDeleted(removedValue, newValues);
		setValues(newValues);
	};

	const handleKeypress = (e: any) => {
		// 13: Enter, 44: Comma
		if (charCodes.includes(e.charCode)) {
			e.preventDefault();
			handleItemAdd(e.target.value);
		}
	};
	const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
		// 13: Enter, 44: Comma
		if (shouldAddOnBlur) {
			e.preventDefault();
			handleItemAdd(e.target.value);
		}
	};
	const valueDisplays = values.map((v) => (
		<InputMultiValue
			value={v}
			key={v}
			handleItemRemove={handleItemRemove}
		/>
	));
	return (
		<Box role="form" mb="29px">
			<FormControl>
				<Flex align="center" justify="space-between">
					<FormLabel
						fontSize="caption2"
						lineHeight="22px"
						color="darkest"
						marginBottom={0}>
						{label}
					</FormLabel>
				</Flex>
				<Input
					borderRadius="8px"
					paddingInlineStart="12px"
					paddingInlineEnd="12px"
					borderColor="lightGrey"
					color="darkest"
					fontSize="14px"
					lineHeight="22px"
					focusBorderColor="primary"
					_placeholder={{
						color: "grey",
					}}
					placeholder={placeholder}
					name={name}
					onKeyPress={handleKeypress}
					onChange={handleValueChange}
					onBlur={handleBlur}
					value={value}
					{...forwardedProps}
				/>
				{/* <FormErrorMessage>{errorMsg}</FormErrorMessage> */}
			</FormControl>
			<Flex my="10px" wrap="wrap">
				{values.length > 0 && <Box>{valueDisplays}</Box>}
			</Flex>
		</Box>
	);
};
