import { quantity } from "./../../../common/constants/quantity";
import { call, put, takeEvery, ForkEffect, select } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
	getAttendanceClassesRequest,
	getAttendanceClassesSuccess,
	getAttendanceClassesError,
	getAttendanceStudentsRequest,
	getAttendanceStudentsSuccess,
	getAttendanceStudentsError,
	getMoreAttendanceStudentsRequest,
	getMoreAttendanceStudentsSuccess,
	getMoreAttendanceStudentsError,
} from "./actions";
import { Alerter } from "../../../common/utils";
import {
	IResponseWithCustomValue,
	SubscriptionPlan,
	// IResponseWithoutValue,
} from "../../../common/types";
import { AttendanceResponse } from "../types";
import { RootStore } from "./../../../redux/index";

function* workerGetClasses(
	action: ReturnType<typeof getAttendanceClassesRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			subscriptionPlans: SubscriptionPlan[];
		}> = yield call(API.get, `/api/v1/plans?StatusId=${action.payload}`);
		if (result.success) {
			yield put(
				getAttendanceClassesSuccess(result.value.subscriptionPlans),
			);
		} else {
			yield put(getAttendanceClassesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetAttendanceStudents(
	action: ReturnType<typeof getAttendanceStudentsRequest>,
) {
	try {
		const result: IResponseWithCustomValue<AttendanceResponse> = yield call(
			API.get,
			`/api/v1/attendance/admin?SubscriptionPlanId=${action.payload.subscriptionPlanId}&AfterDate=${action.payload.afterDate}&BeforeDate=${action.payload.beforeDate}&PageSize=${quantity.ADMIN_ATTENDANCE_STUDENTS_TAKE}`,
		);
		if (result.success) {
			yield put(getAttendanceStudentsSuccess(result.value));
		} else {
			yield put(getAttendanceStudentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreAttendanceStudents(
	action: ReturnType<typeof getMoreAttendanceStudentsRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.attendance.students.page,
		);
		const result: IResponseWithCustomValue<AttendanceResponse> = yield call(
			API.get,
			`/api/v1/attendance/admin?SubscriptionPlanId=${
				action.payload.subscriptionPlanId
			}&AfterDate=${action.payload.afterDate}&BeforeDate=${
				action.payload.beforeDate
			}&PageSize=${quantity.ADMIN_ATTENDANCE_STUDENTS_TAKE}&PageNumber=${
				page + 1
			}`,
		);
		if (result.success) {
			yield put(getMoreAttendanceStudentsSuccess(result.value));
		} else {
			yield put(getMoreAttendanceStudentsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchAttendanceSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getAttendanceClassesRequest.type, workerGetClasses);
	yield takeEvery(getAttendanceStudentsRequest.type, workerGetAttendanceStudents);
	yield takeEvery(getMoreAttendanceStudentsRequest.type, workerGetMoreAttendanceStudents);
}
