import { createAction } from "@reduxjs/toolkit";
import { SlimVacancyApplication, FullVacancyApplication } from "../../types";
import { PayloadError } from "../../../../common/types";

// Get applications
export const getApplicationsRequest = createAction(
	"@jobs/GET_APPLICATIONS_REQUEST",
);
export const getApplicationsSuccess = createAction<{
	entities: SlimVacancyApplication[];
	totalCount: number;
}>("@jobs/GET_APPLICATIONS_SUCCESS");
export const getApplicationsError = createAction<PayloadError[]>(
	"@jobs/GET_APPLICATIONS_ERROR",
);

// Load more applications
export const getMoreApplicationsRequest = createAction(
	"@jobs/GET_MORE_APPLICATIONS_REQUEST",
);
export const getMoreApplicationsSuccess = createAction<{
	entities: SlimVacancyApplication[];
	totalCount: number;
}>("@jobs/GET_MORE_APPLICATIONS_SUCCESS");
export const getMoreApplicationsError = createAction<PayloadError[]>(
	"@jobs/GET_MORE_APPLICATIONS_ERROR",
);

// Get application by Id
export const getApplicationByIdRequest = createAction<string>(
	"@jobs/GET_APPLICATION_BY_ID_REQUEST",
);
export const getApplicationByIdSuccess = createAction<FullVacancyApplication>(
	"@jobs/GET_APPLICATION_BY_ID_SUCCESS",
);
export const getApplicationByIdError = createAction<PayloadError[]>(
	"@jobs/GET_APPLICATION_BY_ID_ERROR",
);
export const clearApplicationById = createAction(
	"@jobs/CLEAR_APPLICATION_BY_ID",
);

// Delete application
export const deleteApplicationRequest = createAction<string>(
	"@jobs/DELETE_APPLICATION_REQUEST",
);
export const deleteApplicationSuccess = createAction(
	"@jobs/DELETE_APPLICATION_SUCCESS",
);
export const deleteApplicationError = createAction<PayloadError[]>(
	"@jobs/DELETE_APPLICATION_ERROR",
);
