import { combineReducers } from "redux";
import filterClassesReducer from "./filter-classes.reducer";
import broadcastReducer from "./broadcast.reducer";
import messagesHistoryReducer from "./messages-history.reducer";
import parentsReducer from "./parents.reducer";
import staffReducer from "./staff.reducer";

const messagesReducer = combineReducers({
	filterClasses: filterClassesReducer,
	broadcast: broadcastReducer,
	messagesHistory: messagesHistoryReducer,
	parents: parentsReducer,
	staff: staffReducer,
});

export default messagesReducer;
