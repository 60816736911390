import {
	Box,
	Flex,
	Text,
	Button,
	Center,
	Heading,
	Avatar,
} from "@chakra-ui/react";
import { ReactComponent as CheckIcon } from "../../../../../assets/ic_chek.svg";
import { getPictureURL } from "../../../../../common/utils";
import dayjs from "dayjs";
import { ImageType } from "../../../../../common/types";

interface IPlanItemProps {
	name: string;
	image: ImageType;
	price: number;
	startDate: string;
	endDate: string;
	isActive: boolean;
}

export const PlanItem: React.FC<IPlanItemProps> = ({
	name,
	image,
	price,
	endDate,
	startDate,
	isActive,
}) => (
	<Box
		px={"24px"}
		border={"1px solid #F3ECFD"}
		borderRadius={"8px"}
		mb="16px">
		<Flex py={"24px"} borderBottom={"1px solid #F3ECFD"}>
			<Avatar
				w={"88px"}
				h={"88px"}
				bg={"gray"}
				borderRadius={"8px"}
				name={name}
				src={getPictureURL(image)}
			/>
			<Box ml={"24px"}>
				<Heading variant={"h6"}>{name}</Heading>
				<Text variant={"caption1"} color={"grey"}>
					Student
				</Text>
				<Text variant={"body3"}>Full Name</Text>
			</Box>
		</Flex>
		<Flex py={"24px"} borderBottom={"1px solid #F3ECFD"}>
			<Box w={"50%"}>
				<Text variant={"caption1"} color={"grey"}>
					Start Date
				</Text>
				<Text fontSize={"inputText"} color={"darkgrey"}>
					{dayjs(startDate).format("D MMMM, YYYY")}
				</Text>
			</Box>
			<Box w={"50%"}>
				<Text variant={"caption1"} color={"grey"}>
					End Date
				</Text>
				<Text fontSize={"inputText"} color={"darkgrey"}>
					{dayjs(endDate).format("D MMMM, YYYY")}
				</Text>
			</Box>
		</Flex>
		<Flex
			justifyContent={"space-between"}
			py={"24px"}
			borderBottom={"1px solid #F3ECFD"}>
			<Box>
				<Text variant={"subtitle2"} color={"darkest"}>
					${price?.toFixed(2)}
				</Text>
				<Text variant={"caption2"} color={"darkgrey"}>
					per month
				</Text>
			</Box>
			{isActive ? (
				<Button
					alignSelf={"flex-end"}
					fontSize={"16px"}
					variant={"ghost"}
					rightIcon={
						<Center
							p={"3px"}
							w={"16px"}
							h={"16px"}
							borderRadius={"50%"}
							border={"1px solid #8C4BC9"}>
							<CheckIcon />
						</Center>
					}>
					Paid Up
				</Button>
			) : null}
		</Flex>
	</Box>
);
