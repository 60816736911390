import { createAction } from "@reduxjs/toolkit";
import {
	CreateJobPositionPayload,
	IVacancy,
	IFullVacancy,
	EditJobPositionPayload,
} from "../../types";
import { PayloadError } from "../../../../common/types";

// Create job
export const createJobPositionRequest = createAction<CreateJobPositionPayload>(
	"@jobs/CREATE_JOB_POSITION_REQUEST",
);
export const createJobPositionSuccess = createAction(
	"@jobs/CREATE_JOB_POSITION_SUCCESS",
);
export const createJobPositionError = createAction<PayloadError[]>(
	"@jobs/CREATE_JOB_POSITION_ERROR",
);

// Get vacancies
export const getVacanciesRequest = createAction("@jobs/GET_VACANCIES_REQUEST");
export const getVacanciesSuccess = createAction<{
	entities: IVacancy[];
	totalCount: number;
}>("@jobs/GET_VACANCIES_SUCCESS");
export const getVacanciesError = createAction<PayloadError[]>(
	"@jobs/GET_VACANCIES_ERROR",
);

// Load more vacancies
export const getMoreVacanciesRequest = createAction(
	"@jobs/GET_MORE_VACANCIES_REQUEST",
);
export const getMoreVacanciesSuccess = createAction<{
	entities: IVacancy[];
	totalCount: number;
}>("@jobs/GET_MORE_VACANCIES_SUCCESS");
export const getMoreVacanciesError = createAction<PayloadError[]>(
	"@jobs/GET_MORE_VACANCIES_ERROR",
);

// Get vacancy by Id
export const getVacancyByIdRequest = createAction<string>(
	"@jobs/GET_VACANCY_BY_ID_REQUEST",
);
export const getVacancyByIdSuccess = createAction<IFullVacancy>(
	"@jobs/GET_VACANCY_BY_ID_SUCCESS",
);
export const getVacancyByIdError = createAction<PayloadError[]>(
	"@jobs/GET_VACANCY_BY_ID_ERROR",
);
export const clearVacancyById = createAction("@jobs/CLEAR_VACANCY_BY_ID");

// Edit vacancy
export const editVacancyRequest = createAction<EditJobPositionPayload>(
	"@jobs/EDIT_VACANCY_REQUEST",
);
export const editVacancySuccess = createAction("@jobs/EDIT_VACANCY_SUCCESS");
export const editVacancyError = createAction<PayloadError[]>(
	"@jobs/EDIT_VACANCY_ERROR",
);

// Delete vacancy
export const deleteVacancyRequest = createAction<string>(
	"@jobs/DELETE_VACANCY_REQUEST",
);
export const deleteVacancySuccess = createAction(
	"@jobs/DELETE_VACANCY_SUCCESS",
);
export const deleteVacancyError = createAction<PayloadError[]>(
	"@jobs/DELETE_VACANCY_ERROR",
);
