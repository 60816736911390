import {
	// Flex,
	// Button,
	// Heading,
	// Popover,
	// PopoverTrigger,
	// PopoverContent,
	// PopoverHeader,
	// PopoverBody,
	// HStack,
	Avatar,
} from "@chakra-ui/react";
// import { NotificationItem } from "../../components";

interface INotificationsPopoverProps {
	name: string;
}

export const NotificationsPopover: React.FC<INotificationsPopoverProps> = ({
	name,
}) => {
	return (
		// <Popover placement="left-start" matchWidth={true}>
		// 	<PopoverTrigger>
		<Avatar
			w="64px"
			h="64px"
			borderRadius="16px"
			name={name}
			tabIndex={0}
			// as="button"
			aria-label="User avatar button"
		/>
		// 	</PopoverTrigger>
		// 	<PopoverContent
		// 		p="16px 24px 32px 24px"
		// 		borderRadius="16px"
		// 		w="auto">
		// 		<PopoverHeader>
		// 			<Flex align="center" justify="space-between">
		// 				<HStack>
		// 					<Heading as="h6" variant="h8" color="darkest">
		// 						Notifications{" "}
		// 					</Heading>
		// 					<Heading
		// 						as="h6"
		// 						variant="h8"
		// 						color="grey"
		// 						display="inline">
		// 						(2)
		// 					</Heading>
		// 				</HStack>
		// 				<Button variant="plain">Clear All</Button>
		// 			</Flex>
		// 		</PopoverHeader>
		// 		<PopoverBody mt="10px">
		// 			<NotificationItem />
		// 			<NotificationItem />
		// 			<NotificationItem />
		// 		</PopoverBody>
		// 	</PopoverContent>
		// </Popover>
	);
};
