import { Modal, InputText, InputTextArea } from "../../../../common/components";
import {
	ModalBody,
	Box,
	Button,
	ModalFooter,
	Flex,
	HStack,
} from "@chakra-ui/react";
import { MessageHistoryItemType } from "../../types";
import dayjs from "dayjs";

interface IMessageDetailsModalProps {
	isOpen: boolean;
	handleClose: () => void;
	toShowInfoMessage: MessageHistoryItemType;
}

export const MessageDetailsModal: React.FC<IMessageDetailsModalProps> = ({
	handleClose,
	isOpen,
	toShowInfoMessage,
}) => {
	return (
		<Modal
			title="Message Details"
			handleClose={handleClose}
			isOpen={isOpen}>
			<ModalBody>
				<Box>
					<InputText
						label="Sender Name"
						placeholder="Sender Name"
						value={toShowInfoMessage.senderName}
						isReadOnly={true}
					/>
					<InputText
						label="Recipient Name"
						placeholder="Recipient Name"
						value={toShowInfoMessage.users
							?.map(
								(user) => `${user.firstName} ${user.lastName}`,
							)
							.join(", ")}
						isReadOnly={true}
					/>
					<InputTextArea
						label="Message Text"
						value={toShowInfoMessage.messageText}
						isReadOnly={true}
						height="130px"
					/>
					<Flex direction={{ base: "column", md: "row" }}>
						<InputText
							label="Sending Date"
							value={dayjs
								.utc(toShowInfoMessage.sendAt)
								.format("DD MMMM, YYYY")}
							isReadOnly={true}
						/>
						<Box w="20px" />
						<InputText
							isReadOnly={true}
							label="Sending Time"
							value={dayjs
								.utc(toShowInfoMessage.sendAt)
								.format("HH:mm A")}
						/>
					</Flex>
				</Box>
			</ModalBody>
			<ModalFooter>
				<HStack borderTop="1px solid #EBEBEB" w="100%" pt="16px">
					<Button w="100%" variant="outline" onClick={handleClose}>
						Close
					</Button>
				</HStack>
			</ModalFooter>
		</Modal>
	);
};
