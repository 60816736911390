import { useState, useEffect } from "react";
import { TeacherAttendance } from "./TeacherAttendance";
import { StudentInfoModal } from "./modals";
import {
	getTeacherAttendanceClassesRequest,
	getTeacherAttendanceStudentsRequest,
	getMoreTeacherAttendanceStudentsRequest,
	setStudentAttendanceValueRequest,
} from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useDelta } from "react-delta";
import { UpdateAttendanceClickPayload, TeacherAttendanceType } from "./types";
import { SubscriptionPlan } from "../../common/types";
import { defaultStudentInfo } from "../../common/constants";

dayjs.extend(utc);

export const TeacherAttendanceContainer: React.FC = () => {
	const dispatch = useDispatch();
	const [showStudentInfo, setShowStudentInfo] = useState(false);
	const [selectedClass, setSelectedClass] = useState(0);
	const [selectedDay, setSelectedDay] = useState(dayjs.utc().format());
	const [selectedYear, setSelectedYear] = useState(dayjs.utc().year());
	const [selectedMonth, setSelectedMonth] = useState(dayjs.utc().month());
	const [studentInfo, setStudentInfo] =
		useState<TeacherAttendanceType>(defaultStudentInfo);
	const { loading: classesLoading, items: classesItems } = useSelector(
		(state: RootStore) => state.teacherAttendance.classes,
	);
	const {
		items: students,
		loading: studentsLoading,
		loadMoreLoading: studentsLoadMoreLoading,
		totalCount: studentsTotalCount,
	} = useSelector((state: RootStore) => state.teacherAttendance.students);

	const selectedClassDelta = useDelta(selectedClass);
	const selectedDayDelta = useDelta(selectedDay);

	const loadMoreStudents = () =>
		dispatch(
			getMoreTeacherAttendanceStudentsRequest({
				utcDate: selectedDay,
				subscriptionPlanId: selectedClass,
			}),
		);

	const updateAttendance = (data: UpdateAttendanceClickPayload) =>
		dispatch(
			setStudentAttendanceValueRequest({
				...data,
				subscriptionPlanId: selectedClass,
				utcDate: selectedDay,
			}),
		);

	useEffect(() => {
		if (classesItems.length) {
			setSelectedClass(classesItems[0].id);
		}
	}, [classesItems]);

	useEffect(() => {
		dispatch(getTeacherAttendanceClassesRequest());
		dispatch(
			getTeacherAttendanceStudentsRequest({
				utcDate: selectedDay,
				subscriptionPlanId: undefined,
			}),
		);
	}, []);

	useEffect(() => {
		if (
			(selectedDayDelta &&
				selectedDayDelta.prev &&
				selectedDayDelta?.prev !== selectedDayDelta?.curr) ||
			(selectedClassDelta &&
				selectedClassDelta.prev &&
				selectedClassDelta?.prev !== selectedClassDelta?.curr)
		)
			dispatch(
				getTeacherAttendanceStudentsRequest({
					utcDate: selectedDay,
					subscriptionPlanId: selectedClass,
				}),
			);
	}, [selectedDayDelta, selectedClassDelta]);

	return (
		<>
			<TeacherAttendance
				setShowStudentInfo={setShowStudentInfo}
				selectedClass={selectedClass}
				setSelectedClass={setSelectedClass}
				classesLoading={classesLoading}
				classesItems={classesItems}
				selectedDay={selectedDay}
				setSelectedDay={setSelectedDay}
				selectedMonth={selectedMonth}
				setSelectedMonth={setSelectedMonth}
				selectedYear={selectedYear}
				setSelectedYear={setSelectedYear}
				students={students}
				studentsLoading={studentsLoading}
				studentsLoadMoreLoading={studentsLoadMoreLoading}
				studentsTotalCount={studentsTotalCount}
				updateAttendance={updateAttendance}
				selectedClassWorkingHours={
					classesItems.find(
						(elem: SubscriptionPlan) => elem.id === selectedClass,
					)?.workingHours
				}
				selectedClassName={
					classesItems.find(
						(elem: SubscriptionPlan) => elem.id === selectedClass,
					)?.name
				}
				setStudentInfo={setStudentInfo}
				loadMoreStudents={loadMoreStudents}
			/>
			<StudentInfoModal
				handleClose={() => {
					setShowStudentInfo(false);
					setStudentInfo(defaultStudentInfo);
				}}
				isOpen={showStudentInfo}
				studentInfo={studentInfo}
			/>
		</>
	);
};
