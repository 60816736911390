import { Box, Flex, Heading, Text, Avatar } from "@chakra-ui/react";
import dayjs from "dayjs";
import { ImageType } from "../../../common/types";
import { getPictureURL } from "../../../common/utils";

type IClassItemProps = {
	selectedClass: number;
	setSelectedClass: (value: number) => void;
	name: string;
	studentsCount: number;
	startDate: string;
	endDate: string;
	id: number;
	image: ImageType;
};

export const ClassItem: React.FC<IClassItemProps> = ({
	selectedClass,
	setSelectedClass,
	endDate,
	name,
	startDate,
	studentsCount,
	id,
	image,
}) => {
	return (
		<Box
			as="button"
			onClick={() => setSelectedClass(id)}
			w={"100%"}
			p={"24px"}
			border={`1px solid ${selectedClass === id ? "#8C4BC9" : "#F3ECFD"}`}
			borderRadius={"16px"}
			mb="16px">
			<Flex
				pb={"24px"}
				borderBottom={"1px solid #F3ECFD"}
				align={"center"}>
				<Avatar
					w={"88px"}
					h={"88px"}
					borderRadius={"8px"}
					name={name}
					src={getPictureURL(image)}
				/>
				<Box ml={"24px"}>
					<Heading variant={"h6"} color="darkest" textAlign="start">
						{name}
					</Heading>
					<Text color={"grey"} variant={"caption3"} textAlign="start">
						Students
					</Text>
					<Text
						variant={"body3"}
						fontWeight="500"
						color="darkest"
						textAlign="start">
						{studentsCount}
					</Text>
				</Box>
			</Flex>

			<Flex mt={"24px"}>
				<Box w={"50%"}>
					<Text variant={"caption3"} color={"grey"} textAlign="start">
						Start Date
					</Text>
					<Text
						fontSize={"inputText"}
						color={"darkGrey"}
						textAlign="start">
						{dayjs(startDate).format("DD MMMM, YYYY")}
					</Text>
				</Box>
				<Box w={"50%"}>
					<Text variant={"caption3"} color={"grey"} textAlign="start">
						End Date
					</Text>
					<Text
						fontSize={"inputText"}
						color={"darkGrey"}
						textAlign="start">
						{dayjs(endDate).format("DD MMMM, YYYY")}
					</Text>
				</Box>
			</Flex>
		</Box>
	);
};
