import * as yup from "yup";
import { ErrorMessages } from "./messages";
import { yupResolver } from "@hookform/resolvers/yup";

const addLevelSchemaResolver = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	price: yup.string().test("price", ErrorMessages.REQUIRED, function (value) {
		if (this.parent.isFree) {
			return true;
		} else {
			return !!value;
		}
	}),
	// price: yup.string().required(ErrorMessages.REQUIRED),
	minGrade: yup
		.number()
		.integer("Should be integer")
		.required(ErrorMessages.REQUIRED)
		.test(
			"empty-plus",
			"Can`t be larger than second value",
			function (value) {
				if (value && value > this.parent.maxGrade) {
					return false;
				} else {
					return true;
				}
			},
		),
	maxGrade: yup.number().integer("Should be integer"),
	period: yup.string(),
	startDate: yup
		.number()
		.required(ErrorMessages.REQUIRED)
		.test(
			"start-date",
			"Start date can`t be later then End date",
			function (value) {
				if (value && value > this.parent.endDate) {
					return false;
				} else {
					return true;
				}
			},
		),
	endDate: yup.number().required(ErrorMessages.REQUIRED),
});

export const addLevelSchema = yupResolver(addLevelSchemaResolver);
