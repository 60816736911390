import { ErrorMessages } from "./messages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const resetPasswordSchemaResolver = yup.object().shape({
	password: yup
		.string()
		.min(6, ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
		.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE_CHAR),
	confirmPassword: yup
		.string()
		.min(6, ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.test("password-match", ErrorMessages.PASSWORD_MATCH, function (value) {
			const { password } = this.parent;
			return password === value;
		})
		.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE_CHAR)
		.matches(/\d/, ErrorMessages.PASSWORD_DIGIT),
});

export const resetPasswordSchema = yupResolver(resetPasswordSchemaResolver);
