import {
	Box,
	useBreakpointValue,
	InputGroup,
	InputLeftElement,
	Input,
	Text,
	Flex,
	Tab,
	Button,
	TabPanels,
	TabPanel,
	TabList,
	Tabs,
	Checkbox,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import { LevelsTabContainer, ClassesTabContainer } from "../tabs";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux";
import { ICChek } from "../../../assets/svg-components";

type IProps = {
	setShowAddLevelModal: (value: boolean) => void;
	setShowAddClassModal: (value: boolean) => void;
	showAddLevelModal: boolean;
	showAddClassModal: boolean;
	setSearchQuery: (value: string) => void;
	setTabIndex: (value: number) => void;
	tabIndex: number;
	searchQuery: string;
	selectedPlan: number;
	setSelectedPlan: (value: number) => void;
	withArchivedFilter: boolean;
	setWithArchivedFilter: (value: boolean) => void;
};

export const ClassesCard: React.FC<IProps> = ({
	setShowAddLevelModal,
	setShowAddClassModal,
	showAddLevelModal,
	showAddClassModal,
	setSearchQuery,
	setTabIndex,
	tabIndex,
	searchQuery,
	selectedPlan,
	setSelectedPlan,
	withArchivedFilter,
	setWithArchivedFilter,
}) => {
	const belowDesktop = useBreakpointValue({
		base: true,
		lg: false,
	});

	const classesCount = useSelector(
		(state: RootStore) => state.subscription.classes.classes.items.length,
	);
	const levelsCount = useSelector(
		(state: RootStore) => state.subscription.levels.levels.items.length,
	);

	return (
		<Box
			p={{ base: "10px 0", lg: "24px 40px 0 40px" }}
			bg={{ base: "transparent", lg: "#FFFFFF" }}
			borderRadius={"8px"}
			boxShadow={{ base: "none", lg: "base" }}>
			<InputGroup>
				<InputLeftElement h="50px" pointerEvents="none">
					<SearchIcon />
				</InputLeftElement>
				<Input
					placeholder="Find levels and classes"
					borderRadius="8px"
					paddingInlineStart="12px"
					paddingInlineEnd="12px"
					h="50px"
					borderColor="lightGrey"
					color="darkest"
					fontSize="14px"
					lineHeight="22px"
					focusBorderColor="primary"
					bg="white"
					_placeholder={{
						color: "grey",
					}}
					onChange={(ev) => setSearchQuery(ev.target.value)}
				/>
			</InputGroup>
			<Tabs variant="unstyled" index={tabIndex}>
				<TabList borderBottom="1px solid #EBEBEB" mt="15px">
					<Tab
						onClick={() => setTabIndex(0)}
						_selected={{ borderBottom: "2px solid #8C4BC9" }}
						borderBottom="2px solid transparent">
						<Text fontSize="16px" lineHeight="21px" color="primary">
							Levels {`(${levelsCount})`}
						</Text>
					</Tab>

					<Tab
						onClick={() => setTabIndex(1)}
						_selected={{ borderBottom: "2px solid #8C4BC9" }}
						borderBottom="2px solid transparent">
						<Text fontSize="16px" lineHeight="21px" color="primary">
							Classes {`(${classesCount})`}
						</Text>
					</Tab>

					<Checkbox
						isChecked={withArchivedFilter}
						sx={{
							".chakra-checkbox__control": {
								borderRadius: "50%",
								border: "1px solid #8C4BC9",
							},
						}}
						icon={<ICChek enabled={withArchivedFilter} />}
						onChange={(ev) => {
							setWithArchivedFilter(ev.target.checked);
						}}>
						<Text variant="body2" color="darkest">
							With archived
						</Text>
					</Checkbox>

					<Flex marginLeft="auto">
						<Button
							variant="unstyled"
							fontSize="14px"
							lineHeight="24px"
							color="primary"
							height="auto"
							onClick={() => {
								if (tabIndex === 0) {
									setShowAddLevelModal(true);
								} else {
									setShowAddClassModal(true);
								}
							}}>
							{tabIndex === 0 ? "Add Level" : "Add Class"}
						</Button>
					</Flex>
				</TabList>

				<TabPanels>
					<TabPanel p="0" h="100%">
						<LevelsTabContainer
							belowDesktop={belowDesktop}
							setShowAddLevelModal={setShowAddLevelModal}
							showAddLevelModal={showAddLevelModal}
							searchQuery={searchQuery}
							selectedPlan={selectedPlan}
							setSelectedPlan={setSelectedPlan}
							withArchivedFilter={withArchivedFilter}
						/>
					</TabPanel>
					<TabPanel h="100%" p="0">
						<ClassesTabContainer
							belowDesktop={belowDesktop}
							setShowAddClassModal={setShowAddClassModal}
							showAddClassModal={showAddClassModal}
							searchQuery={searchQuery}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
