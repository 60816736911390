import { Box } from "@chakra-ui/react";
import { ParentResponseType } from "../../types";
import { InfiniteScroll, Loader } from "../../../../common/components";
import { TabPlaceholder, ParentItem } from "../../components";

interface IParentsTabProps {
	parentsLoading: boolean;
	parentItems: ParentResponseType[];
	parentsLoadMoreLoading: boolean;
	getMoreParents: () => void;
	setShowParentInfoModal: (value: boolean) => void;
	parentsTotalCount: number;
	setParentInfo: (value: ParentResponseType) => void;
}

export const ParentsTab: React.FC<IParentsTabProps> = ({
	parentItems,
	parentsLoading,
	getMoreParents,
	parentsLoadMoreLoading,
	parentsTotalCount,
	setShowParentInfoModal,
	setParentInfo,
}) => {
	return (
		<>
			{parentsLoading ? (
				<Loader centerHeight="calc(100vh - 390px)" />
			) : (
				<>
					{parentItems.length ? (
						<Box>
							<InfiniteScroll
								height="calc(100vh - 390px)"
								onReachBottom={() => {
									if (
										!parentsLoadMoreLoading &&
										parentsTotalCount > parentItems.length
									) {
										getMoreParents();
									}
								}}>
								{parentItems.map((parent) => (
									<ParentItem
										key={parent.userId}
										{...parent}
										setShowParentInfoModal={
											setShowParentInfoModal
										}
										setParentInfo={setParentInfo}
									/>
								))}
								{parentsLoadMoreLoading && (
									<Loader
										spinnerSize="md"
										centerProps={{ py: "10px" }}
									/>
								)}
							</InfiniteScroll>
						</Box>
					) : (
						<TabPlaceholder />
					)}
				</>
			)}
		</>
	);
};
