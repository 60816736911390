import { call, put, takeEvery, ForkEffect, select } from "redux-saga/effects";
import {
	getPendingInvoicesRequest,
	getPendingInvoicesSuccess,
	getPendingInvoicesError,
	createManualSubscriptionRequest,
	createManualSubscriptionSuccess,
	createManualSubscriptionError,
	confirmManualInvoiceRequest,
	confirmManualInvoiceSuccess,
	confirmManualInvoiceError,
	getMorePendingInvoicesRequest,
	getMorePendingInvoicesSuccess,
	getMorePendingInvoicesError,
} from "../actions/manual-invoices.actions";
import API from "../../common/api/api.base";
import {
	IResponseWithoutValue,
	IResponseWithCustomValue,
	PendingManualSubscription,
} from "../../common/types";
import { Alerter } from "../../common/utils";
import { quantity } from "./../../common/constants";
import { RootStore } from "../index";

function* workerGetInvoices() {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			subscriptions: PendingManualSubscription[];
		}> = yield call(
			API.get,
			`/api/v1/subscriptions/pending-manual?PageNumber=1&PageSize=${quantity.INVOICES_TAKE}`,
		);
		if (result.success) {
			yield put(getPendingInvoicesSuccess(result.value));
		} else {
			yield put(getPendingInvoicesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreInvoices() {
	try {
		const page: number = yield select(
			(state: RootStore) => state.manualInvoices.invoices.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			subscriptions: PendingManualSubscription[];
		}> = yield call(
			API.get,
			`/api/v1/subscriptions/pending-manual?PageNumber=${page}&PageSize=${quantity.INVOICES_TAKE}`,
		);
		if (result.success) {
			yield put(getMorePendingInvoicesSuccess(result.value));
		} else {
			yield put(getMorePendingInvoicesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerCreateManualSubscription(
	action: ReturnType<typeof createManualSubscriptionRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.post,
			"/api/v1/subscriptions/create-manual",
			action.payload,
		);
		if (result.success) {
			yield put(createManualSubscriptionSuccess());
			Alerter.success("Subscription(s) successfully created");
		} else {
			yield put(createManualSubscriptionError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerConfirmInvoice(
	action: ReturnType<typeof confirmManualInvoiceRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			"/api/v1/subscriptions/confirm-manual",
			{ subscriptionId: action.payload },
		);
		if (result.success) {
			yield put(confirmManualInvoiceSuccess(action.payload));
			Alerter.success("Invoice confirmed successfully");
			yield put(getPendingInvoicesRequest());
		} else {
			yield put(confirmManualInvoiceError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchManualInvoicesSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	>  {
	yield takeEvery(getPendingInvoicesRequest.type, workerGetInvoices);
	yield takeEvery(createManualSubscriptionRequest.type, workerCreateManualSubscription);
	yield takeEvery(confirmManualInvoiceRequest.type, workerConfirmInvoice);
	yield takeEvery(getMorePendingInvoicesRequest.type, workerGetMoreInvoices);
}
