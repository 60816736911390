import { Box, Grid } from "@chakra-ui/react";
import { DashboardHeader } from "../../common/sections";
import { ApplicationsCardContainer, VacanciesCardContainer } from "./cards";

export const Jobs: React.FC = () => {
	return (
		<Box h="100vh" overflowY="auto" bg={{ base: "#F9F9FB", lg: "white" }}>
			<DashboardHeader title="Jobs" description="" />
			<Box
				px={{ base: "20px", lg: "40px" }}
				mt={{ base: "-50px", lg: "-50px" }}
				width={{ base: "auto", lg: "calc(100vw - 250px)" }}>
				<Grid
					templateColumns={{ base: "auto", lg: "50% 50%" }}
					templateRows="1fr"
					gridGap="24px">
					<VacanciesCardContainer />
					<ApplicationsCardContainer />
					<Box h="100px" display={{ lg: "none" }} />
				</Grid>
			</Box>
		</Box>
	);
};
