import { combineReducers } from "redux";
import vacanciesReducer from "./vacancies.reducer";
import applicationsReducer from "./applications.reducer";

const jobsReducer = combineReducers({
	vacancies: vacanciesReducer,
	applications: applicationsReducer,
});

export default jobsReducer;
