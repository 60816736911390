import PlaceholderPic from "../../../../assets/pic_payment.png";
import { Center, Image, Text, chakra, Link, Box } from "@chakra-ui/react";

export const ClassesPlaceholder: React.FC = () => (
	<Center h="100%">
		<Box>
			<Center>
				<Image src={PlaceholderPic} />
			</Center>
			<Text color="darkGrey" variant="body2" textAlign="center" mt="20px">
				There are no Classes <br /> Go to{" "}
				<chakra.span
					color="primary"
					as={Link}
					href="/dashboard/subscriptions">
					Create classes
				</chakra.span>
			</Text>
		</Box>
	</Center>
);
