import { Box, Center, Heading } from "@chakra-ui/react";
import { ResetPasswordForm } from "./components/ResetPassword.form";
import { SubmitHandler } from "react-hook-form";
import { PayloadError } from "../../common/types";
import { ResetForm } from "./types";

interface IResetPasswordProps {
	loading?: boolean;
	onSubmit: SubmitHandler<ResetForm>;
	errors: PayloadError[];
}

export const ResetPassword: React.FC<IResetPasswordProps> = ({
	errors,
	onSubmit,
	loading,
}) => {
	return (
		<Center h="100vh">
			<Box w="500px" px={{ base: "20px", lg: 0 }}>
				<Heading
					as="h4"
					variant="h4"
					textAlign="center"
					mb="20px"
					color="darkest">
					Reset Password
				</Heading>
				<ResetPasswordForm
					stateErrors={errors}
					onSubmit={onSubmit}
					loading={loading}
				/>
			</Box>
		</Center>
	);
};
