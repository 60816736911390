import React from "react";
import {
	Input,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	InputProps,
} from "@chakra-ui/react";

interface IInputProps extends InputProps {
	errorMsg?: string;
	label: string;
	formControlProps?: FormControlProps;
}

export const InputTime = React.forwardRef<null, IInputProps>(
	({ errorMsg, isDisabled, label, formControlProps, ...rest }, ref) => (
		<FormControl
			isDisabled={isDisabled}
			isInvalid={!!errorMsg}
			{...formControlProps}>
			<FormLabel
				fontSize="caption2"
				lineHeight="22px"
				color="darkest"
				marginBottom={0}>
				{label}
			</FormLabel>
			<Input
				type={"time"}
				borderRadius="8px"
				paddingInlineStart="12px"
				paddingInlineEnd="12px"
				focusBorderColor={"#8C4BC9"}
				color="darkest"
				lineHeight="22px"
				ref={ref}
				{...rest}
			/>

			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	),
);

InputTime.displayName = "InputTime";
