import { Box, Flex, Text, HStack, Avatar } from "@chakra-ui/react";
import { ReactComponent as PresentIcon } from "../../../assets/ic_present.svg";
import { ReactComponent as AbsentIcon } from "../../../assets/ic_absent.svg";
import { ReactComponent as DayoffIcon } from "../../../assets/ic_dayoff.svg";
import { ImageType } from "../../../common/types";
import { getPictureURL } from "../../../common/utils";

interface IStudentItemProps {
	name: string;
	photo: ImageType;
	absentCount: number;
	presentCount: number;
	dayOffCount: number;
}

export const StudentItem: React.FC<IStudentItemProps> = ({
	absentCount,
	dayOffCount,
	name,
	photo,
	presentCount,
}) => {
	return (
		<Flex
			p="16px"
			border="1px solid #F3ECFD"
			borderRadius="8px"
			align="center">
			<Avatar
				w="40px"
				h="40px"
				borderRadius="50%"
				mr="16px"
				name={name}
				src={getPictureURL(photo)}
			/>
			<Box>
				<Text variant="body2" color="darkest">
					{name}
				</Text>
				<HStack
					sx={{
						svg: {
							width: "14px",
							height: "14px",
						},
					}}>
					<HStack>
						<PresentIcon />
						<Text variant="caption3" color="darkGrey">
							{presentCount}
						</Text>
					</HStack>
					<HStack>
						<AbsentIcon />
						<Text variant="caption3" color="darkGrey">
							{absentCount}
						</Text>
					</HStack>
					<HStack>
						<DayoffIcon />
						<Text variant="caption3" color="darkGrey">
							{dayOffCount}
						</Text>
					</HStack>
				</HStack>
			</Box>
		</Flex>
	);
};
