import { SubscriptionPlan, WorkingHoursType } from "../types";
import {
	StaffItem,
	ParentResponseType,
	StudentResponseType,
} from "../../pages/Dashboard/types";
import { TeacherAttendanceType } from "../../pages/TeacherAttendance/types";
import { MessageHistoryItemType } from "../../pages/Messages/types";
import { CreateJobPositionPayload } from "../../pages/Jobs/types";
import { IAddClassForm } from "../../pages/Subscriptions/modal/add-class.modal/AddClassModal";

export const defaultSubPlan: SubscriptionPlan = {
	id: 0,
	name: "",
	classes: [],
	price: 0,
	workingHours: [],
	interval: 0,
	maxGrade: 0,
	minGrade: 0,
	startDate: "",
	endDate: "",
	isActive: false,
	isFree: false,
	studentsCount: 0,
	isPossibleToDelete: false,
	image: {
		id: 0,
		itemHash: null,
		extension: null,
	},
	achievements: [],
	bulletPoints: [],
	colorValue: "",
	orderIndex: 0,
};

export const defaultClass: IAddClassForm = {
	name: "",
	complexity: 1,
	lessons: 0,
	hours: 0,
	description: "",
	imageFile: "",
};

export const defaultStaff: StaffItem = {
	userId: 0,
	firstName: "",
	lastName: "",
	positionName: "",
	staffRole: 0,
	email: "",
	image: {
		id: 0,
		itemHash: "",
		extension: "",
	},
};

export const defaultParent: ParentResponseType = {
	students: [],
	userId: 0,
	firstName: "",
	lastName: "",
	email: "",
	image: {
		id: 0,
		itemHash: null,
		extension: null,
	},
	isDiscountApplicable: false,
	phoneNumber: "",
};

export const defaultStudent: StudentResponseType = {
	id: 0,
	name: "",
	age: 0,
	grade: 0,
	paidSubscriptionPlanNames: [],
	photo: {
		id: 0,
		itemHash: null,
		extension: null,
	},
};

export const defaultStudentInfo: TeacherAttendanceType = {
	id: 0,
	name: "",
	age: 0,
	grade: 0,
	photo: {
		id: 0,
		itemHash: null,
		extension: null,
	},
	parentFullName: "",
	parentEmail: "",
	paidSubscriptionPlanNames: [""],
	presentCount: 0,
	absentCount: 0,
	dayOffCount: 0,
};

export const defaultMessageHistoryItem: MessageHistoryItemType = {
	id: 0,
	senderName: "",
	messageText: "",
	sendAt: "",
	isBulk: false,
	staffRole: 1,
	usersCount: 0,
	subscriptionPlanName: "",
	users: [],
};

export const defaultPublishVacancy: CreateJobPositionPayload = {
	commitmentId: 0,
	description: "",
	location: "",
	teamId: 0,
	title: "",
	payRate: 0,
};

export const defaultWorkingHours: WorkingHoursType[] = [
	{ dayOfWeek: 7, startTime: "00:00", endTime: "00:00", isDayOff: false },
	{ dayOfWeek: 1, startTime: "00:00", endTime: "00:00", isDayOff: false },
	{ dayOfWeek: 2, startTime: "00:00", endTime: "00:00", isDayOff: false },
	{ dayOfWeek: 3, startTime: "00:00", endTime: "00:00", isDayOff: false },
	{ dayOfWeek: 4, startTime: "00:00", endTime: "00:00", isDayOff: false },
	{ dayOfWeek: 5, startTime: "00:00", endTime: "00:00", isDayOff: false },
	{ dayOfWeek: 6, startTime: "00:00", endTime: "00:00", isDayOff: false },
];
