/* eslint-disable no-constant-condition */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import {
	Box,
	Flex,
	Heading,
	Text,
	SimpleGrid,
	useBreakpointValue,
	Center,
} from "@chakra-ui/react";
import {
	InputSelect,
	LevelsStrip,
	InfiniteScroll,
	Loader,
} from "../../../common/components";
import { CalendarIcon } from "../../../assets/svg-components";
import {
	PresenseStatus,
	StudentItem
} from "../components";
import { ReactComponent as PresentIcon } from "../../../assets/ic_present.svg";
import { ReactComponent as AbsentIcon } from "../../../assets/ic_absent.svg";
import { ReactComponent as DayoffIcon } from "../../../assets/ic_dayoff.svg";
import { SubscriptionPlan } from "../../../common/types";
import { SelectedClass } from "../types";
import dayjs from "dayjs";
import { TeacherAttendanceType } from "../../TeacherAttendance/types";
import { StatsPlaceholder } from "../components/placeholders";

interface IStatsCardProps {
	classItems: SubscriptionPlan[];
	selectedClass: SelectedClass;
	setSelectedClass: (value: SelectedClass) => void;
	filterMode: string;
	setFilterMode: (value: string) => void;
	afterDate: string;
	beforeDate: string;
	setShowSelectDateModal: (value: boolean) => void;
	setShowSelectMonthModal: (value: boolean) => void;
	setShowSelectWeekModal: (value: boolean) => void;
	setShowSelectYearModal: (value: boolean) => void;
	absentCount: number;
	presentCount: number;
	dayOffCount: number;
	studentItems: TeacherAttendanceType[];
	studentsLoading: boolean;
	studentsLoadMoreLoading: boolean;
	studentsTotalCount: number;
	loadMoreStudents: () => void;
}

export const StatsCard: React.FC<IStatsCardProps> = ({
	classItems,
	selectedClass,
	setSelectedClass,
	filterMode,
	setFilterMode,
	afterDate,
	beforeDate,
	setShowSelectDateModal,
	setShowSelectMonthModal,
	setShowSelectWeekModal,
	setShowSelectYearModal,
	absentCount,
	dayOffCount,
	presentCount,
	studentItems,
	studentsLoadMoreLoading,
	studentsLoading,
	studentsTotalCount,
	loadMoreStudents,
}) => {
	const belowDesktop = useBreakpointValue({
		base: true,
		lg: false,
	});

	const openCalendarType = () => {
		switch (filterMode) {
			case "daily":
				setShowSelectDateModal(true);
				break;
			case "weekly":
				setShowSelectWeekModal(true);
				break;
			case "monthly":
				setShowSelectMonthModal(true);
				break;
			case "yearly":
				setShowSelectYearModal(true);
				break;
			default:
				break;
		}
	};

	const formatRangeDisplay = () => {
		switch (filterMode) {
			case "daily":
				return dayjs.utc(afterDate).format("DD MMMM, YYYY");
			case "weekly":
				return dayjs.utc(afterDate).month() ===
					dayjs.utc(beforeDate).month()
					? `${dayjs.utc(afterDate).format("DD")} - ${dayjs
							.utc(beforeDate)
							.format("DD MMMM, YYYY")}`
					: `${dayjs.utc(afterDate).format("DD MMMM, YYYY")} - ${dayjs
							.utc(beforeDate)
							.format("DD MMMM, YYYY")}`;
			case "monthly":
				return dayjs.utc(afterDate).format("MMMM, YYYY");
			case "yearly":
				return dayjs.utc(afterDate).format("YYYY");
			default:
				return "";
		}
	};

	return (
		<Box
			p={{ base: "10px 0", lg: "30px 40px 0 30px" }}
			bg={{ base: "transparent", lg: "#FFFFFF" }}
			borderRadius="16px"
			boxShadow={{ base: "none", lg: "base" }}>
			<Flex
				justify="space-between"
				borderBottom={{ base: "none", lg: "1px solid #EBEBEB" }}
				pb="18px">
				<Heading
					variant="h8"
					as="p"
					color="darkest"
					display={{ base: "none", lg: "initial" }}>
					Statistic
				</Heading>
				<Flex direction={{ base: "column", lg: "row" }}>
					<InputSelect
						label=""
						removeBottomMargin={true}
						options={[
							{ value: "daily", label: "Daily" },
							{ value: "weekly", label: "Weekly" },
							{ value: "monthly", label: "Monthly" },
							{ value: "yearly", label: "Yearly" },
						]}
						mb={{ base: "15px", lg: 0 }}
						optionalColor={belowDesktop ? "white" : ""}
						onChange={(ev) => setFilterMode(ev.target.value)}
						value={filterMode}
					/>
					<Flex
						as="button"
						justify="space-between"
						p="8px 12px"
						border="1px solid #EBEBEB"
						borderRadius="8px"
						shrink={0}
						w="200px"
						align="center"
						onClick={openCalendarType}
						ml={{ lg: "15px" }}>
						<Text
							fontSize="14px"
							lineHeight="22px"
							color={{ base: "white", lg: "darkest" }}
							noOfLines={1}>
							{formatRangeDisplay()}
						</Text>
						<CalendarIcon
							fill={belowDesktop ? "white" : "#8C4BC9"}
						/>
					</Flex>
				</Flex>
			</Flex>
			<>
				{/* {true ? ( */}
				{studentsLoading ? (
					<Center h="calc(100% - 80px)">
						<Loader />
					</Center>
				) : (
					<>
						<SimpleGrid columns={3} columnGap="12px" pt="15px">
							<PresenseStatus
								amount={presentCount}
								bgColor="green"
								icon={<PresentIcon />}
								textColor="onGreen"
								title="Present"
							/>
							<PresenseStatus
								amount={absentCount}
								bgColor="lightRed"
								icon={<AbsentIcon />}
								textColor="onRed"
								title="Absent"
							/>
							<PresenseStatus
								amount={dayOffCount}
								bgColor="orange"
								icon={<DayoffIcon />}
								textColor="onOrange"
								title="Day Off"
							/>
						</SimpleGrid>
						<Box display={{ base: "block", lg: "none" }}>
							<LevelsStrip
								items={classItems}
								selectedFilters={selectedClass}
								setSelectedFilters={setSelectedClass}
							/>
						</Box>
						{studentItems.length ? (
							<Box
								h={{
									base: "calc(100vh - 440px)",
									lg: "calc(100vh - 400px)",
								}}
								mt="10px">
								<InfiniteScroll
									boxProps={{
										style: {
											display: "grid",
											gridTemplateColumns: belowDesktop
												? "1fr"
												: "repeat(2, minmax(0, 1fr))",
											gridGap: "12px",
											gridTemplateRows:
												"repeat(auto-fill, 100px)",
										},
									}}
									onReachBottom={() => {
										if (
											!studentsLoadMoreLoading &&
											studentsTotalCount >
												studentItems.length
										) {
											loadMoreStudents();
										}
									}}>
									{studentItems.map((student) => (
										<StudentItem
											key={student.id}
											name={student.name}
											photo={student.photo}
											absentCount={student.absentCount}
											presentCount={student.presentCount}
											dayOffCount={student.dayOffCount}
										/>
									))}
									{studentsLoadMoreLoading && (
										<Center
											gridColumnStart={1}
											gridColumnEnd={3}>
											<Loader spinnerSize="md" />
										</Center>
									)}
									<Box
										height="100px"
										width="100%"
										display={{ base: "block", lg: "none" }}
									/>
								</InfiniteScroll>
							</Box>
						) : null}
					</>
				)}
			</>
		</Box>
	);
};
