import { RootStore } from "./../../../../redux";
import { IResponseWithCustomValue } from "../../../../common/types";
import { call, ForkEffect, put, takeEvery, select } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
	getParentsForMessagesRequest,
	getParentsForMessagesSuccess,
	getParentsForMessagesError,
	getMoreParentsForMessagesRequest,
	getMoreParentsForMessagesSuccess,
	getMoreParentsForMessagesError,
} from "../actions";
import { Alerter } from "../../../../common/utils";
import { ParentResponseType } from "../../../Dashboard/types";
import { quantity } from "../../../../common/constants";

function* workerGetParents(
	action: ReturnType<typeof getParentsForMessagesRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: ParentResponseType[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.PARENTS_TAKE}&StaffRole=2${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}&${
				action.payload.subscriptionPlanId !== "all" &&
				action.payload.subscriptionPlanId !== "archived"
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getParentsForMessagesSuccess(result.value));
		} else {
			yield put(getParentsForMessagesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMoreParents(
	action: ReturnType<typeof getMoreParentsForMessagesRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.messages.parents.page,
		);
		const result: IResponseWithCustomValue<{
			totalCount: number;
			users: ParentResponseType[];
		}> = yield call(
			API.get,
			`/api/v1/users?PageSize=${quantity.PARENTS_TAKE}&PageNumber=${
				page + 1
			}&StaffRole=2${
				action.payload.searchQuery
					? `&SearchQuery=${action.payload.searchQuery}`
					: ""
			}&${
				action.payload.subscriptionPlanId !== "all" &&
				action.payload.subscriptionPlanId !== "archived"
					? `&SubscriptionPlanId=${action.payload.subscriptionPlanId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(getMoreParentsForMessagesSuccess(result.value));
		} else {
			yield put(getMoreParentsForMessagesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchMessagesParentsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	>  {
	yield takeEvery(getParentsForMessagesRequest.type,workerGetParents);
	yield takeEvery(getMoreParentsForMessagesRequest.type, workerGetMoreParents);
}
