import { call, put, takeEvery, ForkEffect, select } from "redux-saga/effects";
import { RootStore } from "../../../../redux";
import { quantity } from "../../../../common/constants";
import API from "../../../../common/api/api.base";
import {
	getPlanSubscriptionsRequest,
	getPlanSubscriptionsSuccess,
	getPlanSubscriptionsError,
	getMorePlanSubscriptionsRequest,
	getMorePlanSubscriptionsSuccess,
	getMorePlanSubscriptionsError,
} from "../actions";
import { IResponseWithCustomValue } from "../../../../common/types";
import { StudentNamedSubscriptionType } from "../../types";
import { Alerter } from "../../../../common/utils";

function* workerGetPlanNamedSubscriptions(
	action: ReturnType<typeof getPlanSubscriptionsRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			subscriptions: StudentNamedSubscriptionType[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/subscriptions/type?PageSize=${quantity.STUDENT_NAMED_SUBSCRIPTIONS_TAKE}&PlanId=${action.payload}`,
		);
		if (result.success) {
			yield put(getPlanSubscriptionsSuccess(result.value));
		} else {
			yield put(getPlanSubscriptionsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerGetMorePlanNamedSubscriptions(
	action: ReturnType<typeof getMorePlanSubscriptionsRequest>,
) {
	try {
		const page: number = yield select(
			(state: RootStore) => state.subscription.planSubscriptions.page,
		);
		const result: IResponseWithCustomValue<{
			subscriptions: StudentNamedSubscriptionType[];
			totalCount: number;
		}> = yield call(
			API.get,
			`/api/v1/subscriptions/type?PageSize=${quantity.STUDENT_NAMED_SUBSCRIPTIONS_TAKE}&PageNumber=${page}&PlanId=${action.payload}`,
		);
		if (result.success) {
			yield put(getMorePlanSubscriptionsSuccess(result.value));
		} else {
			yield put(getMorePlanSubscriptionsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchSubscriptionPlanSubscriptionsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(getPlanSubscriptionsRequest.type, workerGetPlanNamedSubscriptions);
	yield takeEvery(getMorePlanSubscriptionsRequest.type, workerGetMorePlanNamedSubscriptions);
}
