/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { call, put, takeEvery, ForkEffect } from "redux-saga/effects";
import {
	addLevel,
	addLevelSuccess,
	addLevelError,
	getLevelsForSubscriptionsRequest,
	getLevelsForSubscriptionsSuccess,
	getLevelsForSubscriptionsError,
	deleteLevelRequest,
	deleteLevelSuccess,
	deleteLevelError,
	archiveLevelRequest,
	archiveLevelSuccess,
	archiveLevelError,
	editLevelDatesRequest,
	editLevelDatesSuccess,
	editLevelDatesError,
	editLevelRequest,
	editLevelSuccess,
	editLevelError,
} from "../actions";
import { Alerter } from "../../../../common/utils";
import {
	IResponseWithCustomValue,
	IResponseWithoutValue,
	SubscriptionPlan,
} from "../../../../common/types";
import API from "../../../../common/api/api.base";

function* workerGetLevels(
	action: ReturnType<typeof getLevelsForSubscriptionsRequest>,
) {
	try {
		const result: IResponseWithCustomValue<{
			subscriptionPlans: SubscriptionPlan[];
		}> = yield call(
			API.get,
			`/api/v1/plans?${
				action.payload.searchQuery
					? `SearchQuery=${action.payload.searchQuery}`
					: ""
			}${
				action.payload.statusId
					? `StatusId=${action.payload.statusId}`
					: ""
			}`,
		);
		if (result.success) {
			yield put(
				getLevelsForSubscriptionsSuccess(
					result.value.subscriptionPlans,
				),
			);
		} else {
			yield put(getLevelsForSubscriptionsError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerAddLevel(action: ReturnType<typeof addLevel>) {
	try {
		const {
			Name,
			Price,
			Interval,
			MinGrade,
			MaxGrade,
			WorkingHours,
			StartDate,
			EndDate,
			ExistingCourseIds,
			ColorValue,
			Achievements,
			BulletPoints,
			IsFree,
		} = action.payload.params;
		const response: IResponseWithCustomValue<{ value: number }> =
			yield call(
				API.post,
				`/api/v1/plans/add?Name=${Name}&Price=${Price}&Interval=${Interval}
		    &MinGrade=${MinGrade}&MaxGrade=${MaxGrade}&IsFree=${Boolean(
					IsFree,
				)}&WorkingHoursJson=${JSON.stringify(WorkingHours)}
				&ColorValue=${ColorValue?.replace("#", "")}${
					Achievements
						? Achievements.map(
								(elem) => `&Achievements=${elem}`,
						  ).join("")
						: ""
				}${
					BulletPoints
						? BulletPoints?.map(
								(elem) => `&BulletPoints=${elem}`,
						  ).join("")
						: ""
				}&StartDate=${StartDate}&EndDate=${EndDate}${ExistingCourseIds.map(
					(elem) => `&ExistingClassIds=${elem}`,
				).join("")}`,
				action.payload.imageFile,
			);
		if (response.success) {
			yield put(addLevelSuccess());
			yield Alerter.success("Subscription successfully added");
			yield workerGetLevels(
				getLevelsForSubscriptionsRequest({
					searchQuery: action.payload.searchQuery,
					statusId: action.payload.withArchivedFilter ? 3 : 2,
				}),
			);
		} else {
			yield put(addLevelError(response.errors));
		}
	} catch (error) {
		Alerter.error(error);
	}
}

//DELETE WORKER
function* workerDeleteLevel(action: ReturnType<typeof deleteLevelRequest>) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.delete,
			`/api/v1/plans/${action.payload.id}/delete`,
		);
		if (result.success) {
			yield put(deleteLevelSuccess());
			Alerter.success("Subscription successfully deleted");
			yield workerGetLevels(
				getLevelsForSubscriptionsRequest({
					searchQuery: action.payload.searchQuery,
					statusId: action.payload.withArchivedFilter ? 3 : 2,
				}),
			);
		} else {
			yield put(deleteLevelError(result.errors));
			throw result;
		}
	} catch (err) {
		Alerter.error(err);
	}
}

function* workerArchiveLevel(action: ReturnType<typeof archiveLevelRequest>) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			`/api/v1/plans/${action.payload.id}/update-active-status`,
			{ setActive: false },
		);
		if (result.success) {
			yield put(archiveLevelSuccess());
			Alerter.success("Level successfully archived");
			yield workerGetLevels(
				getLevelsForSubscriptionsRequest({
					searchQuery: action.payload.searchQuery,
					statusId: action.payload.withArchivedFilter ? 3 : 2,
				}),
			);
		} else {
			yield put(archiveLevelError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerEditLevelDates(
	action: ReturnType<typeof editLevelDatesRequest>,
) {
	try {
		const result: IResponseWithoutValue = yield call(
			API.put,
			`/api/v1/plans/${action.payload.id}/update-dates`,
			{
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			},
		);
		if (result.success) {
			yield put(editLevelDatesSuccess());
			Alerter.success("Dates successfulle edited");
		} else {
			yield put(editLevelDatesError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

function* workerEditLevel(action: ReturnType<typeof editLevelRequest>) {
	try {
		const {
			Achievements,
			BulletPoints,
			ColorValue,
			ClassesIds,
			MaxGrade,
			MinGrade,
			Name,
			WorkingHours,
			Id,
			NewOrderIndex,
		} = action.payload.params;
		const result: IResponseWithoutValue = yield call(
			API.put,
			`/api/v1/plans/${Id}/edit?Name=${Name}&MinGrade=${MinGrade}&MaxGrade=${MaxGrade}&NewOrderIndex=${NewOrderIndex}&WorkingHoursJson=${JSON.stringify(
				WorkingHours,
			)}
				&ColorValue=${ColorValue?.replace("#", "")}${
				Achievements
					? Achievements.map((elem) => `&Achievements=${elem}`).join(
							"",
					  )
					: ""
			}${
				BulletPoints
					? BulletPoints?.map((elem) => `&BulletPoints=${elem}`).join(
							"",
					  )
					: ""
			}${ClassesIds.map((elem) => `&ClassesIds=${elem}`).join("")}`,
			action.payload.imageFile,
		);
		if (result.success) {
			yield put(editLevelSuccess());
			Alerter.success("Successfully edited");
		} else {
			yield put(editLevelError(result.errors));
			throw result;
		}
	} catch (error) {
		Alerter.error(error);
	}
}

// prettier-ignore
export default function* watchSubscriptionLevelsSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
        yield takeEvery(addLevel.type, workerAddLevel);
        yield takeEvery(getLevelsForSubscriptionsRequest.type, workerGetLevels);
        yield takeEvery(deleteLevelRequest.type, workerDeleteLevel);
        yield takeEvery(archiveLevelRequest.type, workerArchiveLevel);
		yield takeEvery(editLevelDatesRequest.type, workerEditLevelDates);
		yield takeEvery(editLevelRequest.type, workerEditLevel);
	}
