import { useEffect } from "react";
import { Box, VStack, Text } from "@chakra-ui/react";
import { InputText, RippleButton } from "../../../common/components";
import { Resolver, useForm } from "react-hook-form";
import { ForgotPasswordPayload, PayloadError } from "../../../common/types";
import { restorePasswordSchema } from "../../../common/constants/validation";

interface IForgotPasswordForm {
	onForgotPassword: (value: { email: string }) => void;
	loading: boolean;
	stateErrors: PayloadError[];
}

export const ForgotPasswordForm: React.FC<IForgotPasswordForm> = ({
	onForgotPassword,
	loading,
	stateErrors,
}) => {
	const {
		handleSubmit,
		formState: { errors },
		register,
		setError,
		clearErrors,
	} = useForm<ForgotPasswordPayload>({
		resolver: restorePasswordSchema as Resolver<ForgotPasswordPayload>,
	});

	const onSubmit = (data: ForgotPasswordPayload) => {
		onForgotPassword(data);
		// reset({});
	};

	useEffect(() => {
		if (stateErrors.length) {
			setError("email", {
				type: "manual",
				message: stateErrors[0].errorMessage,
			});
		} else {
			clearErrors(undefined);
		}
	}, [stateErrors, setError, clearErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<VStack>
					<Box w={"100%"}>
						<Text
							textAlign={"left"}
							variant="body2"
							color="darkGrey">
							Enter your email and we’ll send you a link to reset
							your password
						</Text>
						<InputText
							type={"email"}
							{...register("email")}
							errorMsg={errors.email?.message}
							label={"E-Mail"}
							placeholder={"E-Mail"}
						/>
					</Box>
					<RippleButton
						type={"submit"}
						w={"100%"}
						isLoading={loading}>
						Send Link to Email
					</RippleButton>
				</VStack>
			</form>
		</Box>
	);
};
