/* eslint-disable indent */
import React, { useState } from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface IInputPhoneProps extends PhoneInputProps {
	errorMsg?: string;
	label: string;
	onChange: (value: any) => void;
	name: string;
	readOnly?: boolean;
}

export const InputPhone = React.forwardRef<null, IInputPhoneProps>(
	({ errorMsg, label, onChange, name, readOnly, ...rest }, ref) => {
		const [focused, setFocused] = useState(false);

		return (
			<FormControl isInvalid={!!errorMsg} mb={!errorMsg ? "29px" : 0}>
				<FormLabel
					fontSize="caption2"
					lineHeight="22px"
					color="darkest"
					marginBottom={0}>
					{label}
				</FormLabel>
				<PhoneInput
					country={"us"}
					placeholder="(201) 555-0123"
					containerClass={`phone_input_container ${
						errorMsg ? "phone_input_error" : ""
					}`}
					inputClass="phone_input_self_input"
					buttonClass="phone_input_button"
					dropdownClass="phone_input_dropdown"
					onFocus={(ev, data) => {
						rest?.onFocus?.(ev, data);
						setFocused(true);
					}}
					onChange={(value, country, e) => {
						onChange?.(e);
					}}
					inputProps={{ name, readOnly }}
					{...rest}
					onBlur={(ev, data) => {
						rest?.onBlur?.(ev, data);
						setFocused(false);
					}}
					inputStyle={{
						boxShadow: errorMsg
							? "0 0 0 1px #e53e3e"
							: focused
							? "0 0 0 1px #8C4BC9"
							: "",
						borderColor: errorMsg
							? "#e53e3e"
							: focused
							? "#8C4BC9"
							: "",
						width: "100%",
					}}
					buttonStyle={{
						borderColor: errorMsg
							? "#e53e3e"
							: focused
							? "#8C4BC9"
							: "",
					}}
				/>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);

InputPhone.displayName = "InputPhone";
