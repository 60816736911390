import { StaffTab } from "./StaffTab";
import { StaffItem as StaffItemType } from "../../types";

interface IStaffTabContainerProps {
	staffItems: StaffItemType[];
	setShowEditEmployeeModal: (value: boolean) => void;
	setToEditEmployee: (value: StaffItemType) => void;
	staffLoading: boolean;
	getMoreEmployees: () => void;
	staffLoadMoreLoading: boolean;
	staffTotalCount: number;
	setShowInviteEmployeeModal: (value: boolean) => void;
}

export const StaffTabContainer: React.FC<IStaffTabContainerProps> = ({
	getMoreEmployees,
	setShowEditEmployeeModal,
	setShowInviteEmployeeModal,
	setToEditEmployee,
	staffItems,
	staffLoadMoreLoading,
	staffLoading,
	staffTotalCount,
}) => {
	return (
		<>
			<StaffTab
				getMoreEmployees={getMoreEmployees}
				setShowEditEmployeeModal={setShowEditEmployeeModal}
				setShowInviteEmployeeModal={setShowInviteEmployeeModal}
				setToEditEmployee={setToEditEmployee}
				staffItems={staffItems}
				staffLoadMoreLoading={staffLoadMoreLoading}
				staffLoading={staffLoading}
				staffTotalCount={staffTotalCount}
			/>
		</>
	);
};
