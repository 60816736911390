import * as yup from "yup";
import { ErrorMessages } from "./messages";
import { yupResolver } from "@hookform/resolvers/yup";

const loginSchemaResolver = yup.object().shape({
	email: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.email(ErrorMessages.EMAIL_INVALID),
	password: yup.string().required(ErrorMessages.REQUIRED),
});

export const loginSchema = yupResolver(loginSchemaResolver);
