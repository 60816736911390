import { Center, Image, Flex, Text } from "@chakra-ui/react";
import PlaceholderImg from "../../../../../../../assets/pic_users-placeholder.png";

export const InvoicesPlaceholder: React.FC = () => (
	<Center height="500px">
		<Flex direction="column" align="center">
			<Image src={PlaceholderImg} />
			<Text
				variant="body2"
				color="darkGrey"
				w={{ base: "auto", lg: "400px" }}
				textAlign="center"
				mt="20px">
				There is no invoices here yet
			</Text>
		</Flex>
	</Center>
);
