import { createReducer } from "@reduxjs/toolkit";
import { PayloadError, SubscriptionPlan } from "../../../../common/types";
import {
	addLevel,
	addLevelSuccess,
	addLevelError,
	getLevelsForSubscriptionsRequest,
	getLevelsForSubscriptionsSuccess,
	getLevelsForSubscriptionsError,
	deleteLevelSuccess,
	deleteLevelRequest,
	deleteLevelError,
	archiveLevelRequest,
	archiveLevelSuccess,
	archiveLevelError,
	clearSubscriptions,
	editLevelDatesRequest,
	editLevelDatesSuccess,
	editLevelDatesError,
	editLevelRequest,
	editLevelSuccess,
	editLevelError,
} from "../actions";

type InitialState = {
	addLevel: {
		loading: boolean;
		errors: PayloadError[];
	};
	addFreeLevel: {
		loading: boolean;
		errors: PayloadError[];
	};
	levels: {
		items: SubscriptionPlan[];
		errors: PayloadError[];
		loading: boolean;
	};
	deleteLevel: {
		loading: boolean;
		errors: PayloadError[];
	};
	archiveLevel: {
		loading: boolean;
		errors: PayloadError[];
	};
	editDates: {
		loading: boolean;
		errors: PayloadError[];
	};
	editLevel: {
		loading: boolean;
		errors: PayloadError[];
	};
};

const initialState: InitialState = {
	addLevel: {
		loading: false,
		errors: [],
	},
	addFreeLevel: {
		errors: [],
		loading: false,
	},
	levels: {
		items: [],
		errors: [],
		loading: false,
	},
	deleteLevel: {
		loading: false,
		errors: [],
	},
	archiveLevel: {
		loading: false,
		errors: [],
	},
	editDates: {
		loading: false,
		errors: [],
	},
	editLevel: {
		loading: false,
		errors: [],
	},
};

export const levelsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(addLevel, (state) => {
			state.addLevel.loading = true;
		})
		.addCase(addLevelSuccess, (state) => {
			state.addLevel.loading = false;
			state.addLevel.errors = [];
		})
		.addCase(addLevelError, (state, action) => {
			state.addLevel.loading = false;
			state.addLevel.errors = action.payload;
		})
		.addCase(getLevelsForSubscriptionsRequest, (state) => {
			state.levels.loading = true;
		})
		.addCase(getLevelsForSubscriptionsSuccess, (state, action) => {
			state.levels.loading = false;
			state.levels.items = action.payload;
			state.levels.errors = [];
		})
		.addCase(getLevelsForSubscriptionsError, (state, action) => {
			state.levels.loading = false;
			state.levels.errors = action.payload;
		})
		.addCase(deleteLevelRequest, (state) => {
			state.deleteLevel.loading = true;
		})
		.addCase(deleteLevelSuccess, (state) => {
			state.deleteLevel.loading = false;
			state.deleteLevel.errors = [];
		})
		.addCase(deleteLevelError, (state, action) => {
			state.deleteLevel.loading = false;
			state.deleteLevel.errors = action.payload;
		})
		.addCase(archiveLevelRequest, (state) => {
			state.archiveLevel.loading = true;
			state.archiveLevel.errors = [];
		})
		.addCase(archiveLevelSuccess, (state) => {
			state.archiveLevel.loading = false;
		})
		.addCase(archiveLevelError, (state, action) => {
			state.archiveLevel.loading = false;
			state.archiveLevel.errors = action.payload;
		})
		.addCase(editLevelDatesRequest, (state) => {
			state.editDates.loading = true;
			state.editDates.errors = [];
		})
		.addCase(editLevelDatesSuccess, (state) => {
			state.editDates.loading = false;
		})
		.addCase(editLevelDatesError, (state, action) => {
			state.editDates.loading = false;
			state.editDates.errors = action.payload;
		})
		.addCase(editLevelRequest, (state) => {
			state.editLevel.errors = [];
			state.editLevel.loading = true;
		})
		.addCase(editLevelSuccess, (state) => {
			state.editLevel.loading = false;
		})
		.addCase(editLevelError, (state, action) => {
			state.editLevel.errors = action.payload;
			state.editLevel.loading = false;
		})
		.addCase(clearSubscriptions, () => {
			return { ...initialState };
		});
});
