import { Box, HStack, Text, Heading } from "@chakra-ui/react";

interface IPresenseStatusProps {
	icon: JSX.Element;
	bgColor: string;
	textColor: string;
	amount: number;
	title: string;
}

export const PresenseStatus: React.FC<IPresenseStatusProps> = ({
	amount,
	bgColor,
	icon,
	textColor,
	title,
}) => {
	return (
		<Box
			bg={{ base: "white", lg: bgColor }}
			p={{ base: "14px", lg: "16px" }}
			borderRadius="8px"
			boxShadow={{ base: "base", lg: "none" }}
			w="100%">
			<HStack mb="30px">
				{icon}
				<Text variant="body3" fontWeight="500" color={textColor}>
					{title}
				</Text>
			</HStack>
			<HStack align="baseline">
				<Heading as="p" variant="h7" color={textColor}>
					{amount}
				</Heading>
				<Text variant="caption3" color={textColor}>
					Students
				</Text>
			</HStack>
		</Box>
	);
};
