import { put, takeEvery, ForkEffect } from "redux-saga/effects";
import { logoutSuccess } from "../../actions/auth.actions";
import { clearDashboard } from "../../../pages/Dashboard/store/actions";
import { clearSubscriptions } from "./../../../pages/Subscriptions/store/actions";
import {clearSettings} from "../../../pages/Settings/store/actions";

function* workerLogout() {
	yield put(clearDashboard());
	yield put(clearSubscriptions());
	yield put(clearSettings());
}

// prettier-ignore
export default function* watchLogoutSaga(): Generator<
	ForkEffect<never>,
	void,
	unknown
	> {
	yield takeEvery(logoutSuccess.type, workerLogout);
}
