import { Box, Grid } from "@chakra-ui/react";
import { DashboardHeader } from "../../common/sections";
import { StaffCard, ParticipantsCard } from "./cards";
import { SubscriptionPlan } from "../../common/types";
import { SelectedFilters, StaffItem, StudentResponseType } from "./types";

interface IDashboardProps {
	setShowInviteEmployeeModal: (value: boolean) => void;
	setShowEditEmployeeModal: (value: boolean) => void;
	setShowStudentInfoModal: (value: boolean) => void;
	filterClasses: SubscriptionPlan[];
	setSelectedFilters: (value: SelectedFilters) => void;
	selectedFilters: SelectedFilters;
	staffLoading: boolean;
	staffItems: StaffItem[];
	setToEditEmployee: (value: StaffItem) => void;
	tabIndex: number;
	setTabIndex: (value: number) => void;
	sortOrderAsc: boolean;
	setSortOrderAsc: (value: boolean) => void;
	setSearchQuery: (value: string) => void;
	studentsLoading: boolean;
	studentItems: StudentResponseType[];
	setStudentInfo: (value: StudentResponseType) => void;
	parentsTotalCount: number;
	studentsTotalCount: number;
	staffTotalCount: number;
	getMoreEmployees: () => void;
	staffLoadMoreLoading: boolean;
	studentsLoadMoreLoading: boolean;
	getMoreStudents: () => void;
	getMoreParents: () => void;
}

export const Dashboard: React.FC<IDashboardProps> = ({
	setShowInviteEmployeeModal,
	setShowEditEmployeeModal,
	setShowStudentInfoModal,
	filterClasses,
	selectedFilters,
	setSelectedFilters,
	staffItems,
	staffLoading,
	setToEditEmployee,
	setSearchQuery,
	setSortOrderAsc,
	setTabIndex,
	sortOrderAsc,
	tabIndex,
	studentItems,
	studentsLoading,
	setStudentInfo,
	parentsTotalCount,
	studentsTotalCount,
	staffTotalCount,
	getMoreEmployees,
	staffLoadMoreLoading,
	getMoreStudents,
	studentsLoadMoreLoading,
	getMoreParents,
}) => {
	return (
		<Box h="100vh" overflowY="auto" bg={{ base: "#F9F9FB", lg: "white" }}>
			<DashboardHeader title="Dashboard" description="" />
			<Box
				px={{ base: "20px", lg: "40px" }}
				mt={{ base: "-30px", lg: "-50px" }}
				width={{ base: "auto", lg: "calc(100vw - 250px)" }}>
				<Grid
					templateColumns={{ base: "auto", lg: "60% 1fr" }}
					templateRows="1fr"
					gridGap="24px">
					<ParticipantsCard
						setShowStudentInfoModal={setShowStudentInfoModal}
						setShowEditEmployeeModal={setShowEditEmployeeModal}
						filterClasses={filterClasses}
						selectedFilters={selectedFilters}
						setSelectedFilters={setSelectedFilters}
						setShowInviteEmployeeModal={setShowInviteEmployeeModal}
						staffItems={staffItems}
						staffLoading={staffLoading}
						setToEditEmployee={setToEditEmployee}
						tabIndex={tabIndex}
						setTabIndex={setTabIndex}
						sortOrderAsc={sortOrderAsc}
						setSortOrderAsc={setSortOrderAsc}
						setSearchQuery={setSearchQuery}
						studentsLoading={studentsLoading}
						studentItems={studentItems}
						setStudentInfo={setStudentInfo}
						parentsTotalCount={parentsTotalCount}
						studentsTotalCount={studentsTotalCount}
						staffTotalCount={staffTotalCount}
						getMoreEmployees={getMoreEmployees}
						staffLoadMoreLoading={staffLoadMoreLoading}
						studentsLoadMoreLoading={studentsLoadMoreLoading}
						getMoreStudents={getMoreStudents}
						getMoreParents={getMoreParents}
					/>
					<StaffCard
						items={staffItems}
						loading={staffLoading}
						setShowInviteEmployeeModal={setShowInviteEmployeeModal}
						setShowEditEmployeeModal={setShowEditEmployeeModal}
						setToEditEmployee={setToEditEmployee}
						getMoreEmployees={getMoreEmployees}
						staffLoadMoreLoading={staffLoadMoreLoading}
						staffTotalCount={staffTotalCount}
					/>
					<Box h="100px" display={{ base: "block", lg: "none" }} />
				</Grid>
			</Box>
		</Box>
	);
};
