import React from "react";
import {
	FormControlProps,
	FormControl,
	FormLabel,
	InputProps,
	FormErrorMessage,
	useDisclosure,
	Button,
	Text,
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@chakra-ui/react";
import { CalendarIcon } from "../../../assets/svg-components";
import { CalendarForDatePicker } from "../CalendarForDatePicker/CalendarForDatePicker";

interface IDatePicker extends InputProps {
	formControlProps?: FormControlProps;
	errMessage?: string;
	label: string;
	setValue: (value: number) => void;
	displayValue: string;
	isDisabled?: boolean;
	value: number;
}

export const DatePicker = React.forwardRef<null, IDatePicker>(
	(
		{
			formControlProps,
			errMessage,
			label,
			setValue,
			displayValue,
			isDisabled = false,
			value,
			...rest
		},
		ref,
	) => {
		const { onOpen, onClose, isOpen } = useDisclosure();
		return (
			<Popover
				placement={"bottom-end"}
				onOpen={onOpen}
				onClose={onClose}
				isOpen={isOpen}>
				<PopoverTrigger>
					<FormControl isInvalid={!!errMessage} {...formControlProps}>
						<FormLabel
							fontSize="caption2"
							lineHeight="22px"
							color="darkest"
							marginBottom={0}
							opacity={isDisabled ? 0.4 : 1}>
							{label}
						</FormLabel>
						<Button
							onClick={onOpen}
							variant="unstyled"
							borderRadius="8px"
							paddingLeft="12px"
							paddingRight="12px"
							boxShadow={errMessage ? "0 0 0 1px #e53e3e" : ""}
							border={`1px solid ${
								errMessage ? "#e53e3e" : "lightGrey"
							}`}
							isDisabled={isDisabled}
							w="100%"
							d="flex"
							justifyContent="space-between"
							height="40px"
							_focus={{
								boxShadow: "none",
								border: "1px solid #8C4BC9",
							}}
							mb={errMessage ? 0 : "29px"}>
							<Text
								color={displayValue ? "darkest" : "grey"}
								fontSize="14px"
								lineHeight="22px"
								fontWeight="normal">
								{displayValue ? displayValue : rest.placeholder}
							</Text>
							<CalendarIcon />
						</Button>
						<FormErrorMessage>{errMessage}</FormErrorMessage>
					</FormControl>
				</PopoverTrigger>

				<PopoverContent boxShadow={"none"} border={"none"}>
					<CalendarForDatePicker
						setValue={setValue}
						onClose={onClose}
						value={value}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
DatePicker.displayName = "DatePicker";
