import { PayloadError } from "../../../../common/types";
import { createReducer } from "@reduxjs/toolkit";
import {
	sendBulkMessageRequest,
	sendBulkMessageSuccess,
	sendBulkMessageError,
	sendIndividualMessageRequest,
	sendIndividualMessageSuccess,
	sendIndividualMessageError,
	clearMessages,
} from "../actions";

type InitialState = {
	bulkMessage: {
		loading: boolean;
		errors: PayloadError[];
	};
	individualMessage: {
		loading: boolean;
		errors: PayloadError[];
	};
};

const initialState: InitialState = {
	bulkMessage: {
		loading: false,
		errors: [],
	},
	individualMessage: {
		loading: false,
		errors: [],
	},
};

const broadcastReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(sendBulkMessageRequest, (state) => {
			state.bulkMessage.loading = true;
			state.bulkMessage.errors = [];
		})
		.addCase(sendBulkMessageSuccess, (state) => {
			state.bulkMessage.loading = false;
		})
		.addCase(sendBulkMessageError, (state) => {
			state.bulkMessage.loading = false;
			// state.bulkMessage.errors = action.payload
		})
		.addCase(sendIndividualMessageRequest, (state) => {
			state.individualMessage.loading = true;
			state.individualMessage.errors = [];
		})
		.addCase(sendIndividualMessageSuccess, (state) => {
			state.individualMessage.loading = false;
		})
		.addCase(sendIndividualMessageError, (state) => {
			state.individualMessage.loading = false;
			// state.individualMessage.errors = action.payload
		})
		.addCase(clearMessages, () => {
			return { ...initialState };
		});
});
export default broadcastReducer;
